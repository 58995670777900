import { handleException } from 'sentry-browser-shared';
import {
  type WorkflowNode,
  BranchData,
  BranchModeEnum,
  NodeTypesEnum,
  RequestMethodEnum,
  Rule,
  TemplateVariable,
  WorkflowConditionalNode,
  WorkflowContinueNode,
  WorkflowDocumentNode,
  WorkflowEmailNode,
  WorkflowFreeformNode,
  WorkflowImageNode,
  WorkflowNewNode,
  WorkflowRequestNode,
  WorkflowRetryNode,
  WorkflowSourceNode,
  WorkflowStopNode,
  WorkflowTemporalNode,
} from 'types-shared';
import {
  filterErrors,
  handleValidateSingleRule,
  templateDataHasContent,
} from './helpers';
import {
  type BranchValidations,
  type ConditionalNodeValidationResult,
  type ConditionalValidationFunction,
  type EmailNodeValidationResult,
  type EmailValidationFunction,
  type FreeformNodeValidationResult,
  type FreeformValidationFunction,
  type ImageNodeValidationResult,
  type ImageValidationFunction,
  type RequestNodeValidationResult,
  type RequestValidationFunction,
  type RetryNodeValidationResult,
  type RetryValidationFunction,
  type ValidationFunction,
  type ValidationResult,
} from './types';
import handleValidateAction from './validateAction';

import {
  branchMissingContentMessage,
  defaultErrorMessage,
  emailNodeVariableMissingError,
  freeformNodeVariableMissingError,
  imageNodeBrokenActionMessage,
  requestNodeVariableMissingError,
} from './constants';

export * from './types';

const skipValidation = (node: WorkflowNode) => {
  return node.hideFromUser;
};

const validateFreeformNode: FreeformValidationFunction<WorkflowNode> = ({
  node,
  variableMap,
  globalVariablesMap,
  workflowId,
}): FreeformNodeValidationResult => {
  const errors: string[] = [];
  const validations: FreeformNodeValidationResult['validations'] = {
    instructionsMissing: false,
    nodeInvalid: false,
  };

  // ✅ Validate the node schema using Zod
  const nodeParse = WorkflowFreeformNode.safeParse(node);
  if (!nodeParse.success) {
    handleException(nodeParse.error, {
      name: 'WorkflowFreeformNode is not valid.',
      source: 'validateFreeformNode',
      extra: { node, workflowId },
    });
    errors.push(defaultErrorMessage);
    validations.nodeInvalid = true;
    return { errors, validations };
  }

  if (skipValidation(node)) {
    return {
      errors,
      validations,
    };
  }

  const freeformNode = nodeParse.data;

  // ✅ Validate the instructions variable
  const instructionsVariable =
    variableMap[freeformNode.data.instructions.variableId];
  const instructionsParse = TemplateVariable.safeParse(instructionsVariable);
  if (!instructionsParse.success) {
    handleException(instructionsParse.error, {
      name: 'Instructions variable is not valid.',
      source: 'validateFreeformNode',
      extra: { node, instructionsVariable, workflowId },
    });
    errors.push(defaultErrorMessage);
    validations.instructionsMissing = true;
    return { errors, validations };
  }

  // ✅ Check if content exists
  if (
    !templateDataHasContent(
      instructionsParse.data.data,
      variableMap,
      globalVariablesMap,
      (err) => {
        if (!errors.includes(err)) {
          errors.push(err);
        }
      },
    )
  ) {
    errors.push(freeformNodeVariableMissingError);
    validations.instructionsMissing = true;
  }

  return {
    errors: filterErrors(errors, freeformNodeVariableMissingError),
    validations,
  };
};

const validateConditionalNode: ConditionalValidationFunction<WorkflowNode> = ({
  node,
  variableMap,
  globalVariablesMap,
  workflowId,
}): ConditionalNodeValidationResult => {
  const payload: ConditionalNodeValidationResult = {
    errors: [],
    validations: { nodeInvalid: false },
  };
  const nodeParse = WorkflowConditionalNode.safeParse(node);
  if (!nodeParse.success) {
    handleException(nodeParse.error, {
      name: 'Conditional node is not valid.',
      source: 'validateConditionalNode',
      extra: { node, workflowId },
    });
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  if (skipValidation(node)) {
    return { errors: [], validations: { nodeInvalid: false } };
  }

  const conditionalNode = nodeParse.data;

  const branchesValidation = conditionalNode.data.branchesData?.map((branch) =>
    BranchData.safeParse(branch),
  );

  const allBranchesValid = branchesValidation?.every((b) => b.success);

  if (!allBranchesValid) {
    const invalidBranches = branchesValidation?.filter((b) => !b.success);
    handleException(new Error('Conditional node has invalid branches.'), {
      name: 'Conditional node is not valid.',
      source: 'validateConditionalNode',
      extra: { node, invalidBranches, workflowId },
    });
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  //   Check if branches have content.
  const branchContentValidations: BranchValidations = {};

  conditionalNode.data.branchesData?.forEach((branch) => {
    branchContentValidations[branch.branchId] = {
      branchInvalid: false,
    };
    const branchMode = branch.selectedMode;

    if (branchMode === BranchModeEnum.Rule) {
      const ruleParse = Rule.safeParse(branch.rule);
      if (!ruleParse.success) {
        handleException(ruleParse.error, {
          name: 'Rule in branch is not valid.',
          source: 'validateConditionalNode',
          extra: { node, rule: branch.rule, workflowId },
        });
        payload.validations.nodeInvalid = true;
        payload.errors.push(defaultErrorMessage);
        return payload;
      }

      // Check if rule has content.
      const ruleValidations = handleValidateSingleRule({
        rule: ruleParse.data,
        variableMap,
        globalVariablesMap,
        workflowId,
        node,
        handleCriticalError: () => {
          payload.validations.nodeInvalid = true;
          payload.errors.push(defaultErrorMessage);
        },
      });

      branchContentValidations[branch.branchId] = {
        ...branchContentValidations[branch.branchId],
        ...ruleValidations,
      };
    }
    if (branchMode === BranchModeEnum.Instruction) {
      branchContentValidations[branch.branchId][branch.instruction.variableId] =
        false;
      payload.validations.branchContentValidations = branchContentValidations;
    }
  });

  Object.entries(branchContentValidations).forEach(([branchId, variables]) => {
    Object.keys(variables).forEach((variableId) => {
      // Early return - skips validation for branchInvalid flag
      if (variableId === 'branchInvalid') return;

      const thisBranch = conditionalNode.data.branchesData?.find(
        (b) => b.branchId === branchId,
      );

      // If the branch is a rule, the validation is already done in the previous loop using handleValidateSingleRule
      if (thisBranch?.selectedMode === BranchModeEnum.Rule) {
        const thisBranchIsInvalid = Object.values(
          branchContentValidations[branchId],
        ).some((v) => v);

        if (thisBranchIsInvalid) {
          if (!payload.errors.includes(branchMissingContentMessage)) {
            payload.errors.push(branchMissingContentMessage);
          }
          branchContentValidations[branchId].branchInvalid = true;
        }
        return;
      }

      const variable = variableMap[variableId];
      const variableParse = TemplateVariable.safeParse(variable);

      if (variableParse.success) {
        const resultCheck = templateDataHasContent(
          variableParse.data.data,
          variableMap,
          globalVariablesMap,
          (err) => {
            if (!payload.errors.includes(err)) {
              payload.errors.push(err);
            }
          },
        );
        if (!resultCheck) {
          if (!payload.errors.includes(branchMissingContentMessage)) {
            payload.errors.push(branchMissingContentMessage);
          }

          branchContentValidations[branchId].branchInvalid = true;
        }
        branchContentValidations[branchId][variableId] = resultCheck;
      }
      if (!variableParse.success) {
        handleException(variableParse.error, {
          name: 'Template variable is not valid.',
          source: 'validateConditionalNode',
          extra: { variableId, variable, node, branchId, workflowId },
        });
        payload.validations.nodeInvalid = true;
        branchContentValidations[branchId].branchInvalid = true;
        payload.errors.push(defaultErrorMessage);
        branchContentValidations[branchId][variableId] = false;
      }
    });
  });

  payload.validations.branchContentValidations = branchContentValidations;
  return {
    ...payload,
    errors: filterErrors(payload.errors, branchMissingContentMessage),
  };
};

const validateImageNode: ImageValidationFunction<WorkflowNode> = ({
  node,
  workflowId,
  variableMap,
  globalVariablesMap,
}): ImageNodeValidationResult => {
  const nodeParse = WorkflowImageNode.safeParse(node);
  if (!nodeParse.success) {
    handleException(nodeParse.error, {
      name: 'Image node is not valid.',
      source: 'validateImageNode',
      extra: { node, workflowId },
    });
    return {
      errors: [defaultErrorMessage],
      validations: {
        nodeInvalid: true,
        actionValidations: {},
        variablesThatHaveNoContent: {},
      },
    };
  }

  if (skipValidation(node)) {
    return {
      errors: [],
      validations: {
        nodeInvalid: false,
        actionValidations: {},
        variablesThatHaveNoContent: {},
      },
    };
  }

  const payload: ImageNodeValidationResult = {
    errors: [],
    validations: {
      nodeInvalid: false,
      actionValidations: {},
      variablesThatHaveNoContent: {},
    },
  };

  const imageNode = nodeParse.data;

  Object.values(imageNode.data.actionData).forEach((action) => {
    const actionCheck = handleValidateAction({
      action,
      node: imageNode,
      workflowId,
      variableMap,
      globalVariablesMap,
    });

    if (actionCheck.actionMalformed) {
      payload.validations.nodeInvalid = true;
    }
    if (actionCheck.error) {
      payload.errors.push(actionCheck.error);
    }

    if (actionCheck.templateVariablesToValidate) {
      actionCheck.templateVariablesToValidate.forEach((variableId) => {
        const varHasContent = templateDataHasContent(
          (variableMap[variableId] as TemplateVariable).data,
          variableMap,
          globalVariablesMap,
          (err) => {
            if (!payload.errors.includes(err)) {
              payload.errors.push(err);
            }
          },
        );
        payload.validations.variablesThatHaveNoContent[variableId] =
          !varHasContent;

        if (
          !varHasContent &&
          !payload.errors.includes(imageNodeBrokenActionMessage)
        ) {
          payload.errors.push(imageNodeBrokenActionMessage);
        }
        if (!varHasContent) {
          payload.validations.actionValidations[action.id] = true;
        }
      });
    }

    if (actionCheck.actionInvalid) {
      payload.validations.actionValidations[action.id] =
        actionCheck.actionInvalid;
    } else if (!payload.validations.actionValidations[action.id]) {
      payload.validations.actionValidations[action.id] = false;
    }

    if (actionCheck.validatedVariables) {
      Object.entries(actionCheck.validatedVariables).forEach(
        ([variableId, isInvalid]) => {
          payload.validations.variablesThatHaveNoContent[variableId] =
            isInvalid;
          if (isInvalid) {
            payload.errors.push(imageNodeBrokenActionMessage);
            payload.validations.actionValidations[action.id] = true;
          }
          if (actionCheck.error) {
            payload.errors.push(actionCheck.error);
            payload.validations.actionValidations[action.id] = true;
          }
        },
      );
    }
  });

  if (payload.validations.nodeInvalid) {
    payload.errors = [defaultErrorMessage];
    return payload;
  }

  return {
    ...payload,
    errors: filterErrors(payload.errors, imageNodeBrokenActionMessage),
  };
};

const validateNewNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowNewNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [defaultErrorMessage], validations: { nodeInvalid: true } };
};

const validateRequestNode: RequestValidationFunction<WorkflowNode> = ({
  node,
  variableMap,
  globalVariablesMap,
  workflowId,
}): RequestNodeValidationResult => {
  const nodeParse = WorkflowRequestNode.safeParse(node);

  const payload: RequestNodeValidationResult = {
    errors: [],
    validations: { nodeInvalid: false, variableValidations: {} },
  };

  if (!nodeParse.success) {
    handleException(nodeParse.error, {
      name: 'Request node is not valid.',
      source: 'validateRequestNode',
      extra: { node, workflowId },
    });
    payload.errors = [defaultErrorMessage];
    payload.validations.nodeInvalid = true;
    return payload;
  }

  if (skipValidation(node)) {
    return payload;
  }
  const requestNode = nodeParse.data;

  const urlVariableId = requestNode.data.url.variableId;
  const urlParameters = requestNode.data.urlParameters
    .map((param) => [param.key.variableId, param.value.variableId])
    .flat();
  const requestHeaders = requestNode.data.headers
    .map((header) => [header.key.variableId, header.value.variableId])
    .flat();
  let requestBody: string[] = [];
  if (requestNode.data.method !== RequestMethodEnum.Get) {
    requestBody = Array.isArray(requestNode.data.body)
      ? requestNode.data.body
          .map((body) => [body.key.variableId, body.value.variableId])
          .flat()
      : [requestNode.data.body.variableId];
  }

  const authKey = requestNode.data.auth.authKey.enabled
    ? [requestNode.data.auth.authKey.value.variableId]
    : [];
  const authForUserId = requestNode.data.auth.forUserId.enabled
    ? [requestNode.data.auth.forUserId.value.variableId]
    : [];

  const variableIds = [
    urlVariableId,
    ...urlParameters,
    ...requestHeaders,
    ...requestBody,
    ...authKey,
    ...authForUserId,
  ]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    .filter((id) => id !== undefined);

  payload.validations.variableValidations = {};
  variableIds.forEach((variableId) => {
    const variableCheck = TemplateVariable.safeParse(variableMap[variableId]);

    if (!variableCheck.success) {
      payload.errors = [defaultErrorMessage];
      payload.validations.nodeInvalid = true;
      return payload;
    }

    payload.validations.variableValidations[variableId] =
      templateDataHasContent(
        variableCheck.data.data,
        variableMap,
        globalVariablesMap,
        (err) => {
          if (!payload.errors.includes(err)) {
            payload.errors.push(err);
          }
        },
      );
  });

  //  Node is malformed. No point in continuing
  if (payload.validations.nodeInvalid) {
    return payload;
  }

  Object.keys(payload.validations.variableValidations).forEach((variableId) => {
    if (
      !payload.validations.variableValidations[variableId] &&
      !payload.errors.includes(requestNodeVariableMissingError)
    ) {
      payload.errors.push(requestNodeVariableMissingError);
    }
  });

  return {
    ...payload,
    errors: filterErrors(payload.errors, requestNodeVariableMissingError),
  };
};

const validateRetryNode: RetryValidationFunction<WorkflowNode> = ({
  node,
}): RetryNodeValidationResult => {
  const nodeParse = WorkflowRetryNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  const retryNode = nodeParse.data;

  const payload: RetryNodeValidationResult = {
    errors: [],
    validations: { nodeInvalid: false },
  };

  if (skipValidation(node)) {
    return payload;
  }

  const maxAttempts = retryNode.data.maxAttempts;
  const maxAttemptsValue = maxAttempts ? Number(maxAttempts) - 1 : 0;

  if (
    maxAttempts !== null &&
    (Number(maxAttemptsValue) === 0 || Number(maxAttemptsValue) > 10)
  ) {
    payload.errors.push(
      'Number of failures to trigger termination must be at least 1',
    );
    payload.validations.invalidRetryCount = true;
  }

  return payload;
};

const validateSourceNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowSourceNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [], validations: { nodeInvalid: false } };
};

const validateStopNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowStopNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [], validations: { nodeInvalid: false } };
};

const validateTemporalNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowTemporalNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [], validations: { nodeInvalid: false } };
};

const validateContinueNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowContinueNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [], validations: { nodeInvalid: false } };
};

const validateDocumentNode: ValidationFunction<WorkflowNode> = ({
  node,
}): ValidationResult => {
  const nodeParse = WorkflowDocumentNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true },
    };
  }

  return { errors: [], validations: { nodeInvalid: false } };
};

const validateEmailNode: EmailValidationFunction<WorkflowNode> = ({
  node,
  variableMap,
  globalVariablesMap,
}): EmailNodeValidationResult => {
  const nodeParse = WorkflowEmailNode.safeParse(node);
  if (!nodeParse.success) {
    return {
      errors: [defaultErrorMessage],
      validations: { nodeInvalid: true, variableValidations: {} },
    };
  }

  const emailNode = nodeParse.data;
  let errors: string[] = [];
  const validations: EmailNodeValidationResult['validations'] = {
    nodeInvalid: false,
    emailDetailsMissing: false,
    variableValidations: {},
  };

  if (skipValidation(node)) {
    return {
      errors,
      validations,
    };
  }

  const emailDetails = emailNode.data.sendEmailDetails;

  // Temporarily removing the provider validation
  // const provider = emailNode.data.emailProvider;

  // if (!provider) {
  //   errors.push('Select an email provider to mark the node as ready.');
  //   validations.providerMissing = true;
  // }

  if (!emailDetails) {
    return {
      errors: [
        ...errors,
        'Email details are missing. Please select an action to continue.',
      ],
      validations: { ...validations, emailDetailsMissing: true },
    };
  }

  //   Check for the subject
  const subjectVariable = emailDetails.subject.variableId;
  const recipientsVariable = emailDetails.recipients.variableId;

  const variableIds = [subjectVariable, recipientsVariable];

  variableIds.forEach((variableId) => {
    if (variableId) {
      const variableCheck = TemplateVariable.safeParse(variableMap[variableId]);

      if (!variableCheck.success) {
        errors = [defaultErrorMessage];
        validations.nodeInvalid = true;
        return { errors, validations };
      }

      validations.variableValidations[variableId] = templateDataHasContent(
        variableCheck.data.data,
        variableMap,
        globalVariablesMap,
        (err) => {
          if (!errors.includes(err)) {
            errors.push(err);
          }
        },
      );
    }
  });

  //  Node is malformed. No point in continuing
  if (validations.nodeInvalid) {
    return { errors, validations };
  }

  Object.keys(validations.variableValidations).forEach((variableId) => {
    if (
      !validations.variableValidations[variableId] &&
      !errors.includes(emailNodeVariableMissingError)
    ) {
      errors.push(emailNodeVariableMissingError);
    }
  });

  return {
    errors: filterErrors(errors, emailNodeVariableMissingError),
    validations,
  };
};

const nodeValidations: {
  [NodeTypesEnum.Conditional]: ConditionalValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Freeform]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Image]: ImageValidationFunction<WorkflowNode>;
  [NodeTypesEnum.New]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Request]: RequestValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Retry]: RetryValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Source]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Stop]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Temporal]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Continue]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Document]: ValidationFunction<WorkflowNode>;
  [NodeTypesEnum.Email]: EmailValidationFunction<WorkflowNode>;
} = {
  [NodeTypesEnum.Conditional]: validateConditionalNode,
  [NodeTypesEnum.Freeform]: validateFreeformNode,
  [NodeTypesEnum.Image]: validateImageNode,
  [NodeTypesEnum.New]: validateNewNode,
  [NodeTypesEnum.Request]: validateRequestNode,
  [NodeTypesEnum.Retry]: validateRetryNode,
  [NodeTypesEnum.Source]: validateSourceNode,
  [NodeTypesEnum.Stop]: validateStopNode,
  [NodeTypesEnum.Temporal]: validateTemporalNode,
  [NodeTypesEnum.Continue]: validateContinueNode,
  [NodeTypesEnum.Document]: validateDocumentNode,
  [NodeTypesEnum.Email]: validateEmailNode,
};

export default nodeValidations;
