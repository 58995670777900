import { QueryVariable } from 'types-shared';
import { LoadingManualRun } from './LoadingManualRun';
import { GrayedOutInput, Input, Switch } from 'ui-kit';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import { isAdmin } from '../../../utils/env';

export interface WorkflowRunVariablesProps {
  variables: QueryVariable[];
  isFetchingNodeViewData: boolean;
  isPendingTest: boolean;
  setVariableState: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  variableState: Record<string, string>;
  isSchedule?: boolean;
  isEditing?: boolean;
  className?: string;
  isEmailTrigger?: boolean;
}

export function WorkflowRunVariables({
  className,
  variables,
  isFetchingNodeViewData,
  isPendingTest,
  setVariableState,
  variableState,
  isSchedule,
  isEditing,
  isEmailTrigger = false,
}: WorkflowRunVariablesProps) {
  const showLabel = !isSchedule || isEditing;
  const [useJsonVariableData, setUseJsonVariableData] =
    useState<boolean>(false);

  const stringifiedVariableState = useMemo(() => {
    if (!useJsonVariableData) return '{}';
    try {
      return JSON.stringify(variableState);
    } catch {
      return '{}';
    }
  }, [useJsonVariableData, variableState]);

  return (
    <>
      {variables.length ? (
        <div className="flex items-center justify-between">
          <span className="text-info-dark text-normal font-medium">
            Workflow variables
          </span>

          {isAdmin ? (
            <div className="flex items-center">
              <span className="text-gray-500 text-sm mr-2">Use JSON</span>
              <Switch
                color="secondary"
                checked={useJsonVariableData}
                onClick={() => {
                  setUseJsonVariableData(!useJsonVariableData);
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {showLabel && variables.length > 0 ? (
        <span className="text-gray-500 text-sm !mt-0">
          Enter values for variables in the workflow
          {!isSchedule ? ' and execute a test' : ''}
        </span>
      ) : null}

      {showLabel && variables.length === 0 && !isEmailTrigger ? (
        <span className="text-gray-500 text-sm">
          Once you add API variables to your workflow, you can enter values in
          this section to test API requests with different input data
        </span>
      ) : null}

      {isFetchingNodeViewData && variables.length === 0 ? (
        <LoadingManualRun />
      ) : null}
      {variables.length > 0 ? (
        <>
          {useJsonVariableData ? (
            <Input
              classes={{ wrapper: 'flex flex-col mt-3' }}
              floatingLabel
              label="Variable Data"
              multiline
              onChange={(val) => {
                setVariableState(
                  val ? (JSON.parse(val) as Record<string, string>) : {},
                );
              }}
              rows={10}
              value={stringifiedVariableState}
            />
          ) : (
            <div
              className={clsx(
                'flex flex-col !mt-4 gap-y-4 overflow-y-auto dark-scrollbar',
                className,
              )}
            >
              {variables.map((v) => {
                const parseResult = QueryVariable.safeParse(v);

                if (!parseResult.success) return null;

                const variableName = parseResult.data.name;

                return variableName ? (
                  <>
                    {isSchedule && !isEditing ? (
                      <GrayedOutInput
                        label={parseResult.data.name}
                        value={variableState[variableName] ?? ''}
                      />
                    ) : (
                      <Input
                        disabled={isPendingTest}
                        floatingLabel
                        id={parseResult.data.id}
                        label={parseResult.data.name}
                        placeholder="Enter value"
                        key={parseResult.data.id}
                        onChange={(data) => {
                          setVariableState((s) => ({
                            ...s,
                            [variableName]: data,
                          }));
                        }}
                        value={variableState[variableName] ?? ''}
                      />
                    )}
                  </>
                ) : null;
              })}
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
