import 'ui-kit/dist/tailwind.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { type AppState, Auth0Provider, EnvironmentProvider } from 'ui-kit';
import { PostHogProvider } from 'posthog-js/react';

import Initializer from './initializer';
import { isAdmin } from './utils/env';

document.title = isAdmin ? 'Admin Dashboard' : 'Sola Dashboard';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  enable_recording_console_log: true,
  session_recording: { maskAllInputs: false },
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Document root not found!');
}

const onRedirectCallback = (appState: AppState | undefined) => {
  window.location.replace(
    appState?.returnTo ? appState.returnTo : window.location.pathname,
  );
};

const app = (
  <EnvironmentProvider>
    <Initializer />
  </EnvironmentProvider>
);

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    {isAdmin ? (
      app
    ) : (
      <Auth0Provider
        authorizationParams={{
          // WARN: We are using the management API identifier for all JWTs issued on the dashboard
          // FIXME: Create a new Auth0 API and use that identifier instead (+ unify with recorder API)
          audience: process.env.REACT_APP_AUTH0_API_IDENTIFIER,
          redirect_uri: window.location.origin,
        }}
        cacheLocation="localstorage"
        clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
        domain={String(process.env.REACT_APP_AUTH0_DOMAIN)}
        onRedirectCallback={onRedirectCallback}
      >
        {process.env.REACT_APP_PUBLIC_POSTHOG_KEY ? (
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
          >
            {app}
          </PostHogProvider>
        ) : (
          app
        )}
      </Auth0Provider>
    )}
  </React.StrictMode>,
);
