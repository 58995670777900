import { z } from 'types-shared';
import { DefaultStringOperators } from './string';
import { DefaultStringArrayOperators } from './string-array';
export function DefaultOperators(schema) {
    // NOTE: We use type erasure to pass typecheck
    switch (schema._def.typeName) {
        case z.ZodFirstPartyTypeKind.ZodString:
            // We know that T is z.ZodString here
            return DefaultStringOperators.ALL.map(function (op) {
                return op.eraseToUnknown();
            });
        case z.ZodFirstPartyTypeKind.ZodArray:
            switch (schema._def.type._def.typeName) {
                case z.ZodFirstPartyTypeKind.ZodString:
                    // We know that T is z.ZodArray<z.ZodString> here
                    return DefaultStringArrayOperators.ALL.map(function (op) {
                        return op.eraseToUnknown();
                    });
            }
    }
}
