"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowProcessedErrorMetadata = exports.WorkflowProcessedErrorReasonSchema = exports.WorkflowProcessedErrorReason = void 0;
var zod_1 = require("zod");
var WorkflowProcessedErrorReason;
(function (WorkflowProcessedErrorReason) {
    WorkflowProcessedErrorReason["MissingAuthentication"] = "missing_authentication";
})(WorkflowProcessedErrorReason || (exports.WorkflowProcessedErrorReason = WorkflowProcessedErrorReason = {}));
exports.WorkflowProcessedErrorReasonSchema = zod_1.z.nativeEnum(WorkflowProcessedErrorReason);
exports.WorkflowProcessedErrorMetadata = zod_1.z
    .object({
    reason: exports.WorkflowProcessedErrorReasonSchema,
})
    .passthrough();
