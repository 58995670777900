import { clsx } from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import {
  getNodeActions,
  type NodeStatusEnum,
  WorkflowImageNode,
} from 'types-shared';
import { Clear, IconButton } from 'ui-kit';
import { useOnEscape } from '../../../../hooks/useOnEscape';
import { isAdmin } from '../../../../utils/env';
import { useNodeValidation } from '../../hooks/useNodeValidation';
import { EditorStore } from '../../store/EditorState';
import validateNode, {
  type ImageNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeHeader from '../NodeHeader';
import type { SelectedAction } from './ActionsList';
import ActionsList from './ActionsList';
import { type RenderPdfProps } from './DocumentVariables/AnnotatedPDF';
import EditAction from './EditAction';
import EditTarget from './EditTarget';
import MoveAction from './MoveAction';
import { useNavigateBetweenNodes } from '../NodeHeader/hooks';

const validateImageNode = validateNode.image;
interface Props {
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
  updateNodeStatus: (status: NodeStatusEnum) => void;
}

export default function ActionsManager({
  setAnnotatedPDFData,
  updateNodeStatus,
}: Props) {
  const { handleCloseNode } = useNavigateBetweenNodes();
  const [selectedActionIds, setSelectedActionIds] = useState<string[]>([]);
  const editorData = EditorStore();
  const { selectedNode, nodes, workflowId, variables, globalVariables } =
    editorData;
  const [selectedAction, setSelectedAction] = useState<SelectedAction | null>(
    null,
  );
  const [selectedTarget, setSelectedTarget] = useState<SelectedAction | null>(
    null,
  );
  const [actionToMove, setActionToMove] = useState<SelectedAction>();
  const [movingActions, setMovingActions] = useState(false);
  const [editingTargets, setEditingTargets] = useState(false);

  const node = useMemo(() => {
    const _selectedNode = nodes.find((_node) => _node.id === selectedNode);
    const parsedImageNode = WorkflowImageNode.safeParse(_selectedNode);
    if (parsedImageNode.success) {
      return parsedImageNode.data;
    }
    return null;
  }, [nodes, selectedNode]);

  const {
    validationResult: validationData,
    validationAttempted,
    handleValidateNode,
  } = useNodeValidation<ImageNodeValidationResult>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    node: node!,
    variablesMap: variables,
    globalVariablesMap: globalVariables ?? {},
    updateNodeStatus,
    validationFunction: validateImageNode,
    workflowId: workflowId ?? '',
  });

  useOnEscape((e) => {
    e.stopPropagation();
    if (selectedAction) {
      setSelectedAction(null);
      return;
    }

    if (selectedNode) {
      handleCloseNode();
    }
  });

  const actions = useMemo(
    () =>
      node
        ? getNodeActions({
            ...node.data,
            isAdmin,
          })
        : [],
    [node],
  );

  const selectedActions = useMemo(() => {
    if (!actions.length || !selectedActionIds.length) {
      return [];
    }
    const selected = actions.filter((a) => selectedActionIds.includes(a.id));
    return selected;
  }, [actions, selectedActionIds]);

  const onClose = () => {
    handleCloseNode();
    setSelectedTarget(null);
    setActionToMove(undefined);
  };

  const onCloseEditTarget = () => {
    setSelectedTarget(null);
  };

  const onCloseMoveAction = () => {
    setActionToMove(undefined);
    setMovingActions(false);
  };

  const onEditTarget = (action: SelectedAction) => {
    setSelectedTarget(action);
  };

  const onMoveAction = (action: SelectedAction) => {
    setActionToMove(action);
  };

  const onFinishEditingTarget = (actionID: string) => {
    const isLast = selectedActionIds.length === 1;
    if (isLast) {
      setEditingTargets(false);
      setSelectedActionIds([]);
    } else {
      setSelectedActionIds((actionIds) =>
        actionIds.filter((a) => a !== actionID),
      );
    }
  };

  const onEditClose = () => {
    setSelectedAction(null);
    setAnnotatedPDFData(undefined);
  };

  const onExitAction = () => {
    onCloseEditTarget();
    onEditClose();
  };

  const { showActionsList, showMoveAction } = useMemo(() => {
    const payload = {
      showActionsList: false,
      showMoveAction: false,
      showEditTargets: false,
    };

    if (!node) return payload;

    payload.showActionsList =
      !selectedTarget &&
      !actionToMove &&
      !selectedAction &&
      !movingActions &&
      !editingTargets;
    payload.showMoveAction =
      Boolean(actionToMove) ||
      Boolean(movingActions && selectedActionIds.length);

    return payload;
  }, [
    node,
    selectedTarget,
    actionToMove,
    selectedAction,
    movingActions,
    selectedActionIds,
    editingTargets,
  ]);

  // Make sure to deselect the action if we switch the node while an action is being previewed
  useEffect(() => {
    if (selectedAction && actions.length) {
      const actionInNode = actions.find((a) => a.id === selectedAction.id);
      if (!actionInNode) {
        setSelectedAction(null);
      }
    }
  }, [actions, selectedAction]);

  if (!node) {
    return null;
  }

  return (
    <div
      className={clsx(
        'min-w-125 max-w-125 max-h-full flex flex-col h-full bg-white relative border-r-2 border-gray-200 overflow-y-auto',
        {
          'pb-8': showActionsList,
        },
      )}
    >
      <NodeHeader
        node={node}
        insideNodeSubSection={Boolean(selectedAction ?? selectedTarget)}
        onExitNodeSubSection={onExitAction}
        onNextWillHideFlow
        exitNodeSubSectionTitle="BACK TO ACTIONS"
      />

      <div className="flex flex-col flex-1 space-y-6 mb-20">
        {showActionsList ? (
          <ActionsList
            node={node}
            onClose={onClose}
            onEditTarget={onEditTarget}
            onMoveAction={onMoveAction}
            onBulkMoveAction={() => {
              setMovingActions(true);
            }}
            onBulkEditTargets={() => {
              setEditingTargets(true);
            }}
            setSelectedAction={setSelectedAction}
            selectedActions={selectedActionIds}
            setSelectedActions={setSelectedActionIds}
            actions={actions}
            updateNodeStatus={updateNodeStatus}
            validationResult={validationData}
            validationAttempted={validationAttempted}
            handleValidateNode={handleValidateNode}
          />
        ) : null}
        {selectedAction ? (
          <EditAction
            action={selectedAction}
            i={selectedAction.i}
            setSelectedAction={setSelectedAction}
            setAnnotatedPDFData={setAnnotatedPDFData}
            validationData={validationData}
            validationAttempted={validationAttempted}
            handleValidateNode={handleValidateNode}
          />
        ) : null}

        {selectedTarget ? (
          <EditTarget action={selectedTarget} onClose={onCloseEditTarget} />
        ) : null}

        {editingTargets ? (
          <>
            <div className="sticky top-0">
              <h1 className="flex items-center justify-between">
                <span className="font-medium text-xl">
                  Editing multiple targets
                </span>

                <IconButton
                  className="!ml-auto !-mr-3 !my-auto"
                  onClick={() => {
                    setEditingTargets(false);
                    setSelectedActionIds([]);
                  }}
                >
                  <Clear className="!w-5 !h-5 !text-info" />
                </IconButton>
              </h1>
            </div>

            <div className="overflow-y-auto">
              {selectedActions.map((a, i) => (
                <EditTarget
                  action={{ ...a, i: i + 1 }}
                  onClose={() => {
                    onFinishEditingTarget(a.id);
                  }}
                  key={a.id}
                />
              ))}
            </div>
          </>
        ) : null}

        {showMoveAction ? (
          <MoveAction
            action={actionToMove}
            onClose={onCloseMoveAction}
            sourceNode={node}
            actions={selectedActions}
            actionIds={selectedActionIds}
            clearSelectedActions={() => {
              setSelectedActionIds([]);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
