import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from '../index';
import { EditorStore, type EditorStoreProps } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { MailIcon } from 'ui-kit';
import { useNavigateBetweenNodes } from '../../NodeHeader/hooks';

export default function EmailNode(workflowData: WorkflowNodeProps) {
  const { handleNavigateToNode } = useNavigateBetweenNodes();
  const { nodes, edges } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      edges: state.edges,
    })),
  );

  const node = nodes.find((n) => n.id === workflowData.id);
  const nodeEdges = useMemo(() => {
    if (isNil(node)) {
      return [];
    }
    return edges
      .filter((edge) => edge.source === node.id)
      .filter((e) => !isNil(e));
  }, [edges, node]);

  if (isNil(node)) {
    return null;
  }

  return (
    <NodeElement
      allowBulkCheck
      label="Email step"
      onClick={() => {
        handleNavigateToNode(workflowData.id);
      }}
      showAddButton
      showDeleteButton={nodeEdges.length <= 1}
      showEditButton
      workflowData={workflowData}
    >
      <div className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center">
        <MailIcon className="!w-16 !h-16" />
      </div>
      <span className="text-sm font-bold truncate">
        {node.name ?? 'Email Step'}
      </span>
    </NodeElement>
  );
}
