"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationDefaults = void 0;
const types_shared_1 = require("types-shared");
const graphql_1 = require("./generated/graphql");
// eslint-disable-next-line @typescript-eslint/no-namespace -- for clarity
var NotificationDefaults;
(function (NotificationDefaults) {
    NotificationDefaults.WorkflowConfigurations = (args, 
    // NOTE: By default, include all user and admin configurations
    selector = {
        user: { include: true },
        admin: { include: true, allowedTags: undefined },
    }) => {
        const userConfigurations = selector.user.include
            ? NotificationDefaults.UserWorkflowConfigurations(args)
            : [];
        let adminConfigurations = selector.admin.include
            ? NotificationDefaults.AdminWorkflowConfigurations(args)
            : [];
        const allowedAdminTags = selector.admin.allowedTags;
        if (allowedAdminTags) {
            adminConfigurations = adminConfigurations.filter((config) => allowedAdminTags.includes(config.tag));
        }
        return [...userConfigurations, ...adminConfigurations];
    };
    // MARK: Default Admin / non-user-configurable Configurations
    NotificationDefaults.AdminWorkflowConfigurations = (args) => {
        switch (args.preset) {
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.SlackOnly:
                return [NotificationDefaults.UserNonConfigurableSlackWorkflowConfiguration(args)];
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.EmailAndShadowSlack:
                return [
                    NotificationDefaults.UserNonConfigurableEmailWorkflowConfiguration(args),
                    NotificationDefaults.UserNonConfigurableSlackWorkflowConfiguration(args),
                    NotificationDefaults.UserShadowSlackWorkflowConfiguration(args),
                ];
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.SlackAndEmail:
                return [
                    NotificationDefaults.UserNonConfigurableSlackWorkflowConfiguration(args),
                    NotificationDefaults.UserNonConfigurableEmailWorkflowConfiguration(args),
                ];
        }
    };
    NotificationDefaults.UserNonConfigurableSlackWorkflowConfiguration = (args) => {
        return {
            tag: types_shared_1.StandardWorkflowNotificationConfigTag.UserNonConfigurable,
            platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
            platformMetadata: {
                platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
                userEmails: [args.ownerEmail],
                conversationId: args.slack.teamChannelId,
            },
            events: [
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowImprovementDetected },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowImported },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowProcessed },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowProcessingError },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowReprocessing },
            ],
        };
    };
    NotificationDefaults.UserNonConfigurableEmailWorkflowConfiguration = (args) => {
        return {
            tag: types_shared_1.StandardWorkflowNotificationConfigTag.UserNonConfigurable,
            platform: graphql_1.Notifications_Enum_Platform_Enum.Email,
            platformMetadata: {
                platform: graphql_1.Notifications_Enum_Platform_Enum.Email,
                recipients: [args.ownerEmail],
            },
            events: [
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowImprovementDetected },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowImported },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowProcessed },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowProcessingError },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.WorkflowReprocessing },
            ],
        };
    };
    NotificationDefaults.UserShadowSlackWorkflowConfiguration = (args) => {
        const userEmails = args.slack.shouldTagOwner ? [args.ownerEmail] : [];
        return {
            tag: types_shared_1.StandardWorkflowNotificationConfigTag.UserShadow,
            platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
            platformMetadata: {
                platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
                userEmails,
                conversationId: args.slack.teamChannelId,
            },
            events: [
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionFailed },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionSuccess },
            ],
        };
    };
    // MARK: Default User (configurable) Configurations
    NotificationDefaults.UserWorkflowConfigurations = (args) => {
        switch (args.preset) {
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.SlackOnly:
                return [NotificationDefaults.UserSlackWorkflowConfiguration(args)];
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.EmailAndShadowSlack:
                return [NotificationDefaults.UserEmailWorkflowConfiguration(args)];
            case graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum.SlackAndEmail:
                return [
                    NotificationDefaults.UserSlackWorkflowConfiguration(args),
                    NotificationDefaults.UserEmailWorkflowConfiguration(args),
                ];
        }
    };
    NotificationDefaults.UserSlackWorkflowConfiguration = (args) => {
        const userEmails = args.slack.shouldTagOwner ? [args.ownerEmail] : [];
        return {
            tag: types_shared_1.StandardWorkflowNotificationConfigTag.User,
            platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
            platformMetadata: {
                platform: graphql_1.Notifications_Enum_Platform_Enum.Slack,
                userEmails,
                conversationId: args.slack.teamChannelId,
            },
            events: [
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionFailed },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionSuccess },
            ],
        };
    };
    NotificationDefaults.UserEmailWorkflowConfiguration = (args) => {
        return {
            tag: types_shared_1.StandardWorkflowNotificationConfigTag.User,
            platform: graphql_1.Notifications_Enum_Platform_Enum.Email,
            platformMetadata: {
                platform: graphql_1.Notifications_Enum_Platform_Enum.Email,
                recipients: [args.ownerEmail],
            },
            events: [
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionFailed },
                { event: graphql_1.Notifications_Enum_Workflow_Event_Enum.ExecutionSuccess },
            ],
        };
    };
})(NotificationDefaults || (exports.NotificationDefaults = NotificationDefaults = {}));
