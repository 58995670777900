import { BuiltInOperator } from '../types';
var UntypedBuiltInOperator = /** @class */ (function () {
    function UntypedBuiltInOperator(_a) {
        var name = _a.name, defaultLabel = _a.defaultLabel;
        this.name = name;
        this.defaultLabel = defaultLabel;
    }
    UntypedBuiltInOperator.prototype.typed = function (zodFieldTypeBrand) {
        return new BuiltInOperator(zodFieldTypeBrand, this.name, this.defaultLabel);
    };
    return UntypedBuiltInOperator;
}());
export var EQUALS = new UntypedBuiltInOperator({
    name: '=',
    defaultLabel: 'equals',
});
export var NOT_EQUALS = new UntypedBuiltInOperator({
    name: '!=',
    defaultLabel: 'does not equal',
});
export var CONTAINS = new UntypedBuiltInOperator({
    name: 'contains',
    defaultLabel: 'contains',
});
export var BEGINS_WITH = new UntypedBuiltInOperator({
    name: 'beginsWith',
    defaultLabel: 'begins with',
});
export var ENDS_WITH = new UntypedBuiltInOperator({
    name: 'endsWith',
    defaultLabel: 'ends with',
});
export var DOES_NOT_CONTAIN = new UntypedBuiltInOperator({
    name: 'doesNotContain',
    defaultLabel: 'does not contain',
});
export var DOES_NOT_BEGIN_WITH = new UntypedBuiltInOperator({
    name: 'doesNotBeginWith',
    defaultLabel: 'does not begin with',
});
export var DOES_NOT_END_WITH = new UntypedBuiltInOperator({
    name: 'doesNotEndWith',
    defaultLabel: 'does not end with',
});
