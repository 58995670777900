import { useMemo } from 'react';
import {
  type DatasourceMetadata,
  type DatasourceTable,
  type GlobalVariable,
  type TemplateVariable,
  type Variable,
  type VariableMap,
  type WorkflowEmailNode,
  EmailTypeEnum,
} from 'types-shared';
import { GrayedOutInput, Select } from 'ui-kit';

import type { SelectChangeEvent } from '@mui/material/Select';
import { RequestVariableInput } from '../RequestVariableInput';

import { type VariableValidation } from '../../../utils/nodeValidations';
import { VariableInputMenuTabsEnum } from '../../VariableTypes/VariableInput/Input2.0/types';
import SectionLabel from '../SectionLabel';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../../../../../utils/constants';
import useUserTeamDetails from '../../../../../hooks/useUserTeamDetails';
import { handleException } from 'sentry-browser-shared';

interface SendEmailActionProps {
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateSendEmailAction: (
    data: WorkflowEmailNode['data']['sendEmailDetails'],
  ) => void;
  sendEmailAction: WorkflowEmailNode['data']['sendEmailDetails'];
  updateVariable: (variable: Variable) => void;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  validations: VariableValidation;
  saveAttempted: boolean;
  workflowOwnerId: string;
}

const emailTypeOptions = Object.values(EmailTypeEnum);

export default function SendEmailAction({
  variablesMap,
  globalVariablesMap,
  updateSendEmailAction,
  sendEmailAction,
  updateVariable,
  datasourceMetadata,
  validations,
  saveAttempted,
  workflowOwnerId,
}: SendEmailActionProps) {
  const { teamDetails } = useUserTeamDetails(workflowOwnerId);
  const emailSenderMap = useFeatureFlagPayload(FeatureFlag.EmailSender) as
    | Record<string, string>
    | undefined;
  const emailSender = useMemo(() => {
    const teamId = teamDetails?.teamId;
    if (!emailSenderMap) {
      handleException(new Error('Email sender map not found'), {
        name: 'Email sender map not found',
        source: 'SendEmailAction',
      });
      return '';
    }
    // We already exception for team not found in useUserTeamDetails hook
    // so we don't need to handle it here
    if (!teamId) {
      return '';
    }
    return emailSenderMap[teamId] ?? emailSenderMap.else;
  }, [teamDetails, emailSenderMap]);

  return sendEmailAction ? (
    <>
      <Select
        classes={{ select: 'w-100' }}
        // eslint-disable-next-line prefer-named-capture-group
        getLabel={(opt: string) => opt.replace(/([a-z])([A-Z])/g, '$1 $2')}
        getValue={(opt: string) => opt}
        getIsDisabled={(opt) =>
          (opt as EmailTypeEnum) !== EmailTypeEnum.NewEmail
        }
        label="Email type"
        placeholder="Select the email type"
        onChange={(event: SelectChangeEvent) => {
          const newEmailType = event.target.value as EmailTypeEnum;

          updateSendEmailAction({
            ...sendEmailAction,
            emailType: newEmailType,
          });
        }}
        options={emailTypeOptions}
        value={sendEmailAction.emailType}
      />

      <GrayedOutInput label="Send from" className="mb-5" value={emailSender} />

      <SectionLabel title="To recipients" />

      <p className="text-zinc-500 text-sm leading-tight">
        A comma-separated list of emails to send the message to
      </p>

      <RequestVariableInput
        label="Recipients"
        isHighlighted={
          saveAttempted
            ? !validations[sendEmailAction.recipients.variableId]
            : false
        }
        placeholder="Add one or more recipients"
        onChange={(updatedVar) => {
          updateVariable(updatedVar);
        }}
        variable={
          variablesMap[
            sendEmailAction.recipients.variableId
          ] as TemplateVariable
        }
        datasourceMetadata={datasourceMetadata}
        enableAddingVariables
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        className="w-full"
        multiline={false}
      />
      {sendEmailAction.ccRecipients ? (
        <RequestVariableInput
          label="CC recipients (optional)"
          placeholder="Add one or more recipients"
          onChange={(updatedVar) => {
            updateVariable(updatedVar);
          }}
          variable={
            variablesMap[
              sendEmailAction.ccRecipients.variableId
            ] as TemplateVariable
          }
          datasourceMetadata={datasourceMetadata}
          enableAddingVariables
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          className="w-full"
          multiline={false}
        />
      ) : null}
      {sendEmailAction.bccRecipients ? (
        <RequestVariableInput
          label="BCC recipients (optional)"
          placeholder="Add one or more recipients"
          onChange={(updatedVar) => {
            updateVariable(updatedVar);
          }}
          variable={
            variablesMap[
              sendEmailAction.bccRecipients.variableId
            ] as TemplateVariable
          }
          datasourceMetadata={datasourceMetadata}
          enableAddingVariables
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          className="w-full"
          multiline={false}
        />
      ) : null}

      <SectionLabel title="Email data" />

      <RequestVariableInput
        isHighlighted={
          saveAttempted
            ? !validations[sendEmailAction.subject.variableId]
            : false
        }
        label="Subject"
        placeholder="Add a subject"
        onChange={(updatedVar) => {
          updateVariable(updatedVar);
        }}
        variable={
          variablesMap[sendEmailAction.subject.variableId] as TemplateVariable
        }
        datasourceMetadata={datasourceMetadata}
        enableAddingVariables
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        className="w-full"
        multiline={false}
      />

      <RequestVariableInput
        label="Content"
        placeholder="Add the email content"
        onChange={(updatedVar) => {
          updateVariable(updatedVar);
        }}
        variable={
          variablesMap[sendEmailAction.emailBody.variableId] as TemplateVariable
        }
        datasourceMetadata={datasourceMetadata}
        enableAddingVariables
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        className="!min-h-[174px] w-full"
        multiline
      />

      {sendEmailAction.attachments ? (
        <>
          <SectionLabel
            title={
              <>
                <span>Attachments</span>{' '}
                <span className="text-zinc-500 ml-2">(optional)</span>
              </>
            }
          />

          <p className="text-zinc-500 text-sm leading-tight">
            Select document variables from this workflow to attach to the email
          </p>
          <RequestVariableInput
            label="Attachments (optional)"
            placeholder="Select document variables to attach"
            onChange={(updatedVar) => {
              updateVariable(updatedVar);
            }}
            variable={
              variablesMap[
                sendEmailAction.attachments.variableId
              ] as TemplateVariable
            }
            enableAddingVariables
            datasourceMetadata={datasourceMetadata}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            className="w-full"
            multiline={false}
            allowedMenuTabs={[VariableInputMenuTabsEnum.Documents]}
          />
        </>
      ) : null}
    </>
  ) : null;
}
