import type {
  EdgeTypes,
  NodeTypes,
  ReactFlowInstance,
  Viewport,
} from 'types-shared/reactflow';
import {
  NodeTypesEnum,
  type WorkflowEdge,
  type WorkflowNode,
} from 'types-shared';
import {
  DEFAULT_ZOOM,
  editorMaxZoomLevel,
  editorMinZoomLevel,
} from '../../../utils/constants';
import {
  Background,
  ConnectionLineType,
  ReactFlow,
  SelectionMode,
} from 'types-shared/reactflow';
import { Connector } from '../../EdgeElement/Connector';

interface Props {
  edgeTypes: EdgeTypes;
  nodeTypes: NodeTypes;
  edges: WorkflowEdge[];
  nodes: WorkflowNode[];
  currentViewport: Viewport | null;
  setCurrentViewport: (viewport: Viewport) => void;
}

export default function ReadonlyReactFlow({
  nodes,
  edgeTypes,
  nodeTypes,
  edges,
  currentViewport,
  setCurrentViewport,
}: Props) {
  const handleDefaultViewport = (instance: ReactFlowInstance) => {
    instance.fitView({
      nodes: nodes.filter((node) => node.type === NodeTypesEnum.Source),
      maxZoom: DEFAULT_ZOOM,
      minZoom: DEFAULT_ZOOM,
    });
  };

  return (
    <ReactFlow
      attributionPosition="top-right"
      className="relative readonly"
      connectionLineComponent={Connector}
      connectionLineType={ConnectionLineType.Bezier}
      defaultEdgeOptions={{ deletable: false, focusable: false }}
      edgeTypes={edgeTypes}
      edges={edges}
      edgesFocusable={false}
      edgesUpdatable={false}
      elementsSelectable={false}
      maxZoom={editorMaxZoomLevel}
      minZoom={editorMinZoomLevel}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable={false}
      nodesFocusable={false}
      onInit={(instance) => {
        if (!currentViewport) {
          handleDefaultViewport(instance);
        } else {
          instance.setViewport(currentViewport);
        }
      }}
      onMove={(_event, data) => {
        setCurrentViewport(data);
      }}
      panOnDrag
      panOnScroll
      proOptions={{ hideAttribution: true }}
      selectionMode={SelectionMode.Partial}
      selectionOnDrag={false}
    >
      <Background className="bg-flow-view" />
    </ReactFlow>
  );
}
