import Skeleton from '@mui/material/Skeleton';
import SwitchRow from './SwitchRow';
import { WarningAmberOutlined } from 'ui-kit';

export default function SlackHeader({
  slackActive,
  channelLoading,
  channelName,
  updateNotificationData,
}: {
  slackActive: boolean;
  channelLoading: boolean;
  channelName: string;
  updateNotificationData: (
    key: 'slackActive' | 'emailActive',
    value: boolean,
  ) => void;
}) {
  return (
    <>
      <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm">
        <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
        <span className="text-[#663C00]">
          For workflows with high execution volume, we recommend turning off
          notifications or enabling only urgent ones. Excessive notifications
          may trigger Slack's rate limits, causing delays in delivery.
        </span>
      </div>

      <SwitchRow
        checked={slackActive}
        onChange={(newChecked) => {
          updateNotificationData('slackActive', newChecked);
        }}
      >
        <h2 className="font-medium text-xl text-info-dark">
          Slack notifications
        </h2>
        <p className="text-sm text-gray-500 !mt-2">
          Set up notifications for your workflow to stay updated in real time.
        </p>
      </SwitchRow>

      <div>
        <h2 className="font-medium text-lg text-info-dark">Slack channel</h2>
        <p className="text-sm text-gray-500 mb-6 mt-2">
          This is the Slack channel where your team receives notifications.
          Below, you can configure who gets tagged in notifications. If you'd
          like to enable tag-only notifications, you can adjust this setting
          directly in Slack to limit visibility to tagged individuals.
          Otherwise, all notifications will be visible to the entire channel.
        </p>
        {channelLoading ? (
          <Skeleton variant="rounded" width={200} height={40} />
        ) : (
          <span className="text-sm text-info-dark bg-[#F5F5F5] rounded-lg p-4">
            #{channelName}
          </span>
        )}
      </div>
    </>
  );
}
