import { z } from 'types-shared';
import * as BuiltIns from '../built-in';
// MARK: - Default Operators for string
// eslint-disable-next-line @typescript-eslint/no-namespace -- namespace good
export var DefaultStringOperators;
(function (DefaultStringOperators) {
    var ZodString = z.string();
    // NOTE: We need to specify the generic value type explicitly here, since it cannot be inferred (!)
    DefaultStringOperators.EQUALS = BuiltIns.EQUALS.typed(ZodString);
    DefaultStringOperators.NOT_EQUALS = BuiltIns.NOT_EQUALS.typed(ZodString);
    DefaultStringOperators.CONTAINS = BuiltIns.CONTAINS.typed(ZodString);
    DefaultStringOperators.BEGINS_WITH = BuiltIns.BEGINS_WITH.typed(ZodString);
    DefaultStringOperators.ENDS_WITH = BuiltIns.ENDS_WITH.typed(ZodString);
    DefaultStringOperators.DOES_NOT_CONTAIN = BuiltIns.DOES_NOT_CONTAIN.typed(ZodString);
    DefaultStringOperators.DOES_NOT_BEGIN_WITH = BuiltIns.DOES_NOT_BEGIN_WITH.typed(ZodString);
    DefaultStringOperators.DOES_NOT_END_WITH = BuiltIns.DOES_NOT_END_WITH.typed(ZodString);
    DefaultStringOperators.ALL = [
        DefaultStringOperators.EQUALS,
        DefaultStringOperators.NOT_EQUALS,
        DefaultStringOperators.CONTAINS,
        DefaultStringOperators.BEGINS_WITH,
        DefaultStringOperators.ENDS_WITH,
        DefaultStringOperators.DOES_NOT_CONTAIN,
        DefaultStringOperators.DOES_NOT_BEGIN_WITH,
        DefaultStringOperators.DOES_NOT_END_WITH,
    ];
})(DefaultStringOperators || (DefaultStringOperators = {}));
