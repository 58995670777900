import { clsx } from 'clsx';
import React, {
  type ReactNode,
  cloneElement,
  isValidElement,
  type ReactElement,
  useMemo,
} from 'react';

import Label from './Label';

interface Props {
  className?: string;
  id?: string;
  label?: ReactNode | string;
  required?: boolean;
  children?: ReactNode;
  error?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  floatingLabel?: boolean;
  isHighlighted?: boolean;
}

export default function Wrapper({
  className,
  id,
  label,
  required,
  children,
  error,
  errorText,
  showErrorText = true,
  floatingLabel = false,
  isHighlighted = false,
}: Props) {
  const classes = clsx(
    'flex flex-col space-y-1',
    { 'relative pt-1': floatingLabel },
    className,
  );

  const LabelComponent = useMemo(() => {
    // Label is always either a react component or a string
    if (typeof label === 'string') {
      return (
        <Label
          error={error}
          floatingLabel={floatingLabel}
          id={id}
          label={label}
          required={required}
          isHighlighted={isHighlighted}
        />
      );
    }

    const _isValidElement = isValidElement(label);

    if (_isValidElement) {
      // The label is a React component, we clone it and add handling for if it is highlighted
      return cloneElement(label as ReactElement<{ className?: string }>, {
        className: clsx(
          (label as ReactElement<{ className?: string }>).props.className,
          {
            '!text-info': isHighlighted,
          },
        ),
      });
    }

    return label;
  }, [label, error, floatingLabel, isHighlighted, required, id]);

  return (
    <div className={classes}>
      {LabelComponent}
      <div className="w-full relative">{children}</div>

      {error && showErrorText ? (
        <span className="text-xs text-red-500 font-medium">
          {errorText ?? 'Invalid Entry'}
        </span>
      ) : null}
    </div>
  );
}
