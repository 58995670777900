"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDatasourceSchema = void 0;
const types_shared_1 = require("types-shared");
exports.createDatasourceSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.DatasourceMetadata.pick({
            workflowId: true,
            name: true,
            description: true,
        }),
    }),
    response: types_shared_1.DatasourceMetadata,
};
