import { gql } from '@apollo/client';
import { getUtcDate, getUtcTime } from './helper';
import { ExecutionStatusEnum } from 'types-shared/executionTypes';

export const generateExecutionHourlyMetricsQuery = (
  dateRange: string[],
  timeRanges: string[][],
  memberId?: string,
) => {
  const queryFields = timeRanges
    .map(([key, startTime, endTime]) => {
      const [startDate, endDate] = dateRange;
      const utcStartDate = getUtcDate(startDate);
      const utcEndDate = getUtcDate(endDate);
      const utcStartTime = getUtcTime(startTime);
      const utcEndTime = getUtcTime(endTime);
      return `
      ${key}_executed: executions_aggregate(
        where: {
          adminRun: { _eq: false }
          finishedAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
          status: { _in: [SUCCESS, FAILED, TERMINATED, TIMEOUT] }
          ${
            utcStartTime > utcEndTime
              ? `_or: [{finishedAtTime: {_gt: "${utcStartTime}", _lte: "23:59:59+00:00"}}, {finishedAtTime: {_gt: "00:00:00+00:00", _lt: "${utcEndTime}"}}]`
              : `finishedAtTime: { _gt: "${utcStartTime}", _lt: "${utcEndTime}" }`
          }
          ownerId: { ${memberId ? `_eq: "${memberId}"` : '_is_null: false'} }
        }
      ) {
        aggregate {
          count
        }
      }
      ${key}_queued: executions_aggregate(
        where: {
          adminRun: { _eq: false }
          createdAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
          ${
            utcStartTime > utcEndTime
              ? `_or: [{createdAtTime: {_gt: "${utcStartTime}", _lte: "23:59:59+00:00"}}, {createdAtTime: {_gt: "00:00:00+00:00", _lt: "${utcEndTime}"}}]`
              : `createdAtTime: { _gt: "${utcStartTime}", _lt: "${utcEndTime}" }`
          }
          ownerId: { ${memberId ? `_eq: "${memberId}"` : '_is_null: false'} }
        }
      ) {
        aggregate {
          count
        }
      }
    `;
    })
    .join('\n');

  return gql`
    query GetExecutionHourlyMetrics {
      ${queryFields}
    }
  `;
};

const getCumulativeQueryFields = (key: string, utcEndDate: string) => {
  return `
    ${key}_cumulative: workflows_aggregate(
      where: {
        deletedAt: { _is_null: true }
        createdAt: { _lt: "${utcEndDate}" }
      }
    ) {
      aggregate {
        count
      }
    }
  `;
};

export const generateMonthWiseWorkflowsCountQuery = (
  dateRanges: string[][],
  memberId?: string,
  includeCumulative?: boolean,
) => {
  const queryFields = dateRanges.map((dateRange: string[]) => {
    const [key, startDate, endDate] = dateRange;
    const utcStartDate = getUtcDate(startDate);
    const utcEndDate = getUtcDate(endDate);
    return `
      ${key}_total: workflows_aggregate(
        where: {
          deletedAt: { _is_null: true }
          createdAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
          ownerId: { ${memberId ? `_eq: "${memberId}"` : '_is_null: false'} }
        }
      ) {
        aggregate {
          count
        }
      }
      ${includeCumulative ? getCumulativeQueryFields(key, utcEndDate) : ''}
    `.trim();
  });

  return gql`
    query GetWorkflowCountInTimeRange {
      ${queryFields}
    }
  `;
};

const getExecutionStatusQueryFields = (
  key: string,
  commonConditions: string,
) => {
  return `
    ${key}_${ExecutionStatusEnum.Success}: executions_aggregate(
      where: {
        ${commonConditions}
        status: { _in: [SUCCESS] }
      }
    ) {
      aggregate {
        count
      }
    }
    ${key}_${ExecutionStatusEnum.Running}: executions_aggregate(
      where: {
        ${commonConditions}
        status: {
          _in: [
            RUNNING,
            PAUSED,
            RETRY,
            PENDING_USER,
            PENDING_ADMIN,
            PENDING_SYSTEM,
            QUEUED
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    ${key}_${ExecutionStatusEnum.Failed}: executions_aggregate(
      where: {
        ${commonConditions}
        status: { _in: [FAILED, TERMINATED, TIMEOUT, CANCELED] }
      }
    ) {
      aggregate {
        count
      }
    }
  `;
};

export const generateMonthWiseExecutionsCountQuery = (
  dateRanges: string[][],
  memberId?: string,
  includeStatusQueries?: boolean,
) => {
  const queryFields = dateRanges.map((dateRange: string[]) => {
    const [key, startDate, endDate] = dateRange;
    const utcStartDate = getUtcDate(startDate);
    const utcEndDate = getUtcDate(endDate);
    const commonConditions = `
      adminRun: { _eq: false }
      createdAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
      ownerId: { ${memberId ? `_eq: "${memberId}"` : '_is_null: false'} }
    `;
    return `
      ${key}_total: executions_aggregate(
        where: {
          ${commonConditions}
        }
      ) {
        aggregate {
          count
        }
      }
      ${includeStatusQueries ? getExecutionStatusQueryFields(key, commonConditions) : ''}
    `.trim();
  });

  return gql`
    query GetExecutionsCountInTimeRange {
      ${queryFields}
    }
  `;
};
