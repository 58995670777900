import {
  WorkflowConditionalNode,
  type WorkflowEdge,
  type WorkflowNode,
} from 'types-shared';
import { isAdmin } from '../../../../utils/env';

/**
 * Handles finding next node for conditional nodes by checking first valid branch
 *
 * Find the available branches
 * Check if the first branch is valid
 * If not, try the next branch
 * If all branches are invalid, return undefined
 */

const handleConditionalNodeForward = (
  node: WorkflowNode,
  edges: WorkflowEdge[],
  nodes: WorkflowNode[],
  startNodesOfExploredBranches: string[] = [],
): string | undefined => {
  try {
    const conditionalNode = WorkflowConditionalNode.parse(node);
    const allBranches = conditionalNode.data.branchesData?.map(
      (b) => b.branchId,
    );
    const allEdgesLeadingToBranches = edges
      .filter((e) => allBranches?.includes(e.id))
      .map((e) => e.target);
    const allBranchNodes = allEdgesLeadingToBranches.map((id) =>
      nodes.find((n) => n.id === id),
    );

    // Filter out already explored branches for non-admin users
    const usableNodes = isAdmin
      ? allBranchNodes
      : allBranchNodes.filter(
          (n) => n && !startNodesOfExploredBranches.includes(n.id),
        );

    // If no usable nodes left, we've exhausted all branches
    if (!usableNodes.length || !usableNodes[0]) {
      return undefined;
    }

    const startNodeOfNewBranchToExplore = usableNodes[0].id;
    const candidateNode = nodes.find(
      (n) => n.id === startNodeOfNewBranchToExplore,
    );

    // If the node is hidden and user is not admin, we need to explore further
    if (candidateNode?.hideFromUser && !isAdmin) {
      // Mark this branch as explored to avoid infinite loops
      const newStartNodesOfExploredBranches = [
        ...startNodesOfExploredBranches,
        startNodeOfNewBranchToExplore,
      ];

      // Try to find a valid node further down this branch
      const nextValidNodeInBranch = recurseNextValidNode(
        startNodeOfNewBranchToExplore,
        nodes,
        edges,
        'forward',
        newStartNodesOfExploredBranches,
      );

      if (nextValidNodeInBranch) {
        return nextValidNodeInBranch;
      }

      // If this branch doesn't have a valid node, try the next branch by recursively
      // calling handleConditionalNodeForward with the updated explored branches list.
      // This ensures we systematically try all branches until we find a valid node.
      // If there is no node, we eventually return undefined
      return handleConditionalNodeForward(
        node,
        edges,
        nodes,
        newStartNodesOfExploredBranches,
      );
    }

    return candidateNode?.id;
  } catch {
    return undefined;
  }
};

/**
 * Recursively finds the next valid node in the workflow graph
 * @param currentNodeId - ID of current node to start search from
 * @param nodes - Array of all workflow nodes
 * @param edges - Array of edges connecting nodes
 * @param direction - Whether to search forward or backward in the graph
 */
const recurseNextValidNode = (
  currentNodeId: string,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  direction: 'forward' | 'backward',
  startNodesOfExploredBranches: string[] = [],
) => {
  const currentNode = nodes.find((n) => n.id === currentNodeId);
  if (!currentNode) return undefined;

  let candidateNodeId: string | undefined;

  const isConditionalNode =
    WorkflowConditionalNode.safeParse(currentNode).success;

  if (isConditionalNode && direction === 'forward') {
    // Since we are calling this recursively, even though initially we only receive non condittional nodes,
    // while recursing we might end up with a conditional node
    WorkflowConditionalNode.parse(currentNode);
    candidateNodeId = handleConditionalNodeForward(
      currentNode,
      edges,
      nodes,
      startNodesOfExploredBranches,
    );
  } else {
    const edge = edges.find(
      direction === 'forward'
        ? (e) => e.source === currentNodeId
        : (e) => e.target === currentNodeId,
    );

    candidateNodeId = direction === 'forward' ? edge?.target : edge?.source;

    if (!candidateNodeId) {
      return undefined;
    }

    if (!isAdmin) {
      const candidateNode = nodes.find((n) => n.id === candidateNodeId);

      if (candidateNode?.hideFromUser) {
        return recurseNextValidNode(candidateNodeId, nodes, edges, direction);
      }
    }
  }

  return candidateNodeId;
};

/**
 * Recursively finds the next valid node in the workflow graph
 * @param currentNodeId - ID of current node to start search from
 * @param nodes - Array of all workflow nodes
 * @param edges - Array of edges connecting nodes
 * @param direction - Whether to search forward or backward in the graph
 * @returns ID of next valid node if found, undefined otherwise
 */

export const findNextValidNode = (
  currentNodeId: string,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  direction: 'forward' | 'backward',
  startNodesOfExploredBranches: string[] = [],
): string | undefined => {
  const currentNode = nodes.find((n) => n.id === currentNodeId);
  if (!currentNode) return undefined;

  let candidateNodeId: string | undefined;

  const isConditionalNode =
    WorkflowConditionalNode.safeParse(currentNode).success;

  // Handle conditional nodes specially when going forward
  if (direction === 'forward' && isConditionalNode) {
    candidateNodeId = handleConditionalNodeForward(currentNode, edges, nodes);
  } else {
    candidateNodeId = recurseNextValidNode(
      currentNodeId,
      nodes,
      edges,
      direction,
      startNodesOfExploredBranches,
    );
  }

  if (!candidateNodeId) {
    return undefined;
  }

  return candidateNodeId;
};
