"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutionDocument = exports.booleanStringTransform = void 0;
const types_shared_1 = require("types-shared");
exports.booleanStringTransform = types_shared_1.z
    .string()
    .or(types_shared_1.z.boolean())
    .transform((val) => val === 'true' || val === true)
    .optional();
exports.ExecutionDocument = types_shared_1.z.object({
    mediaType: types_shared_1.z.string(),
    s3Key: types_shared_1.z.string(),
    uri: types_shared_1.z.string(),
    variableId: types_shared_1.z.string().nullable(),
});
