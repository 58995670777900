import { type WorkflowNode } from 'types-shared';
import { Button, ChevronLeft, ChevronRight } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import { useNextAndPrevNode, useNavigateBetweenNodes } from './hooks';

interface Props {
  node: WorkflowNode;
  insideNodeSubSection?: boolean;
  onExitNodeSubSection?: () => void;
  exitNodeSubSectionTitle?: string;
  onNextWillHideFlow?: boolean;
}
export default function NodeHeader({
  node,
  insideNodeSubSection,
  onExitNodeSubSection,
  exitNodeSubSectionTitle,
  onNextWillHideFlow,
}: Props) {
  const { nodes, edges } = EditorStore();
  const { nextNodeId, prevNodeId } = useNextAndPrevNode({
    currentNodeId: node.id,
    nodes,
    edges,
  });

  const { handleNavigateToNode } = useNavigateBetweenNodes();

  return (
    <div
      className="flex flex-row justify-between items-center px-8 border-b border-gray-200 sticky top-0 bg-white z-[10]"
      style={{ height: '64px', minHeight: '64px' }}
    >
      {insideNodeSubSection && exitNodeSubSectionTitle ? (
        <Button
          startIcon={
            <div className="!-translate-y-[0.12rem]">
              <ChevronLeft className="!h-6 !w-6" />
            </div>
          }
          onClick={onExitNodeSubSection}
          variant="text"
          color="secondary"
        >
          {exitNodeSubSectionTitle}
        </Button>
      ) : null}

      {insideNodeSubSection ? null : (
        <Button
          startIcon={
            <div className="!-translate-y-[0.12rem]">
              <ChevronLeft className="!h-6 !w-6" />
            </div>
          }
          onClick={() => {
            if (prevNodeId) {
              handleNavigateToNode(prevNodeId, onNextWillHideFlow);
            }
          }}
          variant="text"
          color="secondary"
          disabled={!prevNodeId}
        >
          PREV
        </Button>
      )}

      <p className="text-sm text-info-dark font-medium">{`Step ID: ${node.id.slice(0, 5)}`}</p>

      {insideNodeSubSection ? null : (
        <Button
          endIcon={
            <div className="!-translate-y-[0.12rem]">
              <ChevronRight className="!h-6 !w-6" />
            </div>
          }
          onClick={() => {
            if (nextNodeId) {
              handleNavigateToNode(nextNodeId, onNextWillHideFlow);
            }
          }}
          variant="text"
          color="secondary"
          disabled={!nextNodeId}
        >
          NEXT
        </Button>
      )}
    </div>
  );
}
