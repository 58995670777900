import { type SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import values from 'lodash/values';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { handleException } from 'sentry-browser-shared';
import {
  ActionsEnum,
  ActionValueCriteriaEnum,
  DocumentSourceEnum,
  KeyTypesEnum,
  parseTemplateString,
  SourceTypeEnum,
  SourceVariable,
  type TemplateData,
  type Variable,
  VariableString,
  VariableTypeEnum,
  WorkflowActionV2Base,
  WorkflowImageNode,
  type DocumentVariable,
  type MultiChoiceVariable,
  type MultiSelectVariable,
  type Rule,
  type SelectOption,
  type SelectVariable,
  type WorkflowAction,
  type WorkflowNode,
} from 'types-shared';
import {
  Button,
  Flex,
  IconButton,
  InfoOutlined,
  Input,
  Menu,
  MenuItem,
  MoreVert,
  Select as SelectUIKit,
  Tooltip,
} from 'ui-kit';
import { VariableChip } from '../../../../../components/VariableChip';
import { FeatureFlag } from '../../../../../utils/constants';
import { isAdmin } from '../../../../../utils/env';
import {
  openAddVariableModal,
  openPreviewVariableModal,
  useFeatureFlag,
} from '../../../../../utils/helper';
import { useTransformApiReq } from '../../../hooks';
import { EditorStore } from '../../../store/EditorState';
import { pickFromListOptions } from '../../../utils/helper';
import { type ImageNodeValidationResult } from '../../../utils/nodeValidations';
import { ConditionalField } from '../../Conditions/ConditionalField';
import { imageNodeEventChannel } from '../../NodeElement/SelectedImageNodeContent';
import { Select } from '../../Select';
import MultiSelect from '../../VariableTypes/MultiSelect';
import { MultipleChoice } from '../../VariableTypes/MultipleChoice';
import {
  VariableInput,
  type VariableInputRef,
} from '../../VariableTypes/VariableInput';
import { LEGACY_ACTION_TYPES, type SelectedAction } from '../ActionsList';
import { ArbitraryAction } from '../ArbitraryActions';
import DocumentVariables from '../DocumentVariables';
import { type RenderPdfProps } from '../DocumentVariables/AnnotatedPDF';
import EditDocumentAction from './EditDocumentAction';
import { EditV2Action } from './EditV2Action';
import isNil from 'lodash/isNil';

const actionTypeToTitleMapping: Record<string, string> = {
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
  [ActionsEnum.UploadDocument]: 'File upload',
  [ActionsEnum.SwitchTab]: 'Switch tab',
  [ActionsEnum.PickFromList]: 'Pick from list',
  [ActionsEnum.MultiSelect]: 'Multi Select',
  [ActionsEnum.MagicLoop]: 'Begin Magic Loop',
};

const keyPressOptions = Object.values(KeyTypesEnum) as string[];

const onActionHover = (targetId: string) => {
  imageNodeEventChannel.emit('onActionHover', targetId);
};

const onBlur = () => {
  imageNodeEventChannel.emit('onBlur', null);
};

const customInstructionsKey = pickFromListOptions[2];

interface Props {
  i: number;
  action: WorkflowAction;
  setSelectedAction: (val: null | SelectedAction) => void;
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
  validationData: ImageNodeValidationResult;
  validationAttempted: boolean;
  handleValidateNode: () => void;
}

function EditAction({
  i,
  action,
  setSelectedAction,
  setAnnotatedPDFData,
  validationData,
  validationAttempted,
}: Props) {
  const {
    datasourceMetadata,
    variables: variablesMap,
    globalVariables: globalVariablesMap = {},
    updateVariable,
    tableData,
    addVariable,
    updateImageNodeAction,
    selectedNode,
    nodes,
    workflowId,
    targets,
  } = EditorStore();

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const onTransformApiReq = useCallback(
    async (prompt: TemplateData, textToTransform: string, model?: string) => {
      if (prompt.length > 0 && textToTransform) {
        const value = await transformApiReq({
          data: textToTransform,
          prompt,
          model,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformApiReq],
  );

  const selectionByConditionsEnabled = useFeatureFlag(
    FeatureFlag.SelectionByConditions,
  );

  const { variableId, actionType, criteria, targetId, rules } = action;

  const isV2Action = useMemo(() => {
    try {
      WorkflowActionV2Base.parse(action);
      return true;
    } catch (e) {
      return !LEGACY_ACTION_TYPES.includes(actionType);
    }
  }, [action, actionType]);

  const selectedAction = useMemo(() => {
    const node = nodes.find(({ id }) => id === selectedNode);
    if (!node || !WorkflowImageNode.safeParse(node).success) {
      return null;
    }
    return (node as WorkflowImageNode).data.actionData[action.id];
  }, [action.id, nodes, selectedNode]);
  const nonBlocking = Boolean(selectedAction?.options?.nonBlocking);
  const hasDatasource = Boolean(datasourceMetadata);
  const variable = variableId
    ? variablesMap[variableId] ?? globalVariablesMap[variableId]
    : null;
  const target = targets[targetId ?? ''];
  const isArbitraryAction = actionType === ActionsEnum.Arbitrary;

  const inputVariableInputRef = useRef<VariableInputRef>();
  const tabVariableInputRef = useRef<VariableInputRef>();

  const [selectedTabVariableId, setSelectedTabVariableId] = useState<string>(
    variableId ?? '',
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const tabVariableOptions = useMemo(() => {
    return values(variablesMap)
      .filter(({ type }: Variable) => type === VariableTypeEnum.Tab)
      .map((_var) => ({
        text: _var.name ?? 'Unnamed tab',
        value: _var.id,
      }));
  }, [variablesMap]);

  const [selectionType, setSelectionType] =
    useState<ActionValueCriteriaEnum | null>(criteria ?? null);

  const isUploadAction =
    !(actionType === ActionsEnum.Click && action.options?.download) &&
    (action.actionType === ActionsEnum.UploadDocument ||
      Boolean(variable && variable.type === VariableTypeEnum.Document));

  const isDownloadAction =
    (!isUploadAction && actionType === ActionsEnum.Download) ||
    (actionType === ActionsEnum.Click && action.options?.download);

  const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;

  const isDocumentScrape = useMemo(() => {
    if (
      !(action.actionType === ActionsEnum.UploadDocument) &&
      variable &&
      variable.type === VariableTypeEnum.Document &&
      variable.data.source === DocumentSourceEnum.Execution
    ) {
      return true;
    }
    return false;
  }, [variable, action]);

  const isHighlighted = useMemo(() => {
    return Boolean(
      validationAttempted &&
        action.variableId &&
        validationData.validations.variablesThatHaveNoContent[
          action.variableId
        ],
    );
  }, [
    validationAttempted,
    action,
    validationData.validations.variablesThatHaveNoContent,
  ]);

  const actionTitle = useMemo(() => {
    if (Boolean(isDownloadAction) || isDocumentScrape) {
      return 'Download';
    }

    return actionTypeToTitleMapping[actionType] ?? actionType;
  }, [isDownloadAction, isDocumentScrape, actionType]);

  const onDetermineSelectionType =
    (newSelectionType: ActionValueCriteriaEnum | null) => () => {
      setSelectionType(newSelectionType);

      onMenuClose();
    };

  const {
    isScrape,
    isSelect,
    isMultiChoice,
    isInput,
    isSwitchTab,
    isNewTab,
    isPickFromList,
    isKeyPress,
    isMultiSelect,
    isWait,
    isRefresh,
    isMultiSelectVariable,
  } = useMemo(
    () => ({
      isSelect: actionType === ActionsEnum.Select,
      isScrape: actionType === ActionsEnum.Scrape,
      isMultiChoice: actionType === ActionsEnum.MultiChoice,
      isInput:
        actionType === ActionsEnum.Input ||
        actionType === ActionsEnum.Arbitrary,
      isSwitchTab: actionType === ActionsEnum.SwitchTab,
      isNewTab: actionType === ActionsEnum.NewTab,
      isPickFromList: actionType === ActionsEnum.PickFromList,
      isKeyPress:
        actionType === ActionsEnum.KeyPress ||
        actionType === ActionsEnum.KeyUnpress,
      isMultiSelect: actionType === ActionsEnum.MultiSelect,
      isWait: actionType === ActionsEnum.Wait,
      isRefresh: actionType === ActionsEnum.Refresh,
      isMultiSelectVariable:
        actionType === ActionsEnum.MultiSelect &&
        selectionType === ActionValueCriteriaEnum.Variable,
    }),
    [actionType, selectionType],
  );

  const variableIsEditable =
    !isScrape &&
    !isRefresh &&
    selectionType !== ActionValueCriteriaEnum.Condition &&
    !isDownloadAction &&
    !isDocumentScrape;

  useEffect(() => {
    if (targetId) {
      onActionHover(targetId);
    }

    return () => {
      onBlur();
    };
  }, [targetId]);

  const { initialVariableData, initialVariableName } = useMemo(() => {
    const payload: {
      initialVariableData: TemplateData;
      initialVariableName: string;
    } = {
      initialVariableData: [],
      initialVariableName: '',
    };

    const initialVariable = variableId
      ? variablesMap[variableId] ?? globalVariablesMap[variableId]
      : null;

    if (initialVariable) {
      const data: TemplateData = (() => {
        switch (initialVariable.type) {
          case VariableTypeEnum.Document:
          case VariableTypeEnum.Scrape:
          case VariableTypeEnum.Query:
          case VariableTypeEnum.Source:
          case VariableTypeEnum.EmailTrigger:
            return [];
          case VariableTypeEnum.Tab:
            return initialVariable.data.url;
          default:
            return initialVariable.data;
        }
      })();

      payload.initialVariableData = data;
      payload.initialVariableName = initialVariable.name ?? '';
    }

    return payload;
  }, [variablesMap, globalVariablesMap, variableId]);

  const [variableData, setVariableData] =
    useState<TemplateData>(initialVariableData);
  const [variableName, setVariableName] = useState<string>(initialVariableName);
  const prevInputData = useRef<TemplateData>([]);

  useEffect(() => {
    setVariableData(initialVariableData);
    setVariableName(initialVariableName);
  }, [initialVariableData, initialVariableName]);

  const saveVariableChanges = (updatedAction?: WorkflowAction) => {
    const wasUsingUploadDocument = isUploadAction && workflowId;
    if (wasUsingUploadDocument) {
      return;
    }
    let updatedVariable = {
      ...variable,
    };

    if (isV2Action) return;

    if (Boolean(isDownloadAction) || isDocumentScrape) {
      updatedVariable = {
        ...updatedVariable,
        name: variableName,
      };
    } else if (actionType === ActionsEnum.MultiChoice) {
      const selectedChoiceIx = variableData[0] ? Number(variableData[0]) : null;
      updatedVariable = {
        ...updatedVariable,
        selectedChoiceIx,
        ...(selectedChoiceIx !== null
          ? { data: [String(selectedChoiceIx)] }
          : {}),
      };
    } else if (actionType === ActionsEnum.NewTab) {
      updatedVariable = {
        ...updatedVariable,
        name: (variableName || variable?.name) ?? '',
        data: {
          url: variableData,
        },
      };
    } else if (actionType === ActionsEnum.MultiSelect) {
      const newVariable = updatedVariable as MultiSelectVariable;
      updatedVariable = {
        ...newVariable,
        data:
          selectionType === ActionValueCriteriaEnum.Variable
            ? variableData
            : [],
        multiSelectedOptions:
          selectionType !== ActionValueCriteriaEnum.Variable
            ? newVariable.multiSelectedOptions
            : [],
      };
    } else if (actionType === ActionsEnum.Wait) {
      const waitTime = variableData[0];
      if (typeof waitTime === 'string' && !isNil(waitTime)) {
        let waitTimeStr = waitTime;
        if (waitTimeStr && !waitTimeStr.includes('ms')) {
          waitTimeStr = `${waitTimeStr}ms`;
        }
        updatedVariable = {
          ...updatedVariable,
          data: [waitTimeStr],
        };
      }
    } else if (
      actionType === ActionsEnum.SwitchTab ||
      actionType === ActionsEnum.UploadDocument
    ) {
      // For SwitchTabno variable to update
      // just need to update the action directly
      // For UploadDocument, The variable is updated in the EditDocumentAction component
    } else {
      updatedVariable = {
        ...updatedVariable,
        data: variableData,
      };
    }
    updateVariable(updatedVariable as Variable);
    if (
      selectedNode &&
      !isMagicLoopAction &&
      !isDownloadAction &&
      !isDocumentScrape &&
      (Boolean(selectionType) || isMultiSelect || isSwitchTab)
    ) {
      updateImageNodeAction(selectedNode, {
        ...(updatedAction ?? action),
        criteria: selectionType ?? undefined,
        variableId: isSwitchTab ? selectedTabVariableId : variableId,
      });
    }
  };

  const waitTimeValue = useMemo(() => {
    const waitTime = variableData[0];
    if (actionType !== ActionsEnum.Wait || isNil(waitTime)) return '';
    if (typeof waitTime === 'string') {
      return waitTime.replace('ms', '');
    }
    return waitTime;
  }, [actionType, variableData]);

  const onAddNewVariable = (indexForVariableInsert?: number) => {
    saveVariableChanges();
    openAddVariableModal({
      insertVariable(newVariable: Variable) {
        inputVariableInputRef.current?.addVariable(
          newVariable,
          indexForVariableInsert,
        );
      },
    });
  };

  const onPreviewVariable = (variableID: string) => {
    openPreviewVariableModal({
      variableId: variableID,
    });
  };

  const onPreviewVariableV2 = (vId: string) => {
    !isScrape && saveVariableChanges();
    onPreviewVariable(vId);
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const pickFromListValue = useMemo(() => {
    if (!variable || !('data' in variable) || !Array.isArray(variable.data)) {
      return undefined;
    }
    return (variable.data as TemplateData)[0] as string;
  }, [variable]);

  const pickFromListType = useMemo(() => {
    if (!pickFromListValue) {
      return pickFromListOptions[0];
    }
    return !pickFromListOptions.includes(pickFromListValue)
      ? customInstructionsKey
      : pickFromListValue;
  }, [pickFromListValue]);

  const handleOnPickFromListSelect = (event: SelectChangeEvent) => {
    const type = event.target.value;
    if (selectedNode) {
      updateImageNodeAction(selectedNode, {
        ...action,
        options: {
          ...(action.options ?? {}),
          sitl: type !== customInstructionsKey,
        },
      });
    }
    updateVariable({
      ...variable,
      data: [event.target.value],
    } as Variable);
  };

  const sourceType = useMemo(() => {
    const sourceVariable = Object.values(variablesMap).find(
      (v) => SourceVariable.safeParse(v).success,
    ) as SourceVariable | undefined;
    return sourceVariable?.data.sourceType;
  }, [variablesMap]);

  const switchActionPreview = useMemo(() => {
    if (actionType !== ActionsEnum.SwitchTab) return null;

    const selectedTabVar =
      variablesMap[selectedTabVariableId] ??
      globalVariablesMap[selectedTabVariableId];
    if (selectedTabVar.type !== VariableTypeEnum.Tab) return null;

    return VariableString.parse(
      parseTemplateString({
        data: selectedTabVar.data.url,
        variableMap: variablesMap,
        handleException,
      }),
    );
  }, [actionType, selectedTabVariableId, variablesMap, globalVariablesMap]);

  // Fix for variable input re-renders
  useEffect(() => {
    if (
      isNewTab ||
      (!isWait &&
        !isInput &&
        !isMagicLoopAction &&
        !isMultiSelectVariable &&
        !(isPickFromList && pickFromListType === customInstructionsKey))
    )
      return;

    if (variableData.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = variableData;
      inputVariableInputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    variableData,
    prevInputData.current,
    isWait,
    isInput,
    isMagicLoopAction,
    isMultiSelectVariable,
    isPickFromList,
    pickFromListType,
    isNewTab,
  ]);

  // Fix for new tab action
  useEffect(() => {
    if (!isNewTab) return;

    if (variableData.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = variableData;
      tabVariableInputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variableData, prevInputData.current, isNewTab]);

  if (!variable && actionType !== ActionsEnum.UploadDocument) {
    handleException(new Error(), {
      name: 'Variable not found',
      source: 'Editor/EditAction',
      extra: {
        variableId,
        actionType,
        selectedNode,
      },
    });
    return null;
  }

  const onSaveAndExit = () => {
    saveVariableChanges();
    setSelectedAction(null);
  };

  return (
    <div className="flex-1 flex flex-col relative pt-5 px-8 pb-10">
      <div className="flex justify-between items-center">
        <h2 className="flex items-center space-x-3 w-full">
          {!isMagicLoopAction ? (
            <span className="text-xs text-white rounded-full h-6 w-6 flex justify-center items-center bg-gray-800">
              {i}
            </span>
          ) : null}
          <span className="font-medium text-lg w-full truncate">
            {actionTitle}
          </span>
        </h2>
        {!isScrape &&
        !isPickFromList &&
        !isDownloadAction &&
        !isV2Action &&
        !isMagicLoopAction ? (
          <IconButton
            className="!p-0"
            onClick={(e) => {
              setAnchorEl(e.target as HTMLButtonElement);
            }}
          >
            <MoreVert className="!w-5 !h-5 text-black" />
          </IconButton>
        ) : null}
      </div>
      {!isScrape &&
      !isPickFromList &&
      !isDownloadAction &&
      !isDocumentScrape ? (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={onMenuClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isMultiSelect &&
          selectionType !== ActionValueCriteriaEnum.Variable ? (
            <MenuItem
              className="!font-medium"
              onClick={onDetermineSelectionType(
                ActionValueCriteriaEnum.Variable,
              )}
            >
              <span className="font-medium mr-4">
                Determine selection by variable
              </span>
            </MenuItem>
          ) : null}
          {!isMultiSelect ||
          selectionType === ActionValueCriteriaEnum.Variable ? (
            <MenuItem
              className="!font-medium"
              // TODO: In case of multi choice we treat Variable selection as constant selection
              // ask manu for more info
              onClick={onDetermineSelectionType(
                isMultiSelect ? null : ActionValueCriteriaEnum.Variable,
              )}
            >
              <span className="font-medium mr-4">
                Determine selection by constant
              </span>
            </MenuItem>
          ) : null}
          {!isMultiSelect ? (
            // This is a multichoice action
            <Tooltip
              arrow
              title="Determine the value of the action using AI decision-making. Contact sales to learn more!"
              hidden={selectionByConditionsEnabled}
            >
              <MenuItem
                className="!font-medium"
                disabled={!selectionByConditionsEnabled}
                onClick={onDetermineSelectionType(
                  ActionValueCriteriaEnum.Condition,
                )}
              >
                <span className="font-medium mr-4">
                  Determine selection by conditions
                </span>
              </MenuItem>
            </Tooltip>
          ) : null}
        </Menu>
      ) : null}

      {Boolean(isDownloadAction) || isDocumentScrape ? (
        <>
          <div className="text-sm my-4 text-gray-500">
            The downloaded document is included as an output and saved as a
            variable.
          </div>

          {/* Not sure if this is correct */}
          {(isDocumentScrape || Boolean(isDownloadAction)) &&
          workflowId &&
          variable ? (
            <DocumentVariables
              node={selectedNodeData as WorkflowImageNode}
              workflowId={workflowId}
              variable={variable as DocumentVariable}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              action={action}
              setAnnotatedPDFData={setAnnotatedPDFData}
              onPreviewVariable={() => {
                onPreviewVariable(variable.id);
              }}
            />
          ) : null}
        </>
      ) : null}
      {isMagicLoopAction ? (
        <>
          <span className="text-gray-500 text-sm my-4">
            A potential loop has been found during workflow processing.
          </span>
          <VariableInput
            allowAddVariable={false}
            value={variableData}
            label="Loop Name"
            onChange={setVariableData}
            placeholder="Add a name"
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            showPlusButton={false}
            ref={inputVariableInputRef}
          />
          <div className="mt-8 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded">
            <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
            <span className="text-primary-blue-dark text-sm">
              Separate each item with a comma (",") for each of your variables
              while calling this using the "Run workflow" or the API.
            </span>
          </div>
        </>
      ) : null}
      {isPickFromList ? (
        <div className="flex flex-col space-y-2 text-sm mt-10">
          <b>Choose how to select the item(s) from the list</b>
          <span className="text-gray-500 font-medium">
            For complex actions or unsupported tasks, please share your
            instructions or requests, and we'll integrate them into your
            workflows.
          </span>
        </div>
      ) : null}

      {isArbitraryAction ? (
        <ArbitraryAction
          action={action}
          node={selectedNodeData as WorkflowImageNode}
          onSaveAndExit={onSaveAndExit}
          isHighlighted={isHighlighted}
        />
      ) : null}

      {isV2Action ? (
        <EditV2Action
          action={WorkflowActionV2Base.parse(action)}
          node={selectedNodeData as WorkflowImageNode}
          onSaveAndExit={onSaveAndExit}
          updateVariable={updateVariable}
        />
      ) : null}

      {!isUploadAction &&
      (selectionType === ActionValueCriteriaEnum.Variable ||
        isScrape ||
        (!selectionType && !criteria) ||
        (!selectionType && criteria === ActionValueCriteriaEnum.Variable)) &&
      !isDownloadAction &&
      !isMagicLoopAction &&
      !isDocumentScrape &&
      !isArbitraryAction &&
      !isV2Action ? (
        <>
          <div className="my-10 border rounded-lg px-4 py-6 text-sm flex flex-col">
            {isScrape ? (
              <span className="text-gray-500 mb-6">
                Content scraped during the execution is automatically
                transformed into a variable. Click to preview its content.
              </span>
            ) : null}

            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {!isSwitchTab && !isNewTab && !isUploadAction ? (
              <span className="font-medium mb-4 text-info-dark">Value</span>
            ) : null}

            {isMultiSelect &&
            selectionType === ActionValueCriteriaEnum.Variable ? (
              <span className="text-gray-500 mb-6">
                Tie the values of the multiselect to a variable. If multiple
                values may be checked, separate the values in the variable with
                semicolons or commas. Variable value(s) are matched (best fit)
                to multi-select values (case insensitive).
              </span>
            ) : null}

            {isSelect ? (
              <span className="text-gray-500 mb-6">
                You can modify the selected option by adding variables that
                match the items in the dropdown.
              </span>
            ) : null}

            {isInput ? (
              <VariableInput
                allowAddVariable={
                  hasDatasource ||
                  sourceType === SourceTypeEnum.API ||
                  sourceType === SourceTypeEnum.EmailTrigger
                }
                className="mt-4"
                value={variableData}
                onClickAddNew={onAddNewVariable}
                onChange={setVariableData}
                onClickVariableChip={onPreviewVariableV2}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                ref={inputVariableInputRef}
                isHighlighted={isHighlighted}
              />
            ) : null}

            {isMultiChoice ? (
              <MultipleChoice
                className="mt-4"
                data={variableData}
                onChange={setVariableData}
                optionEditable={isAdmin}
                options={(variable as MultiChoiceVariable).multiChoiceOptions}
                updateVariable={updateVariable}
                variable={variable as MultiChoiceVariable}
              />
            ) : null}

            {isMultiSelect && !selectionType ? (
              <MultiSelect
                onChange={updateVariable}
                target={target}
                variable={variable as MultiSelectVariable}
              />
            ) : null}

            {isMultiSelect &&
            selectionType === ActionValueCriteriaEnum.Variable ? (
              <VariableInput
                allowAddVariable={
                  hasDatasource ||
                  sourceType === SourceTypeEnum.API ||
                  sourceType === SourceTypeEnum.EmailTrigger
                }
                className="mt-4"
                value={variableData}
                label="Add Variable"
                onClickAddNew={onAddNewVariable}
                onChange={setVariableData}
                onClickVariableChip={onPreviewVariableV2}
                placeholder="Add variable"
                ref={inputVariableInputRef}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                isHighlighted={isHighlighted}
              />
            ) : null}

            {isPickFromList ? (
              <>
                <SelectUIKit
                  className="mb-6"
                  color="secondary"
                  fullWidth
                  getLabel={(opt: string) => opt}
                  getValue={(opt: string) => opt}
                  label="Option(s) to pick"
                  name="option"
                  onChange={handleOnPickFromListSelect}
                  options={pickFromListOptions}
                  value={pickFromListType}
                  // isHighlighted={isHighlighted}
                />
                {pickFromListType === customInstructionsKey ? (
                  <div className="flex flex-col space-y-4 text-sm">
                    <p className="text-info-dark font-medium">
                      Describe how to pick an option from a list
                    </p>
                    <VariableInput
                      allowAddVariable={
                        hasDatasource ||
                        sourceType === SourceTypeEnum.API ||
                        sourceType === SourceTypeEnum.EmailTrigger
                      }
                      className="mt-4"
                      value={
                        variableData.length === 1 &&
                        variableData[0] === customInstructionsKey
                          ? []
                          : variableData
                      }
                      label="Instructions"
                      onClickAddNew={onAddNewVariable}
                      onChange={setVariableData}
                      onClickVariableChip={onPreviewVariableV2}
                      ref={inputVariableInputRef}
                      variablesMap={variablesMap}
                      globalVariablesMap={globalVariablesMap}
                      isHighlighted={
                        variableData.length === 1 &&
                        variableData[0] === customInstructionsKey
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            {isKeyPress ? (
              <SelectUIKit
                className="mb-6"
                color="secondary"
                fullWidth
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Key Type"
                name="key"
                onChange={(e) => {
                  if (selectedNode) {
                    updateImageNodeAction(selectedNode, {
                      ...action,
                      keyType: e.target.value as KeyTypesEnum,
                    });
                  }
                }}
                options={keyPressOptions}
                value={action.keyType}
              />
            ) : null}

            {variable && isSelect ? (
              <Select
                allowAddVariable={
                  hasDatasource ||
                  sourceType === SourceTypeEnum.API ||
                  sourceType === SourceTypeEnum.EmailTrigger
                }
                className="mt-4"
                data={variableData}
                label={variable.name ?? ''}
                onAddNew={onAddNewVariable}
                onChange={setVariableData}
                onPreview={onPreviewVariableV2}
                options={(variable as SelectVariable).selectOptions}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
              />
            ) : null}

            {isScrape && variable ? (
              <div className="">
                <VariableChip
                  onClick={() => {
                    const varId = variable.id;
                    onPreviewVariableV2(varId);
                  }}
                  variableId={variable.id}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                />
              </div>
            ) : null}

            {isWait ? (
              <div>
                <p className="text-slate-500 text-sm mb-8">
                  Enter the duration to wait in milliseconds
                </p>
                <Input
                  value={waitTimeValue}
                  label="Duration (ms)"
                  type="number"
                  floatingLabel
                  onChange={(v) => {
                    setVariableData([v]);
                  }}
                />
              </div>
            ) : null}

            {variable && variable.type === VariableTypeEnum.Tab && isNewTab ? (
              <div>
                <p className="text-cyan-900 text-sm font-medium mb-2">
                  Tab name
                </p>
                <p className="text-slate-500 text-sm mb-8">
                  Add a recognizable name to this tab.
                  <br />
                  The name will be required to switch between tabs.
                </p>

                <Input
                  classes={{ wrapper: 'mb-8' }}
                  floatingLabel
                  label="Tab name"
                  onChange={setVariableName}
                  value={variableName}
                />

                <p className="text-cyan-900 text-sm font-medium">New tab URL</p>

                <VariableInput
                  allowAddVariable={
                    hasDatasource || sourceType === SourceTypeEnum.API
                  }
                  className="mt-4"
                  value={variableData}
                  ref={tabVariableInputRef}
                  label="Tab URL"
                  onChange={setVariableData}
                  onClickAddNew={onAddNewVariable}
                  onClickVariableChip={onPreviewVariableV2}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                />
              </div>
            ) : null}

            {variable &&
            variable.type === VariableTypeEnum.Tab &&
            isSwitchTab ? (
              <div>
                <p className="text-cyan-900 text-sm font-medium mb-2">
                  Tab to switch
                </p>
                <p className="text-slate-500 text-sm mb-8">
                  Select the tab you want to switch to
                </p>

                <SelectUIKit<SelectOption>
                  color="secondary"
                  fullWidth
                  getLabel={(opt: SelectOption) => opt.text}
                  getValue={(opt: SelectOption) => opt.value}
                  label="Select tab"
                  name="actionType"
                  onChange={(e) => {
                    setSelectedTabVariableId(e.target.value as string);
                  }}
                  options={tabVariableOptions}
                  value={selectedTabVariableId as never}
                />

                <div className="grow flex-col pt-1.5 mt-8 bg-gray-100 rounded-tl-lg rounded-tr-lg w-full">
                  <p className="text-xs text-gray-500 px-3">
                    URL Value preview
                  </p>
                  <p className="text-base text-gray-500 mt-1 px-3 mb-1.5 truncate">
                    {switchActionPreview}
                  </p>
                  <div className="border border-dashed border-slate-300" />
                </div>
              </div>
            ) : null}
          </div>

          {isScrape && isAdmin ? (
            <div className="border rounded-lg px-4 py-6 flex justify-between items-center text-sm font-medium">
              <span className="text-info">Continue if the scrape fails</span>
              <Switch
                color="secondary"
                size="small"
                checked={nonBlocking}
                onChange={(_, newValue) => {
                  if (selectedNode && selectedAction) {
                    updateImageNodeAction(selectedNode, {
                      ...selectedAction,
                      options: {
                        ...selectedAction.options,
                        nonBlocking: newValue,
                      },
                    });
                  }
                }}
              />
            </div>
          ) : null}
        </>
      ) : null}

      {isUploadAction && workflowId ? (
        <EditDocumentAction
          action={action}
          node={selectedNodeData as WorkflowImageNode}
          variableId={variable?.id}
          updateVariable={updateVariable}
          workflowId={workflowId}
          hasDatasource={hasDatasource}
          sourceType={sourceType}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          variableData={variableData}
          setVariableData={setVariableData}
          onAddNewVariable={onAddNewVariable}
          onPreviewVariableV2={onPreviewVariableV2}
          onSaveAndExit={onSaveAndExit}
          validationData={validationData}
          validationAttempted={validationAttempted}
        />
      ) : null}

      {variable &&
      (selectionType === ActionValueCriteriaEnum.Condition ||
        (!selectionType && criteria === ActionValueCriteriaEnum.Condition)) ? (
        <ConditionalField
          addVariable={addVariable}
          datasourceMetadata={datasourceMetadata}
          defaultRules={rules}
          onCancel={() => {
            setSelectionType(null);
          }}
          onSave={(_rules: Rule[]) => {
            if (!selectedNode) return;
            const updatedAction = {
              ...action,
              rules: _rules,
              criteria: ActionValueCriteriaEnum.Condition,
            };
            updateImageNodeAction(selectedNode, updatedAction);
            setSelectedAction({ ...updatedAction, i });
            setSelectionType(null);
            saveVariableChanges(updatedAction);
          }}
          onTransformApiReq={onTransformApiReq}
          preview={!selectionType}
          tableData={tableData}
          transformApiReqStatus={transformApiReqStatus}
          sourceType={sourceType}
          updateVariable={updateVariable}
          variable={variable}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          validationData={validationData}
          validationAttempted={validationAttempted}
        />
      ) : null}

      {!isV2Action &&
      variableIsEditable &&
      !isArbitraryAction &&
      !isUploadAction ? (
        <>
          <span className="flex-1" />
          <Button
            className="!text-info !border-info !mt-5"
            color="secondary"
            onClick={onSaveAndExit}
            variant="outlined"
          >
            Save & Exit
          </Button>
        </>
      ) : null}

      {Boolean(isDownloadAction) || isDocumentScrape ? (
        <>
          <span className="flex-1" />
          <Flex className="gap-2">
            <Button
              className="!text-info !border-info !mt-5 flex-1"
              color="secondary"
              onClick={() => {
                setSelectedAction(null);
                setAnnotatedPDFData(undefined);
              }}
              variant="outlined"
            >
              BACK TO ACTIONS
            </Button>
          </Flex>
        </>
      ) : null}
    </div>
  );
}

export default EditAction;
