"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutAction = exports.KeyboardShortcutGroup = exports.KeyboardShortcutGroupEnum = void 0;
var zod_1 = require("zod");
var legacy_schema_1 = require("../legacy.schema");
var action_types_1 = require("../../../extensionTypes/action-types");
var helper_types_1 = require("../../../helper-types");
// See https://en.wikipedia.org/wiki/Table_of_keyboard_shortcuts
// for how we categorize keyboard shorcuts to sets;
//
// Appliction specific shortcuts will have its own set enum
var KeyboardShortcutGroupEnum;
(function (KeyboardShortcutGroupEnum) {
    KeyboardShortcutGroupEnum["Recorded"] = "Recorded";
    KeyboardShortcutGroupEnum["General"] = "General";
    KeyboardShortcutGroupEnum["Excel"] = "Excel General";
    KeyboardShortcutGroupEnum["ExcelDataTab"] = "Excel Data Tab";
    KeyboardShortcutGroupEnum["ExcelFileTab"] = "Excel File Tab";
    KeyboardShortcutGroupEnum["ExcelHomeTab"] = "Excel Home Tab";
    KeyboardShortcutGroupEnum["FileExplorer"] = "File Explorer";
})(KeyboardShortcutGroupEnum || (exports.KeyboardShortcutGroupEnum = KeyboardShortcutGroupEnum = {}));
exports.KeyboardShortcutGroup = zod_1.z.nativeEnum(KeyboardShortcutGroupEnum);
exports.KeyboardShortcutAction = legacy_schema_1.WorkflowActionV2Base.extend({
    actionType: zod_1.z.literal(action_types_1.ActionsEnum.KeyboardShortcut),
    shortcutGroup: exports.KeyboardShortcutGroup,
    shortcutType: zod_1.z.string(),
    parameters: zod_1.z.object({
        keySequences: helper_types_1.KeySequences,
        platform: helper_types_1.ActionPlatform,
    }),
});
