import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { WorkflowStatusEnum } from 'api-types-shared';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Add,
  Button,
  ContentContainer,
  CustomTypography,
  InfoOutlined,
  modalEventChannel,
  ScrollableContainer,
  Tooltip,
  WarningAmberOutlined,
} from 'ui-kit';
import EmptyStatePlaceholder, {
  EmptyStateEnum,
} from '../../../components/EmptyStatePlaceholder';
import ListSkeleton from '../../../components/ListSkeleton';
import { NavigationPanel } from '../../../components/NavigationPanel';
import usePersistedURLState from '../../../hooks/usePersistedURLState';
import { FeatureFlag } from '../../../utils/constants';
import { isAdmin } from '../../../utils/env';
import { useFeatureFlag } from '../../../utils/helper';
import { getTabTitle } from '../../../utils/tabTitle';
import CloneModal from '../components/CloneModal';
import ConcurrencyLimitModal from '../components/ConcurrencyLimitModal';
import RenameWorkflowModal from '../components/RenameWorkflowModal';
import WorkflowListFilter from '../components/WorkflowListFilter';
import WorkflowListMenu from '../components/WorkflowListMenu';
import WorkflowListMetrics from '../components/WorkflowListMetrics';
import {
  useDeleteWorkflow,
  useFetchWorkflowsListBatched,
  useUpdateWorkflowStatus,
  useWorkflowCols,
} from '../hooks';
import {
  workflowListFiltersSchema,
  type CompactWorkflowMetadata,
  type WorkflowListMenuProps,
} from '../types';
import { defaultWorkflowListFilters } from '../utils/constants';
import { useImportWorkflow } from '../../../hooks/useImportWorkflow';

export default function WorkflowsList() {
  const navigate = useNavigate();
  const [filters, setFilters] = usePersistedURLState(
    workflowListFiltersSchema,
    defaultWorkflowListFilters,
    'workflows-list-filters',
  );
  const [menuProps, setMenuProps] = useState<WorkflowListMenuProps | null>(
    null,
  );
  const [selectedWorkflowData, setSelectedWorkflowData] =
    useState<CompactWorkflowMetadata | null>(null);
  const [showClonePopup, setShowClonePopup] = useState<boolean>(false);
  const [showRenamePopup, setShowRenamePopup] = useState<boolean>(false);
  const [showConcurrencyPopup, setShowConcurrencyPopup] =
    useState<boolean>(false);
  const { isLoading, data } = useFetchWorkflowsListBatched();
  const {
    loadingImport,
    handleOnClickImport,
    importWorkflowProps,
    importsOverLimit,
  } = useImportWorkflow({ isLoading });
  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow();
  const { mutate: updateWorkflowMetadata, status: workflowUpdateStatus } =
    useUpdateWorkflowStatus();
  const demoFeaturesEnabled = useFeatureFlag(FeatureFlag.DemoFeatures, false);
  const enableWorkflowCloning = useFeatureFlag(
    FeatureFlag.EnableWorkflowCloning,
  );
  const hideEditorButton = useFeatureFlag(FeatureFlag.HideEditorButton, false);
  const metricsEnabled =
    useFeatureFlag(FeatureFlag.ExecutionAndWorkflowListMetrics) ?? false;

  const hasFilters = useMemo(
    () => !isDeepEqual(filters, defaultWorkflowListFilters),
    [filters],
  );

  const workflows = useMemo(() => {
    if (isEmpty(filters)) {
      return data;
    }
    const { name, email, status, hideInternal } = filters;
    return data?.filter((workflow) => {
      const workflowStatus = workflow.status;
      return (
        (!name ||
          workflow.workflowName.toLowerCase().includes(name.toLowerCase())) &&
        (!email ||
          workflow.email?.toLowerCase().includes(email.toLowerCase())) &&
        (!status ||
          status === 'All' ||
          (workflowStatus as string) === status ||
          (status === 'Needs review' &&
            (workflowStatus === WorkflowStatusEnum.Invalid ||
              workflowStatus === WorkflowStatusEnum.ProcessedImport))) &&
        (!hideInternal || !workflow.email?.includes('@sola-solutions.com'))
      );
    });
  }, [data, filters]);

  const showEmailCol = useMemo(
    () => data?.some((workflow) => workflow.email),
    [data],
  );

  const hasWorkflowInProcessingState = useMemo(
    () =>
      workflows?.some(
        (workflow) => workflow.status === WorkflowStatusEnum.ProcessingImport,
      ),
    [workflows],
  );

  const onCloseMenu = () => {
    setMenuProps(null);
  };

  const onOpenDuplicateModal = () => {
    onCloseMenu();
    setShowClonePopup(true);
  };

  const onEditWorkflow = () => {
    onCloseMenu();
    if (menuProps?.workflowId) {
      openWorkflowEditor(menuProps.workflowId);
    }
  };

  const openWorkflowEditor = (workflowId: string) => {
    navigate(`/editor/${workflowId}`);
  };

  const onDeleteWorkflow = () => {
    onCloseMenu();
    modalEventChannel.emit('open', {
      title: 'Delete Workflow',
      descriptions: ['Are you sure, you want to delete this workflow?'],
      showCloseIcon: false,
      alignActions: 'right',
      actions: [
        {
          text: 'Cancel',
          variant: 'text',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
        {
          text: 'Proceed',
          color: 'error',
          onClick: async () => {
            modalEventChannel.emit('close');
            if (menuProps?.workflowId) {
              await deleteWorkflow(menuProps.workflowId);
            }
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };

  const onRenameWorkflow = () => {
    onCloseMenu();
    setShowRenamePopup(true);
  };

  const onUpdateConcurrency = () => {
    onCloseMenu();
    setShowConcurrencyPopup(true);
  };

  const columns = useWorkflowCols({
    showEmailCol: Boolean(showEmailCol),
    demoFeaturesEnabled: Boolean(demoFeaturesEnabled),
    workflowUpdateStatus,
    setMenuProps,
    setSelectedWorkflowData,
    handleUpdateWorkflow: updateWorkflowMetadata,
  });

  const placeholderButtonProps = useMemo(() => {
    if (hasFilters) {
      return {
        onClick: () => {
          setFilters(defaultWorkflowListFilters);
        },
        disabled: false,
        loading: false,
      };
    }
    return {
      onClick: handleOnClickImport,
      disabled: importWorkflowProps.disableImport,
      loading: loadingImport,
    };
  }, [
    hasFilters,
    handleOnClickImport,
    importWorkflowProps.disableImport,
    loadingImport,
    setFilters,
  ]);

  useEffect(() => {
    document.title = getTabTitle('Workflows', isAdmin);
  }, []);

  return (
    <>
      <ScrollableContainer>
        <div className="w-full h-full flex">
          <NavigationPanel />
          <ContentContainer>
            <div className="flex justify-between w-full items-center">
              <CustomTypography variant="h4">Workflows</CustomTypography>
              <Tooltip
                arrow
                hidden={importWorkflowProps.tooltipHidden}
                placement="left"
                title={importWorkflowProps.tooltipTitle}
              >
                <Button
                  color="secondary"
                  disabled={importWorkflowProps.disableImport}
                  loading={loadingImport}
                  onClick={handleOnClickImport}
                  variant="contained"
                >
                  <Add />
                  Import Workflow
                </Button>
              </Tooltip>
            </div>

            {metricsEnabled || isAdmin ? <WorkflowListMetrics /> : null}

            {hasWorkflowInProcessingState && !isAdmin ? (
              <div className="mt-10 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded -mb-6">
                <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                <span className="font-medium text-primary-blue-dark">
                  Your workflows are actively processing. We'll notify you once
                  they're ready!
                </span>
              </div>
            ) : null}

            {demoFeaturesEnabled ? (
              <div className="mt-10 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded -mb-6">
                <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                <span className="font-medium text-primary-blue-dark">
                  Members of your organization have imported workflow(s).
                  Approve to begin processing!
                </span>
              </div>
            ) : null}

            {importsOverLimit ? (
              <div className="bg-warning-light my-6 flex flex-row gap-2 rounded-lg px-4 py-3.5 mt-8 -mb-6">
                <WarningAmberOutlined className="text-warning" />
                <p className="text-warning-dark text-base font-medium">
                  You've reached your workflow import limit. Please contact the
                  Sola team to upgrade your plan for more workflows!
                </p>
              </div>
            ) : null}
            <WorkflowListFilter
              filters={filters}
              setFilters={setFilters}
              hasFilters={hasFilters}
            />
            <div className="mt-11">
              {isLoading ? (
                <ListSkeleton columns={columns} />
              ) : (
                <>
                  {workflows?.length ? (
                    <DataGrid
                      columns={columns}
                      getRowId={(row) => row.workflowId}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'createdAt', sort: 'desc' }],
                        },
                      }}
                      rows={workflows}
                    />
                  ) : (
                    <div className="w-full h-full min-h-[70vh] border border-gray-200 rounded-lg mt-2 flex justify-center items-center">
                      <EmptyStatePlaceholder
                        type={EmptyStateEnum.WORKFLOW}
                        buttonProps={placeholderButtonProps}
                        title={
                          hasFilters
                            ? 'No workflows were found for this view'
                            : "No workflows yet? Let's get started!"
                        }
                        description={
                          hasFilters
                            ? 'Try adjusting your filters to find workflows.'
                            : 'Use a Sola recorder to capture your workflow process, then import it here to edit and run.'
                        }
                        buttonText={
                          hasFilters ? 'Remove filters' : 'Import workflow'
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </ContentContainer>
        </div>
        <WorkflowListMenu
          menuProps={menuProps}
          editWorkflowEnabled={!hideEditorButton}
          enableWorkflowCloning={Boolean(enableWorkflowCloning)}
          onEditWorkflow={onEditWorkflow}
          onOpenDuplicateModal={onOpenDuplicateModal}
          onDeleteWorkflow={onDeleteWorkflow}
          onCloseMenu={onCloseMenu}
          onRenameWorkflow={onRenameWorkflow}
          onUpdateConcurrency={onUpdateConcurrency}
        />
      </ScrollableContainer>
      {showClonePopup && selectedWorkflowData ? (
        <CloneModal
          onCancel={() => {
            setShowClonePopup(false);
          }}
          workflow={selectedWorkflowData}
        />
      ) : null}
      {showRenamePopup && selectedWorkflowData ? (
        <RenameWorkflowModal
          onCancel={() => {
            setShowRenamePopup(false);
          }}
          workflow={selectedWorkflowData}
        />
      ) : null}
      {showConcurrencyPopup && selectedWorkflowData ? (
        <ConcurrencyLimitModal
          onCancel={() => {
            setShowConcurrencyPopup(false);
          }}
          workflowId={selectedWorkflowData.workflowId}
        />
      ) : null}
    </>
  );
}
