"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteWorkflowSchema = void 0;
const types_shared_1 = require("types-shared");
exports.deleteWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({}),
        body: types_shared_1.z.object({}),
    }),
    response: types_shared_1.z.object({}),
};
