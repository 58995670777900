import { useCallback, useMemo } from 'react';
import {
  type WorkflowEdge,
  WorkflowImageNode,
  type WorkflowNode,
  WorkflowSourceNode,
} from 'types-shared';
import { findNextValidNode } from './helpers';
import { useSearchParams } from 'react-router-dom';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { EditorStore } from '../../store/EditorState';
import { handleException } from 'sentry-browser-shared';

export const useNextAndPrevNode = ({
  currentNodeId,
  nodes,
  edges,
}: {
  currentNodeId?: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
}) => {
  const { datasourceNodeId } = useMemo(() => {
    const payload = {
      datasourceNodeId: '',
    };

    const dataNode = nodes.find((n) => WorkflowSourceNode.safeParse(n).success);
    if (dataNode) {
      payload.datasourceNodeId = dataNode.id;
    }
    return payload;
  }, [nodes]); // skip the datasource node

  const filteredEdges = useMemo(
    () =>
      edges.filter(
        (e) => e.source !== datasourceNodeId && e.target !== datasourceNodeId,
      ),
    [datasourceNodeId, edges],
  );

  const { nextNodeId, prevNodeId } = useMemo(() => {
    const payload: {
      nextNodeId?: string;
      prevNodeId?: string;
    } = {
      nextNodeId: undefined,
      prevNodeId: undefined,
    };

    if (!currentNodeId) {
      return payload;
    }

    payload.nextNodeId = findNextValidNode(
      currentNodeId,
      nodes,
      filteredEdges,
      'forward',
    );
    payload.prevNodeId = findNextValidNode(
      currentNodeId,
      nodes,
      filteredEdges,
      'backward',
    );

    return payload;
  }, [filteredEdges, currentNodeId, nodes]);

  return {
    nextNodeId,
    prevNodeId,
  };
};

export const useNavigateBetweenNodes = () => {
  const { setSelectedNode, workflowId, setSelectedAction } = EditorStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setEditingNodeId, hideFlow } = useEditingNodeId();
  const currentNodeType = searchParams.get('nodeType');
  const selectedNode = searchParams.get('focusNode');

  const handleCloseNode = useCallback(() => {
    if (selectedNode) {
      setSelectedNode(null);
      setSelectedAction(null);
      setEditingNodeId(undefined);
      setSearchParams({});
    }
  }, [
    selectedNode,
    setSelectedNode,
    setSelectedAction,
    setEditingNodeId,
    setSearchParams,
  ]);

  const handleNavigateToNode = useCallback(
    (chosenNextNodeId: string, shouldHideFlow?: boolean) => {
      // This is to ensure that the nodes are updated
      const nodes = EditorStore.getState().nodes;
      const _node = nodes.find((n) => n.id === chosenNextNodeId);
      // Unlikely but this will handle the case where the node is not found
      if (!_node) {
        handleCloseNode();
        handleException(new Error('Tried to navigate to non existent node'), {
          extra: {
            nodeId: chosenNextNodeId,
            nodes,
            workflowId,
            source: 'handleNavigateToNode',
          },
        });
        return;
      }

      setSelectedAction(null);
      setSearchParams({
        focusNode: chosenNextNodeId,
        hideFlow: shouldHideFlow || hideFlow ? 'true' : 'false',
        nodeType: _node.type,
      });
      setSelectedNode(chosenNextNodeId);
      // If the node is not an image node, we need to set the editing node id
      if (!WorkflowImageNode.safeParse(_node).success) {
        setEditingNodeId(chosenNextNodeId);
      }
    },
    [
      setSelectedNode,
      setSearchParams,
      hideFlow,
      workflowId,
      handleCloseNode,
      setEditingNodeId,
      setSelectedAction,
    ],
  );

  return {
    handleNavigateToNode,
    handleCloseNode,
    currentNodeType,
  };
};
