import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createEventBus, type EventMap } from 'ui-kit';

interface EditNodeEvents extends EventMap {
  open: (nodeId: string | undefined) => void;
  close: () => void;
}

const editNodeEventsEventBus = createEventBus<EditNodeEvents>();

export const useEditingNodeId = () => {
  const [editingNodeId, setEditingNodeId] = useState<string | undefined>();
  const [searchParams, _] = useSearchParams();

  const hideFlow = searchParams.get('hideFlow') === 'true';

  useEffect(() => {
    const unsubscribe = editNodeEventsEventBus.on('open', (id) => {
      setEditingNodeId(undefined);

      // This is a workaround to beat a race condition
      setTimeout(() => {
        setEditingNodeId(id);
      }, 0);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    editingNodeId,
    hideFlow,
    setEditingNodeId: (id: string | undefined) => {
      editNodeEventsEventBus.emit('open', id);
    },
  };
};
