import { type SelectChangeEvent } from '@mui/material/Select';
import { useMemo, useState } from 'react';
import { VariableTypeEnum, type Variable } from 'types-shared/workflowTypes';
import { AlertVariant, notify, Select, Spinner } from 'ui-kit';
import { s3Shim } from '../../../../config/aws';
import { executionBucket } from '../../../../utils/env';
import { getPathFromUri } from '../../utils';

interface Props {
  title: string;
  uri: string;
  executionId: string;
  variables: Variable[];
  fetchExecutionArtifacts: () => Promise<unknown>;
}

const getVariableNameFromUri = (uri: string) => {
  return uri.split('/artifacts/1.')[1]?.split('.')[0];
};

export default function LinkDocumentVariable({
  title,
  uri,
  executionId,
  variables,
  fetchExecutionArtifacts,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLinkedToDocumentVariable = uri.includes('/artifacts/1.');
  const variableId = useMemo(() => getVariableNameFromUri(uri), [uri]);
  const value = useMemo(
    () => variables.find((v) => v.id === variableId),
    [variableId, variables],
  );
  const documentVariables = useMemo(
    () => variables.filter((v) => v.type === VariableTypeEnum.Document),
    [variables],
  );

  if (isLinkedToDocumentVariable || documentVariables.length === 0) return null;

  const linkVariable = async (e: SelectChangeEvent<Variable>) => {
    const selectedVariableId = e.target.value as string;
    setIsLoading(true);
    const path = getPathFromUri(uri, executionId);
    try {
      await s3Shim.copyObject(
        executionBucket,
        path,
        executionBucket,
        `${executionId}/artifacts/1.${selectedVariableId}.${title}`,
      );
      await s3Shim.deleteObject(executionBucket, path);
      await fetchExecutionArtifacts();
    } catch (error) {
      notify({
        message: 'Failed to link variable',
        variant: AlertVariant.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Spinner size={20} className="!text-info" />
  ) : (
    <Select<Variable>
      getLabel={(variable) => variable.name ?? variable.id}
      getValue={(variable) => variable.id}
      options={documentVariables}
      value={value}
      onChange={linkVariable}
    />
  );
}
