import { LinearProgress } from '@mui/material';
import {
  type WorkflowMetadataType,
  WorkflowStatusEnum,
} from 'api-types-shared';
import { clsx } from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  SourceTypeEnum,
  type WorkflowImageNode,
  type WorkflowNode,
} from 'types-shared';
import 'types-shared/reactflow.css';
import {
  AccessTimeIcon,
  AlertVariant,
  APITriggerIcon,
  AutoFixHigh,
  Button,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  FolderIcon,
  IconButton,
  MailIcon,
  Menu,
  MenuItem,
  Modal,
  modalEventChannel,
  notify,
  SettingsIcon,
  Tooltip,
  Typography,
} from 'ui-kit';
import {
  FeatureFlag,
  workflowListStatusToTitleMap,
  workflowProcessingStatuses,
} from '../../../../utils/constants';
import { isAdmin } from '../../../../utils/env';
import { useFeatureFlag } from '../../../../utils/helper';
import { type EditorToolbarMenuItem } from '../../../Execution/utils';
import ManualRun from '../../../ManualRun';
import ScheduleWorkflow from '../../../ScheduleWorkflow';
import WorkflowChip from '../../../Workflows/components/Chips/WorkflowChip';
import StatusLabel from '../../../Workflows/components/StatusLabel';
import { useQueueAutolinkTask } from '../../hooks';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { useVersionHistory } from '../../hooks/useVersionHistory';
import { EditorStore } from '../../store/EditorState';
import ToolbarModal from './ToolbarModal';
import { ComponentsToolbar } from './ComponentsToolbar';

interface Props {
  autolinkLoading?: boolean;
  workflowId: string;
  workflowName?: string;
  onSave: () => Promise<void>;
  setAutolinkTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSourceType: (sourceType: SourceTypeEnum) => void;
  hasSuggestions?: boolean;
  onReject: () => void;
  onAccept: () => void;
  acceptingSuggestion: boolean;
  rejectingSuggestion: boolean;
  saving?: boolean;
  unsavedChanges?: boolean;
  isReadonlyView?: boolean;
  isForceErrorPush?: boolean;
  workflowMetadata?: WorkflowMetadataType | null;
  nodes: WorkflowNode[];
  workflowStatus?: WorkflowStatusEnum;
  selectedMode: NodeSelectionModeEnums | null;
}

export default function UserToolbar({
  workflowStatus,
  nodes,
  autolinkLoading,
  workflowId,
  onSave,
  setAutolinkTaskId,
  setSourceType,
  hasSuggestions,
  onReject,
  onAccept,
  acceptingSuggestion,
  rejectingSuggestion,
  saving,
  unsavedChanges,
  isReadonlyView = false,
  isForceErrorPush,
  workflowMetadata,
  selectedMode,
}: Props): JSX.Element {
  const { state, pathname } = useLocation() as {
    pathname: string;
    state: {
      databaseCreated: boolean;
    } | null;
  };
  const navigate = useNavigate();
  const databaseCreated = state?.databaseCreated;
  const { datasourceMetadata, variables } = EditorStore();

  const isEditorReadonly = isReadonlyView || hasSuggestions;

  const workflowTriggersEnabled = useFeatureFlag(FeatureFlag.WorkflowTriggers);
  const demoFeaturesEnabled = useFeatureFlag(FeatureFlag.DemoFeatures);
  const autolinkEnabled = useFeatureFlag(FeatureFlag.AutolinkDemo);
  const manualRunEnabled = useFeatureFlag(FeatureFlag.ManualRun);
  const webhookPageEnabled = useFeatureFlag(FeatureFlag.WebhookPage);
  const workflowFilesEnabled = useFeatureFlag(FeatureFlag.WorkflowFiles);
  const emailTriggerManualRunEnabled = useFeatureFlag(
    FeatureFlag.ArmstrongSandboxEmailTriggerManualRun,
  );
  const [showManualRunPopup, setShowManualRunPopup] = useState<boolean>(false);
  const [showRunWorkflowPopup, setShowRunWorkflowPopup] =
    useState<boolean>(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const { setEditingNodeId } = useEditingNodeId();

  const { mutateAsync: queueAutolink, status } = useQueueAutolinkTask();
  const { toggleVersionHistory } = useVersionHistory();

  const isLoading = status === 'pending' || autolinkLoading;

  const processedWorkflowName = useMemo(() => {
    if (!workflowMetadata) {
      return 'Workflow';
    }
    if (workflowMetadata.workflowName.length > 50) {
      return `${workflowMetadata.workflowName.slice(0, 50).trim()}...`;
    }
    return workflowMetadata.workflowName;
  }, [workflowMetadata]);

  const openSavePrompt = () => {
    modalEventChannel.emit('open', {
      title: 'Save your workflow changes to cloud',
      descriptions: [
        'This workflow is saved on your device. To maintain these changes across devices and execute the workflow remotely, you must save your workflow changes to the cloud.',
      ],
      actions: [
        {
          text: 'SAVE CHANGES IN CLOUD',
          variant: 'contained',
          onClick: async () => {
            modalEventChannel.emit('close');
            await onSave();
            setShowRunWorkflowPopup(true);
          },
        },
        {
          text: 'Leave',
          variant: 'outlined',
          onClick: () => {
            // On leave
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };

  const scheduleWorkflow = () => {
    if (unsavedChanges) {
      openSavePrompt();
      return;
    }

    setShowRunWorkflowPopup(true);
  };

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const onAutoLink = useCallback(async () => {
    const taskId: string = await queueAutolink({
      datasourceId: datasourceMetadata?.datasourceId ?? null,
      variables: Object.values(variables),
    });
    setAutolinkTaskId(taskId);
  }, [
    queueAutolink,
    datasourceMetadata?.datasourceId,
    setAutolinkTaskId,
    variables,
  ]);

  useEffect(() => {
    if (databaseCreated) {
      notify({
        message:
          'Database connected! You can now Auto-link your data source to variables/actions',
        variant: AlertVariant.SUCCESS,
        action: (
          <Button
            className="!text-white !border-white"
            onClick={onAutoLink}
            variant="outlined"
          >
            Autolink
          </Button>
        ),
      });
      navigate(pathname, { replace: true });
    }
  }, [pathname, navigate, databaseCreated, onAutoLink]);

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const { isApi, isTrigger /*isDataSource*/ } = useSourceVariable(variables);

  const menuItems: EditorToolbarMenuItem[] = [
    {
      label: 'API CALL',
      value: SourceTypeEnum.API,
      isCurrent: isApi,
      icon: (
        <APITriggerIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: isApi && !workflowTriggersEnabled,
    },
    // {
    //   label: 'DATABASE',
    //   value: SourceTypeEnum.Datasource,
    //   isCurrent: isDataSource,
    //   icon: (
    //     <DatabaseIcon
    //       className="!text-info-dark group-hover:!text-info"
    //       fontSize="small"
    //     />
    //   ),
    //   disabled: true,
    // },
    {
      label: 'EMAIL TRIGGER',
      value: SourceTypeEnum.EmailTrigger,
      isCurrent: isTrigger,
      icon: (
        <MailIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
      disabled: !workflowTriggersEnabled,
      tooltipText:
        'Trigger the workflow via email. Contact sales to learn more!',
    },
  ];

  const currentItem = menuItems.find((item) => item.isCurrent);

  const finishedDetails = useMemo(() => {
    const payload: {
      finishedSteps: number;
      unFinishedSteps: number;
      totalSteps: number;
      finishedPercent: number;
      hasIssues: boolean;
      status?: WorkflowStatusEnum;
    } = {
      finishedSteps: 0,
      unFinishedSteps: 0,
      totalSteps: 0,
      finishedPercent: 0,
      hasIssues: false,
    };

    const selectingNodes = selectedMode === NodeSelectionModeEnums.MergeNodes;

    if (nodes.length) {
      const nodesToUse = nodes.filter(
        (n) =>
          n.type !== NodeTypesEnum.Source &&
          n.type !== NodeTypesEnum.Temporal &&
          (isAdmin || !n.hideFromUser),
      );
      const totalSteps = nodesToUse.length;
      const finishedSteps = selectingNodes
        ? nodesToUse.filter((node) => (node as WorkflowImageNode).data.selected)
            .length
        : nodesToUse.filter(
            (node) => node.data.nodeStatus === NodeStatusEnum.Checked,
          ).length;
      const unfinishedSteps = totalSteps - finishedSteps;
      const finishedPercent = 100 * (finishedSteps / totalSteps);

      payload.finishedSteps = finishedSteps;
      payload.totalSteps = totalSteps;
      payload.finishedPercent = finishedPercent;
      payload.unFinishedSteps = unfinishedSteps;

      if (workflowStatus) {
        const hasIssues = [
          WorkflowStatusEnum.ProcessingImportErrorAuthentication,
        ].includes(workflowStatus);
        payload.hasIssues = hasIssues;

        const workflowTitle = isAdmin
          ? workflowStatus
          : workflowListStatusToTitleMap[workflowStatus] ?? workflowStatus;

        if (hasIssues) {
          payload.status = workflowTitle;
        } else {
          payload.status =
            finishedPercent === 100
              ? WorkflowStatusEnum.Ready
              : WorkflowStatusEnum.ProcessedImport;
        }
      }
    }

    return payload;
  }, [nodes, workflowStatus, selectedMode]);

  const { disableRunWorkflow, tooltipText } = useMemo(() => {
    const payload = { disableRunWorkflow: false, tooltipText: '' };
    if (saving) {
      payload.disableRunWorkflow = true;
      payload.tooltipText = "We're saving your workflow, please wait.";
    } else if (!manualRunEnabled && isApi) {
      payload.disableRunWorkflow = true;
      payload.tooltipText = 'Contact sales to access this feature.';
    } else if (isTrigger && !emailTriggerManualRunEnabled) {
      payload.disableRunWorkflow = true;
      payload.tooltipText =
        'Email-triggered workflows execute automatically according to the trigger rules. Manual runs are not supported.';
    }
    return payload;
  }, [
    isApi,
    isTrigger,
    saving,
    manualRunEnabled,
    emailTriggerManualRunEnabled,
  ]);

  return (
    <>
      <div className="absolute left-0 right-0 z-[100]">
        <LinearProgress
          color={
            finishedDetails.finishedPercent === 100 ? 'success' : 'secondary'
          }
          value={finishedDetails.finishedPercent}
          variant="determinate"
          sx={{
            opacity: finishedDetails.finishedPercent === 100 ? 1 : 0.7,
            ...(finishedDetails.finishedPercent === 100
              ? {
                  backgroundColor: '#bbf0c7',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#bbf0c7',
                  },
                }
              : {}),
          }}
        />
      </div>
      <ComponentsToolbar
        hasOuterContainer={false}
        containerClassName="zoom-adjusted-container"
        crumbs={[
          { title: 'Workflow details', link: `/workflows/${workflowId}` },
          { title: `${processedWorkflowName} - Flow view` },
        ]}
        LeftEndContent={
          <div className="flex items-center space-x-6">
            {!isEditorReadonly ? (
              <>
                <Button
                  className="group !mr-2 !px-2 !text-nowrap !font-medium !leading-6 !text-sm !text-info-dark hover:!text-info !min-w-[140px]"
                  color="secondary"
                  endIcon={
                    anchorEl ? (
                      <ExpandLessOutlined
                        className="!text-info-dark group-hover:!text-info"
                        fontSize="small"
                      />
                    ) : (
                      <ExpandMoreOutlined
                        className="!text-info-dark group-hover:!text-info"
                        fontSize="small"
                      />
                    )
                  }
                  onClick={() => {
                    setAnchorEl(triggerRef.current);
                  }}
                  ref={triggerRef}
                  startIcon={currentItem?.icon}
                  variant="text"
                >
                  {currentItem?.label}
                </Button>
                <Menu
                  BackdropProps={{
                    style: {
                      backgroundColor: 'transparent',
                    },
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClose={onMenuClose}
                  open={Boolean(anchorEl)}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '4px',
                    },
                    '& .MuiMenu-list': {
                      padding: '0',
                    },
                    '& .MuiMenu-paper': {
                      minWidth: '140px',
                    },
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {menuItems
                    .filter((i) => !i.isCurrent)
                    .map((item) => (
                      <MenuItem
                        className={clsx({
                          '!font-medium flex-row items-center': true,
                          'opacity-50 !cursor-not-allowed': item.disabled,
                        })}
                        key={item.value}
                        // disabled={item.disabled}
                        onClick={() => {
                          if (item.disabled) return;
                          setEditingNodeId(undefined);
                          setSourceType(item.value);
                          onMenuClose();
                        }}
                      >
                        <Tooltip
                          arrow
                          hidden={!item.disabled}
                          title={item.tooltipText}
                        >
                          <span>
                            {item.icon}
                            <span className="font-medium text-sm text-info-dark ml-2 mr-4">
                              {item.label}
                            </span>
                          </span>
                        </Tooltip>
                      </MenuItem>
                    ))}
                </Menu>
              </>
            ) : null}
            {autolinkEnabled && !isEditorReadonly ? (
              <span className="rounded p-px !bg-gradient-to-r from-primary-blue to-primary-purple">
                <Button
                  className="!uppercase bg-transparent !text-black !text-sm !bg-white !border-0 !rounded"
                  color="secondary"
                  endIcon={
                    !isLoading ? (
                      <AutoFixHigh className="text-xs text-black" />
                    ) : null
                  }
                  loading={isLoading}
                  onClick={onAutoLink}
                  variant="outlined"
                >
                  Autolink
                </Button>
              </span>
            ) : null}
          </div>
        }
        RightEndContent={
          <div className="flex items-center space-x-4">
            {finishedDetails.status ? (
              <WorkflowChip
                className={clsx('!h-6 !overflow-x-hidden', {
                  '!bg-info-dark':
                    workflowProcessingStatuses.processingAndAccessible.includes(
                      finishedDetails.status,
                    ) && !isAdmin,
                })}
                classes={{
                  label: '!overflow-hidden !max-w-full',
                }}
                status={finishedDetails.status}
                label={
                  <StatusLabel
                    showExtraInfo
                    showProcessingState={!isAdmin}
                    processedCount={`${(finishedDetails.totalSteps - finishedDetails.finishedSteps).toString()} of ${finishedDetails.totalSteps.toString()} steps `}
                    workflowStatus={finishedDetails.status}
                    onlyOneStepLeft={
                      finishedDetails.totalSteps -
                        finishedDetails.finishedSteps ===
                      1
                    }
                  />
                }
                variant="filled"
              />
            ) : null}
            {workflowFilesEnabled ? (
              <Tooltip arrow title="Workflow files">
                <IconButton
                  onClick={() => {
                    navigate(`/editor/${workflowId}/files`);
                  }}
                >
                  <FolderIcon className="!text-info" fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip arrow hidden={isReadonlyView} title="Version History">
              <IconButton
                disabled={isReadonlyView}
                onClick={() => {
                  toggleVersionHistory(true);
                }}
              >
                <AccessTimeIcon
                  className={clsx({
                    '!text-info': !isReadonlyView,
                    '!text-gray-400': isReadonlyView,
                  })}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
            {webhookPageEnabled ? (
              <Tooltip arrow placement="bottom" title="Workflow settings">
                <IconButton
                  onClick={() => {
                    navigate(`/workflows/${workflowId}/settings`);
                  }}
                >
                  <SettingsIcon color="secondary" fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Button
              color="secondary"
              onClick={() => {
                void onSave();
              }}
              disabled={isReadonlyView}
              variant="outlined"
              loading={saving}
            >
              Save
            </Button>

            {demoFeaturesEnabled ? (
              <Button
                className="!uppercase"
                color="secondary"
                disabled={isReadonlyView}
                variant="contained"
                loading={isExportLoading}
                onClick={() => {
                  setIsExportLoading(true);
                  setTimeout(() => {
                    setIsExportLoading(false);
                  }, 4000);
                }}
              >
                Export Workflow
              </Button>
            ) : null}

            <Tooltip hidden={!disableRunWorkflow} title={tooltipText} arrow>
              <Button
                className="!uppercase"
                color="secondary"
                disabled={disableRunWorkflow || isReadonlyView}
                onClick={scheduleWorkflow}
                variant="contained"
              >
                Run workflow
              </Button>
            </Tooltip>
          </div>
        }
      />
      <Modal
        className="!max-w-2xl -top-20"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
        showCloseIcon
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your execution is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </Modal>
      {showManualRunPopup && !saving ? (
        <ManualRun
          onClose={() => {
            setShowManualRunPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
      {showRunWorkflowPopup && !saving ? (
        <ScheduleWorkflow
          onClose={() => {
            setShowRunWorkflowPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}

      <ToolbarModal
        open={Boolean(hasSuggestions)}
        onAccept={onAccept}
        onReject={onReject}
        isForceErrorPush={isForceErrorPush}
        loading={acceptingSuggestion || rejectingSuggestion}
        workflowMetadata={workflowMetadata}
      />
    </>
  );
}
