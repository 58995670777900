"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeleniumWebConditionEnum = exports.ProxyModeDefaultEnum = exports.ExecutionTriggerEnum = exports.ExecutionStatusEnum = void 0;
var ExecutionStatusEnum;
(function (ExecutionStatusEnum) {
    ExecutionStatusEnum["Queued"] = "QUEUED";
    ExecutionStatusEnum["PendingUser"] = "PENDING_USER";
    ExecutionStatusEnum["PendingAdmin"] = "PENDING_ADMIN";
    ExecutionStatusEnum["PendingSystem"] = "PENDING_SYSTEM";
    ExecutionStatusEnum["Canceled"] = "CANCELED";
    ExecutionStatusEnum["Running"] = "RUNNING";
    ExecutionStatusEnum["Success"] = "SUCCESS";
    ExecutionStatusEnum["Failed"] = "FAILED";
    ExecutionStatusEnum["Terminated"] = "TERMINATED";
    ExecutionStatusEnum["Paused"] = "PAUSED";
    ExecutionStatusEnum["Timeout"] = "TIMEOUT";
    ExecutionStatusEnum["Retry"] = "RETRY";
})(ExecutionStatusEnum || (exports.ExecutionStatusEnum = ExecutionStatusEnum = {}));
var ExecutionTriggerEnum;
(function (ExecutionTriggerEnum) {
    ExecutionTriggerEnum["Manual"] = "MANUAL";
    ExecutionTriggerEnum["Scheduled"] = "SCHEDULED";
    ExecutionTriggerEnum["Webhook"] = "WEBHOOK";
    ExecutionTriggerEnum["API"] = "API";
    ExecutionTriggerEnum["Email"] = "EMAIL";
})(ExecutionTriggerEnum || (exports.ExecutionTriggerEnum = ExecutionTriggerEnum = {}));
var ProxyModeDefaultEnum;
(function (ProxyModeDefaultEnum) {
    ProxyModeDefaultEnum["Any"] = "any";
    ProxyModeDefaultEnum["None"] = "none";
})(ProxyModeDefaultEnum || (exports.ProxyModeDefaultEnum = ProxyModeDefaultEnum = {}));
var SeleniumWebConditionEnum;
(function (SeleniumWebConditionEnum) {
    SeleniumWebConditionEnum["Located"] = "Located";
    SeleniumWebConditionEnum["IsDisabed"] = "IsDisabled";
    SeleniumWebConditionEnum["IsEnabled"] = "IsEnabled";
    SeleniumWebConditionEnum["TextIs"] = "TextIs";
    SeleniumWebConditionEnum["TextContains"] = "TextContains";
    SeleniumWebConditionEnum["TextMatches"] = "TextMatches";
    SeleniumWebConditionEnum["IsVisiable"] = "IsVisiable";
    SeleniumWebConditionEnum["IsSelected"] = "IsSelected";
    SeleniumWebConditionEnum["IsNotVisiable"] = "IsNotVisible";
})(SeleniumWebConditionEnum || (exports.SeleniumWebConditionEnum = SeleniumWebConditionEnum = {}));
