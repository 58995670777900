"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutionVariables = exports.ExecutionWithWorkflowName = exports.ExecutionBase = exports.ExecutionProgress = exports.ExecutionStepData = exports.ExecutionStep = exports.ExecutionStatus = exports.ExecutionTrigger = exports.ProxyConfig = void 0;
var zod_1 = require("zod");
var helper_types_1 = require("../helper-types");
var legacy_schema_1 = require("../workflowTypes/variable/legacy.schema");
var enums_1 = require("./enums");
exports.ProxyConfig = zod_1.z.object({
    address: zod_1.z.string(),
    username: zod_1.z.string().optional(),
    password: zod_1.z.string().optional(),
});
exports.ExecutionTrigger = zod_1.z.nativeEnum(enums_1.ExecutionTriggerEnum);
exports.ExecutionStatus = zod_1.z.nativeEnum(enums_1.ExecutionStatusEnum);
exports.ExecutionStep = zod_1.z.object({
    nodeId: helper_types_1.uuidSchema,
    stepId: helper_types_1.uuidSchema.optional(),
});
exports.ExecutionStepData = zod_1.z.object({
    nodeId: helper_types_1.uuidSchema,
    // TODO: Rename this after sandbox, the name doesn't make sense but it's fine
    stepIds: zod_1.z.array(zod_1.z.object({
        id: helper_types_1.uuidSchema,
        data: legacy_schema_1.Variable.or(zod_1.z.object({
            value: zod_1.z.union([zod_1.z.string(), zod_1.z.record(zod_1.z.string(), zod_1.z.unknown())]),
        })).optional(),
    })),
    timestamp: zod_1.z.string().optional(),
});
exports.ExecutionProgress = zod_1.z.array(exports.ExecutionStepData);
exports.ExecutionBase = zod_1.z.object({
    executionId: helper_types_1.uuidSchema,
    workflowId: helper_types_1.uuidSchema,
    setId: helper_types_1.uuidSchema,
    createdAt: zod_1.z.string(),
    status: exports.ExecutionStatus,
    recordId: zod_1.z.string(),
    trigger: exports.ExecutionTrigger,
    adminRun: zod_1.z.boolean().optional().default(false),
    startedAt: zod_1.z.string().optional(),
    lastUpdatedAt: zod_1.z.string().optional(),
    statusDescr: zod_1.z.string().optional(),
    variableData: zod_1.z.record(zod_1.z.string(), zod_1.z.string()).optional(),
    sessionId: zod_1.z.string().optional(),
    attempt: zod_1.z.number().default(1),
    slackChannelId: zod_1.z.string().optional(),
    userId: zod_1.z.string(),
    userEmail: zod_1.z.string().optional(),
    currentStep: exports.ExecutionStep.optional(),
    completedSteps: exports.ExecutionProgress.optional(),
    proxyMode: zod_1.z.string().optional(),
    workflowVersionId: zod_1.z.string().optional(),
});
exports.ExecutionWithWorkflowName = exports.ExecutionBase.extend({
    workflowName: zod_1.z.string(),
});
exports.ExecutionVariables = zod_1.z.record(zod_1.z.union([zod_1.z.string(), legacy_schema_1.Variable]));
