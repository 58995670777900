import { useState } from 'react';
import { Button, IconButton, Typography, Flex, Modal, Clear } from 'ui-kit';
import type { WorkflowNodeProps } from 'types-shared';
import InputLabel from '@mui/material/InputLabel';
import DragDropZone from './DragDropZone';
import { formatFileSize } from '../../../../../utils/helper';
import { EditorStore } from '../../../store/EditorState';
import { updateNodeImage } from '../../../utils/image';
import { handleException } from 'sentry-browser-shared';

interface Props {
  workflowId: string;
  nodeId: string;
  open: boolean;
  onClose: () => void;
  workflowData: WorkflowNodeProps;
}
export default function UpdateImageModal({
  workflowId,
  nodeId,
  open,
  onClose,
  workflowData,
}: Props) {
  const { nodes, setNodes } = EditorStore();
  const [pickedImage, setPickedImage] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const updateImages = async () => {
    if (pickedImage) {
      setLoading(true);
      try {
        await updateNodeImage(workflowId, pickedImage, nodeId, setNodes, nodes);
        onClose();
      } catch (e) {
        handleException(e, {
          name: 'UpdateImageModal',
          source: 'UpdateImageModal/updateImages',
          userMessage: {
            title: 'Failed to update image',
          },
          extra: {
            workflowId,
            nodeId,
            pickedImage,
            nodes,
          },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const upload = (file: File) => {
    setPickedImage(file);
  };

  return (
    <Modal className="!max-w-3xl" onClose={onClose} open={open}>
      <Typography className="font-medium" variant="h5">
        Update Image
      </Typography>
      <Flex className="gap-8 mt-7">
        <div className="w-full">
          <InputLabel className="!text-primary mb-2">Node Image</InputLabel>
          <div>
            <DragDropZone
              className="w-full mr-6"
              onFileSelection={(files) => {
                upload(files[0]);
              }}
              workflowData={workflowData}
            />
            {pickedImage ? (
              <div className="p-4 border rounded flex items-start space-x-2 mt-4 w-full">
                <div className="flex flex-col text-sm">
                  <b className="text-sm">{pickedImage.name}</b>
                  <span className="text-gray-500">
                    {formatFileSize(pickedImage.size)}
                  </span>
                </div>
                <IconButton
                  className="!ml-auto !-mr-3 !my-auto"
                  onClick={() => {
                    setPickedImage(null);
                  }}
                >
                  <Clear className="!w-5 !h-5 !text-black" />
                </IconButton>
              </div>
            ) : null}
          </div>
        </div>
      </Flex>
      <Flex className="gap-4 mt-9">
        <Button
          color="secondary"
          disabled={!pickedImage}
          onClick={updateImages}
          loading={loading}
          variant="contained"
        >
          Update
        </Button>
        <Button color="secondary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
