import { DataLoader } from 'ui-kit';

export default function WorkflowLoading() {
  return (
    <div className="w-full dotted-bg-dark flex justify-center items-center flex-grow">
      <DataLoader
        className="mt-0 flex-col items-center justify-center !space-x-0 space-y-4 p-6 rounded-lg bg-white shadow-lg"
        size={30}
        loadingText={
          <span className="text-info-dark text-xs">Loading workflow</span>
        }
      />
    </div>
  );
}
