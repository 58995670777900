import { useState } from 'react';
import { Button, Spinner } from 'ui-kit';
import EventTriggerPopup from './EventTriggerPopup';
import { useGetEmailTriggers } from '../../../hooks/useEmailTriggers';
import { emailTriggerEventTitleMap } from '../../../utils/constants';
import { type GetWorkflowMetadataResponse } from 'api-types-shared';

interface Props {
  workflowMetadata: GetWorkflowMetadataResponse;
}

export default function TriggerConditions({ workflowMetadata }: Props) {
  const [showConditionsPopup, setShowConditionsPopup] =
    useState<boolean>(false);
  const { data: emailTriggers = [], loading } = useGetEmailTriggers(
    workflowMetadata.workflowId,
  );

  const hasEmailTriggers = emailTriggers.length > 0;

  return (
    <>
      <div className="flex flex-col rounded-lg bg-gray-50 p-6 text-sm border border-gray-400">
        <h2 className="font-medium text-navy-blue">
          Email conditions to trigger the workflow
        </h2>
        <p className="text-color-grey mt-1">
          {hasEmailTriggers
            ? 'Review or edit the conditions an email must meet to trigger the workflow'
            : 'The workflow is triggered when an e-mail meets these conditions'}
        </p>
        {loading ? (
          <div className="mt-4">
            <Spinner size={24} />
          </div>
        ) : null}
        {emailTriggers.length > 0 ? (
          <>
            {emailTriggers.map((trigger) => (
              <div key={trigger.id} className="text-sm text-color-grey mt-4">
                <h3 className="font-medium text-navy-blue">{trigger.name}</h3>
                <p>Receiver: {trigger.receiverEmailAddress}</p>
                <p className="text-sm">
                  {emailTriggerEventTitleMap[trigger.event]}
                </p>
              </div>
            ))}
          </>
        ) : null}
        <Button
          className="!w-fit !mt-6"
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={() => {
            setShowConditionsPopup(true);
          }}
        >
          {hasEmailTriggers ? 'Edit Conditions' : 'Add Conditions'}
        </Button>
      </div>
      {showConditionsPopup ? (
        <EventTriggerPopup
          workflowMetadata={workflowMetadata}
          emailTriggers={emailTriggers}
          onCancel={() => {
            setShowConditionsPopup(false);
          }}
        />
      ) : null}
    </>
  );
}
