import { z } from 'types-shared';
import * as CustomOperators from '../custom';
// MARK: - Default Operators for String Array fields
// eslint-disable-next-line @typescript-eslint/no-namespace -- namespace good
export var DefaultStringArrayOperators;
(function (DefaultStringArrayOperators) {
    var ZodStringArray = z.array(z.string());
    DefaultStringArrayOperators.ANY_CONTAINS = CustomOperators.ANY_CONTAINS_STRING.typed(ZodStringArray);
    DefaultStringArrayOperators.NONE_CONTAINS = CustomOperators.NONE_CONTAINS_STRING.typed(ZodStringArray);
    DefaultStringArrayOperators.ANY_EQUALS = CustomOperators.ANY_EQUALS_STRING.typed(ZodStringArray);
    DefaultStringArrayOperators.NONE_EQUALS = CustomOperators.NONE_EQUALS_STRING.typed(ZodStringArray);
    DefaultStringArrayOperators.COUNT_EQUALS = CustomOperators.COUNT_EQUALS_NUMBER.typed(ZodStringArray);
    DefaultStringArrayOperators.ALL = [
        DefaultStringArrayOperators.ANY_CONTAINS,
        DefaultStringArrayOperators.NONE_CONTAINS,
        DefaultStringArrayOperators.ANY_EQUALS,
        DefaultStringArrayOperators.NONE_EQUALS,
        DefaultStringArrayOperators.COUNT_EQUALS,
    ];
})(DefaultStringArrayOperators || (DefaultStringArrayOperators = {}));
