import type { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import upperCase from 'lodash/upperCase';
import { useCallback, useEffect, useState } from 'react';
import {
  type DatasourceMetadata,
  type DatasourceTable,
  type GlobalVariable,
  NodeStatusEnum,
  RequestIntegrationTypeEnum,
  RequestMethodEnum,
  type RequestPayloadType,
  type SourceTypeEnum,
  TemplateData,
  type TemplateVariable,
  type Variable,
  type VariableMap,
  type WorkflowRequestNode,
} from 'types-shared';
import { Add, Button, DeleteOutlineIcon, Input, Select } from 'ui-kit';
import { VariableChip } from '../../../../components/VariableChip';
import { isAdmin } from '../../../../utils/env';
import {
  createTemplateVariable,
  getRequestBlockTitle,
  getRequestNodeDescription,
} from './request.helpers';
import { RequestVariableInput } from './RequestVariableInput';
import SectionLabel from './SectionLabel';

import { useNodeValidation } from '../../hooks/useNodeValidation';
import nodeValidations, {
  type RequestNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';

const validateRequestNode = nodeValidations.request;

export type WorkflowRequestNodeCoreData = Omit<
  WorkflowRequestNode['data'],
  'nodeStatus' | 'selected'
>;

interface Props {
  node: WorkflowRequestNode;
  onCancel: () => void;
  onUpdateData: (data: WorkflowRequestNodeCoreData) => void;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
    model?: string,
  ) => Promise<string | undefined>;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  updateNodeName: (name: string) => void;
  workflowId?: string;
}

const typeOptions = Object.values(RequestMethodEnum);
const integrationTypeOptions = Object.values(RequestIntegrationTypeEnum);

export function RequestBlockAdmin({
  node,
  onCancel,
  onUpdateData,
  variablesMap,
  globalVariablesMap,
  updateNodeStatus,
  updateNodeName,
  workflowId,
  ...props
}: Props) {
  const { updateVariable } = props;
  const requestData = node.data;
  const { urlParameters = [], headers = [], body } = requestData;
  const [hasRawJson, setHasRawJson] = useState<boolean>(true);
  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<RequestNodeValidationResult>({
      node,
      variablesMap,
      globalVariablesMap,
      updateNodeStatus,
      validationFunction: validateRequestNode,
      workflowId: workflowId ?? '',
    });

  const handleOnChangeMethod = (event: SelectChangeEvent) => {
    const method = event.target.value as RequestMethodEnum;
    onUpdateData({ ...requestData, method });
  };

  const addTemplateKeyValue = useCallback(
    (key: 'urlParameters' | 'headers' | 'body') => {
      onUpdateData({
        ...requestData,
        [key]: [
          ...(requestData[key] as RequestPayloadType[]),
          {
            key: {
              variableId: createTemplateVariable(props.addVariable).id,
            },
            value: {
              variableId: createTemplateVariable(props.addVariable).id,
            },
          },
        ],
      });
    },
    [onUpdateData, props.addVariable, requestData],
  );

  const deleteTemplateKeyValue = useCallback(
    (key: 'urlParameters' | 'headers' | 'body', id: string) => {
      // @Paul Implement variable deletion here.
      onUpdateData({
        ...requestData,
        [key]: (requestData[key] as RequestPayloadType[]).filter(
          (param) => param.key.variableId !== id,
        ),
      });
    },
    [onUpdateData, requestData],
  );

  const updateAuthEnabled = useCallback(
    (key: 'forUserId' | 'authKey', enabled: boolean) => {
      onUpdateData({
        ...requestData,
        auth: {
          ...requestData.auth,
          [key]: {
            enabled,
            value: requestData.auth[key].value,
          },
        },
      });
    },
    [onUpdateData, requestData],
  );

  useEffect(() => {
    if (typeof body === 'object' && !Array.isArray(body)) {
      const bodyVariable = variablesMap[body.variableId] as TemplateVariable;
      setHasRawJson(
        body.variableId && body.variableId in variablesMap
          ? TemplateData.safeParse(bodyVariable.data).success
          : false,
      );
    } else {
      setHasRawJson(false);
    }
  }, [body, variablesMap]);

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-auto pb-20">
          <NodeCheckV2
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            onCheck={handleValidateNode}
            errors={validationAttempted ? validationResult.errors : []}
            showErrors={validationAttempted}
            hidden={node.hideFromUser}
            noTopMargin
          />
          <div className="my-6 px-8">
            <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
              {getRequestBlockTitle(node)}
            </h2>
            <p className="text-zinc-500 text-sm leading-tight">
              {getRequestNodeDescription(node)}
            </p>
          </div>
          <div className="request-block flex-1 flex flex-col gap-4 px-8">
            {isAdmin ? (
              <Select
                classes={{ select: 'w-100' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Integration type"
                onChange={(event: SelectChangeEvent) => {
                  const integrationType = event.target
                    .value as RequestIntegrationTypeEnum;
                  const updatedData = { ...requestData, integrationType };
                  onUpdateData(updatedData);
                }}
                options={integrationTypeOptions}
                value={requestData.integrationType}
              />
            ) : null}

            <Input
              floatingLabel
              label="Step name"
              onChange={(val: string) => {
                updateNodeName(val);
                updateVariable({
                  ...variablesMap[requestData.responseVariableId],
                  name: val,
                });
              }}
              placeholder="Step name"
              value={node.name ?? ''}
            />

            <Input
              floatingLabel
              multiline
              rows={4}
              label="Step description"
              placeholder="Description"
              value={requestData.description}
              onChange={(newDescription) => {
                onUpdateData({
                  ...requestData,
                  description: newDescription,
                });
              }}
            />

            {isAdmin ? (
              <div className="border border-slate-300 px-4 py-4 rounded-lg flex justify-between items-center space-x-4">
                <span className="font-medium text-neutral-600 text-sm">
                  Changes requested by users are resolved
                </span>
                <Switch
                  checked={requestData.changesResolved}
                  color="secondary"
                  onChange={(_, checked) => {
                    const updatedData = {
                      ...requestData,
                      changesResolved: checked,
                    };
                    onUpdateData(updatedData);
                  }}
                />
              </div>
            ) : null}

            <Select
              classes={{ select: 'w-100' }}
              getLabel={(opt: string) => upperCase(opt)}
              getValue={(opt: string) => opt}
              label="Method"
              labelId="method"
              onChange={handleOnChangeMethod}
              options={typeOptions}
              value={requestData.method}
            />

            <RequestVariableInput
              isHighlighted={
                !validationResult.validations.variableValidations[
                  requestData.url.variableId
                ] && validationAttempted
              }
              label="URL"
              onChange={(updatedVar) => {
                updateVariable(updatedVar);
              }}
              variable={
                variablesMap[requestData.url.variableId] as TemplateVariable
              }
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              className="w-full"
              multiline={false}
              {...props}
            />

            <SectionLabel title="URL parameters" />

            {urlParameters.map((urlParameter) => {
              return (
                <div
                  className="flex w-100 justify-between space-x-3"
                  key={urlParameter.key.variableId}
                >
                  <RequestVariableInput
                    label="Key"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        urlParameter.key.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[
                        urlParameter.key.variableId
                      ] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    isHalf
                    multiline={false}
                    {...props}
                  />
                  <RequestVariableInput
                    label="Value"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        urlParameter.value.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[
                        urlParameter.value.variableId
                      ] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    isHalf
                    multiline={false}
                    {...props}
                  />
                  <DeleteOutlineIcon
                    className="hover:text-red-500 cursor-pointer mt-3"
                    onClick={() => {
                      deleteTemplateKeyValue(
                        'urlParameters',
                        urlParameter.key.variableId,
                      );
                    }}
                  />
                </div>
              );
            })}
            <AddButton
              onClick={() => {
                addTemplateKeyValue('urlParameters');
              }}
            />

            <SectionLabel title="Headers" />

            {headers.map((header) => {
              return (
                <div
                  className="flex w-100 justify-between space-x-3"
                  key={header.key.variableId}
                >
                  <RequestVariableInput
                    label="Key"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        header.key.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[header.key.variableId] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    isHalf
                    multiline={false}
                    {...props}
                  />
                  <RequestVariableInput
                    label="Value"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        header.value.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[header.value.variableId] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    isHalf
                    multiline={false}
                    {...props}
                  />
                  <DeleteOutlineIcon
                    className="hover:text-red-500 cursor-pointer mt-3"
                    onClick={() => {
                      deleteTemplateKeyValue('headers', header.key.variableId);
                    }}
                  />
                </div>
              );
            })}
            <AddButton
              onClick={() => {
                addTemplateKeyValue('headers');
              }}
            />

            {requestData.method !== RequestMethodEnum.Get ? (
              <>
                <SectionLabel title="Body">
                  <div className="flex items-center space-x-1">
                    <Switch
                      checked={hasRawJson}
                      color="secondary"
                      onChange={(e, checked) => {
                        setHasRawJson(checked);
                        onUpdateData({
                          ...requestData,
                          body: checked
                            ? {
                                variableId: createTemplateVariable(
                                  props.addVariable,
                                ).id,
                              }
                            : [],
                        });
                      }}
                    />
                    <span className="text-sm text-primaryLight">Raw JSON</span>
                  </div>
                </SectionLabel>

                {hasRawJson &&
                typeof body === 'object' &&
                !Array.isArray(body) ? (
                  <RequestVariableInput
                    multiline
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        body.variableId
                      ] && validationAttempted
                    }
                    onChange={(val) => {
                      const bodyVariable = variablesMap[
                        body.variableId
                      ] as TemplateVariable;

                      if (!val.data.length) {
                        updateVariable({
                          ...bodyVariable,
                          data: [''],
                        } as TemplateVariable);
                      } else {
                        updateVariable({
                          ...bodyVariable,
                          data: val.data as TemplateData,
                        } as TemplateVariable);
                      }
                    }}
                    variable={variablesMap[body.variableId] as TemplateVariable}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    placeholder={JSON.stringify(
                      {
                        object: {
                          key1: 'value1',
                          key2: 'value2',
                        },
                      },
                      null,
                      2,
                    )}
                    className="!min-h-[174px]"
                    {...props}
                  />
                ) : (
                  <>
                    {(body as RequestPayloadType[]).map(
                      (b: RequestPayloadType) => {
                        return (
                          <div
                            className="flex items-center w-100 justify-between"
                            key={b.key.variableId}
                          >
                            <RequestVariableInput
                              label="Key"
                              isHighlighted={
                                !validationResult.validations
                                  .variableValidations[b.key.variableId] &&
                                validationAttempted
                              }
                              onChange={(updatedVariable) => {
                                updateVariable(updatedVariable);
                              }}
                              variable={
                                variablesMap[
                                  b.key.variableId
                                ] as TemplateVariable
                              }
                              variablesMap={variablesMap}
                              globalVariablesMap={globalVariablesMap}
                              isHalf
                              multiline={false}
                              {...props}
                            />
                            <RequestVariableInput
                              label="Value"
                              isHighlighted={
                                !validationResult.validations
                                  .variableValidations[b.value.variableId] &&
                                validationAttempted
                              }
                              onChange={(updatedVariable) => {
                                updateVariable(updatedVariable);
                              }}
                              variable={
                                variablesMap[
                                  b.value.variableId
                                ] as TemplateVariable
                              }
                              variablesMap={variablesMap}
                              globalVariablesMap={globalVariablesMap}
                              isHalf
                              multiline={false}
                              {...props}
                            />
                            <DeleteOutlineIcon
                              className="hover:text-red-500 cursor-pointer"
                              onClick={() => {
                                deleteTemplateKeyValue(
                                  'body',
                                  b.key.variableId,
                                );
                              }}
                            />
                          </div>
                        );
                      },
                    )}
                    <AddButton
                      onClick={() => {
                        addTemplateKeyValue('body');
                      }}
                    />
                  </>
                )}
              </>
            ) : null}

            {isAdmin ? (
              <>
                <SectionLabel title="Authentication" />

                <div className="flex w-100 justify-between">
                  <RequestVariableInput
                    label="On behalf of user ID"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        requestData.auth.forUserId.value.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[
                        requestData.auth.forUserId.value.variableId
                      ] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    className="w-[344px]"
                    multiline={false}
                    {...props}
                  />
                  <Switch
                    className="mt-3"
                    checked={requestData.auth.forUserId.enabled}
                    color="secondary"
                    onChange={(_, checked) => {
                      updateAuthEnabled('forUserId', checked);
                    }}
                  />
                </div>

                <div className="flex w-100 justify-between">
                  <RequestVariableInput
                    label="Auth key"
                    isHighlighted={
                      !validationResult.validations.variableValidations[
                        requestData.auth.authKey.value.variableId
                      ] && validationAttempted
                    }
                    onChange={(updatedVariable) => {
                      updateVariable(updatedVariable);
                    }}
                    variable={
                      variablesMap[
                        requestData.auth.authKey.value.variableId
                      ] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    className="w-[344px]"
                    multiline={false}
                    {...props}
                  />
                  <Switch
                    className="mt-3"
                    checked={requestData.auth.authKey.enabled}
                    color="secondary"
                    onChange={(_, checked) => {
                      updateAuthEnabled('authKey', checked);
                    }}
                  />
                </div>
              </>
            ) : null}

            <SectionLabel title="Response" />

            <div className="mb-6">
              <VariableChip
                className="!block !max-w-full !w-max !min-w-[auto]"
                variableId={requestData.responseVariableId}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
              />
            </div>

            <div className="border border-slate-300 px-4 py-4 rounded-lg flex justify-between items-center space-x-4">
              <span className="font-medium text-neutral-600 text-sm">
                Continue the workflow if the request fails
              </span>
              <Switch
                checked={!requestData.blocking}
                color="secondary"
                onChange={(_, checked) => {
                  onUpdateData({ ...requestData, blocking: !checked });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}

function AddButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      className="!max-w-min"
      color="secondary"
      onClick={onClick}
      variant="outlined"
    >
      <Add />
    </Button>
  );
}
