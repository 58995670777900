import { clsx } from 'clsx';
import { type EmailTrigger } from '../../../../hooks/useEmailTriggers';
import { MailIcon } from 'ui-kit';
import { emailTriggerEventTitleMap } from '../../../../utils/constants';

interface TriggerItemProps {
  selectedTriggerId: string;
  trigger?: EmailTrigger;
  onSelect: () => void;
}

function TriggerItem({
  trigger,
  onSelect,
  selectedTriggerId,
}: TriggerItemProps) {
  const isDraftTrigger = !trigger;
  const isSelected =
    selectedTriggerId === trigger?.id || (isDraftTrigger && !selectedTriggerId);

  return (
    <div
      role="presentation"
      className={clsx(
        'px-4 py-3 flex flex-col w-full cursor-pointer mb-3 text-color-grey',
        {
          'bg-[rgba(49,116,250,0.04)] !border-secondary-blue !border-r-2':
            isSelected,
        },
      )}
      onClick={onSelect}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="flex items-center space-x-3">
        {isDraftTrigger ? (
          <MailIcon className="!w-5 !h-5 text-cyan-900" />
        ) : null}
        <span
          className={clsx('truncate text-sm font-medium', {
            'text-secondary-blue': isSelected,
            'text-navy-blue': !isSelected,
          })}
        >
          {trigger?.name ?? 'New Trigger'}
        </span>
      </div>
      {!isDraftTrigger ? (
        <>
          <p className="text-sm break-all">
            Receiver: {trigger.receiverEmailAddress}
          </p>
          <p className="text-xs">{emailTriggerEventTitleMap[trigger.event]}</p>
        </>
      ) : null}
    </div>
  );
}

interface Props {
  formId: string;
  emailTriggers: EmailTrigger[];
  onSelect: (id: string) => void;
}

export default function TriggersList({
  formId,
  emailTriggers,
  onSelect,
}: Props) {
  return (
    <div className="flex-shrink-0 w-64 max-h-full overflow-y-auto flex flex-col">
      <TriggerItem
        onSelect={() => {
          onSelect('');
        }}
        selectedTriggerId={formId}
      />
      {emailTriggers.map((trigger) => (
        <TriggerItem
          key={trigger.id}
          trigger={trigger}
          onSelect={() => {
            onSelect(trigger.id);
          }}
          selectedTriggerId={formId}
        />
      ))}
    </div>
  );
}
