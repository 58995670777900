import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import uniq from 'lodash/uniq';
import {
  type Condition,
  DocumentVariable,
  EmailTriggerVariable,
  ExecutionVariable,
  GlobalVariable,
  type Group,
  isGroup,
  MultiChoiceVariable,
  MultiSelectVariable,
  QueryVariable,
  type Rule,
  StringComparatorEnum,
  ScrapeVariable,
  SelectVariable,
  SourceVariable,
  TabVariable,
  type TemplateData,
  TemplateVariable,
  type Variable,
  type VariableMap,
  VariableRef,
  VariableIdContainer,
  VariableTypeEnum,
  type z,
  type WorkflowNode,
  NumberComparatorEnum,
} from 'types-shared';
import {
  brokenVariablesMessage,
  defaultErrorMessage,
  deletedVariablesMessage,
  requestNodeVariableMissingError,
} from './constants';
import { handleException } from 'sentry-browser-shared';

// The issue was that we were trying to use Variable as the base type,
// but each variable type is actually a Zod schema that extends the base Variable schema.
// We need to specify that these are Zod schemas that validate to Variable types
const VARIABLE_TYPE_MAP: Record<VariableTypeEnum, z.ZodType<Variable>> = {
  [VariableTypeEnum.Select]: SelectVariable,
  [VariableTypeEnum.MultiChoice]: MultiChoiceVariable,
  [VariableTypeEnum.Template]: TemplateVariable,
  [VariableTypeEnum.Source]: SourceVariable,
  [VariableTypeEnum.Query]: QueryVariable,
  [VariableTypeEnum.Scrape]: ScrapeVariable as z.ZodType<Variable>,
  [VariableTypeEnum.Global]: GlobalVariable,
  [VariableTypeEnum.Execution]: ExecutionVariable,
  [VariableTypeEnum.EmailTrigger]: EmailTriggerVariable,
  [VariableTypeEnum.Document]: DocumentVariable,
  [VariableTypeEnum.Tab]: TabVariable,
  [VariableTypeEnum.MultiSelect]: MultiSelectVariable,
};

const validateVariablesAndRefs = (
  variables: VariableRef[],
  globalVariablesMap: VariableMap,
  variablesMap: VariableMap,
) => {
  const payload = {
    hasInvalidVariables: false,
    hasDeletedVariables: false,
  };

  variables.forEach((_variableRef) => {
    const parsedRefCheck = VariableRef.safeParse(_variableRef);

    if (!parsedRefCheck.success) {
      payload.hasInvalidVariables = true;
      return;
    }

    const parsedRef = parsedRefCheck.data;
    const variable =
      // A variable can be in either of the two maps so this is a safe check
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      variablesMap[parsedRef.id] || globalVariablesMap[parsedRef.id];

    // Sometimes a variable could be missing in the maps so this is a safe check
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!variable) {
      payload.hasDeletedVariables = true;
      return;
    }

    const variableType = variable.type;
    const VariableSchema = VARIABLE_TYPE_MAP[variableType];
    const parsedVariable = VariableSchema.safeParse(variable);
    if (!parsedVariable.success) {
      payload.hasInvalidVariables = true;
    }
  });

  return payload;
};

// Returns true if template data has valid content:
// - For single values: the value must not be empty, null, or just whitespace unless allowEmpty is set to true
// - For multiple values: must have at least one value unless allowEmpty is set to true
export const templateDataHasContent = (
  data: TemplateData,
  variablesMap: VariableMap,
  globalVariablesMap: VariableMap,
  onError?: (error: string) => void,
  allowEmpty?: boolean,
) => {
  const dataIsEmpty =
    data.length === 0 ||
    (data.length === 1 && typeof data[0] === 'string' && !data[0]?.trim());
  if (allowEmpty && dataIsEmpty) {
    return true;
  }

  const hasContent = !dataIsEmpty;
  const hasOneValue = data.length === 1;
  const firstValueEmptyString =
    data[0] === '' ||
    data[0] === '\n' ||
    (typeof data[0] === 'string' && data[0]?.trim() === '');
  const firstValueIsNull = isNull(data[0]) || isEmpty(data[0]);

  const variablesInData = data.filter((item) => typeof item !== 'string');

  const { hasInvalidVariables, hasDeletedVariables } = validateVariablesAndRefs(
    variablesInData,
    globalVariablesMap,
    variablesMap,
  );

  const thisHasContent = hasOneValue
    ? !firstValueEmptyString && !firstValueIsNull
    : hasContent;

  if (!thisHasContent) {
    onError?.(requestNodeVariableMissingError);
    return false;
  }

  if (hasInvalidVariables) {
    onError?.(brokenVariablesMessage);
    return false;
  }

  if (hasDeletedVariables) {
    onError?.(deletedVariablesMessage);
    return false;
  }

  return true;
};

export const extractVariableIds = (element: Condition | Group): string[] => {
  if (isGroup(element)) {
    return element.elements.flatMap((el) => extractVariableIds(el));
  }
  return [element.field.variableId, element.value.variableId];
};

// Recursively validates an element and returns a record of invalid variables
const validateElement = (
  element: Condition | Group,
  variableMap: VariableMap,
  globalVariablesMap: VariableMap,
  variablesAreInvalid: Record<string, boolean>,
  workflowId: string,
  node: WorkflowNode,
  handleCriticalError: (error: string) => void,
) => {
  // Each variable ID maps to a boolean indicating whether that variable is invalid. true === invalid
  const newVariablesAreInvalid = { ...variablesAreInvalid };

  if (isGroup(element)) {
    element.elements.forEach((el) => {
      const validation = validateElement(
        el,
        variableMap,
        globalVariablesMap,
        newVariablesAreInvalid,
        workflowId,
        node,
        handleCriticalError,
      );

      Object.assign(newVariablesAreInvalid, validation);
    });

    return newVariablesAreInvalid;
  }
  const fieldVariableId = element.field.variableId;
  const valueVariableId = element.value.variableId;
  const comparator = element.comparator;

  const fieldVariable = TemplateVariable.safeParse(
    variableMap[fieldVariableId],
  );
  const valueVariable = TemplateVariable.safeParse(
    variableMap[valueVariableId],
  );

  if (!fieldVariable.success || !valueVariable.success) {
    const actualError = fieldVariable.error ?? valueVariable.error;
    handleException(actualError, {
      name: 'Did not find a valid template variable for the field or value in an element of a rule',
      source: 'validateElement',
      extra: {
        element,
        fieldVariableId,
        valueVariableId,
        variableMap,
        workflowId,
        node,
      },
    });

    handleCriticalError(defaultErrorMessage);
    newVariablesAreInvalid[fieldVariableId] = true;
    newVariablesAreInvalid[valueVariableId] = true;
    return newVariablesAreInvalid;
  }

  // We check both the field and the value to see if they have content and we do not allow empty values
  //  unless the comparator is Exists or DoesNotExist
  const fieldHasContent = templateDataHasContent(
    fieldVariable.data.data,
    variableMap,
    globalVariablesMap,
  );

  const valueHasContent = templateDataHasContent(
    valueVariable.data.data,
    variableMap,
    globalVariablesMap,
  );

  const validateOnlyOneValue = [
    StringComparatorEnum.Exists,
    StringComparatorEnum.DoesNotExist,
    StringComparatorEnum.Is,
    StringComparatorEnum.IsNot,
    NumberComparatorEnum.Equal,
    NumberComparatorEnum.NotEqual,
  ].includes(comparator as StringComparatorEnum | NumberComparatorEnum);

  if (validateOnlyOneValue) {
    const atLeastOneHasContent = fieldHasContent || valueHasContent;
    newVariablesAreInvalid[fieldVariableId] = !atLeastOneHasContent;
    newVariablesAreInvalid[valueVariableId] = !atLeastOneHasContent;
  } else {
    newVariablesAreInvalid[fieldVariableId] = !fieldHasContent;
    newVariablesAreInvalid[valueVariableId] = !valueHasContent;
  }

  return newVariablesAreInvalid;
};

// Validates all variables in a rule. If a variable is invalid, It will be mapped to its id. result[variableId] === true means it is invalid.
export const handleValidateSingleRule = ({
  rule,
  variableMap,
  globalVariablesMap,
  workflowId,
  node,
  handleCriticalError,
}: {
  rule: Rule;
  variableMap: VariableMap;
  globalVariablesMap: VariableMap;
  workflowId: string;
  node: WorkflowNode;
  handleCriticalError: (error: string) => void;
}) => {
  let variablesAreInvalid: Record<string, boolean> = {};

  rule.data.elements.forEach((element) => {
    const validation = validateElement(
      element,
      variableMap,
      globalVariablesMap,
      variablesAreInvalid,
      workflowId,
      node,
      handleCriticalError,
    );

    variablesAreInvalid = {
      ...variablesAreInvalid,
      ...validation,
    };
  });

  return variablesAreInvalid;
};

// Validates all variables in all the rules in an array of rules. If a variable is invalid, It will be mapped to its id. result[variableId] === true means it is invalid.
export const handleValidateMultipleRules = ({
  rules,
  variableMap,
  globalVariablesMap,
  workflowId,
  node,
  handleCriticalError,
}: {
  rules: Rule[];
  variableMap: VariableMap;
  globalVariablesMap: VariableMap;
  workflowId: string;
  node: WorkflowNode;
  handleCriticalError: (error: string) => void;
}) => {
  let variablesAreInvalid: Record<string, boolean> = {};

  // Only conditional actions have variables linked to the output so we will validate only those.
  const outputVariableIds = rules
    .flatMap((rule) => {
      const outputVariableIdCheck = VariableIdContainer.safeParse(
        rule.output[0],
      );
      if (outputVariableIdCheck.success) {
        return outputVariableIdCheck.data.variableId;
      }
      return undefined;
    })
    .filter((id) => id !== undefined);

  const outVariableValidations = outputVariableIds.map((outputVariableId) => {
    const outputVariableCheck = TemplateVariable.safeParse(
      variableMap[outputVariableId],
    );

    const outputIsMultiChoiceCheck = MultiChoiceVariable.safeParse(
      variableMap[outputVariableId],
    );

    const outputIsSelectCheck = SelectVariable.safeParse(
      variableMap[outputVariableId],
    );

    const outputIsBroken =
      !outputVariableCheck.success &&
      !outputIsMultiChoiceCheck.success &&
      !outputIsSelectCheck.success;

    if (outputIsBroken) {
      handleException(new Error('No valid variable found for rule output'), {
        name: 'Did not find a valid template, multi-choice, or select variable for the output in a rule',
        source: 'handleValidateMultipleRules',
        extra: {
          outputVariableId,
          variable: variableMap[outputVariableId],
          variableMap,
          workflowId,
          node,
        },
      });
    }

    const outputContent = (
      outputVariableCheck.data ||
      outputIsMultiChoiceCheck.data ||
      outputIsSelectCheck.data
    )?.data;

    return {
      outputVariableId,
      outputIsBroken,
      outputHasContent: templateDataHasContent(
        outputContent ?? [],
        variableMap,
        globalVariablesMap,
      ),
    };
  });

  const atLeastOneOutputHasContent = outVariableValidations.some(
    (validation) => validation.outputHasContent,
  );

  outVariableValidations.forEach((validation) => {
    if (validation.outputIsBroken) {
      variablesAreInvalid = {
        ...variablesAreInvalid,
        [validation.outputVariableId]: true,
      };
    } else {
      variablesAreInvalid = {
        ...variablesAreInvalid,
        [validation.outputVariableId]: !atLeastOneOutputHasContent,
      };
    }
  });

  rules.forEach((rule) => {
    const ruleResult = handleValidateSingleRule({
      rule,
      variableMap,
      globalVariablesMap,
      workflowId,
      node,
      handleCriticalError,
    });

    variablesAreInvalid = {
      ...variablesAreInvalid,
      ...ruleResult,
    };
  });

  return variablesAreInvalid;
};

export const nodeHasCriticalError = (errors: string[]) => {
  return (
    errors.includes(defaultErrorMessage) ||
    errors.includes(brokenVariablesMessage) ||
    errors.includes(deletedVariablesMessage)
  );
};

export const isCriticalError = (error: string) => {
  return (
    error === defaultErrorMessage ||
    error === brokenVariablesMessage ||
    error === deletedVariablesMessage
  );
};

// Only return either critical errors or the default error with no duplicates
export const filterErrors = (errors: string[], defaultError: string) => {
  if (!errors.length) {
    return [];
  }
  if (nodeHasCriticalError(errors)) {
    return uniq(errors.filter((error) => isCriticalError(error)));
  }
  return [defaultError];
};
