"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createVariableDataEntryWithLegacyCompat = exports.VariableDataEntryBaseSchema = exports.ApiVariableInput = void 0;
var zod_1 = require("zod");
var legacy_schema_1 = require("../legacy.schema");
exports.ApiVariableInput = zod_1.z.discriminatedUnion('type', [
    zod_1.z.object({ type: zod_1.z.literal('single'), name: zod_1.z.string(), value: zod_1.z.string() }),
    zod_1.z.object({
        type: zod_1.z.literal('multiple'),
        name: zod_1.z.string(),
        values: zod_1.z.array(zod_1.z.string()),
    }),
]);
exports.VariableDataEntryBaseSchema = zod_1.z.object({
    version: zod_1.z.literal('1.0'),
    groupId: zod_1.z.string(), // Unique per loop groups, always unique for non-loop variables (singletons)
    orderInGroup: zod_1.z.number(), // a.k.a "loop" index (0, 1, 2, 3, 4, ...)
    actionId: zod_1.z.string(),
    variableId: zod_1.z.string(),
    executionId: zod_1.z.string(),
    name: zod_1.z.string(),
    value: zod_1.z.string().optional(),
    type: zod_1.z.nativeEnum(legacy_schema_1.VariableTypeEnum),
});
var createVariableDataEntryWithLegacyCompat = function (legacyCompatPayload) {
    return exports.VariableDataEntryBaseSchema.extend({
        legacyCompat: legacyCompatPayload,
    });
};
exports.createVariableDataEntryWithLegacyCompat = createVariableDataEntryWithLegacyCompat;
