"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadMetaSchema = void 0;
const types_shared_1 = require("types-shared");
exports.uploadMetaSchema = {
    request: types_shared_1.z.object({
        workflowId: types_shared_1.z.string(),
        cookiesUploadUrl: types_shared_1.z.string().optional(),
        localStorageUploadUrl: types_shared_1.z.string().optional(),
    }),
    response: types_shared_1.z.boolean(),
};
