import type { WorkflowStopNode } from 'types-shared';
import { NodeStatusEnum, StopNodeStatusEnum } from 'types-shared';
import { FormControlLabel, Radio, RadioGroup } from 'ui-kit';
import { useNodeValidation } from '../../hooks/useNodeValidation';
import validations, {
  type ValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';

const validateStopNode = validations.stop;
interface Props {
  node: WorkflowStopNode;
  updateNodeDataStatus: (status: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
  workflowId?: string;
}

export function StopBlock({
  node,
  onCancel,
  updateNodeDataStatus,
  updateNodeStatus,
  workflowId,
}: Props) {
  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<ValidationResult>({
      node,
      // We currently don't have any variables for stop nodes
      variablesMap: {},
      globalVariablesMap: {},
      updateNodeStatus,
      validationFunction: validateStopNode,
      workflowId: workflowId ?? '',
    });

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-visible pb-20">
          <NodeCheckV2
            noTopMargin
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            onCheck={handleValidateNode}
            errors={validationAttempted ? validationResult.errors : []}
            showErrors={validationAttempted}
            hidden={node.hideFromUser}
          />

          <div className="my-6 px-8">
            <h2 className="text-lg font-medium">Stop step</h2>
            <p className="text-sm font-normal mt-2 text-info-dark">
              Stop the workflow at this point.
            </p>
          </div>

          <div className="px-8">
            <RadioGroup
              name="node-types-group-2 stop-block"
              onChange={(e, v) => {
                updateNodeDataStatus(v);
              }}
              value={node.data.status}
            >
              <FormControlLabel
                classes={{
                  root: '!items-start',
                }}
                control={<Radio color="secondary" />}
                label={
                  <div className="flex flex-col mt-2">
                    <div className="leading-normal">
                      Stop and mark as successful
                    </div>
                    <div className="text-zinc-500 text-sm leading-tight">
                      When the workflow reaches this step, halt execution and
                      mark as a successful execution.
                    </div>
                  </div>
                }
                value={StopNodeStatusEnum.Success}
              />
              <div className="mt-4" />
              <FormControlLabel
                classes={{
                  root: '!items-start',
                }}
                control={<Radio color="secondary" />}
                label={
                  <div className="flex flex-col mt-2">
                    <div className="leading-normal">
                      Stop and mark as failure
                    </div>
                    <div className="text-zinc-500 text-sm leading-tight">
                      When the workflow reaches this step, halt execution and
                      mark as a failed execution.
                    </div>
                  </div>
                }
                value={StopNodeStatusEnum.Failure}
              />
            </RadioGroup>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}
