import { clsx } from 'clsx';
import React, { useMemo } from 'react';
import {
  Condition,
  type DatasourceMetadata,
  type DatasourceTable,
  type GlobalVariable,
  Group,
  isGroup,
  OperatorEnum,
  type SourceTypeEnum,
  type TemplateData,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { Button } from 'ui-kit';
import { type VariableValidation } from '../../utils/nodeValidations';
import SingleCondition from './SingleCondition';
import { ConditionalLabel, Separator } from './UiElements';

type ElementIndices = Record<string, number>;

const mapElementIndices = (
  prevIndex: number,
  item?: Condition | Group,
): ElementIndices => {
  if (!item) return {};
  const conditionCheck = Condition.safeParse(item);

  if (conditionCheck.success) {
    // Return an object with the condition's id and updated index
    return { [conditionCheck.data.id]: prevIndex + 1 };
  }

  // If it's a Group, process its elements and merge them into a single object
  const groupCheck = Group.safeParse(item);

  if (groupCheck.success) {
    return groupCheck.data.elements.reduce<ElementIndices>((acc, element) => {
      const result = mapElementIndices(
        prevIndex + Object.keys(acc).length,
        element,
      );
      return { ...acc, ...result };
    }, {});
  }

  return {};
};

interface Props {
  label?: string;
  edgeName?: string;
  group: Group;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  onAddCondition: (
    groupId: string,
    operator: OperatorEnum,
    andConditionId?: string,
  ) => void;
  onDeleteCondition: (id: string) => void;
  onUpdateCondition: (id: string, data: Partial<Condition>) => void;
  tableData: DatasourceTable | null;
  updateVariable: (variable: Variable) => void;
  setShowingModal?: (showingModal: boolean) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  disallowDeleteOnCondition?: string | null;
  sourceType?: SourceTypeEnum;
  groupIndices?: ElementIndices;
  branchValidations?: VariableValidation;
}

export default function GroupBlock({ label, ...props }: Props) {
  const disallowDeleteOnCondition = useMemo(() => {
    if (props.group.elements.length === 1) {
      return isGroup(props.group.elements[0])
        ? null
        : props.group.elements[0].id;
    }
    return null;
  }, [props.group.elements]);

  const groupIndices = useMemo(
    () => mapElementIndices(0, props.group),
    [props.group],
  );

  return (
    <div>
      {label ? (
        <ConditionalLabel className="mb-4">{label}</ConditionalLabel>
      ) : null}

      <GroupInternal
        {...props}
        groupIndices={groupIndices}
        disallowDeleteOnCondition={disallowDeleteOnCondition}
        branchValidations={props.branchValidations}
      />

      <ConditionalLabel className="mt-6">Or When</ConditionalLabel>
      <div className="mt-4">
        <Button
          color="secondary"
          onClick={() => {
            props.onAddCondition(props.group.id, OperatorEnum.Or);
          }}
          variant="text"
        >
          Add condition
        </Button>
      </div>
    </div>
  );
}

function GroupInternal({
  variablesMap,
  globalVariablesMap,
  group,
  datasourceMetadata,
  onAddCondition,
  onDeleteCondition,
  tableData,
  onUpdateCondition,
  updateVariable,
  edgeName,
  onTransformApiReq,
  transformApiReqStatus,
  disallowDeleteOnCondition,
  sourceType,
  setShowingModal,
  groupIndices,
  branchValidations,
}: Props) {
  if (group.elements.length === 0) {
    return (
      <Button
        color="secondary"
        onClick={() => {
          onAddCondition(group.id, OperatorEnum.And);
        }}
        variant="text"
      >
        Add condition
      </Button>
    );
  }

  return (
    <div
      className={clsx(
        group.operator === OperatorEnum.And &&
          'rounded-lg border border-gray-300',
      )}
    >
      {group.elements.map((groupOrCondition, index) => {
        const isLastElement = index === group.elements.length - 1;

        if (isGroup(groupOrCondition)) {
          return (
            <React.Fragment key={groupOrCondition.id}>
              <GroupInternal
                datasourceMetadata={datasourceMetadata}
                edgeName={edgeName}
                group={groupOrCondition}
                onAddCondition={onAddCondition}
                onDeleteCondition={onDeleteCondition}
                onTransformApiReq={onTransformApiReq}
                onUpdateCondition={onUpdateCondition}
                setShowingModal={setShowingModal}
                tableData={tableData}
                transformApiReqStatus={transformApiReqStatus}
                sourceType={sourceType}
                updateVariable={updateVariable}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                groupIndices={groupIndices}
                branchValidations={branchValidations}
              />
              {group.operator === OperatorEnum.Or && !isLastElement ? (
                <ConditionalLabel className="pt-4 pb-4">
                  Or When
                </ConditionalLabel>
              ) : null}
            </React.Fragment>
          );
        }

        const condition = groupOrCondition;
        const label =
          index > 0 && group.operator === OperatorEnum.And
            ? `And condition ${(groupIndices?.[condition.id] ?? index + 1).toString()}`
            : `Condition ${(groupIndices?.[condition.id] ?? index + 1).toString()}`;

        const andOp = group.operator === OperatorEnum.And;
        const orOp = group.operator === OperatorEnum.Or;

        return (
          <div key={condition.id}>
            <div className={clsx(orOp && 'rounded-lg border border-gray-300')}>
              <div className={clsx('p-6')}>
                <SingleCondition
                  condition={condition}
                  datasourceMetadata={datasourceMetadata}
                  edgeName={edgeName}
                  label={label}
                  onAdd={() => {
                    onAddCondition(group.id, OperatorEnum.And, condition.id);
                  }}
                  onDelete={() => {
                    onDeleteCondition(condition.id);
                  }}
                  showAdd={
                    group.operator === OperatorEnum.And ? isLastElement : true
                  }
                  showDelete={disallowDeleteOnCondition !== condition.id}
                  sourceType={sourceType}
                  updateCondition={(c) => {
                    onUpdateCondition(condition.id, c);
                  }}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  updateVariable={updateVariable}
                  branchValidations={branchValidations}
                />
              </div>
              {andOp && !isLastElement ? <Separator /> : null}
            </div>

            {orOp && !isLastElement ? (
              <ConditionalLabel className="pt-4 pb-4">Or When</ConditionalLabel>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
