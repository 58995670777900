"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendSlackMessageWithFileSchema = exports.sendSlackMessageWithFilePayload = void 0;
const types_shared_1 = require("types-shared");
exports.sendSlackMessageWithFilePayload = types_shared_1.z.object({
    workflowId: types_shared_1.z.string(),
    channelId: types_shared_1.z.string(),
    text: types_shared_1.z.string(),
    fileBase64: types_shared_1.z.string().optional(),
    fileName: types_shared_1.z.string().optional(),
});
exports.sendSlackMessageWithFileSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: exports.sendSlackMessageWithFilePayload,
    }),
    getUploadUrlResponse: types_shared_1.z.object({
        ok: types_shared_1.z.boolean(),
        upload_url: types_shared_1.z.string(),
        file_id: types_shared_1.z.string(),
    }),
    response: types_shared_1.z.object({
        ok: types_shared_1.z.boolean(),
        error: types_shared_1.z.string().optional(),
    }),
};
