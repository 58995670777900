"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadUiRecorderFilesSchema = exports.UiRecorderFileExtEnum = exports.UiRecorderFileTypeEnum = void 0;
const types_shared_1 = require("types-shared");
var UiRecorderFileTypeEnum;
(function (UiRecorderFileTypeEnum) {
    UiRecorderFileTypeEnum["UserFlow"] = "USER_FLOW";
    UiRecorderFileTypeEnum["Screenshot"] = "SCREENSHOT";
    UiRecorderFileTypeEnum["HtmlPageSource"] = "HTML_PAGE_SOURCE";
    UiRecorderFileTypeEnum["XmlPageSource"] = "XML_PAGE_SOURCE";
})(UiRecorderFileTypeEnum || (exports.UiRecorderFileTypeEnum = UiRecorderFileTypeEnum = {}));
var UiRecorderFileExtEnum;
(function (UiRecorderFileExtEnum) {
    UiRecorderFileExtEnum["JSON"] = "json";
    UiRecorderFileExtEnum["HTML"] = "html";
    UiRecorderFileExtEnum["XML"] = "xml";
    UiRecorderFileExtEnum["PNG"] = "png";
})(UiRecorderFileExtEnum || (exports.UiRecorderFileExtEnum = UiRecorderFileExtEnum = {}));
exports.uploadUiRecorderFilesSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            recordingId: types_shared_1.z.string(),
        }),
        body: types_shared_1.z.object({
            fileMetas: types_shared_1.z.array(types_shared_1.z.object({
                fileType: types_shared_1.z.nativeEnum(UiRecorderFileTypeEnum),
                stepIndex: types_shared_1.z.number().optional(),
            })),
        }),
    }),
    response: types_shared_1.z.object({
        presignedUrls: types_shared_1.z.array(types_shared_1.z.string()),
    }),
};
