import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  type GlobalVariable,
  NodeStatusEnum,
  type RequestPayloadType,
  TemplateVariable,
  type Variable,
  type VariableMap,
  type WorkflowRequestNode,
} from 'types-shared';
import { AlertVariant, Button, Input, Label, notify, SubLabel } from 'ui-kit';
import { contactModalEventChannel } from '../../../../utils/contactModal';
import { TemplateDataPreview } from '../ActionsManager/Action/ActionRenderer';
import { VariableInput } from '../VariableTypes/VariableInput';
import {
  getRequestBlockTitle,
  getRequestNodeDescription,
} from './request.helpers';
import { type WorkflowRequestNodeCoreData } from './RequestBlockAdmin';

import { useNodeValidation } from '../../hooks/useNodeValidation';
import nodeValidations, {
  type RequestNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';

const validateRequestNode = nodeValidations.request;
interface Props {
  node: WorkflowRequestNode;
  updateNodeName: (name: string) => void;
  onCancel: () => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  updateVariable: (variable: Variable) => void;
  variablesMap: Record<string, Variable>;
  onUpdateData: (data: WorkflowRequestNodeCoreData) => void;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  workflowId?: string;
}

export function RequestBlockCustomer({
  node,
  onCancel,
  updateNodeName,
  updateNodeStatus,
  variablesMap,
  globalVariablesMap,
  onUpdateData,
  updateVariable,
}: Props) {
  const requestData = node.data;
  const { workflowId } = useParams();

  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<RequestNodeValidationResult>({
      node,
      variablesMap,
      globalVariablesMap,
      updateNodeStatus,
      validationFunction: validateRequestNode,
      workflowId: workflowId ?? '',
    });

  const isRawJSON = useMemo(() => {
    const body = node.data.body;

    if (
      typeof body === 'object' &&
      !Array.isArray(body) &&
      (variablesMap[body.variableId] as TemplateVariable).data.length > 0
    ) {
      return TemplateVariable.safeParse(variablesMap[body.variableId]).success;
    }
    return false;
  }, [node.data.body, variablesMap]);

  useEffect(() => {
    const unsubscribe = contactModalEventChannel.on('submit', () => {
      notify({
        message: 'Your change request to Sola’s team has been sent!',
        variant: AlertVariant.SUCCESS,
      });
      onUpdateData({ ...node.data, changesResolved: false });
    });

    return () => {
      unsubscribe();
    };
  }, [node.data, onUpdateData]);

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-auto pb-20">
          <NodeCheckV2
            noTopMargin
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            onCheck={handleValidateNode}
            errors={validationAttempted ? validationResult.errors : []}
            showErrors={validationAttempted}
            hidden={node.hideFromUser}
          />

          <div className="my-6 px-8">
            <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
              {getRequestBlockTitle(node)}
            </h2>
            <p className="text-zinc-500 text-sm leading-tight">
              {getRequestNodeDescription(node)}
            </p>
          </div>
          <div className="request-block flex-1 flex flex-col gap-4 px-8 pb-8">
            <Input
              floatingLabel
              label="Step name"
              onChange={(val: string) => {
                updateNodeName(val);
                updateVariable({
                  ...variablesMap[requestData.responseVariableId],
                  name: val,
                });
              }}
              placeholder="Step name"
              value={node.name ?? ''}
            />

            <Input
              floatingLabel
              multiline
              rows={4}
              label="Step description"
              placeholder="Description"
              value={node.data.description}
              onChange={(newDescription) => {
                onUpdateData({
                  ...node.data,
                  description: newDescription,
                });
              }}
            />
          </div>

          <hr className="border-indigo-light w-full border-b" />

          <div className="flex-1 flex flex-col gap-6 p-8">
            <div>
              <Label className="!leading-7">Method</Label>
              <SubLabel className="!leading-7">
                {node.data.method.toUpperCase()}
              </SubLabel>
            </div>

            {isRawJSON &&
            typeof node.data.body === 'object' &&
            !Array.isArray(node.data.body) ? (
              <VariableInput
                isHighlighted={
                  !validationResult.validations.variableValidations[
                    (node.data.body as { variableId: string }).variableId
                  ] && validationAttempted
                }
                disabled
                multiline
                onChange={(val) => {
                  const bodyVariable = variablesMap[
                    (node.data.body as { variableId: string }).variableId
                  ] as TemplateVariable;

                  if (!val.length) {
                    updateVariable({
                      ...bodyVariable,
                      data: [''],
                    } as TemplateVariable);
                  } else {
                    updateVariable({
                      ...bodyVariable,
                      data: val,
                    } as TemplateVariable);
                  }
                }}
                value={
                  (variablesMap[node.data.body.variableId] as TemplateVariable)
                    .data
                }
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
              />
            ) : (
              <>
                {(node.data.body as RequestPayloadType[]).map((body) => {
                  return (
                    <div key={body.key.variableId}>
                      <Label className="!leading-7">Request body</Label>
                      <SubLabel className="flex items-center gap-1 !leading-7 truncate">
                        <span className="font-medium">Key:</span>
                        <TemplateDataPreview
                          dataLike={
                            (
                              variablesMap[
                                body.key.variableId
                              ] as TemplateVariable
                            ).data
                          }
                          variablesMap={variablesMap}
                          globalVariablesMap={globalVariablesMap}
                          className="inline-block"
                        />
                      </SubLabel>
                      <SubLabel className="flex items-center gap-1 !leading-7 truncate">
                        <span className="font-medium">Value:</span>
                        <TemplateDataPreview
                          dataLike={
                            (
                              variablesMap[
                                body.value.variableId
                              ] as TemplateVariable
                            ).data
                          }
                          variablesMap={variablesMap}
                          globalVariablesMap={globalVariablesMap}
                          className="inline-block"
                        />
                      </SubLabel>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="request-block px-8">
            {!node.data.changesResolved ? (
              <div className="mb-8">
                <Alert severity="info">
                  <AlertTitle>Your change request has been sent!</AlertTitle>
                  The Sola team will review your changes as soon as possible and
                  keep you updated via Slack.
                </Alert>
              </div>
            ) : null}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                contactModalEventChannel.emit('open', {
                  problemType: 'Change request on salesforce integration step',
                  workflowId,
                });
              }}
            >
              Request a change
            </Button>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}
