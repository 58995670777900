"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExecutionScreenshotSchema = exports.getTemporalExecutionDataSchema = exports.getZippedExecutionOutputsSchema = exports.getMultipleExecutionsSchema = exports.getExecutionSchema = void 0;
const types_shared_1 = require("types-shared");
const utils_schema_1 = require("../utils.schema");
exports.getExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({}).optional(),
    }),
    response: types_shared_1.z.object({
        metadata: types_shared_1.ExecutionBase,
        variableData: types_shared_1.VariableMap,
        imageUrls: types_shared_1.z.array(types_shared_1.z.string()),
        artifacts: types_shared_1.z.array(utils_schema_1.ExecutionDocument),
        inputFiles: types_shared_1.z.array(utils_schema_1.ExecutionDocument),
        webhookResultsUrl: types_shared_1.z.string().optional(),
    }),
};
exports.getMultipleExecutionsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).optional(),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({
            executionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
            statusFilters: types_shared_1.z.array(types_shared_1.ExecutionStatus).optional(),
        }),
    }),
    response: types_shared_1.z.array(types_shared_1.ExecutionBase),
};
exports.getZippedExecutionOutputsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z
            .object({
            executionId: types_shared_1.uuidSchema.optional(),
        })
            .optional(),
        body: types_shared_1.z
            .object({
            executionIds: types_shared_1.z.array(types_shared_1.uuidSchema).optional(),
        })
            .optional(),
        query: types_shared_1.z
            .object({
            includeScreenshots: types_shared_1.z.string().optional(),
        })
            .optional(),
    }),
    response: types_shared_1.z.object({
        url: types_shared_1.z.string(),
    }),
};
exports.getTemporalExecutionDataSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({}).optional(),
    }),
    response: types_shared_1.z.object({
        scrapeData: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.string()),
        artifactUrls: types_shared_1.z.array(types_shared_1.z.string()),
        status: types_shared_1.ExecutionStatus,
    }),
};
exports.getExecutionScreenshotSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
            index: types_shared_1.z.coerce.number().int(),
        }),
    }),
    response: types_shared_1.z.object({
        screenshotUrl: types_shared_1.z.string(),
    }),
};
