import { Button, Input } from 'ui-kit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useCallback, useState } from 'react';
import {
  type GlobalVariable,
  type TemplateData,
  type Variable,
  type VariableMap,
  type WorkflowSourceNode,
} from 'types-shared';
import { useSourceVariable } from '../../../hooks/useSourceVariable';
import NonImageNodeWrapper from '../../NonImageNodeWrapper';
import { getTriggerBlockTitle } from './trigger.helpers';
import { TriggerBlockData } from './TriggerBlockData';
import { isAdmin } from '../../../../../utils/env';
import TriggerConditions from './TriggerConditions';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';
import { posthog } from 'posthog-js';
import { type GetWorkflowMetadataResponse } from 'api-types-shared';

interface Props {
  node: WorkflowSourceNode;
  onCancel: () => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateNode: (node: WorkflowSourceNode) => void;
  updateVariable: (variable: Variable) => void;
  addVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
    model?: string,
  ) => Promise<string | undefined>;
  workflowId: string;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
}

enum TabNames {
  Trigger = 'Trigger',
  EmailData = 'EmailData',
}

export function TriggerBlock({
  onCancel,
  node,
  variablesMap,
  globalVariablesMap,
  updateVariable,
  addVariable,
  updateNode,
  transformApiReqStatus,
  onTransformApiReq,
  workflowMetadata,
}: Props) {
  const [nodeDescription, setNodeDescription] = useState<string>(
    node.data.description ?? '',
  );
  const emailTriggerFlowV2Enabled = useFeatureFlag(
    FeatureFlag.EmailTriggerFlowV2,
  );
  const [selectedTab, setSelectedTab] = useState<TabNames>(
    emailTriggerFlowV2Enabled ? TabNames.Trigger : TabNames.EmailData,
  );

  const { sourceVariable } = useSourceVariable(variablesMap);

  const handleOnSave = useCallback(() => {
    const updatedNode = {
      ...node,
      data: {
        ...node.data,
        description: nodeDescription,
      },
    };

    posthog.capture('user_updated_email_trigger_description', {
      previousDescription: node.data.description,
      newDescription: nodeDescription,
    });

    updateNode(updatedNode);
  }, [nodeDescription, node, updateNode]);

  if (!sourceVariable) return null;

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-auto p-8 pb-24">
          <div className="my-6">
            <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
              {getTriggerBlockTitle(node, sourceVariable)}
            </h2>
            <p className="text-sm font-normal mt-2 text-grey-light">
              Set up the conditions for the email trigger to kick off your
              workflow automatically.
            </p>
          </div>
          <div className="flex-1 flex flex-col gap-6">
            {isAdmin ? (
              <Input
                floatingLabel
                label="Step name"
                placeholder="Add a name to the step"
                value={node.name ?? ''}
                onChange={(newName) => {
                  const updatedNode = {
                    ...node,
                    name: newName,
                  };
                  updateNode(updatedNode);
                }}
              />
            ) : null}

            {!emailTriggerFlowV2Enabled && !isAdmin ? (
              <Input
                floatingLabel
                label="Description"
                placeholder='Trigger this workflow every time that you receive an email with "Sola" in the subject.'
                value={nodeDescription}
                onChange={(newDesc) => {
                  setNodeDescription(newDesc);
                }}
                multiline
                rows={5}
              />
            ) : null}

            {emailTriggerFlowV2Enabled ? (
              <Tabs
                className="!border-b !border-gray-200"
                onChange={(_: React.SyntheticEvent, newValue: TabNames) => {
                  setSelectedTab(newValue);
                }}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
              >
                <Tab label="Trigger" value={TabNames.Trigger} />
                <Tab label="Email Data" value={TabNames.EmailData} />
              </Tabs>
            ) : null}

            {selectedTab === TabNames.Trigger && workflowMetadata ? (
              <TriggerConditions workflowMetadata={workflowMetadata} />
            ) : null}

            {selectedTab === TabNames.EmailData && (
              <TriggerBlockData
                node={node}
                sourceVariable={sourceVariable}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                updateVariable={updateVariable}
                addVariable={addVariable}
                transformApiReqStatus={transformApiReqStatus}
                onTransformApiReq={onTransformApiReq}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex mx-8 !mb-6 !mt-0 space-x-4">
        {!emailTriggerFlowV2Enabled && !isAdmin ? (
          <>
            <Button
              className="!flex-1"
              color="secondary"
              fullWidth
              disabled={!nodeDescription}
              onClick={() => {
                handleOnSave();
                onCancel();
              }}
              variant="contained"
            >
              Save changes
            </Button>
            <Button
              className="!flex-1"
              color="secondary"
              fullWidth
              onClick={onCancel}
              variant="outlined"
            >
              Cancel
            </Button>
          </>
        ) : null}
      </div>
    </NonImageNodeWrapper>
  );
}
