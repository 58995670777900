"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiKeySchema = void 0;
const types_shared_1 = require("types-shared");
exports.getApiKeySchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.ApiKey.pick({ keyId: true }),
    }),
    response: types_shared_1.z.object({
        apiKey: types_shared_1.ApiKey,
    }),
};
