import {
  ChevronLeft,
  ChevronRight,
  CustomFullscreenIcon,
  InfoOutlined,
  PlayCircleOutlineIcon,
  Button,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  CustomTypography,
  DataLoader,
} from 'ui-kit';
import React, {
  type ComponentType,
  type CSSProperties,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import {
  type ExecutionBase,
  type ExecutionProgress,
  type ExecutionStatus,
  ExecutionStatusEnum,
  type ExecutionVariables,
  SourceVariable,
  type TargetMap,
  type VariableMap,
  WorkflowImageNode,
  type WorkflowNode,
  WorkflowSourceNode,
  type WorkflowEdge,
  type GlobalVariable,
  NodeTypesEnum,
} from 'types-shared';

import {
  type ExecutionDocument,
  type GetExecutionResponse,
  SignalTypeEnum,
} from 'api-types-shared';

import {
  extractToEmail,
  type ScreenshotForDelete,
  type ScreenshotUrl,
} from '../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UpdateStatusDescModal } from './UpdateStatusDescModal';
import * as WorkflowScreenshotsFullscreen from './WorkflowScreenshotsFullscreen';
import { useWorkflowCurrentStepActions } from '../hooks';
import WorkflowScreenshotTabs from './WorkflowScreenshotTabs';
import ExecutionScreenshotDetails from './ExecutionScreenshotDetails';
import { isAdmin } from '../../../utils/env';
import { useFeatureFlag } from '../../../utils/helper';
import {
  FeatureFlag,
  hitlStatuses,
  canResumeStatuses,
} from '../../../utils/constants';
import { Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';
import ExecutionScreenshotThumbnail from './ExecutionScreenshotThumbnail';
import { type WebhookData } from '../hooks/useGetExecutionWebhookData';

const signalsConfig = [
  {
    Icon: PlayCircleOutlineIcon,
    tooltipText: 'Resume Signal',
    signal: SignalTypeEnum.Resume,
  },
];

const nonFixHeightTypes = ['blob', 'video'];

interface Props {
  executionDetail?: GetExecutionResponse;
  completedSteps: ExecutionProgress;
  currentStepId?: string;
  executionScreenshotDetailsEnabled: boolean;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  screenshotUrls: ScreenshotUrl[];
  loading: boolean;
  onSignalClick?: (signal: SignalTypeEnum, payload?: object) => void;
  children?: ReactNode;
  showLive?: boolean;
  status?: ExecutionStatus;
  executionMetadata?: ExecutionBase;
  executionArtifacts?: ExecutionDocument[];
  inputFiles?: ExecutionDocument[];
  workflowName?: string;
  isAPITrigger?: boolean;
  targetData?: TargetMap;
  variablesData?: VariableMap;
  showAllNodeLogs: boolean;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  hasSuggestions?: boolean;
  workflowId?: string;
  targets: TargetMap;
  updateExecution?: (newStatusDesc: string) => void;
  updateExecutionStatus?: 'error' | 'success' | 'pending' | 'idle';
  liveExecutionViewEnabled?: boolean;
  onDeleteScreenshot?: (
    id: string,
    isImage: boolean,
    item: ScreenshotUrl,
  ) => void;
  globalVariables: Record<string, GlobalVariable> | VariableMap;
  signalLoading: boolean;
  setStopPolling: (newPolling: boolean) => void;
  onAddScreenshotForDelete: (newItem: ScreenshotForDelete) => void;
  screenshotsForDelete: ScreenshotForDelete[];
  onBulkDeleteScreenshots: () => void;
  deletingScreenshots: boolean;
  webhookData?: WebhookData[] | null;
  selectedIndex?: number;
  setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
}

export function WorkflowScreenshots({
  completedSteps,
  currentStepId,
  executionScreenshotDetailsEnabled,
  screenshotUrls = [],
  loading,
  nodes,
  edges,
  onSignalClick,
  showLive = false,
  status,
  children,
  executionMetadata,
  isAPITrigger,
  workflowName,
  variablesData,
  executionVariables,
  onDownloadLinkData,
  executionArtifacts,
  inputFiles,
  hasSuggestions,
  workflowId,
  targets,
  updateExecution,
  updateExecutionStatus,
  liveExecutionViewEnabled,
  onDeleteScreenshot,
  globalVariables,
  signalLoading,
  setStopPolling,
  onAddScreenshotForDelete,
  screenshotsForDelete,
  onBulkDeleteScreenshots,
  deletingScreenshots,
  executionDetail,
  webhookData,
  selectedIndex,
  setSelectedIndex,
}: Props) {
  const hitlLiveViewEnabled = useFeatureFlag(FeatureFlag.HitlLiveView);
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const focusStep = searchParams.get('focusStep');
  const navigate = useNavigate();
  const screenshotListRef = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [isLive, setIsLive] = useState<boolean>(isAdmin ? showLive : false);
  const steps = completedSteps.flatMap((step) => step.stepIds);

  const [menuProps, setMenuProps] = useState<{
    el: HTMLButtonElement | null;
    actionId: string;
    isScrape: boolean;
  } | null>();
  const [showScrapeValueModal, setShowScrapeValueModal] = useState(false);
  const [scrapeValue, setScrapeValue] = useState('');
  const [showUpdateErrModal, setShowUpdateErrModal] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageNodeHeight, setImageNodeHeight] = useState<number>();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [reRenderKey, setReRenderKey] = useState('');
  const [screenshotListKey, setScreenshotListKey] = useState('');

  // Re-render screenshot list when loading and screenshot list change
  useEffect(() => {
    if (!screenshotUrls.length) return;
    setScreenshotListKey(uuid());
  }, [screenshotUrls, loading]);

  // Rerender right execution tab to keep its height a match to the left tab
  useEffect(() => {
    setReRenderKey(uuid());
  }, [isFullscreen, loading]);

  const sourceNode = useMemo(() => {
    return nodes.find((n) => WorkflowSourceNode.safeParse(n).success) as
      | WorkflowSourceNode
      | undefined;
  }, [nodes]);

  const sourceVariable = useMemo(() => {
    if (!sourceNode) {
      return undefined;
    }
    const { variableId } = sourceNode.data;
    return SourceVariable.parse(variablesData?.[variableId]);
  }, [sourceNode, variablesData]);

  const isLastItemSelected =
    selectedIndex === screenshotUrls.length - 1 || selectedIndex === -1;

  const _selectedIndex = isLastItemSelected
    ? screenshotUrls.length - 1
    : selectedIndex ?? 0;

  useEffect(() => {
    setSelectedIndex((val) => {
      if (val === undefined && screenshotUrls.length > 0) {
        return -1;
      }
      return val;
    });
  }, [screenshotUrls, setSelectedIndex]);

  const nodeId = useMemo(() => {
    if (!screenshotUrls.length) {
      return undefined;
    }
    const _selectedScreenshot = screenshotUrls[_selectedIndex];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!_selectedScreenshot) {
      return undefined;
    }
    return _selectedScreenshot.sortData.nodeId;
  }, [_selectedIndex, screenshotUrls]);

  const currentNode = useMemo(() => {
    return nodes.find((n) => n.id === nodeId);
  }, [nodes, nodeId]);

  const currentWebhookData = useMemo(() => {
    if (!screenshotUrls.length) {
      return undefined;
    }
    const _selectedScreenshot = screenshotUrls[_selectedIndex];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!_selectedScreenshot) {
      return undefined;
    }
    const webhookUrl = _selectedScreenshot.sortData.webhookUrl;
    const timestamp = _selectedScreenshot.sortData.timestamp;

    return webhookData?.find(
      (w) => w.url === webhookUrl && w.timestamp === timestamp,
    );
  }, [_selectedIndex, screenshotUrls, webhookData]);

  const currentStepActions = useWorkflowCurrentStepActions({
    currentNode: WorkflowImageNode.safeParse(currentNode).success
      ? (currentNode as WorkflowImageNode)
      : undefined,
    steps,
    targets,
    variablesData,
  });
  const selectedScreenshot = useMemo(
    () => screenshotUrls[_selectedIndex] ?? {},
    [screenshotUrls, _selectedIndex],
  );

  const { type, src: ScreenshotSrc, nodeType } = selectedScreenshot;

  useEffect(() => {
    if (focusStep) {
      setSelectedIndex(Number(focusStep));
    }
  }, [focusStep, setSelectedIndex]);

  useEffect(() => {
    if (screenshotListRef.current && (isLastItemSelected || !hasScrolled)) {
      screenshotListRef.current.scrollLeft =
        screenshotListRef.current.scrollWidth;
    }
  }, [hasScrolled, screenshotListRef.current?.scrollWidth, isLastItemSelected]);

  useEffect(() => {
    if (isLive) {
      setSelectedIndex(screenshotUrls.length - 1);
    }
  }, [isLive, screenshotUrls, setSelectedIndex]);

  const closeScrapeValueModal = () => {
    setShowScrapeValueModal(false);
    setMenuProps(null);
  };

  const statusDescr = executionMetadata?.statusDescr;
  const errorMsg = statusDescr;
  const receiverEmail = useMemo(() => {
    return extractToEmail(executionVariables);
  }, [executionVariables]);

  useEffect(() => {
    const imageHeight = imageRef.current?.clientHeight ?? 0;
    if (type === 'blob' && imageHeight && selectedIndex) {
      setImageNodeHeight(imageHeight);
    }
  }, [type, selectedIndex]);

  useEffect(() => {
    if (isAdmin) {
      setIsLive(showLive);
    }
  }, [showLive]);

  const style =
    !nonFixHeightTypes.includes(type) && imageNodeHeight && !isLive
      ? ({
          minHeight: `${imageNodeHeight.toString()}px`,
          maxHeight: `${imageNodeHeight.toString()}px`,
          height: `${imageNodeHeight.toString()}px`,
        } as CSSProperties)
      : undefined;

  const canResume =
    status &&
    currentNode?.type !== NodeTypesEnum.Conditional &&
    canResumeStatuses.includes(status);

  return (
    <>
      {!isLive &&
      isLastItemSelected &&
      errorMsg &&
      !executionScreenshotDetailsEnabled ? (
        <div className="mt-4 !-mb-6 bg-[#FDEDED] flex space-x-2 px-6 py-4 rounded mx-auto max-w-[70%]">
          <InfoOutlined className="!w-5 !h-5 !text-error !mt-0.5" />
          <p
            className="text-red-900 text-sm"
            dangerouslySetInnerHTML={{
              __html: errorMsg.replaceAll('\n', '<br />'),
            }}
          />
        </div>
      ) : null}

      <div
        className={clsx('flex flex-col items-stretch', {
          'fixed top-0 left-0 w-full h-full z-[99] bg-white': isFullscreen,
          'mt-10 border border-gray-300 rounded': !isFullscreen,
        })}
      >
        {executionMetadata ? (
          <WorkflowScreenshotsFullscreen.FullscreenHeader
            visible={isFullscreen}
            isAPITrigger={Boolean(isAPITrigger)}
            workflowName={workflowName}
            setIsFullscreen={setIsFullscreen}
            executionMetadata={executionMetadata}
          />
        ) : null}

        <div
          className={clsx({
            'flex flex-1': true,
            'items-stretch': isFullscreen,
            'items-start': !isFullscreen,
          })}
        >
          <div
            id="execution-screenshots-container"
            className={clsx(
              'relative flex flex-col space-y-2 bg-gradient-to-b from-primary-purple-100 via-transparent to-transparent',
              'border-r border-gray-300 ',
              {
                'w-[70%]': executionScreenshotDetailsEnabled,
                'w-full': !executionScreenshotDetailsEnabled,
              },
            )}
            ref={leftContainerRef}
          >
            <div className="w-full h-full flex flex-col">
              <div className="flex flex-1 justify-center items-center m-4">
                <SwipeIcon
                  className={clsx('mr-6', {
                    '!invisible':
                      loading || !_selectedIndex || _selectedIndex <= 0,
                  })}
                  hide={loading}
                  onClick={() => {
                    setIsLive(false);
                    const newIndex = _selectedIndex - 1;
                    setSelectedIndex(newIndex);
                    setSearchParams({ focusStep: newIndex.toString() });
                  }}
                >
                  <ChevronLeft />
                </SwipeIcon>

                <div
                  className={clsx(
                    '!w-3/4 !relative flex justify-center items-center h-full select-none overflow-x-hidden rounded-xl',
                    {
                      '!border-4 !border-info':
                        status === ExecutionStatusEnum.PendingSystem && isAdmin,
                      '!border-4 !border-warning':
                        status === ExecutionStatusEnum.PendingAdmin && isAdmin,
                      '!min-h-[30rem] !max-h-[30rem]':
                        type !== 'blob' && !isFullscreen && !style && !isLive,
                      '!min-h-[50vh] !max-h-[50vh]':
                        type !== 'blob' && isFullscreen && !style && !isLive,
                      '!min-h-[31rem]':
                        !(type !== 'blob' && !isFullscreen && !style) &&
                        !(type !== 'blob' && isFullscreen && !style),
                      'h-[31rem]': loading,
                      'overflow-y-auto': !loading,
                      'overflow-y-hidden': loading,
                    },
                  )}
                  style={style}
                >
                  {isLive ? children : null}
                  {!isLive && isFullscreen && type ? (
                    <div className="flex-1 h-full relative">
                      {(() => {
                        switch (type) {
                          case 'blob':
                            return (
                              <div
                                className="w-full h-full bg-contain bg-no-repeat bg-center rounded-xl"
                                style={{
                                  backgroundImage: `url("${ScreenshotSrc as string}")`,
                                }}
                              />
                            );
                          case 'video':
                            // eslint-disable-next-line no-case-declarations
                            const videoUrl = ScreenshotSrc as string;
                            return (
                              <video
                                className="w-full h-full absolute"
                                controls
                                key={videoUrl}
                              >
                                <source src={videoUrl} type="video/mp4" />
                                <track kind="captions" />
                              </video>
                            );
                          case 'icon':
                            // eslint-disable-next-line no-case-declarations
                            const Icon =
                              selectedScreenshot.src as ComponentType<{
                                className?: string;
                              }>;
                            return (
                              <Icon className="bg-white rounded-xl text-info-dark !w-full !max-h-[50vh] !min-h-[50vh] !py-32 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
                            );
                          default:
                            return (
                              <div className="w-full h-full [&>img]:w-40 bg-white rounded-xl flex items-center justify-center">
                                {ScreenshotSrc as ReactNode}
                              </div>
                            );
                        }
                      })()}
                    </div>
                  ) : null}
                  {!isLive && !isFullscreen && type && !loading ? (
                    <>
                      {(() => {
                        switch (type) {
                          case 'blob':
                            return (
                              <img
                                alt=""
                                className="border-2 border-primary-purple-200 rounded-xl !min-h-[30rem] !max-h-[30rem]"
                                loading="lazy"
                                src={ScreenshotSrc as string}
                                ref={imageRef}
                              />
                            );
                          case 'video':
                            // eslint-disable-next-line no-case-declarations
                            const videoUrl = ScreenshotSrc as string;
                            return (
                              <video
                                className="w-full h-full absolute"
                                controls
                                key={videoUrl}
                              >
                                <source src={videoUrl} type="video/mp4" />
                                <track kind="captions" />
                              </video>
                            );
                          case 'icon':
                            // eslint-disable-next-line no-case-declarations
                            const Icon =
                              selectedScreenshot.src as ComponentType<{
                                className?: string;
                              }>;
                            return (
                              <Icon className="bg-white rounded-xl text-info-dark !w-full !h-full !py-40 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 border-2 border-primary-purple-200" />
                            );
                          default:
                            return (
                              <div className="border-2 border-primary-purple-200 w-full h-full !min-h-[29.75rem] !max-h-[29.75rem] [&>img]:w-40 bg-white rounded-xl flex items-center justify-center">
                                {ScreenshotSrc as ReactNode}
                              </div>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    <>
                      {loading && !isLive ? (
                        <DataLoader
                          className="min-h-[31rem] mt-0 flex-col items-center justify-center space-x-0 space-y-4"
                          size={50}
                          loadingText={
                            <span className="text-info-dark">
                              Loading workflow steps
                            </span>
                          }
                        />
                      ) : null}
                    </>
                  )}
                </div>

                <SwipeIcon
                  className={clsx('ml-6', {
                    '!invisible':
                      loading ||
                      _selectedIndex === -1 ||
                      !screenshotUrls.length ||
                      _selectedIndex === screenshotUrls.length - 1,
                  })}
                  hide={loading}
                  onClick={() => {
                    setIsLive(false);
                    const newIndex = _selectedIndex + 1;
                    setSelectedIndex(newIndex);
                    setSearchParams({ focusStep: newIndex.toString() });
                  }}
                >
                  <ChevronRight />
                </SwipeIcon>
              </div>

              <div className="w-full flex items-center px-2 border-t border-gray-300">
                <div
                  className="flex overflow-x-auto my-3 px-4 gap-4 flex-1 overflow-y-hidden"
                  ref={screenshotListRef}
                  key={screenshotListKey}
                  onScroll={() => {
                    setHasScrolled(true);
                  }}
                >
                  {loading
                    ? Array.from({ length: 6 }, (_, i) => i + 1).map((item) => (
                        <Skeleton
                          variant="rounded"
                          height="2rem"
                          width="3rem"
                          key={item}
                          className="border-2 border-primary-purple-200 last:mr-auto flex items-center"
                        />
                      ))
                    : null}

                  {!loading &&
                    screenshotUrls.map((currentScreenshot, index) => {
                      const {
                        sortData: {
                          timestamp = '',
                          nodeId: nId = '',
                          stepId = '',
                        },
                      } = currentScreenshot;
                      return (
                        <ExecutionScreenshotThumbnail
                          // TODO: doing this because of time sensitivity, use timestamp in future
                          key={`${timestamp}_${nId}_${stepId}`}
                          onClick={() => {
                            setIsLive(false);
                            setSelectedIndex(index);
                            setSearchParams({ focusStep: index.toString() });
                          }}
                          index={index}
                          screenshotUrlsLength={screenshotUrls.length}
                          screenshot={currentScreenshot}
                          _selectedIndex={_selectedIndex}
                          onAddScreenshotForDelete={onAddScreenshotForDelete}
                          screenshotsForDelete={screenshotsForDelete}
                        />
                      );
                    })}
                </div>
                {showLive &&
                !isFullscreen &&
                !loading &&
                Boolean(liveExecutionViewEnabled) ? (
                  <Button
                    {...(isLive ? { color: 'error' } : {})}
                    onClick={() => {
                      setIsLive(true);
                      setSelectedIndex(screenshotUrls.length - 1);
                    }}
                  >
                    Live
                  </Button>
                ) : null}
                {isLive || canResume ? (
                  <div className="flex items-center ml-1">
                    {signalsConfig.map(({ Icon, signal, tooltipText }) => (
                      <IconButton
                        className={!canResume ? 'opacity-50' : ''}
                        key={signal}
                        disabled={!canResume}
                        onClick={() => onSignalClick?.(signal)}
                      >
                        <Tooltip title={tooltipText}>
                          <Icon className="!w-5 !h-5 !text-black hover:!text-info" />
                        </Tooltip>
                      </IconButton>
                    ))}
                  </div>
                ) : null}
              </div>

              {!isLive && !isFullscreen ? (
                <Button
                  className="!absolute top-3 right-3 !p-2 !min-w-min !rounded-lg !bg-white !border-info"
                  color="primary"
                  onClick={() => {
                    setIsFullscreen((val) => !val);
                  }}
                  variant="outlined"
                >
                  <CustomFullscreenIcon className="!w-5 !h-5 !text-info" />
                </Button>
              ) : null}
            </div>
          </div>

          {executionScreenshotDetailsEnabled ? (
            <div
              id="right-container"
              key={reRenderKey}
              className="w-[30%] flex flex-col max-h-full"
              style={{
                maxHeight: isFullscreen
                  ? 'calc(100% - 6rem)'
                  : `${leftContainerRef.current?.clientHeight.toString() ?? ''}px`,
              }}
            >
              <ExecutionScreenshotDetails
                selectedIndex={_selectedIndex}
                hasError={Boolean(errorMsg)}
                currentNode={currentNode}
                completedStepsLength={completedSteps.length}
                sourceVariable={sourceVariable}
                receiverEmail={receiverEmail}
                screenshotUrlsLength={screenshotUrls.length}
                setShowUpdateErrModal={setShowUpdateErrModal}
                onDeleteScreenshot={onDeleteScreenshot}
                workflowId={workflowId}
                selectedScreenshot={selectedScreenshot}
                loading={loading}
                canBulkDeleteScreenshots={screenshotsForDelete.length > 0}
                onBulkDeleteScreenshots={onBulkDeleteScreenshots}
                deletingScreenshots={deletingScreenshots}
                bulkDeleteScreenshotsCount={screenshotsForDelete.length}
                currentWebhookData={currentWebhookData}
              />

              <WorkflowScreenshotTabs
                currentWebhookData={currentWebhookData}
                executionDetail={executionDetail}
                workflowName={workflowName}
                setStopPolling={setStopPolling}
                loading={loading}
                screenshotUrls={screenshotUrls}
                nodeOfCurrentStep={nodes.find(
                  (n) => n.id === executionMetadata?.currentStep?.nodeId,
                )}
                key={currentNode?.id}
                completedSteps={completedSteps}
                nodes={nodes}
                variablesMap={variablesData ?? {}}
                globalVariablesMap={globalVariables}
                currentNode={currentNode}
                edges={edges}
                errorMsg={errorMsg}
                hasError={Boolean(errorMsg)}
                nodeType={nodeType}
                isLastItemSelected={isLastItemSelected}
                currentStepId={currentStepId}
                selectedIndex={_selectedIndex}
                currentStepActions={currentStepActions}
                executionVariables={executionVariables}
                onDownloadLinkData={onDownloadLinkData}
                executionArtifacts={executionArtifacts}
                inputFiles={inputFiles}
                onSignalClick={onSignalClick}
                setMenuProps={setMenuProps}
                signalLoading={signalLoading}
                showHITLPrompt={Boolean(
                  status &&
                    hitlStatuses.includes(status) &&
                    Boolean(hitlLiveViewEnabled),
                )}
                executionMetadata={executionMetadata}
                onUpdateStatusDesc={updateExecution}
                updateDescStatus={updateExecutionStatus}
              />

              {hasSuggestions && isLastItemSelected ? (
                <div className="m-4 bg-primary-blue-extralight flex space-x-2 px-6 py-4 rounded">
                  <InfoOutlined className="!w-5 !h-5 !text-info !mt-0.5" />
                  <div className="flex flex-col space-y-2">
                    <span className="text-info-dark text-base font-medium">
                      Built in logic to handle future errors like this
                    </span>
                    <span className="text-sm">
                      A new branch has been recommended in your workflow to
                      handle this error. Visit the workflow to review and modify
                      the proposed error handling logic.
                    </span>
                    <Button
                      className="!mt-4 !max-w-max"
                      color="secondary"
                      onClick={() => {
                        if (workflowId) {
                          navigate(`/editor/${workflowId}`);
                        }
                      }}
                      variant="contained"
                    >
                      Review error handling
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {menuProps ? (
          <Menu
            anchorEl={menuProps.el}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={() => {
              setMenuProps(null);
            }}
            open={Boolean(menuProps.el)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {canResume ? (
              <MenuItem
                className="!font-medium"
                onClick={() => {
                  onSignalClick?.(SignalTypeEnum.Resume, {
                    nextActionId: menuProps.actionId,
                    nextNodeId: nodeId,
                  });
                  setMenuProps(null);
                }}
              >
                <span className="font-medium mr-4">Resume</span>
              </MenuItem>
            ) : null}
            {menuProps.isScrape ? (
              <MenuItem
                className="!font-medium"
                onClick={() => {
                  setShowScrapeValueModal(true);
                }}
              >
                <span className="font-medium mr-4">Set scrape value</span>
              </MenuItem>
            ) : null}
          </Menu>
        ) : null}

        {updateExecution &&
        updateExecutionStatus &&
        isAdmin &&
        executionMetadata ? (
          <UpdateStatusDescModal
            open={showUpdateErrModal}
            setOpen={setShowUpdateErrModal}
            onUpdateStatusDesc={updateExecution}
            updateStatus={updateExecutionStatus}
            workflowId={executionMetadata.workflowId}
            workflowName={workflowName}
          />
        ) : null}

        {menuProps && showScrapeValueModal ? (
          <Modal open={showScrapeValueModal} onClose={closeScrapeValueModal}>
            <CustomTypography className="font-medium mb-4" variant="h6">
              Override scrape value
            </CustomTypography>

            <Input
              value={scrapeValue}
              onChange={(val) => {
                setScrapeValue(val);
              }}
              multiline
              rows={4}
            />
            <div className="mt-4">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  // onSignalClick?.(SignalTypeEnum.SetScrapeValue, {
                  //   scrapeValue,
                  //   nodeId,
                  //   actionId: menuProps?.actionId,
                  // });
                  closeScrapeValueModal();
                }}
              >
                Save
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    </>
  );
}

interface SwipeIconProps {
  hide: boolean;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

function SwipeIcon({ hide, onClick, className, children }: SwipeIconProps) {
  if (hide) {
    return <div className={clsx('w-[40px]', className)} role="none" />;
  }

  return (
    <div className={clsx('rounded-full border border-gray-300', className)}>
      <IconButton className="!bg-white" onClick={onClick}>
        {children}
      </IconButton>
    </div>
  );
}
