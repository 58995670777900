"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadRawExtensionDataSchema = void 0;
const types_shared_1 = require("types-shared");
exports.uploadRawExtensionDataSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({}).nullable(),
    }),
    response: types_shared_1.z.object({
        uploadExtensionDataUrl: types_shared_1.z.string(),
    }),
};
