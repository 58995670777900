import { clsx } from 'clsx';
import type {
  IConnectCredential,
  ParagonIntegrationEnum,
} from 'dashboard-shared';
import { useState } from 'react';
import { CheckedCheckmarkIcon, Flex, WarningAmberOutlined } from 'ui-kit';
import { useIntegrationConfig } from '../feature_flag';
import AccountsModal from './AccountsModal';

interface Props {
  displayName: string;
  isConnected?: boolean;
  hasInvalidCredentials?: boolean;
  iconUrl?: string;
  accounts?: IConnectCredential[];
  integrationType: ParagonIntegrationEnum;
}

export default function IntegrationTile({
  displayName,
  isConnected = false,
  hasInvalidCredentials = false,
  iconUrl,
  accounts = [],
  integrationType,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { ENABLED_INTEGRATIONS, VISIBLE_INTEGRATIONS } = useIntegrationConfig();
  const isEnabled = ENABLED_INTEGRATIONS.has(integrationType);

  const handleClick = () => {
    if (!isEnabled) {
      return;
    }
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (!VISIBLE_INTEGRATIONS.has(integrationType)) {
    return null;
  }

  return (
    <div className="relative">
      <Flex
        onClick={handleClick}
        className={clsx({
          'min-w-80 p-7 relative transition max-w-80 h-52 justify-center items-center rounded-lg border border-gray-200 hover:shadow-md overflow-hidden':
            true,
          'hover:border-primary-blue cursor-pointer': isEnabled,
          'opacity-50 cursor-not-allowed': !isEnabled,
        })}
      >
        {/* Center the icon/logo */}
        <div className="flex items-center justify-center w-full h-full">
          {iconUrl ? (
            <img
              alt={displayName}
              className="w-20 h-20 object-contain"
              src={iconUrl}
            />
          ) : null}
        </div>

        {/* Status and Account Info Section */}
        {isConnected || hasInvalidCredentials ? (
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 border-t border-gray-200">
            <div className="flex items-center justify-between px-4 py-3">
              <div className="flex items-center space-x-2">
                {hasInvalidCredentials ? (
                  <>
                    <WarningAmberOutlined color="error" className="w-4 h-4" />
                    <span className="text-sm font-medium text-error">
                      Reconnection necessary
                    </span>
                  </>
                ) : (
                  <>
                    <CheckedCheckmarkIcon color="success" className="w-4 h-4" />
                    <span className="text-sm font-medium text-gray-700">
                      Connected
                    </span>
                  </>
                )}
              </div>
              {accounts.length > 0 && (
                <div className="inline-flex items-center justify-center">
                  <div className="bg-primary-blue w-5 h-5 rounded-full flex items-center justify-center relative">
                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-medium leading-none text-white select-none">
                      {
                        accounts.filter(
                          (account) =>
                            account.status !==
                            ('INVALID' as IConnectCredential['status']),
                        ).length
                      }
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </Flex>

      {/* Accounts Modal */}
      <AccountsModal
        open={isModalOpen}
        onClose={handleClose}
        platform={displayName}
        integrationType={integrationType}
        accounts={accounts}
        iconUrl={iconUrl}
      />
    </div>
  );
}
