import { useQuery } from '@tanstack/react-query';
import { type GetExecutionResponse } from 'api-types-shared';
import { handleException } from 'sentry-browser-shared';
import { getWebhookIdFromUrl } from '../utils';

interface WebhookRequestData {
  executionId: string;
  workflowId: string;
  status: string;
  statusDescription: string | null;
  createdAt: string;
  lastUpdatedAt: string;
  variableData: GetExecutionResponse['variableData'];
  artifacts: GetExecutionResponse['artifacts'];
  scrapeData: Record<string, string>;
}

export interface WebhookData {
  url: string;
  method: string;
  requestHeaders: {
    'Content-Type': string;
  };
  requestBody: {
    event: string;
    data: WebhookRequestData;
  };
  responseStatus: number;
  responseHeaders: {
    'cache-control': string;
    'content-type': string;
    date: string;
    server: string;
    'transfer-encoding': string;
    'x-request-id': string;
    'x-token-id': string;
  };
  responseBody: string;
  timestamp: string;
}

export const useGetExecutionWebhook = (
  executionDetail?: GetExecutionResponse,
) => {
  const webhookUrl = executionDetail?.webhookResultsUrl;
  const webhookId = getWebhookIdFromUrl(webhookUrl);
  return useQuery<WebhookData[]>({
    queryKey: ['executionWebhook', webhookId],
    queryFn: async () => {
      if (!webhookUrl) {
        return [];
      }

      try {
        const response = await fetch(webhookUrl);

        if (!response.ok) {
          handleException(new Error(), {
            name: 'Fetch webhook invalid response',
            source: 'useGetExecutionWebhook',
            extra: {
              response,
            },
          });
          return [];
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const webhookData: WebhookData[] = await response.json();
        return webhookData;
      } catch (error) {
        handleException(error, {
          name: 'Fetch webhook url data failed',
          source: 'useGetExecutionWebhook',
          extra: {
            webhookUrl,
          },
        });

        throw error;
      }
    },
    enabled: Boolean(webhookUrl),
  });
};
