"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseStepEventEntry = exports.WindowInfo = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../extensionTypes");
var helper_types_1 = require("../helper-types");
exports.WindowInfo = zod_1.z.object({
    currHandle: zod_1.z.string(),
    prevHandle: zod_1.z.string().optional(),
    uri: zod_1.z.string().optional(),
    title: zod_1.z.string().optional(),
});
exports.BaseStepEventEntry = zod_1.z.object({
    id: helper_types_1.uuidSchema,
    utcTimestamp: zod_1.z.number(), // UTC timestamp in milliseconds
    type: zod_1.z.string(),
    executionId: helper_types_1.uuidSchema,
    platform: helper_types_1.ActionPlatform,
    createdAt: zod_1.z.string(),
    window: exports.WindowInfo,
    selector: zod_1.z.string().optional(),
    legacyCompatibility: zod_1.z
        .object({
        nodeId: zod_1.z.string(),
        actionType: extensionTypes_1.Actions,
        options: zod_1.z.object({
            adminOnly: zod_1.z.boolean().optional().default(false),
        }),
    })
        .optional(),
});
// export const StepEventEntry = NavigationEventEntry; // TODO(z.Union([...]))
// export type StepEventEntry = z.infer<typeof StepEventEntry>;
