"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutParameters = exports.KeyboardShortcutType = void 0;
const types_shared_1 = require("types-shared");
const keyboard_shortcuts_1 = require("../../legacy/actions/keyboard-shortcuts");
const excel_1 = require("../../legacy/actions/keyboard-shortcuts/groups/excel");
const file_explorer_1 = require("../../legacy/actions/keyboard-shortcuts/groups/file-explorer");
const file_tab_1 = require("../../legacy/actions/keyboard-shortcuts/groups/excel/file-tab");
exports.KeyboardShortcutType = types_shared_1.z.union([
    types_shared_1.z.nativeEnum(keyboard_shortcuts_1.KeyboardShortcuts),
    types_shared_1.z.nativeEnum(excel_1.ExcelShortcuts),
    types_shared_1.z.nativeEnum(excel_1.ExcelDataTabShortcuts),
    types_shared_1.z.nativeEnum(excel_1.ExcelHomeTabShortcuts),
    types_shared_1.z.nativeEnum(file_tab_1.ExcelFileTabShortcuts),
    types_shared_1.z.nativeEnum(file_explorer_1.FileExplorerShortcuts),
]);
exports.KeyboardShortcutParameters = types_shared_1.z.object({
    type: exports.KeyboardShortcutType,
    keySequences: types_shared_1.KeySequences,
    recordedStepIds: types_shared_1.z.array(types_shared_1.z.string()).default([]), // StepIds coalesced from the recording
});
