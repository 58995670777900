import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import { clsx } from 'clsx';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import {
  NodeTypesEnum,
  RequestIntegrationTypeEnum,
  SourceTypeEnum,
  type WorkflowNode,
} from 'types-shared';
import {
  APICallIcon,
  Clear,
  ConditionalErrorIcon,
  ConditionalIcon,
  DatabaseIcon,
  DocumentIcon,
  EditPencilIcon,
  FlagIcon as EndingNodeIcon,
  IconButton,
  MailIcon,
  RequestBlockIcon,
  RetryV2Icon,
} from 'ui-kit';
import { isAdmin } from '../../../../utils/env';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { EditorStore } from '../../store/EditorState';
import NodeHeader from '../NodeHeader';
import { ComponentsToolbar } from '../Toolbar/ComponentsToolbar';
import {
  useNavigateBetweenNodes,
  useNextAndPrevNode,
} from '../NodeHeader/hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useOnEscape } from '../../../../hooks/useOnEscape';

export default function NonImageNodeWrapper({
  node,
  children,
  onClose,
  workflowMetadata,
  insideNodeSubSection,
  onExitNodeSubSection,
  hideNodeHeader = false,
}: {
  node?: WorkflowNode;
  children: React.ReactNode;
  onClose?: () => void;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  insideNodeSubSection?: boolean;
  onExitNodeSubSection?: () => void;
  hideNodeHeader?: boolean;
}) {
  const { variables, datasourceMetadata, nodes, edges } = EditorStore();
  const { handleNavigateToNode, handleCloseNode, currentNodeType } =
    useNavigateBetweenNodes();
  const { hideFlow } = useEditingNodeId();

  const isNewNode = currentNodeType === 'new';

  const { isTrigger, isDataSource, sourceVariable } =
    useSourceVariable(variables);

  const hasDatasource = !isNil(datasourceMetadata);

  const handleClose = () => {
    onClose?.();
    handleCloseNode();
  };

  const { nextNodeId, prevNodeId } = useNextAndPrevNode({
    currentNodeId: node?.id,
    nodes,
    edges,
  });

  useOnEscape((e) => {
    e.stopPropagation();
    if (onExitNodeSubSection) {
      onExitNodeSubSection();
      return;
    }

    handleClose();
  });

  const { NodeIcon, bgColor, nodeTitle } = useMemo(() => {
    switch (node?.type) {
      case NodeTypesEnum.Conditional:
        if (node.errorOverlay) {
          return {
            NodeIcon: (
              <ConditionalErrorIcon className="!w-[20rem] !h-[20rem]" />
            ),
            bgColor: '#f3f4f6',
            nodeTitle: 'Error control step',
          };
        }
        return {
          NodeIcon: (
            <ConditionalIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
          ),
          bgColor: '#eaf0f3',
          nodeTitle: 'Conditional step',
        };
      case NodeTypesEnum.Document:
        return {
          NodeIcon: (
            <DocumentIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'Document step',
        };
      case NodeTypesEnum.Stop:
        return {
          NodeIcon: (
            <EndingNodeIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'Stop step',
        };
      case NodeTypesEnum.Retry:
        return {
          NodeIcon: <RetryV2Icon className="!w-[20rem] !h-[20rem]" />,
          bgColor: '#f3f4f6',
          nodeTitle: 'Retry step',
        };
      case NodeTypesEnum.Freeform:
        return {
          NodeIcon: (
            <img alt="logo" className="w-[20rem]" src="/logo-blue.png" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'Freeform Instructions',
        };
      case NodeTypesEnum.Request:
        if (
          node.data.integrationType === RequestIntegrationTypeEnum.Salesforce
        ) {
          return {
            NodeIcon: (
              <img alt="logo" className="w-[20rem]" src="/salesforce.png" />
            ),
            bgColor: '#f3f4f6',
            nodeTitle: 'Salesforce step',
          };
        }
        return {
          NodeIcon: (
            <RequestBlockIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'Request step',
        };
      case NodeTypesEnum.Temporal:
        return {
          NodeIcon: <div className="text-cyan-900 text-4xl">⚡</div>,
          bgColor: '#f3f4f6',
          nodeTitle: 'Temporal workflow step',
        };
      case NodeTypesEnum.Email:
        return {
          NodeIcon: (
            <MailIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'Email Integration',
        };
      case NodeTypesEnum.Source:
        if (
          isTrigger &&
          sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger
        ) {
          return {
            NodeIcon: (
              <MailIcon className="!w-[20rem] !h-[20rem] text-cyan-900" />
            ),
            bgColor: '#f3f4f6',
            nodeTitle: 'Email trigger',
          };
        }
        if (isDataSource && hasDatasource) {
          return {
            NodeIcon: <DatabaseIcon className="!w-[20rem] !h-[20rem]" />,
            bgColor: '#f3f4f6',
            nodeTitle: 'Data source step',
          };
        }
        return {
          NodeIcon: <APICallIcon className="!w-[20rem] !h-[20rem]" />,
          bgColor: '#f3f4f6',
          nodeTitle: 'API call step',
        };

      default:
        return {
          NodeIcon: (
            <EditPencilIcon className="!text-info !h-[20rem] !w-[20rem]" />
          ),
          bgColor: '#f3f4f6',
          nodeTitle: 'New step',
        };
    }
  }, [node, hasDatasource, isDataSource, isTrigger, sourceVariable]);

  return node || isNewNode ? (
    <div
      className={clsx(
        'zoom-adjusted-container fixed inset-0 flex flex-col  z-10',
        { 'pointer-events-none': !hideFlow },
      )}
    >
      <div
        className={clsx({
          'px-4 w-full bg-white py-4 border-b-2 border-gray-200 flex items-center space-x-2 justify-between pointer-events-auto':
            hideFlow,
          'px-4 w-full bg-transparent py-4 border-b-2 border-gray-200 flex items-center space-x-2 justify-between pointer-events-none opacity-0':
            !hideFlow,
        })}
      >
        <ComponentsToolbar
          crumbs={[
            {
              title: `${workflowMetadata?.workflowName ?? 'Workflow'} - Flow view`,
            },
          ]}
          onClose={handleClose}
        />
      </div>

      <div className="flex flex-row flex-grow flex-1 h-full overflow-y-auto">
        <div
          className={clsx(
            'min-w-125 h-full max-w-125 bg-white relative border-r-2 border-gray-200 flex flex-col pointer-events-auto',
            {
              'shadow-xl': !hideFlow,
            },
          )}
        >
          {!hideFlow && !hideNodeHeader ? (
            <div className="flex items-center justify-between pl-8 pr-4 py-2 text-sm text-info-dark font-medium border-b-2 border-gray-200">
              <span>{nodeTitle}</span>
              <IconButton onClick={handleClose}>
                <Clear className="!w-7 !h-7 !text-info" />
              </IconButton>
            </div>
          ) : null}

          {node && hideFlow && !hideNodeHeader ? (
            <NodeHeader
              node={node}
              insideNodeSubSection={insideNodeSubSection}
              onExitNodeSubSection={onExitNodeSubSection}
              exitNodeSubSectionTitle="BACK TO ACTIONS"
            />
          ) : null}
          <div className="h-full overflow-y-auto">{children}</div>
        </div>

        <div
          className={clsx('p-20 flex-1', {
            'hidden pointer-events-none bg-transparent': !hideFlow,
            'bg-white': hideFlow,
          })}
        >
          {isAdmin && node ? (
            <p className="text-zinc-500 text-sm leading-tight mb-5 -mt-2 px-8 text-center">
              Node Id: {node.id}
            </p>
          ) : null}

          <div className="flex flex-row items-center h-full">
            <div className="w-[40px] mr-6">
              <IconButton
                onClick={() => {
                  if (prevNodeId) {
                    handleNavigateToNode(prevNodeId);
                  }
                }}
                disabled={!prevNodeId}
                className="!bg-white rounded-full p-2 shadow cursor-pointer"
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>

            <div
              className="p-5 border-gray-200 border-2 h-full max-h-full w-full max-w-full"
              style={{ borderRadius: '12px' }}
            >
              <div
                className="border-gray-200 border-2 h-full flex items-center justify-center"
                style={{ backgroundColor: bgColor, borderRadius: '10px' }}
              >
                {NodeIcon}
              </div>
            </div>

            <div className="w-[40px] ml-6">
              <IconButton
                className="!bg-white rounded-full p-2 shadow cursor-pointer"
                disabled={!nextNodeId}
                onClick={() => {
                  if (nextNodeId) {
                    handleNavigateToNode(nextNodeId);
                  }
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
