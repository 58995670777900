import { useEffect, useMemo, useState } from 'react';
import {
  useImportWorkflowButton,
  useOwnedWorkflowsCount,
} from '../pages/Workflows/hooks';
import { FeatureFlag } from '../utils/constants';
import { useAuth, useFeatureFlag } from '../utils/helper';
import isNil from 'lodash/isNil';
import { isAdmin } from '../utils/env';
import { MOCK_USER_ID } from 'types-shared/constants';
import useWorkflowLimit from './useWorkflowLimit';
import { getExtensionData } from '../utils/extension';

interface Props {
  isLoading: boolean;
}

export const useImportWorkflow = ({ isLoading }: Props) => {
  const { user } = useAuth();
  const userID = isAdmin ? MOCK_USER_ID : user?.sub;
  const [extensionHasData, setExtensionHasData] = useState<boolean>(false);
  const { data: ownedWorkflowsCount } = useOwnedWorkflowsCount(userID);
  const workflowLimit = useWorkflowLimit();
  const { loadingImport, handleOnClickImport } = useImportWorkflowButton();

  const importsOverLimit = useMemo(
    () =>
      // we don't need to check if workflowLimit is null
      !isNil(workflowLimit) &&
      ownedWorkflowsCount &&
      ownedWorkflowsCount >= workflowLimit,
    [ownedWorkflowsCount, workflowLimit],
  );

  const apiKeysManagerPageEnabled = useFeatureFlag(FeatureFlag.ApiKeysManager);
  const workflowImportPollingEnabled = useFeatureFlag(
    FeatureFlag.WorkflowImportPolling,
  );

  const importWorkflowProps = useMemo(() => {
    const tooltipHidden =
      apiKeysManagerPageEnabled &&
      !importsOverLimit &&
      (extensionHasData || !workflowImportPollingEnabled);
    let tooltipTitle = '';

    if (!apiKeysManagerPageEnabled) {
      tooltipTitle =
        'As soon as your setup is complete, this feature will be available to you!';
    }

    if (!extensionHasData) {
      tooltipTitle =
        'Start a new recording to import it into your Sola dashboard.';
    }

    if (importsOverLimit) {
      tooltipTitle = 'Upgrade to a full plan to continue building workflows.';
    }

    const disableImport =
      !apiKeysManagerPageEnabled ||
      Boolean(importsOverLimit) ||
      isLoading ||
      loadingImport ||
      (workflowImportPollingEnabled && !extensionHasData);

    return {
      disableImport,
      tooltipTitle,
      tooltipHidden,
    };
  }, [
    apiKeysManagerPageEnabled,
    extensionHasData,
    importsOverLimit,
    isLoading,
    loadingImport,
    workflowImportPollingEnabled,
  ]);

  useEffect(() => {
    if (!workflowImportPollingEnabled) {
      return;
    }
    const timerId = setInterval(() => {
      void getExtensionData().then((extensionData) => {
        const {
          capturedUrls = [],
          scans = [],
          actions = [],
        } = extensionData ?? {};
        const hasData = Boolean(
          capturedUrls.length || scans.length || actions.length,
        );
        setExtensionHasData(hasData);
        if (hasData) {
          clearInterval(timerId);
        }
      });
      // poll extension data every 5s until we receive the data
    }, 5 * 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [workflowImportPollingEnabled]);

  return {
    loadingImport,
    handleOnClickImport,
    importWorkflowProps,
    extensionHasData,
    importsOverLimit,
  };
};
