import { clsx } from 'clsx';
import React from 'react';

interface Props {
  id?: string;
  className?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  floatingLabel?: boolean;
  isHighlighted?: boolean;
}

export default function Label({
  id,
  className,
  label,
  required,
  error,
  floatingLabel = false,
  isHighlighted = false,
}: Props) {
  const classes = clsx(
    {
      [`flex items-center text-base text-cyan-900 ${isHighlighted ? '!text-info' : ''}`]:
        !floatingLabel,
      [`absolute bg-white z-10 top-0 ml-3 px-1 text-gray-400 text-xs ${isHighlighted ? '!text-info' : ''}`]:
        floatingLabel,
      'text-red-500': error,
      // '!text-info': isHighlighted,
    },
    className,
  );

  return (
    <label className={classes} htmlFor={id}>
      {label}
      {required ? <sup className="text-red-500">*</sup> : null}
    </label>
  );
}
