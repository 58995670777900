import { useQuery, type ApolloError } from '@apollo/client';
import { GetTeamAndRoleDocument, type TeamAndRoleInfo } from 'hasura-gql';

export const useTeamDetails = (
  userId = '',
): { data?: TeamAndRoleInfo; loading: boolean; error?: ApolloError } => {
  const { data, loading, error } = useQuery(GetTeamAndRoleDocument, {
    variables: { userId },
    skip: !userId,
  });

  // NOTE: We use the first membership -- this has undefined behavior for users on multiple teams
  // TODO: Support multiple teams properly
  const firstMembership = data?.organizations_team_memberships[0];
  if (firstMembership) {
    const teamAndRoleInfo = {
      teamId: firstMembership.team.id,
      teamName: firstMembership.team.name,
      // NOTE: We use the first role -- this has undefined behavior for users with multiple roles
      roleName: firstMembership.roles[0]?.role.description ?? 'Member',
      // FIXME: Hack based on existing role names to determine if the role is an `admin` role or not
      isAdmin:
        firstMembership.roles[0]?.role.identifier.startsWith('admin') ?? false,
      workflowLimit: firstMembership.team.workflowLimit,
    };
    return { data: teamAndRoleInfo, loading, error };
  }

  return { data: undefined, loading, error };
};
