"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailMetadataSchemaV1 = exports.EmailMetadataSchemaV1Version = exports.EmailMetadataSchemaIdentifier = void 0;
var zod_1 = require("zod");
exports.EmailMetadataSchemaIdentifier = 'EmailMetadata';
exports.EmailMetadataSchemaV1Version = '1.1.0';
exports.EmailMetadataSchemaV1 = zod_1.z.object({
    // WARN: DO NOT MAKE BREAKING CHANGES TO THIS SCHEMA.
    // If you need to make a *breaking change*, create a new version of the schema (and a new version const) and use that in your application code.
    // Additive changes are allowed -- bump the version const above by a minor (new functionality) or patch (bug fixes) version number for tracking.
    subject: zod_1.z.string(),
    sender: zod_1.z.string().email(),
    attachmentNames: zod_1.z.array(zod_1.z.string()),
    recipients: zod_1.z.array(zod_1.z.string().email()),
});
