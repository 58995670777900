import { useState, useEffect } from 'react';
import { Switch } from 'ui-kit';
import { type UpdateExecutionPayload } from '../../Execution/hooks';

interface Props {
  id: string;
  checked: boolean;
  adminRunStatus?: 'error' | 'idle' | 'pending' | 'success';
  updateAdminRun?: (payload: UpdateExecutionPayload) => unknown;
}

export function ExecutionAdminRun({
  id,
  checked,
  adminRunStatus = 'idle',
  updateAdminRun,
}: Props) {
  const [hasRequestedUpdate, setHasRequestedUpdate] = useState(false);

  useEffect(() => {
    if (adminRunStatus === 'idle' && hasRequestedUpdate)
      setHasRequestedUpdate(false);
  }, [adminRunStatus, hasRequestedUpdate]);

  return (
    <Switch
      checked={checked}
      disabled={adminRunStatus === 'pending' && hasRequestedUpdate}
      onChange={(_, newChecked) => {
        setHasRequestedUpdate(true);
        updateAdminRun?.({
          id,
          adminRun: newChecked,
        });
      }}
    />
  );
}
