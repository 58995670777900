import React, { useRef, useEffect } from 'react';
import type {
  DatasourceMetadata,
  TemplateVariable,
  VariableMap,
  GlobalVariable,
  Variable,
  TemplateData,
} from 'types-shared';
import { SourceTypeEnum } from 'types-shared';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import { clsx } from 'clsx';

import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';
import { type VariableInputMenuTabsEnum } from '../VariableTypes/VariableInput/Input2.0/types';

interface Props {
  label?: string;
  placeholder?: string;
  variable?: TemplateVariable;
  variablesMap: VariableMap;
  onChange?: (variable: TemplateVariable) => void;
  datasourceMetadata: DatasourceMetadata | null;
  sourceType?: SourceTypeEnum;
  disabled?: boolean;
  className?: string;
  isHalf?: boolean;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  multiline?: boolean;
  containerClassName?: string;
  enableAddingVariables?: boolean;
  allowedMenuTabs?: VariableInputMenuTabsEnum[];
  isHighlighted?: boolean;
}

export function RequestVariableInput({
  label,
  variable,
  variablesMap,
  onChange,
  globalVariablesMap,
  datasourceMetadata,
  sourceType,
  disabled,
  className,
  isHalf,
  placeholder,
  multiline = true,
  containerClassName,
  enableAddingVariables,
  allowedMenuTabs,
  isHighlighted,
}: Props) {
  const inputRef = useRef<VariableInputRef>();
  const prevInputData = useRef<TemplateData>([]);

  const hasDatasource = Boolean(datasourceMetadata);

  useEffect(() => {
    if (variable?.data.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = variable.data;
      inputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variable, prevInputData.current]);

  return variable ? (
    <VariableInput
      ref={inputRef}
      willRerender
      allowAddVariable={
        hasDatasource ||
        sourceType === SourceTypeEnum.API ||
        sourceType === SourceTypeEnum.EmailTrigger ||
        enableAddingVariables
      }
      isHighlighted={isHighlighted}
      containerClassName={clsx(containerClassName, isHalf && '!w-[45%]')}
      className={className}
      value={variable.data}
      disabled={disabled}
      label={label}
      onClickAddNew={(indexForVariableInsert) => {
        openAddVariableModal({
          insertVariable(newVariable: Variable) {
            inputRef.current?.addVariable(newVariable, indexForVariableInsert);
          },
        });
      }}
      onChange={(newData) => {
        onChange?.({
          ...variable,
          data: newData,
        });
      }}
      onClickVariableChip={(variableId) => {
        openPreviewVariableModal({
          variableId,
        });
      }}
      variablesMap={variablesMap}
      globalVariablesMap={globalVariablesMap}
      placeholder={placeholder}
      multiline={multiline}
      allowedMenuTabs={allowedMenuTabs}
    />
  ) : null;
}
