import { clsx } from 'clsx';
import { Checkbox, InfoOutlined } from 'ui-kit';

interface Props {
  isChecked: boolean;
  onCheck: () => void;
  errors: string[];
  showErrors: boolean;
  hidden?: boolean;
  noTopMargin?: boolean;
}
function NodeCheckV2({
  isChecked,
  onCheck,
  errors,
  showErrors,
  hidden,
  noTopMargin,
}: Props) {
  const isDisabled = errors.length > 0;

  return hidden ? null : (
    <div
      className={clsx('px-8 border-b border-gray-300', {
        'bg-[#f7f9ff]': isChecked,
        'mt-0': noTopMargin,
        'mt-8': !noTopMargin,
      })}
    >
      <div
        className="flex items-center cursor-pointer"
        style={{ height: '72px', minHeight: '72px' }}
        onClick={onCheck}
        role="presentation"
      >
        <Checkbox
          onChange={onCheck}
          checked={isChecked}
          className="!border-secondary"
          sx={{
            color: !isChecked && isDisabled ? '#666666' : '#3274fb',
            '&.Mui-checked': {
              color: '#3274fb',
            },
          }}
        />
        <span
          className={clsx({
            'text-sm': true,
            'text-gray-500': !isChecked && isDisabled,
          })}
        >
          Step reviewed and ready to run
        </span>
      </div>
      {showErrors && errors.length > 0 ? (
        <div className="pb-5">
          {errors.map((error) => (
            <div
              key={error}
              className="bg-primary-blue-extralight flex items-center space-x-2 px-4 py-3 rounded text-sm mb-3"
            >
              <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
              <span className="text-primary-blue-dark">{error}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default NodeCheckV2;
