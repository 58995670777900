"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasuraShim = void 0;
const client_1 = require("@apollo/client");
const retry_1 = require("@apollo/client/link/retry");
const dev_1 = require("@apollo/client/dev");
const graphql_1 = require("graphql");
const option_utils_1 = require("option-utils");
const types_shared_1 = require("types-shared");
const graphql_2 = require("./generated/graphql");
const handle_error_1 = require("./handle-error");
const types_1 = require("./types");
if (process.env.NODE_ENV !== 'production' ||
    process.env.DEPLOYED_STAGE === 'development') {
    (0, dev_1.loadDevMessages)();
    (0, dev_1.loadErrorMessages)();
}
class HasuraShim {
    constructor({ userId, jwt, endpoint, retryOptions, }) {
        const hasuraEndpoint = endpoint !== null && endpoint !== void 0 ? endpoint : process.env.HASURA_GRAPHQL_ENDPOINT;
        if (!hasuraEndpoint) {
            throw new Error('HASURA_GRAPHQL_ENDPOINT is not set');
        }
        const graphQLEndpoint = `${hasuraEndpoint}/v1/graphql`;
        const headers = {};
        if (userId && jwt) {
            throw new Error('Should not use JWT and userId together. JWT takes precedence.');
        }
        // If a JWT is provided, this must be a User Hasura client
        if (jwt) {
            headers.Authorization = `Bearer ${jwt}`;
            headers['x-hasura-role'] = 'user';
        }
        else if (userId) {
            // We are authenticating using the admin secret...
            headers['x-hasura-admin-secret'] = String(process.env.HASURA_GRAPHQL_ADMIN_SECRET);
            // ...so we can also access backend-only operations...
            headers['x-hasura-use-backend-only-permissions'] = 'true';
            // ...but we are authorized as the user
            headers['x-hasura-user-id'] = userId;
            headers['x-hasura-role'] = 'user';
        }
        else {
            // We are authenticating and authorized as admin...
            headers['x-hasura-admin-secret'] = String(process.env.HASURA_GRAPHQL_ADMIN_SECRET);
            // ...so we can also access backend-only operations
            headers['x-hasura-use-backend-only-permissions'] = 'true';
        }
        // Set up retry link
        // Default retry options are at: https://www.apollographql.com/docs/react/api/link/apollo-link-retry#default-configuration
        const retryLink = new retry_1.RetryLink(retryOptions);
        const httpLink = new client_1.HttpLink({
            uri: graphQLEndpoint,
            headers,
        });
        // MARK: Creating Apollo Client instance
        this.client = new client_1.ApolloClient({
            link: (0, client_1.from)([retryLink, httpLink]),
            // FIXME: Do not give apollo a cache or fix caching errors
            // ex: https://www.apollographql.com/docs/react/errors#%7B%22version%22%3A%223.11.2%22%2C%22message%22%3A12%2C%22args%22%3A%5B%22workflowId%22%2C%22%7B%7D%22%5D%7D
            cache: new client_1.InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                mutate: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    }
    static asAdmin(endpoint, retryOptions) {
        return new HasuraShim({ endpoint, retryOptions });
    }
    static asUser(jwt, retryOptions) {
        return new HasuraShim({ jwt, retryOptions });
    }
    /**
     * Use with caution. Prefer JWT authentication when possible.
     */
    static asUserWithoutJwt(userId, retryOptions) {
        return new HasuraShim({ userId, retryOptions });
    }
    static serverlessClient({ jwt, isAdmin, userId, retryOptions, }) {
        // This function assumes that the actual authentication is done by the serverless middleware.
        // If the userId is provided, we are authenticating as the user, without a JWT
        // This is used for user-initiated API Key calls
        if (userId) {
            return HasuraShim.asUserWithoutJwt(userId, retryOptions);
        }
        else if (jwt) {
            // Otherwise, we are authenticating as the user, via their JWT, likely via a website request
            return HasuraShim.asUser(jwt, retryOptions);
        }
        throw new Error(`Either userId or jwt must be provided. Instead got: isAdmin: ${String(isAdmin)}, userId: ${String(userId)}, jwt: ${String(jwt)}`);
    }
    checkedQuery(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query(options);
            return (0, handle_error_1.checkApolloQueryResult)(result, 'checkedQuery');
        });
    }
    checkedMutation(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.mutate(options);
            return (0, handle_error_1.checkApolloMutationResult)(result, 'checkedMutation');
        });
    }
    listWorkflowIDs() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({ query: graphql_2.ListAllWorkflowsDocument });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'listWorkflowIDs');
            return data.workflows.map((workflow) => workflow.id);
        });
    }
    getAllowedWorkflowIDs(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetAllowedWorkflowIDsDocument,
                variables: { ids },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getAllowedWorkflowIDs');
            return data.workflows.map((workflow) => workflow.id);
        });
    }
    getWorkflowName(workflowId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.query({
                query: graphql_2.GetWorkflowNameDocument,
                variables: { id: workflowId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getWorkflowName');
            if (!((_a = data.workflows_by_pk) === null || _a === void 0 ? void 0 : _a.name)) {
                throw new Error(`Failed to get workflow: No name returned: ${JSON.stringify(data)}`);
            }
            return data.workflows_by_pk.name;
        });
    }
    createWorkflow(_a) {
        return __awaiter(this, arguments, void 0, function* ({ id, ownerId, name, currentVersionId, }) {
            var _b;
            const result = yield this.client.mutate({
                mutation: graphql_2.CreateWorkflowDocument,
                variables: {
                    id,
                    ownerId,
                    name,
                    currentVersionId: currentVersionId !== null && currentVersionId !== void 0 ? currentVersionId : null,
                },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'createWorkflow');
            const createdWorkflowID = (_b = data.insert_workflows) === null || _b === void 0 ? void 0 : _b.returning[0].id;
            if (!createdWorkflowID) {
                throw new Error(`Failed to create workflow: No ID returned: ${JSON.stringify(data)}`);
            }
            return createdWorkflowID;
        });
    }
    /**
     * NOTE: This is a soft delete that sets `deletedAt` to the provided value
     */
    deleteWorkflow(id, deletedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.mutate({
                mutation: graphql_2.MarkWorkflowAsDeletedDocument,
                variables: { id, deletedAt: deletedAt.toISOString() },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'deleteWorkflow');
            const deletedWorkflowID = (_a = data.update_workflows_by_pk) === null || _a === void 0 ? void 0 : _a.id;
            if (!deletedWorkflowID) {
                throw new Error(`Failed to delete workflow: No ID returned: ${JSON.stringify(data)}`);
            }
            return deletedWorkflowID;
        });
    }
    updateWorkflow(_a) {
        return __awaiter(this, arguments, void 0, function* ({ workflowId, currentVersionId, name, }) {
            var _b;
            if (!currentVersionId && !name) {
                return workflowId;
            }
            const variables = {
                id: workflowId,
                set: {
                    currentVersionId,
                    name,
                },
            };
            const result = yield this.client.mutate({
                mutation: graphql_2.UpdateWorkflowDocument,
                variables,
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateWorkflow');
            const updatedWorkflowID = (_b = data.update_workflows_by_pk) === null || _b === void 0 ? void 0 : _b.id;
            if (!updatedWorkflowID) {
                throw new Error(`Workflow not found: ${JSON.stringify(data)}`);
            }
            return updatedWorkflowID;
        });
    }
    getUserEmails(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUsersDocument,
                variables: { ids },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmails');
            const emails = {};
            data.users.forEach((user) => {
                if (user.id && user.email) {
                    emails[user.id] = user.email;
                }
            });
            return emails;
        });
    }
    getUserEmail(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUserDocument,
                variables: { id },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmail');
            const user = data.users.at(0);
            if (!user) {
                throw new Error(`No user found for ${id}`);
            }
            return user.email;
        });
    }
    attemptToGetOwnerId(workflowId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.query({
                query: graphql_2.GetOwnerDocument,
                variables: { workflowId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'attemptToGetOwnerId');
            return (_a = data.workflows_by_pk) === null || _a === void 0 ? void 0 : _a.ownerId;
        });
    }
    getAssumableRoleForAction(userId, action, workflowId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            // Get the teams that the workflow owner is a member of
            const ownerTeamsResult = yield this.client.query({
                query: graphql_2.GetOwnerTeamsDocument,
                variables: { workflowId },
            });
            const ownerTeamsData = (0, handle_error_1.checkApolloQueryResult)(ownerTeamsResult, 'getAssumableRoleForAction');
            const teamIds = (_a = ownerTeamsData.workflows_by_pk) === null || _a === void 0 ? void 0 : _a.owner.memberships.map((membership) => membership.teamId);
            if (!teamIds) {
                throw new Error(`Failed to get team IDs: ${JSON.stringify(ownerTeamsData)}`);
            }
            // Get roles which allow this action on Workflows for each team
            const teamRolesPromises = teamIds.map((teamId) => this.client.query({
                query: graphql_2.GetTeamMemberRolesDocument,
                variables: {
                    teamId,
                    userId,
                    resourceType: graphql_2.Access_Control_Enum_Resource_Enum.Workflow,
                    actionType: action,
                },
            }));
            const teamRolesResponses = yield Promise.all(teamRolesPromises);
            teamRolesResponses.forEach((response) => {
                if (response.errors) {
                    throw new Error(`Failed to get team member roles: ${response.errors.map((e) => e.message).join(', ')}`);
                }
            });
            const assumableRoles = teamRolesResponses.flatMap((teamRoles) => teamRoles.data.organizations_team_member_roles.map((memberRole) => ({
                roleIdentifier: memberRole.role.identifier,
                teamId: memberRole.membership.teamId,
            })));
            if (assumableRoles.length === 0) {
                throw new Error(`No assumable roles found for teams: ${teamIds.join(', ')}`);
            }
            return types_1.MemberRole.parse(assumableRoles[0]);
        });
    }
    getUserClusterData(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUserClusterDataDocument,
                variables: { userId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserClusterData');
            const user = data.users.at(0);
            if (!user) {
                throw new Error(`No custer data found for user: ${userId}`);
            }
            // NOTE: Assumes that the user is a member of exactly one team, which is the case for now
            const memberships = user.memberships;
            const clustersData = memberships
                .map((membership) => { var _a; return (_a = membership.team.clusterConfiguration) === null || _a === void 0 ? void 0 : _a.cluster; })
                .filter(Boolean);
            if (!clustersData[0]) {
                return null;
            }
            return types_1.ClusterData.parse(clustersData[0]);
        });
    }
    getTeamClusterData(teamId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const result = yield this.client.query({
                query: graphql_2.GetTeamClusterDataDocument,
                variables: { teamId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getTeamClusterData');
            const clusterData = (_b = (_a = data.organizations_teams_by_pk) === null || _a === void 0 ? void 0 : _a.clusterConfiguration) === null || _b === void 0 ? void 0 : _b.cluster;
            if (!clusterData) {
                return null;
            }
            return types_1.ClusterData.parse(clusterData);
        });
    }
    getClusterInfo(clusterId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetClusterInfoDocument,
                variables: { clusterId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getClusterInfo');
            const clusterData = data.clusters_by_pk;
            if (!clusterData) {
                throw new Error(`No cluster data found for cluster ID ${clusterId}`);
            }
            return types_1.ClusterData.parse(clusterData);
        });
    }
    getAllowedExecutionIDs(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetAllowedExecutionIDsDocument,
                variables: { ids },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getAllowedExecutionIDs');
            return data.executions.map((execution) => execution.id);
        });
    }
    createExecution(execution) {
        return __awaiter(this, void 0, void 0, function* () {
            const hasuraExecution = Object.assign(Object.assign({}, execution), { status: this._toHasuraExecutionStatus(execution.status) });
            const result = yield this.client.mutate({
                mutation: graphql_2.CreateExecutionDocument,
                variables: { execution: hasuraExecution },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'createExecution');
            const createdExecution = data.insert_executions_one;
            if (!createdExecution) {
                throw new Error(`Failed to create execution: No execution returned: ${JSON.stringify(data)}`);
            }
            return createdExecution;
        });
    }
    getUserTeamIds(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUserTeamsDocument,
                variables: { userId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamIds');
            return data.organizations_team_memberships.map((membership) => membership.team.id);
        });
    }
    getUserTeamMaxConcurrency(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUserTeamsDocument,
                variables: { userId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamMaxConcurrency');
            const maxConcurrentExecutionsArray = data.organizations_team_memberships.map((membership) => {
                const maxConcurrent = membership.team.maxConcurrentExecutions;
                // NOTE: Undefined maxConcurrentExecutions means that this function doesn't have access to the team's maxConcurrentExecutions
                if (maxConcurrent === undefined) {
                    throw new Error('maxConcurrentExecutions is undefined');
                }
                return maxConcurrent;
            });
            // Null maxConcurrentExecutions (which is the default value) means that the team has no limit
            if (maxConcurrentExecutionsArray.includes(null)) {
                return null;
            }
            // Otherwise, return the maximum limit across all teams the user is a member of
            // TODO: patch up single user, multi-team cases
            return Math.max(...maxConcurrentExecutionsArray);
        });
    }
    checkGlobalVariableExists(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetGlobalVariableNameDocument,
                variables: { id },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'checkGlobalVariableExists');
            return Boolean(data.global_variables_by_pk);
        });
    }
    getUserTeamExecutionsCount(userId, 
    // eslint-disable-next-line camelcase -- We're using the generated type here
    filter) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const result = yield this.client.query({
                query: graphql_2.GetUserTeamExecutionCountDocument,
                variables: {
                    userId,
                    filter,
                },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamExecutionsCount');
            return (_b = (_a = data.executions_aggregate.aggregate) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0;
        });
    }
    updateExecutionStatus(id, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.mutate({
                mutation: graphql_2.UpdateExecutionStatusDocument,
                variables: {
                    id,
                    status: this._toHasuraExecutionStatus(status),
                },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateExecutionStatus');
            const updatedExecution = data.update_executions_by_pk;
            if (!updatedExecution) {
                throw new Error(`Failed to update execution: No execution returned: ${JSON.stringify(data)}`);
            }
            return updatedExecution.status;
        });
    }
    updateExecutionAdmin(id, updates) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.mutate({
                mutation: graphql_2.UpdateExecutionDocument,
                variables: { id, updates },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateExecution');
            const updatedExecution = data.update_executions_by_pk;
            if (!updatedExecution) {
                throw new Error(`Failed to update execution: No execution returned: ${JSON.stringify(data)}`);
            }
            return Object.assign(Object.assign({}, updatedExecution), { status: types_shared_1.ExecutionStatus.parse(updatedExecution.status), trigger: types_shared_1.ExecutionTrigger.parse(updatedExecution.trigger), proxyMode: (_a = updatedExecution.proxyMode) !== null && _a !== void 0 ? _a : null, createdAt: new Date(updatedExecution.createdAt), updatedAt: new Date(updatedExecution.updatedAt), startedAt: updatedExecution.startedAt
                    ? new Date(updatedExecution.startedAt)
                    : null });
        });
    }
    getUserEmailAndChannelId(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.query({
                query: graphql_2.GetUserEmailAndChannelIdDocument,
                variables: { userId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmailAndChannelId');
            return {
                userEmail: data.users[0].email,
                teamChannelId: (_a = data.users[0].memberships[0].team.slackChannelId) !== null && _a !== void 0 ? _a : null,
            };
        });
    }
    getUserEmailAndTeamConfiguration(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.query({
                query: graphql_2.GetUserEmailAndTeamConfigurationDocument,
                variables: { userId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmailAndTeamConfiguration');
            const firstUser = data.users[0];
            const firstMembershipTeam = firstUser.memberships[0].team;
            return {
                userEmail: firstUser.email,
                teamChannelId: (_a = firstMembershipTeam.slackChannelId) !== null && _a !== void 0 ? _a : null,
                notificationPreset: firstMembershipTeam.workflowNotificationPreset,
            };
        });
    }
    getUsersClusterConfigurations(userIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetUsersClusterConfigurationsDocument,
                variables: { userIds },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUsersClusterConfigurations');
            return data.users.reduce((acc, user) => {
                var _a;
                // NOTE: Assumes that the user is a member of exactly one team
                const cluster = (_a = user.memberships[0].team.clusterConfiguration) === null || _a === void 0 ? void 0 : _a.cluster;
                if (cluster) {
                    acc[user.id] = types_1.ClusterData.omit({
                        datahubAddress: true,
                        seleniumAddress: true,
                    }).parse(cluster);
                }
                return acc;
            }, {});
        });
    }
    listAllExecutionsFiltered(limit_1) {
        return __awaiter(this, arguments, void 0, function* (limit, options = {}) {
            const { fromTime, toTime, nextExecutionCursor, workflowId, statuses } = options;
            // Build the where conditions
            // eslint-disable-next-line camelcase -- We're using the generated type here
            const whereConditions = [];
            if (fromTime) {
                whereConditions.push({ createdAt: { _gte: fromTime } });
            }
            if (toTime) {
                whereConditions.push({ createdAt: { _lte: toTime } });
            }
            // Only add cursor conditions if we have a cursor
            if (nextExecutionCursor) {
                whereConditions.push({
                    _or: [
                        { createdAt: { _lt: nextExecutionCursor.createdAt } },
                        {
                            _and: [
                                { createdAt: { _eq: nextExecutionCursor.createdAt } },
                                { id: { _lt: nextExecutionCursor.id } },
                            ],
                        },
                    ],
                });
            }
            if (workflowId) {
                whereConditions.push({ workflowId: { _eq: workflowId } });
            }
            if (statuses) {
                whereConditions.push({
                    status: {
                        _in: statuses.map((status) => this._toHasuraExecutionStatus(status)),
                    },
                });
            }
            else {
                whereConditions.push({
                    status: {
                        _in: [
                            graphql_2.Execution_Status_Enum.Queued,
                            graphql_2.Execution_Status_Enum.Running,
                            graphql_2.Execution_Status_Enum.Failed,
                            graphql_2.Execution_Status_Enum.Success,
                        ],
                    },
                });
            }
            const variables = {
                limit,
                whereConditions,
            };
            const result = yield this.client.query({
                query: graphql_2.ListAllExecutionsFilteredDocument,
                variables,
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'listAllExecutionsFiltered');
            return data.executions.map((execution) => (Object.assign(Object.assign({}, execution), { status: types_shared_1.ExecutionStatus.parse(execution.status) })));
        });
    }
    createRecording(id) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.mutate({
                mutation: graphql_2.CreateRecordingDocument,
                variables: {
                    id,
                },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'createRecording');
            const createdRecordingId = (_a = data.insert_recordings_one) === null || _a === void 0 ? void 0 : _a.id;
            if (!createdRecordingId) {
                throw new Error(`Failed to create recording: No ID returned: ${JSON.stringify(data)}`);
            }
            return createdRecordingId;
        });
    }
    getRecordingById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const result = yield this.client.query({
                query: graphql_2.GetRecordingByIdDocument,
                variables: { id },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getRecordingById');
            return (_a = data.recordings_by_pk) !== null && _a !== void 0 ? _a : undefined;
        });
    }
    getActiveWorkflowConfigurations(workflowId, event) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetActiveWorkflowConfigurationsDocument,
                variables: { workflowId, event },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getActiveWorkflowConfigurations');
            return data.notifications_workflow_configurations.map((config) => {
                const parsedMetadata = types_shared_1.PlatformMetadataSchema.parse(config.platformMetadata);
                return types_1.WorkflowNotificationConfiguration.parse(Object.assign(Object.assign({}, config), { platformMetadata: parsedMetadata }));
            });
        });
    }
    /**
     * WARN: This method will only work using the admin shim
     */
    getAllActiveWorkflowConfigurationsAdmin(workflowId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetActiveWorkflowNotificationsAdminDocument,
                variables: { workflowId },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'GetActiveWorkflowNotificationsAdmin');
            return data.notifications_workflow_configurations.map((config) => {
                const parsedMetadata = types_shared_1.PlatformMetadataSchema.parse(config.platformMetadata);
                return types_1.WorkflowNotificationConfigurationAdmin.parse(Object.assign(Object.assign({}, config), { platformMetadata: parsedMetadata }));
            });
        });
    }
    getAllInternalConfigurationsAdmin(eventSpecifier) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.checkedQuery({
                query: graphql_2.GetActiveInternalNotificationsAdminDocument,
            });
            const filteredConfigs = data.notifications_internal_configurations
                .map((config) => {
                const parsedConfig = types_1.InternalNotificationConfiguration.parse(config);
                const { eventTypes: allowedEventTypes, workflowIds: allowedWorkflowIds, } = parsedConfig.eventFilter;
                // If allowedEventTypes is set on the filter:
                // - if the filter doesn't include this event type, skip this config
                if (allowedEventTypes) {
                    if (!allowedEventTypes.includes(eventSpecifier.eventType)) {
                        return null;
                    }
                }
                // If allowedWorkflowIds is set on the filter:
                // - if this event doesn't have a workflow ID, skip this config
                // - if the filter doesn't include this workflow ID, skip this config
                if (allowedWorkflowIds) {
                    if (!eventSpecifier.workflowId) {
                        return null;
                    }
                    if (!allowedWorkflowIds.includes(eventSpecifier.workflowId)) {
                        return null;
                    }
                }
                return parsedConfig;
            })
                .filter(option_utils_1.isNotNull);
            return filteredConfigs;
        });
    }
    /**
     * WARN: This method will only work using the admin shim
     */
    createWorkflowNotificationConfigurationsAdmin(workflowId, configs) {
        return __awaiter(this, void 0, void 0, function* () {
            const configsToCreate = configs.map((config) => {
                // Only set the fields we want to include based on the GraphQL mutation type
                return {
                    workflowId,
                    ownerId: config.ownerId,
                    platform: config.platform,
                    platformMetadata: config.platformMetadata,
                    tag: config.tag,
                    // NOTE: We need to re-wrap the events array due to how the graphql mutation is structured
                    events: {
                        data: config.events,
                    },
                    deletedAt: null,
                };
            });
            const response = yield this.executeMutation(graphql_2.BatchCreateNotificationConfigurationsAdminDocument, {
                configs: configsToCreate,
            });
            if (!response.insert_notifications_workflow_configurations) {
                throw new Error(`Failed to create notification configs for workflow: ${workflowId}`);
            }
            const createdCount = response.insert_notifications_workflow_configurations.returning.length;
            const expectedCount = configs.length;
            if (createdCount !== expectedCount) {
                throw new Error(`Failed to create all notification configs for workflow: ${workflowId}. Expected ${expectedCount.toString()}, created ${createdCount.toString()}.`);
            }
            return response.insert_notifications_workflow_configurations.returning.map((config) => config.id);
        });
    }
    getActiveEmailTriggers(teamId, emailEvent, receiverEmailAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query: graphql_2.GetActiveEmailTriggersForTeamAndEmailEventAndReceiverDocument,
                variables: { teamId, emailEvent, receiverEmailAddress },
            });
            const data = (0, handle_error_1.checkApolloQueryResult)(result, 'getActiveEmailTriggersForTeamAndEmailEventAndReceiver');
            return data.triggers_email.map((trigger) => {
                return Object.assign(Object.assign({}, trigger), { createdAt: new Date(trigger.createdAt), updatedAt: new Date(trigger.updatedAt), deletedAt: trigger.deletedAt ? new Date(trigger.deletedAt) : null });
            });
        });
    }
    _toHasuraExecutionStatus(status) {
        if (Object.values(graphql_2.Execution_Status_Enum).includes(status)) {
            return status;
        }
        throw new Error(`Invalid execution status: ${status}`);
    }
    executeQuery(query, variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.query({
                query,
                variables,
            });
            const queryNameDebug = this._getOperationName(query);
            return (0, handle_error_1.checkApolloQueryResult)(result, queryNameDebug);
        });
    }
    executeMutation(mutation, variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.client.mutate({ mutation, variables });
            const mutationNameDebug = this._getOperationName(mutation);
            return (0, handle_error_1.checkApolloMutationResult)(result, mutationNameDebug);
        });
    }
    _getOperationName(document) {
        var _a, _b;
        const operationNameFromAST = (_b = (_a = document.definitions.find((def) => def.kind === graphql_1.Kind.OPERATION_DEFINITION)) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.value;
        return operationNameFromAST !== null && operationNameFromAST !== void 0 ? operationNameFromAST : document.constructor.name;
    }
    setExecutionStartedAt(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const startedAt = new Date().toISOString();
            const result = yield this.client.mutate({
                // TODO: Just use UpdateExecutionDocument
                mutation: graphql_2.UpdateExecutionStartedAtDocument,
                variables: { id, startedAt },
            });
            const data = (0, handle_error_1.checkApolloMutationResult)(result, 'setExecutionStartedAt');
            const updatedExecution = data.update_executions_by_pk;
            if (!updatedExecution) {
                throw new Error(`Failed to update execution startedAt: No execution returned: ${JSON.stringify(data)}`);
            }
            if (!updatedExecution.startedAt) {
                throw new Error(`Failed to update execution startedAt: No startedAt returned: ${JSON.stringify(data)}`);
            }
            return {
                id: updatedExecution.id,
                startedAt: updatedExecution.startedAt,
            };
        });
    }
}
exports.HasuraShim = HasuraShim;
