import {
  VariableInput,
  type VariableInputRef,
} from './VariableTypes/VariableInput';
import { clsx } from 'clsx';
import {
  CheckCircleIcon,
  IconButton,
  Input,
  Spinner,
  Switch,
  AutoFixInactiveIcon,
  InfoOutlined,
  SendIcon,
  Tooltip,
} from 'ui-kit';
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  SourceTypeEnum,
  type GlobalVariable,
  type TemplateData,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { TRANSFORM_INPUT_CHAR_LIMIT } from '../../../utils/constants';
import { type TransformationFormValues } from '../../../utils/variableModal';
import omit from 'lodash/omit';
import { isAdmin } from '../../../utils/env';
import { ModelSelect } from '../../../components/ModelSelect';
import { type LlmTransformResponse } from 'api-types-shared';
import isNil from 'lodash/isNil';

interface Props {
  enabled: boolean;
  isAddingAVariable?: boolean;
  setEnabled: (newEnabled: boolean) => void;

  formValues: TransformationFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<TransformationFormValues>>;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    prompt: TemplateData,
    model?: string,
  ) => Promise<LlmTransformResponse | null | undefined>;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  hidden?: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  variableId?: string;
}

export interface TransformationsRef {
  extractPrompt: () => {
    prompt: TemplateData;
    transformConfig?: { model?: string };
  };
}

export const Transformations = forwardRef<TransformationsRef, Props>(
  (
    {
      isAddingAVariable = false,
      enabled,
      setEnabled,
      formValues,
      setFormValues,
      transformApiReqStatus,
      onTransformApiReq,
      variablesMap,
      globalVariablesMap,
      hidden,
      setIsDirty,
      isDirty,
      variableId,
    },
    ref,
  ) => {
    const isLoading =
      transformApiReqStatus === 'pending' ||
      transformApiReqStatus === 'loading';
    const isSuccess = transformApiReqStatus === 'success';
    const isError = transformApiReqStatus === 'error';
    const [localDirty, setLocalDirty] = useState(false);
    const [reasoning, setReasoning] = useState<string | null>(null);
    const inputVariableInputRef = useRef<VariableInputRef>();
    const prevFormValues = useRef<TemplateData>([]);
    const [renderCount, setRenderCount] = useState(0);

    useImperativeHandle(ref, () => ({
      extractPrompt: () => {
        const transformConfig = { model: formValues.model };
        return {
          prompt: prevFormValues.current,
          transformConfig,
        };
      },
    }));

    const isTransformInputExceeded =
      !isAdmin &&
      (formValues.initialValue?.length ?? 0) > TRANSFORM_INPUT_CHAR_LIMIT;

    const noPlaceholder = useMemo(() => {
      return Boolean(
        prevFormValues.current.length || formValues.transformQuery?.length,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.transformQuery, prevFormValues.current]);

    const transformApiReq = useCallback(async () => {
      setLocalDirty(false);
      if (prevFormValues.current.length === 0) {
        return;
      }

      const result = await onTransformApiReq(
        prevFormValues.current,
        formValues.model,
      );
      if (result) {
        const { processedData, reasoning: newReasoning } = result;
        setReasoning(newReasoning);
        setFormValues((prevValues) => ({
          ...prevValues,
          previewTransform: processedData,
          transformQuery: prevFormValues.current,
        }));
      }
    }, [onTransformApiReq, setFormValues, formValues.model]);

    const iconButtonContent = useMemo(() => {
      if (isLoading) {
        return <Spinner size={16} />;
      }

      if (isSuccess && !isError && !localDirty) {
        return <CheckCircleIcon className="text-transparent" />;
      }

      if (isError && !localDirty) {
        return <InfoOutlined className="text-error" />;
      }

      if ((!isSuccess && !isError) || localDirty) {
        return (
          <SendIcon
            className={clsx('text-white', {
              'opacity-60': isTransformInputExceeded,
            })}
          />
        );
      }
    }, [isTransformInputExceeded, isLoading, isSuccess, isError, localDirty]);

    useEffect(() => {
      if (
        formValues.transformQuery?.length &&
        !prevFormValues.current.length &&
        renderCount <= 2
      ) {
        // Force a re-render if the content has changed, but not yet reflected
        setRenderCount(renderCount + 1); //FIXME:  Nasty hack. Find and fix whatever is causing this to not render
        prevFormValues.current = formValues.transformQuery;
        inputVariableInputRef.current?.reRender(formValues.transformQuery);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.transformQuery, prevFormValues.current]);

    return hidden ? null : (
      <div
        className={clsx({
          'rounded-[10px] mb-9 mt-5 p-[2px]': true,
          '!bg-gradient-to-r from-primary-blue to-primary-purple':
            enabled || isAddingAVariable,
          '!bg-gradient-to-r from-gray-300 to-gray-500':
            !enabled && !isAddingAVariable,
        })}
      >
        <div
          className="rounded-[8px] p-7 text-sm flex flex-col bg-white overflow-y-hidden"
          // key={inputRerenderKey}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              {isAdmin ? (
                <Tooltip
                  title={reasoning || 'No reasoning available'}
                  placement="right"
                  arrow
                >
                  <AutoFixInactiveIcon
                    fontSize="small"
                    className={clsx({
                      'text-info-dark': enabled || isAddingAVariable,
                      'text-gray-400': !enabled && !isAddingAVariable,
                      'cursor-help': Boolean(reasoning),
                    })}
                  />
                </Tooltip>
              ) : (
                <AutoFixInactiveIcon
                  fontSize="small"
                  className={clsx({
                    'text-info-dark': enabled || isAddingAVariable,
                    'text-gray-400': !enabled && !isAddingAVariable,
                  })}
                />
              )}
              <span
                className={clsx({
                  'font-medium mb-1': true,
                  'text-info-dark': enabled || isAddingAVariable,
                  'text-gray-400': !enabled && !isAddingAVariable,
                })}
              >
                Apply GPT Transformations
              </span>
            </div>

            <Switch
              color="secondary"
              checked={enabled}
              // disabled={!enabled}
              onChange={(e, newEnabled) => {
                setIsDirty(true);
                setEnabled(newEnabled);
              }}
            />
          </div>

          <div
            className={clsx({
              'mt-5 text-sm': true,
              'text-slate-500': enabled || isAddingAVariable,
              'text-gray-400': !enabled && !isAddingAVariable,
            })}
          >
            Automatically convert the variable’s data with a natural language
            prompt.
          </div>

          <div
            className={clsx(
              'transition-all duration-500',
              enabled && 'opacity-1',
              !enabled && 'max-h-0 opacity-0',
            )}
          >
            <div className="mb-5 text-slate-500 text-sm">
              Use a value example to test and preview the transformation that
              will be applied.
            </div>

            <div className="relative flex flex-col mt-3 pt-1">
              <Input
                classes={{ wrapper: 'flex flex-col truncate mb-8 text-sm' }}
                floatingLabel
                multiline
                label="Variable value example"
                onChange={(newInitialValue: string) => {
                  if (!isDirty) {
                    setIsDirty(true);
                  }
                  if (!localDirty) {
                    setLocalDirty(true);
                  }

                  setFormValues((prevValues) => ({
                    ...prevValues,
                    initialValue: newInitialValue,
                  }));
                }}
                placeholder="Enter in sample value"
                value={formValues.initialValue}
                error={isTransformInputExceeded}
                showErrorText
                errorText={`You have exceeded the character limit of ${TRANSFORM_INPUT_CHAR_LIMIT.toString()}. Please crop your text to test the transformation.`}
              />

              {isAdmin ? (
                <ModelSelect
                  value={formValues.model || ''}
                  onChange={(newModel) => {
                    setIsDirty(true);
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      model: newModel,
                    }));
                  }}
                />
              ) : null}

              <VariableInput
                ref={inputVariableInputRef}
                showPlusButton
                allowAddVariable={false}
                disabledAddVariableTooltip="Variables cannot be added on the transformation prompt. Create them on the input instead."
                label="Prompt"
                value={prevFormValues.current}
                onChange={(newTransformQuery) => {
                  if (!isDirty) {
                    setIsDirty(true);
                  }

                  if (!localDirty) {
                    setLocalDirty(true);
                  }

                  prevFormValues.current = newTransformQuery;
                }}
                className={clsx('min-h-40')}
                variablesMap={
                  variableId ? omit(variablesMap, [variableId]) : variablesMap
                }
                globalVariablesMap={globalVariablesMap}
                placeholder={noPlaceholder ? '' : 'Write the instructions'}
                onClickAddVariable={() => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    sourceId: SourceTypeEnum.Variable,
                  }));
                }}
              />
              <IconButton
                className="!absolute bottom-1 right-1"
                disabled={
                  localDirty
                    ? isTransformInputExceeded
                    : isSuccess || isError || isLoading
                }
                onClick={transformApiReq}
              >
                {iconButtonContent}
              </IconButton>
            </div>
            {isError ? (
              <p className="px-1 pt-1 text-error text-xs">
                Your transformation is still processing due to high system load.
                Please try again later as our system relies on external services
                that may be experiencing delays.
              </p>
            ) : null}

            <div className="font-medium mt-8 text-info-dark">
              Transformation result
            </div>
            <span className="text-color-grey text-sm">
              Sola's AI transformations are designed for accuracy, though
              reviewing results in key cases is always a good practice.
            </span>
            <div
              className={clsx('mt-4 p-[1px] !rounded bg-zinc-100', {
                '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':
                  formValues.previewTransform,
              })}
            >
              <div className="!rounded-sm p-3 min-h-[2.75rem] break-words bg-zinc-100 whitespace-pre-wrap font-mono">
                {!isNil(formValues.previewTransform) ? (
                  formValues.previewTransform
                ) : (
                  <span className="text-neutral-400">
                    Transformation Result
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Transformations.displayName = 'Transformations';
