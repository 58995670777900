"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listApiKeysSchema = void 0;
const types_shared_1 = require("types-shared");
exports.listApiKeysSchema = {
    request: types_shared_1.z.object({}),
    response: types_shared_1.z.object({
        apiKeys: types_shared_1.z.array(types_shared_1.ApiKey),
    }),
};
