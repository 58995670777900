import { useState } from 'react';
import { Button } from 'ui-kit';
import ConcurrencyLimitModal from '../../Workflows/components/ConcurrencyLimitModal';

interface Props {
  concurrencyLimit: number | null | undefined;
  workflowId: string;
}

export default function EditConcurrencyBtn({
  concurrencyLimit,
  workflowId,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex items-center space-x-10">
        <span>{concurrencyLimit ?? '-'}</span>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Edit Limit
        </Button>
      </div>
      {isOpen ? (
        <ConcurrencyLimitModal
          onCancel={() => {
            setIsOpen(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
    </>
  );
}
