import {
  ContentContainer,
  ScrollableContainer,
  CustomTypography,
  AccessTimeIcon,
  Button,
  RunningExecutionIcon,
  Select,
  Tooltip,
} from 'ui-kit';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import ReactECharts from 'echarts-for-react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FeatureFlag } from '../../utils/constants';
import { useFeatureFlag } from '../../utils/helper';
import { NavigationPanel } from '../../components/NavigationPanel';
import { ExecutionStatusEnum } from 'types-shared/executionTypes';
import { runningStatuses } from '../Workflows/utils/helper';
import MetricCard from '../../components/MetricCard';
import { useInsights } from './hooks';
import ListSkeleton from '../../components/ListSkeleton';
import { clsx } from 'clsx';
import Skeleton from '@mui/material/Skeleton';
import {
  createExecutionsListPageUrl,
  getDateRange,
  getExecutionHourlyChartOptions,
  getExecutionMonthsChartOptions,
  getExecutionsPieChartOptions,
  getPercentageIncrease,
  getWorkflowsMonthsChartOptions,
  membersTableColumns,
  titleClasses,
  type MemberWithWorkflowCount,
} from './helper';
import TopWorkflows from './components/TopWorkflows';
import ExecutionListDateFilter, {
  type DateFilterValue,
} from '../Workflows/components/ExecutionListDateFilter';

interface MemberFilterOption {
  label: string;
  value: string;
}

export default function Insights() {
  const navigate = useNavigate();
  const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>();
  const [memberId, setMemberId] = useState<string>('all');
  const showInsightsPage = useFeatureFlag(FeatureFlag.InsightsPage, false);

  const selectedMemberId: string | undefined = useMemo(() => {
    if (memberId === 'all') {
      // we don't want to apply ownerId filter when memberId is all
      return undefined;
    }
    return memberId;
  }, [memberId]);

  const dateValue = useMemo(() => {
    if (!dateFilterValue) {
      return undefined;
    }
    const range = getDateRange(dateFilterValue);
    return {
      ...dateFilterValue,
      dateRange: range,
    };
  }, [dateFilterValue]);

  const {
    data: {
      maxConcurrentExecutions,
      teamMembers,
      teamMembersWithWorkflowCountData,
      workflowsMonthData,
      executionsMonthData,
      totalExecutionsData,
      monthWiseExecutionsCountData,
      monthWiseWorkflowsCountData,
      executionHourlyMetricsData,
      workflowsWithExecutionsCount,
    },
    loading: {
      maxConcurrentExecutionsLoading,
      teamMembersLoading,
      teamMembersWithWorkflowCountLoading,
      workflowsMonthLoading,
      executionsMonthLoading,
      totalExecutionsLoading,
      monthWiseExecutionsCountLoading,
      monthWiseWorkflowsCountLoading,
      executionHourlyMetricsLoading,
      workflowsWithExecutionsCountLoading,
    },
    refetch,
  } = useInsights(selectedMemberId, dateValue);

  const queuedExecutionsUrl = useMemo(
    () => createExecutionsListPageUrl([ExecutionStatusEnum.Queued]),
    [],
  );
  const runningExecutionsUrl = useMemo(
    () =>
      createExecutionsListPageUrl(
        runningStatuses.filter(
          (status) => status !== ExecutionStatusEnum.Queued,
        ),
      ),
    [],
  );
  const executionHourlyChartOptions = useMemo(
    () => getExecutionHourlyChartOptions(executionHourlyMetricsData),
    [executionHourlyMetricsData],
  );
  const currentMonthExecutionsIncrease = useMemo(
    () =>
      getPercentageIncrease(
        executionsMonthData.currentMonthExecutions,
        executionsMonthData.lastMonthExecutions,
      ),
    [executionsMonthData],
  );
  const currentMonthWorkflowsIncrease = useMemo(
    () =>
      getPercentageIncrease(
        workflowsMonthData.currentMonthWorkflows,
        workflowsMonthData.lastMonthWorkflows,
      ),
    [workflowsMonthData],
  );
  const teamMembersWithWorkflowCount = useMemo(() => {
    return teamMembersWithWorkflowCountData?.users ?? [];
  }, [teamMembersWithWorkflowCountData]);
  const memberFilterOptions: MemberFilterOption[] = useMemo(() => {
    return [
      {
        label: 'All',
        value: 'all',
      },
      ...teamMembers.map((member) => ({
        label: member.email,
        value: member.id,
      })),
    ];
  }, [teamMembers]);
  const pieChartOptions = useMemo(
    () => getExecutionsPieChartOptions(totalExecutionsData),
    [totalExecutionsData],
  );
  const executionsMonthChartOptions = useMemo(
    () => getExecutionMonthsChartOptions(monthWiseExecutionsCountData),
    [monthWiseExecutionsCountData],
  );
  const workflowsMonthChartOptions = useMemo(
    () => getWorkflowsMonthsChartOptions(monthWiseWorkflowsCountData),
    [monthWiseWorkflowsCountData],
  );

  const selectedMemberOption = useMemo(() => {
    if (memberId === 'all') {
      return memberFilterOptions[0];
    }
    return memberFilterOptions.find((option) => option.value === memberId);
  }, [memberFilterOptions, memberId]);

  const hasFiltersApplied = useMemo(() => {
    return dateFilterValue || memberId !== 'all';
  }, [dateFilterValue, memberId]);

  useEffect(() => {
    if (showInsightsPage === false) {
      navigate('/');
    }
  }, [showInsightsPage, navigate]);

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />

        <ContentContainer className="flex flex-col space-y-8">
          <div className="flex justify-between items-center">
            <CustomTypography variant="h4">Insights</CustomTypography>
            <Button variant="outlined" color="secondary" onClick={refetch}>
              Refresh Insights
            </Button>
          </div>
          <div className="flex w-full space-x-4 items-stretch">
            <MetricCard
              title="Running executions"
              value={totalExecutionsData.runningExecutions.toString()}
              loading={totalExecutionsLoading}
              link={runningExecutionsUrl}
              Icon={RunningExecutionIcon}
              tooltip="View executions"
            />
            <MetricCard
              title="Queued executions"
              value={totalExecutionsData.queuedExecutions.toString()}
              loading={totalExecutionsLoading}
              link={queuedExecutionsUrl}
              Icon={AccessTimeIcon}
              tooltip="View executions"
            />
          </div>

          <div className="flex items-center space-x-4">
            <ExecutionListDateFilter
              filterValue={dateFilterValue}
              setFilterValue={setDateFilterValue}
              label=""
              className="!pt-4"
            />
            <Select<MemberFilterOption>
              value={selectedMemberOption}
              className="min-h-[50px]"
              classes={{ select: '!pt-2' }}
              onChange={(e) => {
                setMemberId(e.target.value as string);
              }}
              getLabel={(op) => op.label}
              getValue={(op) => op.value}
              options={memberFilterOptions}
            />
            {hasFiltersApplied ? (
              <Button
                color="secondary"
                onClick={() => {
                  setDateFilterValue(undefined);
                  setMemberId('all');
                }}
                variant="text"
              >
                Clear Filters
              </Button>
            ) : null}
          </div>

          <div className="grid grid-cols-2 gap-4 w-full items-stretch lg:grid-cols-4">
            <MetricCard
              classes={{
                container: 'bg-transparent border',
              }}
              loading={workflowsMonthLoading}
              title="Total workflows"
              value={workflowsMonthData.allWorkflows.toString()}
              description={
                currentMonthWorkflowsIncrease > 0 ? (
                  <p className={clsx('text-sm text-checked-green-dark')}>
                    +{currentMonthWorkflowsIncrease.toFixed(0)}% from last 30
                    days
                  </p>
                ) : null
              }
            />
            <MetricCard
              classes={{
                container: 'bg-transparent border',
              }}
              loading={executionsMonthLoading}
              title={
                <>
                  Executions
                  <span className="text-gray-500 ml-1">from last 30 days</span>
                </>
              }
              value={String(executionsMonthData.currentMonthExecutions)}
              description={
                <p
                  className={clsx('text-sm', {
                    'text-checked-green-dark':
                      currentMonthExecutionsIncrease >= 0,
                    'text-error': currentMonthExecutionsIncrease < 0,
                  })}
                >
                  {currentMonthExecutionsIncrease >= 0 ? '+' : ''}
                  {currentMonthExecutionsIncrease.toFixed(0)}% from last 30 days
                </p>
              }
            />
            <MetricCard
              classes={{
                container: 'bg-transparent border',
              }}
              title="Virtual machine cluster"
              value={maxConcurrentExecutions.toString()}
              description={
                <Tooltip
                  arrow
                  title="This is the current number of machines on your plan. Contact our sales team if more machines are needed!"
                >
                  <Button className="!p-0" variant="text" color="secondary">
                    Upgrade
                  </Button>
                </Tooltip>
              }
              loading={maxConcurrentExecutionsLoading}
            />
            <MetricCard
              classes={{
                container: 'bg-transparent border',
              }}
              title="Members in the organization"
              value={teamMembers.length.toString()}
              loading={teamMembersLoading}
            />
          </div>

          <div className="flex flex-col w-full gap-4 items-stretch xl:flex-row">
            <div className="flex flex-col flex-1 px-6 py-4 rounded-lg border">
              <Title
                title={`Total executions: ${totalExecutionsData.totalExecutions.toString()}`}
                description="All time"
                showDescription={!dateFilterValue}
              />
              {totalExecutionsLoading ? (
                <div className="flex flex-col items-center justify-center space-y-4 m-auto">
                  <Skeleton variant="circular" height={225} width={225} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem' }}
                    className="w-full"
                    height={30}
                  />
                </div>
              ) : null}
              {totalExecutionsData.totalExecutions &&
              !totalExecutionsLoading ? (
                <ReactECharts
                  className="zoom-countered-element"
                  option={pieChartOptions}
                />
              ) : null}
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <Title
                title="Executions over time"
                description="All time"
                showDescription={!dateFilterValue}
              />
              {monthWiseExecutionsCountLoading ? (
                <div className="flex flex-col items-center justify-center space-y-4 w-full !mt-4">
                  <Skeleton
                    className="w-[90%]"
                    variant="rectangular"
                    height={225}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem' }}
                    className="w-[90%]"
                    height={30}
                  />
                </div>
              ) : null}
              {monthWiseExecutionsCountData.length > 0 &&
              !monthWiseExecutionsCountLoading ? (
                <ReactECharts
                  className="zoom-countered-element"
                  option={executionsMonthChartOptions}
                />
              ) : null}
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <Title
                title="Workflows created over time"
                description="All time"
                showDescription={!dateFilterValue}
              />
              {monthWiseWorkflowsCountLoading ? (
                <div className="flex flex-col items-center justify-center space-y-4 w-full !mt-4">
                  <Skeleton
                    className="w-[90%]"
                    variant="rectangular"
                    height={225}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem' }}
                    className="w-[90%]"
                    height={30}
                  />
                </div>
              ) : null}
              {monthWiseWorkflowsCountData.length > 0 &&
              !monthWiseWorkflowsCountLoading ? (
                <ReactECharts
                  className="zoom-countered-element"
                  option={workflowsMonthChartOptions}
                />
              ) : null}
            </div>
          </div>

          <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
            <Title
              title="Cluster busy hours"
              description="Last 30 days"
              showDescription={!dateFilterValue}
            />
            {executionHourlyMetricsLoading ? (
              <div className="flex flex-col items-center justify-center space-y-4 w-full !mt-4">
                <Skeleton
                  className="w-full"
                  variant="rectangular"
                  height={225}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem' }}
                  className="w-1/5 mx-auto"
                  height={30}
                />
              </div>
            ) : null}
            {executionHourlyMetricsData.length > 0 &&
            !executionHourlyMetricsLoading ? (
              <ReactECharts
                className="zoom-countered-element"
                option={executionHourlyChartOptions}
              />
            ) : null}
          </div>

          <div className="flex flex-col w-full gap-4 items-stretch xl:flex-row">
            <TopWorkflows
              loading={workflowsWithExecutionsCountLoading}
              data={workflowsWithExecutionsCount}
            />
            <div className="flex flex-col space-y-4 flex-1 px-6 py-4 rounded-lg border">
              <div className="flex w-full justify-between items-center">
                <p className={titleClasses}>Member insights</p>
                <Link
                  to="/members"
                  className="text-sm px-3 py-1.5 font-medium !border-sola-primary border rounded !text-sola-primary uppercase"
                >
                  View all members
                </Link>
              </div>
              {teamMembersWithWorkflowCountLoading ? (
                <ListSkeleton columns={membersTableColumns} length={6} />
              ) : (
                <DataGrid<MemberWithWorkflowCount>
                  columns={membersTableColumns}
                  getRowId={(row) => row.id}
                  hideFooter
                  rows={teamMembersWithWorkflowCount}
                />
              )}
            </div>
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}

interface TitleProps {
  title: string;
  description?: string;
  showDescription?: boolean;
}

function Title({ title, description, showDescription }: TitleProps) {
  return (
    <div className="flex justify-between items-center">
      <p className="text-base font-medium">{title}</p>
      {description && showDescription ? (
        <span className="text-sm text-gray-500 font-medium">{description}</span>
      ) : null}
    </div>
  );
}
