import { useMemo, useState } from 'react';
import { type Workflow } from '../../Orchestration/hooks';
import { titleClasses } from '../helper';
import Skeleton from '@mui/material/Skeleton';
import { Select } from 'ui-kit';
import { ChartColors } from '../../../utils/constants';

enum FilterType {
  All = 'all',
  Successful = 'successful',
  Failed = 'failed',
}

const filterOptions = Object.values(FilterType);

const titleMapping: Record<FilterType, string> = {
  [FilterType.All]: 'Most executions',
  [FilterType.Successful]: 'Most successful executions',
  [FilterType.Failed]: 'Most failed executions',
};

const colorMapping: Record<FilterType, string> = {
  [FilterType.All]: '#5D9FF3',
  [FilterType.Successful]: ChartColors.SUCCESS,
  [FilterType.Failed]: ChartColors.FAILURE,
};

type WorkflowWithCount = Workflow & {
  count: number;
};

interface WorkflowItemProps {
  workflow: WorkflowWithCount;
  index: number;
  type: FilterType;
  maxCount: number;
}

function WorkflowItem({ workflow, index, type, maxCount }: WorkflowItemProps) {
  const executionCount = workflow.count;

  const percentage = useMemo(() => {
    if (maxCount === 0) {
      // If maxCount is 0, return 100% so that bar is visible
      return 100;
    }
    return (executionCount / maxCount) * 100;
  }, [executionCount, maxCount]);

  return (
    <div className="flex space-x-4 relative group">
      <div className="w-10 h-10 flex justify-center items-center rounded bg-primary-blue-extralight-2 font-bold">
        {index + 1}
      </div>
      <div className="flex flex-col flex-1 space-y-1">
        <p className="text-sm text-navy-blue">{workflow.name}</p>
        <div
          className="h-3 rounded"
          style={{
            width: `${String(percentage)}%`,
            backgroundColor: colorMapping[type],
          }}
        />
      </div>
      <div className="absolute border shadow-lg bottom-0 left-1/2 bg-white rounded h-10 px-3 py-2 items-center space-x-2 hidden group-hover:flex">
        <div
          className="rounded w-3 h-3"
          style={{ backgroundColor: colorMapping[type] }}
        />
        <p className="text-sm text-color-grey capitalize">{type} executions</p>
        <p className="text-sm text-black">{executionCount}</p>
      </div>
    </div>
  );
}

interface TopWorkflowsProps {
  loading: boolean;
  data: Workflow[];
}

export default function TopWorkflows({ loading, data }: TopWorkflowsProps) {
  const [type, setType] = useState<FilterType>(FilterType.All);

  const filteredWorkflows: WorkflowWithCount[] = useMemo(() => {
    if (loading) {
      return [];
    }
    let keyToUseForSorting: keyof Workflow = 'total';
    switch (type) {
      case FilterType.Successful:
        keyToUseForSorting = 'successful';
        break;
      case FilterType.Failed:
        keyToUseForSorting = 'failed';
        break;
      case FilterType.All:
      default:
        keyToUseForSorting = 'total';
        break;
    }
    return [...data]
      .map((workflow) => ({
        ...workflow,
        count: workflow[keyToUseForSorting].aggregate?.count ?? 0,
      }))
      .sort((a: WorkflowWithCount, b: WorkflowWithCount) => {
        return b.count - a.count;
      })
      .slice(0, 6);
  }, [type, data, loading]);

  return (
    <div className="flex flex-col space-y-6 flex-1 p-6 pt-4 rounded-lg border">
      <div className="flex justify-between items-center">
        <p className={titleClasses}>Workflows top 6</p>
        <Select
          className="min-h-12"
          classes={{ select: '!pt-2' }}
          value={type}
          onChange={(e) => {
            setType(e.target.value as FilterType);
          }}
          getLabel={(option) => titleMapping[option as FilterType]}
          getValue={(option) => option}
          options={filterOptions}
        />
      </div>
      {loading ? (
        <Skeleton className="w-full" variant="rectangular" height={280} />
      ) : null}
      {filteredWorkflows.map((workflow, index) => (
        <WorkflowItem
          key={workflow.id}
          workflow={workflow}
          index={index}
          type={type}
          maxCount={filteredWorkflows[0].count}
        />
      ))}
    </div>
  );
}
