"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplayStepType = exports.MOCK_USER_ID = void 0;
__exportStar(require("./keys"), exports);
__exportStar(require("./supported-applications"), exports);
exports.MOCK_USER_ID = 'google-oauth2|109435487003362441242';
// Sourced from:
// https://github.com/puppeteer/replay/blob/1de14d61a7cdb14839517dd00716b507f298277a/src/Schema.ts#L32
exports.ReplayStepType = {
    change: 'change',
    click: 'click',
    close: 'close',
    customStep: 'customStep',
    doubleClick: 'doubleClick',
    emulateNetworkConditions: 'emulateNetworkConditions',
    hover: 'hover',
    keyDown: 'keyDown',
    keyUp: 'keyUp',
    navigate: 'navigate',
    scroll: 'scroll',
    setViewport: 'setViewport',
    waitForElement: 'waitForElement',
    waitForExpression: 'waitForExpression',
};
