"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addApiKeySchema = void 0;
const types_shared_1 = require("types-shared");
exports.addApiKeySchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.ApiKey.pick({
            name: true,
        }),
    }),
    response: types_shared_1.ApiKeyFull,
};
