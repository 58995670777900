import { type MouseEvent, useMemo } from 'react';
import {
  type VersionConfigurations,
  WorkflowStatusEnum,
} from 'api-types-shared';
import {
  DoneIcon,
  InfoOutlined,
  Button,
  modalEventChannel,
  Tooltip,
} from 'ui-kit';
import Container from './VersionHistoryContainer';
import { clsx } from 'clsx';
import dayjs from '../../../../../config/dayjs';

interface VersionItemProps {
  className?: string;
  onClick: (version: string) => void;
  onRestore: (version: string) => void;
  selectedVersion?: string;
  version: VersionConfigurations;
  disableRestore?: boolean;
  userMap: Record<string, string>;
}

export default function VersionItem({
  className,
  onClick,
  onRestore,
  selectedVersion,
  version,
  disableRestore,
  userMap,
}: VersionItemProps) {
  const ready = version.status === WorkflowStatusEnum.Ready;
  const selected = selectedVersion === version.versionId;

  const commitUserEmail = useMemo(
    () => (version.commitUsers?.[0] ? userMap[version.commitUsers[0]] : ''),
    [version.commitUsers, userMap],
  );

  const onRestoreVersion = (e: MouseEvent) => {
    e.stopPropagation();
    modalEventChannel.emit('open', {
      title: 'Are you sure you want to restore the version?',
      descriptions: [
        'Restoring a version overwrites the current version in the cloud and on this device.',
      ],
      actions: [
        {
          text: 'yes, restore version',
          onClick: () => {
            modalEventChannel.emit('close');
            onRestore(version.stateVersion);
          },
        },
        {
          text: 'cancel',
          onClick: () => {
            modalEventChannel.emit('close');
          },
          variant: 'outlined',
        },
      ],
      alignActions: 'left',
    });
  };

  return (
    <Container
      className={clsx('text-sm', className)}
      key={version.versionId}
      onClick={() => {
        if (version.versionId) {
          onClick(version.versionId);
        }
      }}
      selected={selected}
    >
      <div className="flex justify-between items-center text-sm">
        <div className="flex flex-col items-start space-y-3 w-2/5">
          <p className="inline-flex items-center font-medium">
            {dayjs(version.committedAt).format('MM/DD/YY - h:mm A')}
          </p>
          {commitUserEmail ? (
            <p className="text-sm text-slate-700 w-full truncate">
              {commitUserEmail}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col items-end space-y-3 w-2/5">
          <p className="inline-flex items-center">
            {ready ? 'Ready to execute' : 'Not ready to execute'}
            {ready ? (
              <DoneIcon className="!w-4 !h-4 !ml-2 !text-success" />
            ) : (
              <Tooltip
                placement="right"
                title="This version was saved but cannot be executed."
              >
                <InfoOutlined className="!w-4 !h-4 !ml-2" />
              </Tooltip>
            )}
          </p>
          {version.versionId && ready ? (
            <p className="inline-flex items-center text-xs">
              Version ID: <b className="ml-1">{version.versionId}</b>
            </p>
          ) : null}
        </div>
      </div>
      {selected ? (
        <Button
          className="!mt-8"
          color="secondary"
          disabled={disableRestore}
          onClick={onRestoreVersion}
          variant="outlined"
        >
          Restore version
        </Button>
      ) : null}
    </Container>
  );
}
