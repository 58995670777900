import { useState, useEffect, useRef } from 'react';
import type { SelectedAction } from './ActionsList';
import { Button, Input } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import type { Target } from 'types-shared';

interface Props {
  action: SelectedAction;
  onClose: () => void;
}

function EditTarget({ action, onClose }: Props) {
  const { targets, updateTarget } = EditorStore();
  const targetInfo = action.targetId ? targets[action.targetId] : null;
  const initialTargetRef = useRef<Target | null>(
    action.targetId ? targets[action.targetId] : null,
  );
  const [updatedTargetInfo, setUpdatedTargetInfo] = useState<string>(
    JSON.stringify(targetInfo, null, 2),
  );
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    try {
      const json = JSON.parse(updatedTargetInfo) as Target;
      setHasError(false);
      updateTarget(json);
    } catch (error) {
      setHasError(true);
    }
  }, [updateTarget, updatedTargetInfo]);

  const onCancel = () => {
    updateTarget((initialTargetRef.current ?? {}) as Target);
    onClose();
  };

  return (
    <div className="flex-1 flex flex-col mb-10 px-8 py-6 ">
      <div className="flex justify-between items-center">
        <h2 className="flex items-center space-x-3">
          <span className="text-xs text-white rounded-full h-6 w-6 flex justify-center items-center bg-gray-800">
            {action.i}
          </span>
          <span className="font-medium text-lg">{action.actionType}</span>
        </h2>
      </div>

      <Input
        classes={{ wrapper: 'flex flex-col mt-3' }}
        error={hasError}
        errorText={hasError ? 'Invalid Target Object' : ''}
        floatingLabel
        label="Edit Target"
        multiline
        onChange={setUpdatedTargetInfo}
        rows={28}
        value={updatedTargetInfo}
      />

      <div className="flex flex-row justify-between gap-4 mt-auto pt-4">
        <Button
          className="flex-1"
          color="secondary"
          disabled={hasError}
          onClick={onClose}
          variant="contained"
        >
          SAVE
        </Button>
        <Button
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}

export default EditTarget;
