// This file is easier to read starting from the bottom.
import { handleException } from 'sentry-browser-shared';
import {
  type VariableMap,
  type WorkflowImageNode,
  ActionsEnum,
  ActionValueCriteriaEnum,
  MultiChoiceVariable,
  MultiSelectVariable,
  Rule,
  SelectVariable,
  TemplateVariable,
  WorkflowAction,
} from 'types-shared';
import { defaultErrorMessage, imageNodeBrokenActionMessage } from './constants';
import { handleValidateMultipleRules, templateDataHasContent } from './helpers';
import { pickFromListOptions } from '../helper';

interface ValidateActionOutput {
  actionInvalid: boolean;
  error: string | null;
  actionMalformed: boolean;
  validatedVariables?: Record<string, boolean>;
  templateVariablesToValidate?: string[];
  multichoiceVariablesToValidate?: string[];
}

interface ValidateActionArgs {
  action: WorkflowAction;
  node: WorkflowImageNode;
  workflowId: string;
  variableMap: VariableMap;
  globalVariablesMap: VariableMap;
}

const defaultValidateActionOutput: ValidateActionOutput = {
  actionInvalid: false,
  error: null,
  actionMalformed: false,
  templateVariablesToValidate: [],
  multichoiceVariablesToValidate: [],
};

// Handle actions where validations are not needed for the moment
const noValidationNeeded = (
  _args: ValidateActionArgs,
): ValidateActionOutput => {
  return defaultValidateActionOutput;
};

const validateInputAction = ({
  action,
  node,
  workflowId,
  variableMap,
  globalVariablesMap,
}: ValidateActionArgs): ValidateActionOutput => {
  let error = '';

  if (!action.variableId) {
    handleException(
      new Error('Input actions must have a variable id attached'),
      {
        name: 'Input actions must have a variable id attached',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      },
    );
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: 'Input actions must have a variable',
      actionMalformed: true,
    };
  }
  if (action.criteria === ActionValueCriteriaEnum.Condition) {
    const actionRules = Rule.array().safeParse(action.rules);
    if (!actionRules.success) {
      handleException(actionRules.error, {
        name: 'Action rules are not valid.',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      });

      error = 'An action is not valid.';
      return {
        ...defaultValidateActionOutput,
        actionInvalid: true,
        error,
        actionMalformed: true,
      };
    }

    const rules = actionRules.data;

    // Validate the rules:
    let hasMalformedAction = false;
    const result = handleValidateMultipleRules({
      rules,
      variableMap,
      globalVariablesMap,
      workflowId,
      node,
      handleCriticalError: (_err) => {
        hasMalformedAction = true;
      },
    });

    const hasError = Object.values(result).some((value) => value);

    return {
      ...defaultValidateActionOutput,
      error,
      validatedVariables: result,
      actionInvalid: hasError || hasMalformedAction,
      actionMalformed: hasMalformedAction,
    };
  }

  const inputVariableCheck = TemplateVariable.safeParse(
    variableMap[action.variableId],
  );

  if (!inputVariableCheck.success) {
    handleException(inputVariableCheck.error, {
      name: 'Input actions must have a valid template variable',
      source: 'handleValidateAction',
      extra: { action, node, workflowId },
    });
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };
  }

  const inputVariableHasContent = templateDataHasContent(
    inputVariableCheck.data.data,
    variableMap,
    globalVariablesMap,
    (err) => {
      if (!error.includes(err)) {
        error = ` ${err}`;
      }
    },
    true, // Allow empty inputs, but validate them if they have content
  );

  if (!inputVariableHasContent) {
    return {
      ...defaultValidateActionOutput,
      templateVariablesToValidate: [action.variableId],
      actionInvalid: true,
      error: imageNodeBrokenActionMessage,
    };
  }

  return defaultValidateActionOutput;
};

const validateMultiChoiceAction = ({
  action,
  node,
  workflowId,
  variableMap,
  globalVariablesMap,
}: ValidateActionArgs): ValidateActionOutput => {
  const error = '';

  if (action.criteria === ActionValueCriteriaEnum.Condition) {
    const multichoiceRules = action.rules;

    if (!multichoiceRules) {
      handleException(
        new Error(
          'Multi-choice actions with criteria set to condition must have rules',
        ),
        {
          name: 'Action is not valid.',
          source: 'handleValidateAction',
          extra: { action, node, workflowId },
        },
      );
      return {
        ...defaultValidateActionOutput,
        actionInvalid: true,
        error: 'Action is not valid.',
        actionMalformed: true,
      };
    }

    const rulesParse = Rule.array().safeParse(multichoiceRules);
    if (!rulesParse.success) {
      handleException(rulesParse.error, {
        name: 'Multi-choice actions with criteria set to condition must have valid rules',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      });
      return {
        ...defaultValidateActionOutput,
        actionInvalid: true,
        error: 'Action is not valid.',
        actionMalformed: true,
      };
    }

    const rulesData = rulesParse.data;

    const ruleValidationResults = handleValidateMultipleRules({
      rules: rulesData,
      variableMap,
      globalVariablesMap,
      workflowId,
      node,
      handleCriticalError: () => {
        return {
          ...defaultValidateActionOutput,
          actionInvalid: true,
          error: 'Action is not valid.',
        };
      },
    });

    const someDataMissing = Object.values(ruleValidationResults).some((v) => v);

    return {
      ...defaultValidateActionOutput,
      validatedVariables: ruleValidationResults,
      actionInvalid: someDataMissing,
    };
  }

  if (!action.variableId) {
    handleException(
      new Error(
        'Multi-choice actions with criteria set to variable must have a variable id',
      ),
      {
        name: 'Action is not valid.',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      },
    );
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: `Action is not valid.${error}`,
      actionMalformed: true,
    };
  }

  const multichoiceVariableParse = MultiChoiceVariable.safeParse(
    variableMap[action.variableId],
  );

  if (!multichoiceVariableParse.success) {
    handleException(multichoiceVariableParse.error, {
      name: 'Multi-choice actions with criteria set to variable must have a valid multichoice variable',
      source: 'handleValidateAction',
      extra: { action, node, workflowId },
    });
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: `Action is not valid.${error}`,
      actionMalformed: true,
    };
  }

  return defaultValidateActionOutput;
};

const validateMultiSelectAction = ({
  action,
  node,
  workflowId,
  variableMap,
  globalVariablesMap,
}: ValidateActionArgs): ValidateActionOutput => {
  let error = '';

  if (!action.variableId)
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };

  const multiSelectVariableCheck = MultiSelectVariable.safeParse(
    variableMap[action.variableId],
  );
  if (!multiSelectVariableCheck.success) {
    handleException(multiSelectVariableCheck.error, {
      name: 'Multi-select actions with criteria set to variable must have a valid multiselect variable',
      source: 'handleValidateAction',
      extra: { action, node, workflowId },
    });
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };
  }

  let isInvalid = false;
  const multiSelectVariable = multiSelectVariableCheck.data;

  // We are allowed to not have selected anything if using the default multi selectClasses,
  // so we do not validate it here. We only validate the template data if there is any, while also allowing an empty field
  if (action.criteria === ActionValueCriteriaEnum.Variable) {
    const multiSelectVariableHasData = templateDataHasContent(
      multiSelectVariable.data,
      variableMap,
      globalVariablesMap,
      (err) => {
        if (!error.includes(err)) {
          error = ` ${err}`;
        }
      },
      true,
    );

    isInvalid = !multiSelectVariableHasData;
  }

  const payload = {
    ...defaultValidateActionOutput,
    validatedVariables: { [multiSelectVariable.id]: isInvalid },
    actionInvalid: isInvalid,
    error: isInvalid ? imageNodeBrokenActionMessage : null,
  };
  return payload;
};

const validateSelectAction = ({
  action,
  node,
  workflowId,
  variableMap,
  globalVariablesMap,
}: ValidateActionArgs): ValidateActionOutput => {
  let error = '';
  if (!action.variableId)
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };

  const selectVariableCheck = SelectVariable.safeParse(
    variableMap[action.variableId],
  );
  if (!selectVariableCheck.success)
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };

  const selectVariable = selectVariableCheck.data;
  selectVariable.data;

  const selectActionHasRules =
    action.criteria === ActionValueCriteriaEnum.Condition;

  if (selectActionHasRules) {
    const rulesParse = Rule.array().safeParse(action.rules);

    if (!rulesParse.success) {
      handleException(rulesParse.error, {
        name: 'Select actions with criteria set to condition must have valid rules',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      });

      return {
        ...defaultValidateActionOutput,
        actionInvalid: true,
        error: 'Action is not valid.',
        actionMalformed: true,
      };
    }
    const rulesData = rulesParse.data;

    const ruleValidationResults = handleValidateMultipleRules({
      rules: rulesData,
      variableMap,
      globalVariablesMap,
      workflowId,
      node,
      handleCriticalError: () => {
        return {
          ...defaultValidateActionOutput,
          actionInvalid: true,
          error: 'Action is not valid.',
        };
      },
    });

    const someDataMissing = Object.values(ruleValidationResults).some((v) => v);

    return {
      ...defaultValidateActionOutput,
      validatedVariables: ruleValidationResults,
      actionInvalid: someDataMissing,
    };
  }

  const selectVariableHasData = templateDataHasContent(
    selectVariable.data,
    variableMap,
    globalVariablesMap,
    (err) => {
      if (!error.includes(err)) {
        error = ` ${err}`;
      }
    },
    true,
  );

  return {
    ...defaultValidateActionOutput,
    validatedVariables: { [selectVariable.id]: !selectVariableHasData },
    actionInvalid: !selectVariableHasData,
    error: !selectVariableHasData ? imageNodeBrokenActionMessage : null,
  };
};

const validatePickFromListAction = (
  args: ValidateActionArgs,
): ValidateActionOutput => {
  const { action, node, workflowId, variableMap, globalVariablesMap } = args;

  if (!action.variableId) {
    handleException(
      new Error('Pick from list actions must have a variable id attached'),
      {
        name: 'Pick from list actions must have a variable id attached',
        source: 'handleValidateAction',
        extra: { action, node, workflowId, variableMap },
      },
    );
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: 'Pick from list actions must have a variable',
      actionMalformed: true,
    };
  }

  const maybeVariable = variableMap[action.variableId];

  const variableCheck = TemplateVariable.safeParse(maybeVariable);

  if (!variableCheck.success) {
    handleException(variableCheck.error, {
      name: 'Pick from list actions must have a valid template variable',
      source: 'handleValidateAction',
      extra: { action, node, workflowId, variableMap },
    });

    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: defaultErrorMessage,
      actionMalformed: true,
    };
  }

  const variable = variableCheck.data;

  const isUsingDefaultData =
    variable.data.length === 1 && variable.data[0] === pickFromListOptions[2];
  const isValid =
    !isUsingDefaultData &&
    templateDataHasContent(variable.data, variableMap, globalVariablesMap);

  const payload = {
    ...defaultValidateActionOutput,
    actionInvalid: !isValid,
    error: !isValid ? imageNodeBrokenActionMessage : '',
    validatedVariables: {
      [action.variableId]: !isValid,
    },
  };

  return payload;
};

// Check file rename
const validateDownloadAction = (
  _args: ValidateActionArgs,
): ValidateActionOutput => {
  return defaultValidateActionOutput;
};

const actionValidationMap: Record<
  ActionsEnum,
  (args: ValidateActionArgs) => ValidateActionOutput
> = {
  // Don't need to validate these actions
  [ActionsEnum.Click]: noValidationNeeded,
  [ActionsEnum.Scrape]: noValidationNeeded,
  [ActionsEnum.NewTab]: noValidationNeeded,
  [ActionsEnum.Open]: noValidationNeeded,
  [ActionsEnum.SwitchTab]: noValidationNeeded,
  [ActionsEnum.UploadDocument]: noValidationNeeded,
  [ActionsEnum.Wait]: noValidationNeeded,
  [ActionsEnum.KeyPress]: noValidationNeeded,
  [ActionsEnum.KeyUnpress]: noValidationNeeded,
  [ActionsEnum.KeyboardShortcut]: noValidationNeeded,
  [ActionsEnum.MagicLoop]: noValidationNeeded,
  [ActionsEnum.Refresh]: noValidationNeeded,
  [ActionsEnum.RightClick]: noValidationNeeded,
  [ActionsEnum.Screenshot]: noValidationNeeded,
  [ActionsEnum.Keydown]: noValidationNeeded,
  [ActionsEnum.Arbitrary]: noValidationNeeded,

  // Validated
  [ActionsEnum.MultiChoice]: validateMultiChoiceAction,
  [ActionsEnum.MultiSelect]: validateMultiSelectAction,
  [ActionsEnum.Select]: validateSelectAction,
  [ActionsEnum.Input]: validateInputAction,
  [ActionsEnum.PickFromList]: validatePickFromListAction,

  // Not validated
  [ActionsEnum.Download]: validateDownloadAction,
};

// Map action types to their validation functions. Each validation function checks if the action
// is properly configured and has all required data. Returns a ValidateActionOutput indicating if
// the action is valid and what variables have failed validation.
const handleValidateAction = (
  args: ValidateActionArgs,
): ValidateActionOutput => {
  const { action, node, workflowId } = args;

  try {
    WorkflowAction.parse(action);

    // If validation is not necessary, return the default output
    const validationNotNeeded =
      action.options && (action.options.adminOnly || action.options.hidden);

    if (validationNotNeeded) {
      return defaultValidateActionOutput;
    }

    const validationFunction = actionValidationMap[action.actionType];

    // This is a safety measure for if we push a breaking change e.g if we delete an existing action type,
    // but a workflow is still using that older action type
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!validationFunction) {
      handleException(new Error('Action type is not valid.'), {
        name: 'Action type is not valid.',
        source: 'handleValidateAction',
        extra: { action, node, workflowId },
      });

      return {
        ...defaultValidateActionOutput,
        actionInvalid: true,
        error: 'Action type is not valid.',
        actionMalformed: true,
      };
    }
    return validationFunction(args);
  } catch (error) {
    handleException(error, {
      name: 'Action is not valid.',
      source: 'handleValidateAction',
      extra: { action, node, workflowId },
    });
    return {
      ...defaultValidateActionOutput,
      actionInvalid: true,
      error: 'Action is not valid.',
      actionMalformed: true,
    };
  }
};

export default handleValidateAction;
