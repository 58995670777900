import { useEffect, useState } from 'react';
import type { WorkflowRetryNode } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import {
  FormControlLabel,
  InfoOutlined,
  Input,
  Radio,
  RadioGroup,
  Tooltip,
} from 'ui-kit';
import {
  extractDaysHoursMinutes,
  getTotalMinutes,
} from '../../../../utils/helper';
import { useNodeValidation } from '../../hooks/useNodeValidation';
import nodeValidations, {
  type RetryNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';

const validateRetryNode = nodeValidations.retry;

interface Props {
  node: WorkflowRetryNode;
  updateNodeData: (data: object) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
  workflowId?: string;
}

const defaultRetryConfig = {
  retryInterval: 1440,
  maxAttempts: 4,
};

export function RetryBlock({
  node,
  onCancel,
  updateNodeData,
  updateNodeStatus,
  workflowId,
}: Props) {
  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<RetryNodeValidationResult>({
      node,
      // We currently don't have any variables for retry nodes
      variablesMap: {},
      globalVariablesMap: {},
      updateNodeStatus,
      validationFunction: validateRetryNode,
      workflowId: workflowId ?? '',
    });

  const settings = node.data;
  const { retryInterval, maxAttempts } = settings;
  const maxAttemptsValue = maxAttempts ? maxAttempts - 1 : 0;

  const [duration, setDuration] = useState<{
    days: number | string;
    hours: number | string;
    minutes: number | string;
  }>(() => {
    return retryInterval
      ? extractDaysHoursMinutes(Number(retryInterval))
      : {
          days: '',
          hours: '',
          minutes: '',
        };
  });

  const updateInterval = (value: number) => () => {
    updateNodeData({
      retryInterval: value,
    });
    if (value === 0) {
      setDuration({
        days: '1',
        hours: '0',
        minutes: '0',
      });
    }
  };

  const updateSettings = (key: 'maxAttempts') => (value: string) => {
    if (
      Number(value) > 10 ||
      ['e', 'E', '-', '.'].some((char) => value.includes(char))
    ) {
      return;
    }
    updateNodeData({ [key]: Number(value) + 1 });
  };

  const updateDuration =
    (key: 'days' | 'hours' | 'minutes') => (value: string | undefined) => {
      if (value === undefined) {
        setDuration({
          ...duration,
          [key]: value,
        });
      } else if (
        Number(value) < 0 ||
        (key === 'minutes' && Number(value) > 59) ||
        (key === 'hours' && Number(value) > 23) ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setDuration({ ...duration, [key]: Number(value) });
    };

  useEffect(() => {
    const minutes = getTotalMinutes({
      days: Number(duration.days),
      hours: Number(duration.hours),
      minutes: Number(duration.minutes),
    });
    if (retryInterval && retryInterval !== minutes) {
      updateNodeData({
        retryInterval: minutes,
      });
    }
  }, [duration, retryInterval, updateNodeData]);

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-y-auto flex-1 pb-20">
          <NodeCheckV2
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            onCheck={handleValidateNode}
            errors={validationAttempted ? validationResult.errors : []}
            showErrors={validationAttempted}
            hidden={node.hideFromUser}
            noTopMargin
          />

          <div className="my-6 px-8">
            <h2 className="text-lg font-medium">Retry step</h2>
            <p className="text-sm font-normal mt-2 text-grey-light">
              Specify when to implement a workflow retry. These settings will
              override the default API retry workflow settings, if any.
            </p>
          </div>
          <div className="retry-block flex flex-col space-y-8 px-8">
            <h2 className="text-info-dark text-sm font-medium">Retry timing</h2>
            <div className="flex flex-col space-y-2 !mt-2">
              <RadioGroup
                defaultValue="true"
                name="radio-buttons-group"
                onChange={(_, val) => {
                  const newVal =
                    val === 'true' ? defaultRetryConfig.retryInterval : 0;
                  setDuration({
                    days: '1',
                    hours: '0',
                    minutes: '0',
                  });
                  updateInterval(newVal)();
                }}
                value={retryInterval ? 'true' : 'false'}
              >
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label={
                    <span className="text-info-dark text-sm font-medium">
                      Retry immediately
                    </span>
                  }
                  value="false"
                />
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label={
                    <span className="text-info-dark text-sm font-medium">
                      Time between retries
                    </span>
                  }
                  value="true"
                />
              </RadioGroup>
              <div className="ml-7 flex flex-row gap-4">
                <Input
                  InputProps={{
                    className: 'w-24',
                    inputMode: 'numeric',
                    inputProps: { max: 1000 },
                  }}
                  disabled={retryInterval === 0}
                  floatingLabel
                  id="retry-days"
                  label="Days"
                  onChange={updateDuration('days')}
                  placeholder="1"
                  type="number"
                  value={duration.days}
                />
                <Input
                  InputProps={{
                    className: 'w-24',
                    inputMode: 'numeric',
                    inputProps: { max: 23 },
                  }}
                  disabled={retryInterval === 0}
                  floatingLabel
                  id="retry-hours"
                  label="Hours"
                  onChange={updateDuration('hours')}
                  placeholder="0"
                  type="number"
                  value={duration.hours}
                />
                <Input
                  InputProps={{
                    className: 'w-24',
                    inputMode: 'numeric',
                    inputProps: { max: 59 },
                  }}
                  disabled={retryInterval === 0}
                  floatingLabel
                  id="retry-minutes"
                  label="Minutes"
                  onChange={updateDuration('minutes')}
                  placeholder="0"
                  type="number"
                  value={duration.minutes}
                />
              </div>
            </div>
            <div className="mt-8 flex flex-col space-y-2">
              <Tooltip title="The highest number of times a workflow will retry from the start before moving forward. If there are no steps after, the workflow will fail.">
                <span className="text-info-dark text-sm font-medium">
                  Maximum retry count
                </span>
              </Tooltip>
              <Input
                classes={{
                  root: '!w-32',
                }}
                InputProps={{
                  inputMode: 'numeric',
                  inputProps: { max: 10 },
                }}
                error={maxAttemptsValue === 0 || maxAttemptsValue > 10}
                floatingLabel
                id="retry-count"
                label="Max retry count"
                onChange={updateSettings('maxAttempts')}
                placeholder="3"
                type="number"
                value={maxAttemptsValue}
              />
            </div>
            {maxAttempts !== null && maxAttemptsValue === 0 ? (
              <p className="text-red-500 text-sm font-normal mt-3">
                Number of failures to trigger termination must be at least 1
              </p>
            ) : null}
            <div className="mt-10 w-full bg-[#F9FAFA] flex space-x-2 px-4 py-3 rounded">
              <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
              <span className="text-sm text-[#014361]">
                If the maximum retry count is reached, the workflow will proceed
                to the next step. However, if it's the final step, the execution
                will stop and be marked as a failure.
              </span>
            </div>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}
