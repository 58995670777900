import {
  AlertVariant,
  Button,
  FormControlLabel,
  Input,
  notify,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from 'ui-kit';
import { type SelectChangeEvent } from '@mui/material/Select';
import { formatQuery, type RuleGroupType } from 'react-querybuilder';
import {
  TriggersEmailEventEnum,
  useCreateEmailTrigger,
  useDeleteEmailTrigger,
  useGetEmailTriggerAccounts,
  useUpdateEmailTrigger,
  type EmailTrigger,
} from '../../../../hooks/useEmailTriggers';
import QueryBuilder from '../../../../../../components/QueryBuilder';
import { useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import {
  createQueryBuilderConfig,
  EmailMetadataForTriggerFields,
} from 'query-builder-utils';
import { clsx } from 'clsx';
import { type AuthenticatedConnectUser } from 'dashboard-shared';
import {
  EmailMetadataSchemaIdentifier,
  EmailMetadataSchemaV1Version,
} from 'types-shared/emailTriggerTypes';
import { isQueryValid } from '../../../../utils/helper';
import { v4 as uuid } from 'uuid';
import { emailTriggerEventTitleMap } from '../../../../utils/constants';
import { getRandomRuleGroup } from '../../../../../../components/QueryBuilder/utils';

// MARK: Zod Schema Field Configuration <== Change here to test different schemas
const zodSchemaFieldConfiguration = EmailMetadataForTriggerFields;

// Using EmailMetadataForTriggerFields converted to the format expected by QueryBuilder
const { fields, celRuleProcessor } = createQueryBuilderConfig(
  zodSchemaFieldConfiguration,
);

const staticFields = {
  conditionDataSchemaName: EmailMetadataSchemaIdentifier,
  conditionDataSchemaVersion: EmailMetadataSchemaV1Version,
};

export const defaultCondition = {
  combinator: 'or',
  rules: [getRandomRuleGroup(fields)],
  id: uuid(),
};

export type Form = Pick<
  EmailTrigger,
  | 'id'
  | 'name'
  | 'receiverEmailAddress'
  | 'conditionQueryBuilderJson'
  | 'event'
  | 'workflowId'
>;

interface Props {
  form: Form;
  onUpdate: (form: Partial<EmailTrigger>) => void;
  paragonUser: AuthenticatedConnectUser | undefined;
  onClose: () => void;
  resetForm: () => void;
  workflowId: string;
}

export default function EventTriggerForm({
  form,
  onUpdate,
  resetForm,
  workflowId,
  paragonUser,
}: Props) {
  const [query, setQuery] = useState<RuleGroupType>(
    form.conditionQueryBuilderJson as unknown as RuleGroupType,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { createEmailTrigger, loading: isCreatingEmailTrigger } =
    useCreateEmailTrigger();
  const { updateEmailTrigger, loading: isUpdatingEmailTrigger } =
    useUpdateEmailTrigger();
  const { deleteEmailTrigger, loading: isDeletingEmailTrigger } =
    useDeleteEmailTrigger();

  const {
    gmailAddresses,
    outlookAddresses,
    loading: isLoadingIdentifiers,
  } = useGetEmailTriggerAccounts(paragonUser);

  const triggerTypeOptions = useMemo(
    () => [
      {
        label:
          emailTriggerEventTitleMap[TriggersEmailEventEnum.OutlookNewMessage],
        value: TriggersEmailEventEnum.OutlookNewMessage,
        description:
          'Triggers on all incoming emails and replies within email threads',
        disabled: outlookAddresses.length === 0,
        disabledTooltip: 'Connect an Outlook account to use this trigger event',
      },
      {
        label:
          emailTriggerEventTitleMap[
            TriggersEmailEventEnum.GmailNewThreadCreated
          ],
        value: TriggersEmailEventEnum.GmailNewThreadCreated,
        disabled: gmailAddresses.length === 0,
        description:
          'Triggers on new incoming emails, not including replies to existing threads',
        disabledTooltip: 'Connect a Gmail account to use this trigger event',
      },
      {
        label:
          emailTriggerEventTitleMap[TriggersEmailEventEnum.GmailThreadUpdated],
        value: TriggersEmailEventEnum.GmailThreadUpdated,
        disabled: gmailAddresses.length === 0,
        description: 'Triggers on replies within existing email threads',
        disabledTooltip: 'Connect a Gmail account to use this trigger event',
      },
    ],
    [gmailAddresses.length, outlookAddresses.length],
  );

  const receiverEmailOptions = useMemo(() => {
    return form.event === TriggersEmailEventEnum.OutlookNewMessage
      ? outlookAddresses
      : gmailAddresses;
  }, [form.event, outlookAddresses, gmailAddresses]);

  const allowSave = useMemo(() => {
    return (
      !isNil(form.event) && form.receiverEmailAddress && query.rules.length > 0
    );
  }, [form, query.rules]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const isValid = isQueryValid(query, fields);
    if (!isValid || !form.name) {
      return;
    }
    const isUpdate = Boolean(form.id);
    const payload = {
      ...form,
      ...staticFields,
      conditionQueryBuilderJson: query as unknown as Record<string, unknown>,
      conditionCelExpression: formatQuery(query, {
        format: 'cel',
        ruleProcessor: celRuleProcessor,
      }),
      ...(isUpdate ? { id: form.id } : { workflowId, id: undefined }),
    };
    if (isUpdate) {
      await updateEmailTrigger({
        variables: {
          ...payload,
          id: form.id,
        },
      });
    } else {
      await createEmailTrigger({
        variables: {
          ...omit(payload, ['id']),
          workflowId,
        },
      });
    }
    notify({
      message: isUpdate
        ? 'Trigger updated successfully'
        : 'Trigger created successfully',
      variant: AlertVariant.SUCCESS,
    });
    resetForm();
    setQuery(defaultCondition);
    setIsSubmitting(false);
  };

  const handleDeleteTrigger = async () => {
    await deleteEmailTrigger({
      variables: {
        id: form.id,
      },
    });
    notify({
      message: 'Trigger deleted successfully',
      variant: AlertVariant.SUCCESS,
    });
    resetForm();
    setQuery(defaultCondition);
  };

  useEffect(() => {
    if (!isNil(form.conditionQueryBuilderJson)) {
      setQuery(form.conditionQueryBuilderJson as unknown as RuleGroupType);
    }
  }, [form.conditionQueryBuilderJson]);

  useEffect(() => {
    if (
      receiverEmailOptions.length > 0 &&
      (isNil(form.receiverEmailAddress) ||
        !receiverEmailOptions.includes(form.receiverEmailAddress))
    ) {
      onUpdate({ receiverEmailAddress: receiverEmailOptions[0] });
    }
  }, [receiverEmailOptions, form.receiverEmailAddress, onUpdate]);

  return (
    <div className="flex flex-col space-y-8 flex-1 text-sm max-h-full overflow-y-auto -mr-3 pr-3 pl-2">
      <div className="flex flex-col space-y-3 w-full">
        <h2 className="text-navy-blue font-medium">Trigger name</h2>
        <Input
          classes={{ wrapper: 'w-full' }}
          floatingLabel
          label="Trigger name"
          value={form.name}
          onChange={(name: string) => {
            onUpdate({ name });
          }}
          error={Boolean(isSubmitting && !form.name)}
          errorText="Enter a trigger name"
        />
      </div>

      <div className="flex flex-col w-full">
        <h2 className="text-navy-blue font-medium">Email event trigger</h2>
        <p className="text-color-grey">
          Select the email provider and event for the trigger
        </p>
        <RadioGroup
          className="!flex !flex-row !justify-between items-center !space-x-4 mt-4 !flex-nowrap"
          name="node-types-group-2 stop-block"
          value={form.event}
        >
          {triggerTypeOptions.map((option) => (
            <TriggerRadioButton
              key={option.value}
              label={option.label}
              value={option.value}
              selectedValue={form.event}
              onClick={(val) => {
                onUpdate({ event: val });
              }}
              description={option.description}
              disabled={option.disabled}
              tooltip={option.disabledTooltip}
              loading={isLoadingIdentifiers}
            />
          ))}
        </RadioGroup>
      </div>

      <div className="flex flex-col space-y-3 w-full">
        <h2 className="text-navy-blue font-medium">Receiver account</h2>
        <Select
          disabled={isLoadingIdentifiers || receiverEmailOptions.length <= 1}
          label="Receiver account"
          getLabel={(option: string) => option}
          getValue={(option: string) => option}
          options={receiverEmailOptions}
          value={form.receiverEmailAddress}
          onChange={(event: SelectChangeEvent) => {
            onUpdate({ receiverEmailAddress: event.target.value });
          }}
        />
      </div>

      <div className="flex flex-col space-y-3 w-full">
        <h2 className="text-navy-blue font-medium">
          The workflow is triggered when
        </h2>
        <QueryBuilder
          query={query}
          fields={fields}
          onQueryChange={setQuery}
          showValidationErrors={isSubmitting}
        />
      </div>

      <div className="flex w-full space-x-8">
        <Button
          disabled={!allowSave || isDeletingEmailTrigger}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          loading={isCreatingEmailTrigger || isUpdatingEmailTrigger}
        >
          Save Changes
        </Button>
        {form.id ? (
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteTrigger}
            loading={isDeletingEmailTrigger}
          >
            Delete
          </Button>
        ) : null}
      </div>
    </div>
  );
}

interface TriggerRadioButtonProps {
  label: string;
  value: TriggersEmailEventEnum;
  selectedValue: TriggersEmailEventEnum;
  onClick: (val: TriggersEmailEventEnum) => void;
  tooltip?: string;
  loading?: boolean;
  description?: string;
  disabled?: boolean;
}

const IconUrlMap = {
  [TriggersEmailEventEnum.GmailNewThreadCreated]: '/gmail.png',
  [TriggersEmailEventEnum.GmailThreadUpdated]: '/gmail.png',
  [TriggersEmailEventEnum.OutlookNewMessage]: '/outlook.svg',
};

function TriggerRadioButton({
  label,
  value,
  selectedValue,
  onClick,
  tooltip,
  description,
  loading,
  disabled,
}: TriggerRadioButtonProps) {
  const iconUrl = IconUrlMap[value];
  const isChecked = value === selectedValue;

  return (
    <Tooltip
      arrow
      containerClassName="!flex-1"
      tooltipClassName="!mb-3"
      placement="top"
      hidden={!disabled || loading}
      title={tooltip}
    >
      <button
        type="button"
        className={clsx(
          'flex flex-col items-stretch space-y-2 border border-indigo-light flex-1 rounded-md py-3 pr-5 pl-1',
          { '!border-secondary-blue !bg-blue-50': isChecked },
          { '!opacity-50': disabled },
        )}
        disabled={disabled}
        onClick={() => {
          onClick(value);
        }}
      >
        <FormControlLabel
          classes={{ label: '!flex-1' }}
          disabled={disabled}
          checked={Boolean(isChecked && !disabled)}
          className="w-full !mx-0"
          control={<Radio color="secondary" />}
          label={
            <div className="flex items-center w-full space-x-4">
              <h2 className="text-sm font-medium text-navy-blue mr-auto">
                {label}
              </h2>
              <img
                className="h-7 w-7 object-contain"
                src={iconUrl}
                alt={label}
              />
            </div>
          }
        />
        <p className="text-color-grey pl-3 text-left">{description}</p>
      </button>
    </Tooltip>
  );
}
