"use strict";
/* eslint-disable tsdoc/syntax -- Disabled due to copy from original zod-query file */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createZodFetcher = createZodFetcher;
function createZodFetcher(fetcher) {
    // TODO: use Sentry reporting here
    return (schema, ...args) => __awaiter(this, void 0, void 0, function* () {
        const response = (yield fetcher(...args));
        let data;
        if ('data' in response) {
            data = response.data;
        }
        else {
            data = yield response.json();
        }
        if (response.status >= 400) {
            throw new Error(`Request failed with status ${response.status.toString()}; args: ${JSON.stringify(data)}`);
        }
        const { data: parsedData, success, error } = schema.safeParse(data);
        if (success && parsedData) {
            return parsedData;
        }
        throw new Error(`Schema not valid: ${error.message}; args: ${JSON.stringify(args)}`);
    });
}
