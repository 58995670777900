"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadGoogleSheetsSchema = exports.createUploadUrlSchema = exports.UploadUrlContentType = exports.UploadUrlContentTypeEnum = void 0;
const types_shared_1 = require("types-shared");
var UploadUrlContentTypeEnum;
(function (UploadUrlContentTypeEnum) {
    UploadUrlContentTypeEnum["PNG"] = "image/png";
    UploadUrlContentTypeEnum["PDF"] = "application/pdf";
    UploadUrlContentTypeEnum["JSON"] = "application/json";
    UploadUrlContentTypeEnum["CSV"] = "text/csv";
})(UploadUrlContentTypeEnum || (exports.UploadUrlContentTypeEnum = UploadUrlContentTypeEnum = {}));
exports.UploadUrlContentType = types_shared_1.z.nativeEnum(UploadUrlContentTypeEnum);
exports.createUploadUrlSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z
            .object({
            filename: types_shared_1.z.string(),
            contentType: exports.UploadUrlContentType,
        })
            .merge(types_shared_1.DatasourceMetadata.pick({
            workflowId: true,
            name: true,
            description: true,
        })),
    }),
    response: types_shared_1.z.object({
        method: types_shared_1.z.literal('PUT'),
        url: types_shared_1.z.string(),
        meta: types_shared_1.DatasourceMetadata,
    }),
};
exports.uploadGoogleSheetsSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z
            .object({
            sheetId: types_shared_1.z.string(),
        })
            .merge(types_shared_1.DatasourceMetadata.pick({
            workflowId: true,
            name: true,
            description: true,
        })),
    }),
    response: types_shared_1.z.object({
        meta: types_shared_1.DatasourceMetadata,
    }),
};
