import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { VncScreenHandle } from 'react-vnc';
import { VncScreen } from 'react-vnc';

import type { SessionInfo } from 'selenium-gql';
import { SeleniumGQLClient } from 'selenium-gql';
import { handleException } from 'sentry-browser-shared';
import { useGetSeleniumAddress } from '../hooks';

interface LiveViewProps {
  sessionId: string;
  userId: string;
}

function DefaultView({ text }: { text: string }) {
  return (
    <Box className="!min-h-[30rem] flex items-center justify-center">
      <Typography>{text}</Typography>
    </Box>
  );
}

// RFB type definition that doesn't conflict with react-vnc
interface RFBType {
  clipboardPasteFrom?: (text: string) => void;
  sendKey: (keysym: number, code: string, down?: boolean) => void;
  focus?: () => void;
}

// Define custom interface for the VncScreenHandle with the methods we need
interface VncScreenHandleCustom {
  connect: () => void;
  disconnect: () => void;
  connected: boolean;
  rfb: RFBType | null;
  sendCredentials: (credentials: unknown) => void;
  sendKey: (keysym: number, code: string, down?: boolean) => void;
  sendCtrlAltDel: () => void;
  focus: () => void;
  blur: () => void;
  clipboardPaste: (text: string) => void;
  clipboardPasteFrom?: (text: string) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  eventListeners: Record<string, unknown>;
  machineShutdown: () => void;
  machineReboot: () => void;
  machineReset: () => void;
}

export function LiveView({ sessionId, userId }: LiveViewProps) {
  const vncRef = useRef<VncScreenHandleCustom | null>(null);
  const [vncUrl, setVncUrl] = useState<string>();
  const [connectionReady, setConnectionReady] = useState<boolean>(false);

  // Store the RFB object for direct access
  const [rfbObject, setRfbObject] = useState<RFBType | null>(null);

  // Store the current VNC clipboard content
  const [vncClipboardContent, setVncClipboardContent] = useState<string>('');

  const [fetchingSession, setFetchingSession] = useState<boolean>(true);
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>();

  const { data: seleniumAddress, loading } = useGetSeleniumAddress(userId);

  const gqlClient = useMemo(() => {
    if (loading) {
      return null;
    } else if (!seleniumAddress) {
      handleException(new Error(), {
        name: 'Selenium Address not found in LiveView',
        source: 'Execution/LiveView',
        extra: {
          userId,
          seleniumAddress,
          sessionId,
        },
      });
      return null;
    }
    return new SeleniumGQLClient(`${seleniumAddress}/graphql`);
  }, [seleniumAddress, loading, userId, sessionId]);

  useEffect(() => {
    if (!gqlClient) {
      return;
    }
    try {
      setFetchingSession(true);
      const fetchSessionInfo = async () => {
        const resp = await gqlClient.querySessionInfo(sessionId);
        setSessionInfo(resp.session);
      };
      void fetchSessionInfo();
    } catch (e) {
      handleException(e, {
        name: 'Session info fetch failed in LiveView',
        source: 'Execution/LiveView',
        extra: {
          sessionId,
        },
      });
    } finally {
      setFetchingSession(false);
    }
  }, [gqlClient, sessionId]);

  useEffect(() => {
    if (!sessionInfo) {
      return;
    }
    const capabilities = JSON.parse(sessionInfo.capabilities) as {
      'se:vnc': string;
    };
    const vnc = capabilities['se:vnc'];
    if (!vnc) {
      throw new Error('Vnc not found');
    }
    if (!seleniumAddress) {
      handleException(new Error(), {
        name: 'Selenium Address not found in LiveView',
        source: 'Execution/LiveView',
        extra: {
          sessionId,
          seleniumAddress,
          userId,
          sessionInfo,
          loading,
        },
      });
      return;
    }
    const url = new URL(seleniumAddress);
    const newVncUrl = new URL(vnc);
    url.pathname = newVncUrl.pathname;
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    setVncUrl(url.href);
  }, [seleniumAddress, sessionId, sessionInfo, userId, loading]);

  // Get the RFB object directly
  const getRfbObject = useCallback((): RFBType | null => {
    if (rfbObject) {
      return rfbObject;
    }

    if (vncRef.current?.rfb) {
      return vncRef.current.rfb;
    }

    return null;
  }, [rfbObject]);

  // Handle VNC connection event and store RFB object
  const handleVncConnected = (rfb: unknown) => {
    setRfbObject(rfb as RFBType);
    setConnectionReady(true);
  };

  // Handle VNC disconnection
  const handleVncDisconnected = () => {
    setConnectionReady(false);
  };

  // Find the VNC canvas element
  const findVncCanvas = useCallback((): HTMLCanvasElement | null => {
    // Find the canvas directly on the page
    // Previously we tried to use containerRef first, but logs showed
    // that approach consistently failed, so we're using the reliable method
    const allCanvases = document.querySelectorAll('canvas');
    if (allCanvases.length === 1) {
      return allCanvases[0];
    }

    // Fallback to ref approach only if we have multiple canvases
    if (allCanvases.length > 1) {
      const container = vncRef.current?.containerRef?.current;
      const canvas = container?.querySelector('canvas');
      if (canvas) {
        return canvas;
      }
    }

    // If we get here, no canvas was found - report this critical error
    handleException(new Error('Could not find VNC canvas element'), {
      name: 'VNC Canvas Not Found',
      source: 'Execution/LiveView',
      extra: {
        numCanvases: allCanvases.length,
        hasRef: Boolean(vncRef.current?.containerRef?.current),
      },
    });

    return null;
  }, [vncRef]);

  // Focus function - wrapped in useCallback
  const focusVnc = useCallback(() => {
    const canvasElement = findVncCanvas();
    if (!canvasElement) {
      // Canvas not found when trying to focus - this is a critical UX issue
      return;
    }

    // Ensure canvas can receive focus
    if (canvasElement.tabIndex === -1 || isNaN(canvasElement.tabIndex)) {
      canvasElement.tabIndex = 0;
    }

    // Focus the canvas
    canvasElement.focus();

    // Also try the preventScroll focus and RFB focus after a delay
    setTimeout(() => {
      try {
        canvasElement.focus({ preventScroll: true });

        // Try to focus the RFB object itself if possible
        const rfb = getRfbObject();
        rfb?.focus?.();
      } catch (e) {
        handleException(e, {
          name: 'Error focusing VNC canvas',
          source: 'Execution/LiveView',
        });
      }
    }, 50);
  }, [findVncCanvas, getRfbObject]);

  // Text sending function
  const sendTextToVnc = useCallback(
    (text: string) => {
      if (!text) return;

      // Get the RFB object
      const rfb = getRfbObject();
      if (!rfb) {
        handleException(new Error('Could not access VNC connection'), {
          name: 'VNC Paste Error',
          source: 'Execution/LiveView',
        });
        return;
      }

      try {
        // Use the direct clipboardPasteFrom method
        if (rfb.clipboardPasteFrom) {
          rfb.clipboardPasteFrom(text);

          // Send Ctrl+V key sequence to trigger paste in the VNC session
          setTimeout(() => {
            try {
              // Constants for VNC key codes
              const KEYSYM_CTRL = 0xffe3; // Left control key
              const KEYSYM_V = 118; // 'v' key

              // Send the key combination with sufficient delays
              rfb.sendKey(KEYSYM_CTRL, 'ControlLeft', true); // Press Ctrl
              setTimeout(() => {
                rfb.sendKey(KEYSYM_V, 'KeyV', true); // Press V
                setTimeout(() => {
                  rfb.sendKey(KEYSYM_V, 'KeyV', false); // Release V
                  rfb.sendKey(KEYSYM_CTRL, 'ControlLeft', false); // Release Ctrl
                }, 100);
              }, 100);
            } catch (err) {
              handleException(err, {
                name: 'Error sending key sequence to VNC',
                source: 'Execution/LiveView',
              });
            }
          }, 200); // Longer delay before trying to paste
        } else {
          handleException(new Error('VNC clipboard paste not supported'), {
            name: 'VNC Paste Not Supported',
            source: 'Execution/LiveView',
          });
        }
      } catch (err) {
        handleException(err, {
          name: 'Error in clipboard paste method',
          source: 'Execution/LiveView',
        });
      }
    },
    [getRfbObject],
  );

  // Clipboard paste function - wrapped in useCallback
  const handlePasteFromClipboard = useCallback(async () => {
    try {
      // Ensure connection is ready
      if (!connectionReady) {
        handleException(new Error('VNC connection not ready'), {
          name: 'VNC Not Ready',
          source: 'Execution/LiveView',
        });
        return;
      }

      // Check if clipboard API is available
      const hasClipboardAPI = Boolean(navigator.clipboard);
      if (!hasClipboardAPI) {
        handleException(
          new Error('Clipboard API not available in this browser'),
          {
            name: 'Clipboard API Unavailable',
            source: 'Execution/LiveView',
          },
        );
        return;
      }

      // Get text from clipboard
      const textFromClipboard = await navigator.clipboard.readText();

      // Verify we have text to paste
      if (!textFromClipboard) {
        handleException(new Error('No text content found in clipboard'), {
          name: 'Empty Clipboard',
          source: 'Execution/LiveView',
        });
        return;
      }

      // Focus VNC and send text
      focusVnc();
      sendTextToVnc(textFromClipboard);
    } catch (err) {
      handleException(err, {
        name: 'Clipboard paste error in LiveView',
        source: 'Execution/LiveView',
      });
    }
  }, [connectionReady, focusVnc, sendTextToVnc]);

  // Handle VNC clipboard event - store content in state but don't copy to system clipboard yet
  const handleVncClipboard = useCallback((e?: { detail: { text: string } }) => {
    try {
      // Get the text from the VNC clipboard event
      if (!e) return;
      const text = e.detail.text;
      if (!text) return;

      // Store the clipboard content from VNC
      setVncClipboardContent(text);
    } catch (error: unknown) {
      handleException(error, {
        name: 'Error handling VNC clipboard event',
        source: 'Execution/LiveView',
      });
    }
  }, []);

  // Copy VNC clipboard to system clipboard when explicitly requested
  const copyVncToSystemClipboard = useCallback(() => {
    if (!vncClipboardContent) return;

    try {
      void navigator.clipboard
        .writeText(vncClipboardContent)
        .catch((error: unknown) => {
          handleException(error, {
            name: 'Failed to copy from VNC to system clipboard',
            source: 'Execution/LiveView',
            extra: {
              clipboardTextLength: vncClipboardContent.length,
            },
          });
        });
    } catch (error: unknown) {
      handleException(error, {
        name: 'Error copying VNC content to system clipboard',
        source: 'Execution/LiveView',
      });
    }
  }, [vncClipboardContent]);

  // Keyboard shortcut handler
  useEffect(() => {
    if (!vncUrl) return;

    // Handle keyboard shortcuts globally
    const handleKeyDown = (e: KeyboardEvent) => {
      // Skip handling keyboard shortcuts if the target is an input field or within a modal
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        (e.target instanceof Element &&
          (e.target.closest('[role="dialog"]') ||
            e.target.closest('.MuiDialog-root') ||
            e.target.closest('.MuiModal-root')))
      ) {
        return;
      }

      // Check for Ctrl+V (or Cmd+V on Mac)
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        // Focus the VNC canvas first
        focusVnc();

        // Initiate paste from clipboard
        void handlePasteFromClipboard();

        // Prevent default to avoid conflicts
        e.preventDefault();
        e.stopPropagation();
      }

      // Check for Ctrl+C (or Cmd+C on Mac) to copy from VNC to system clipboard
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        // Only copy to system clipboard if focus is on VNC
        const canvasElement = findVncCanvas();
        if (canvasElement && document.activeElement === canvasElement) {
          copyVncToSystemClipboard();
        }
      }
    };

    // Add the single event listener at window level
    window.addEventListener('keydown', handleKeyDown, true);

    // Add click handler for canvas to ensure it's focused
    const canvas = findVncCanvas();
    if (canvas) {
      const handleCanvasClick = () => {
        focusVnc();
      };

      canvas.addEventListener('click', handleCanvasClick);

      // Clean up on unmount
      return () => {
        window.removeEventListener('keydown', handleKeyDown, true);
        canvas.removeEventListener('click', handleCanvasClick);
      };
    } else if (connectionReady) {
      // Only report as error if we're connected but can't find the canvas
      // This could impact usability as clicks won't focus the VNC
      handleException(
        new Error('Connected to VNC but canvas not found for click handler'),
        {
          name: 'VNC Canvas Click Handler Failed',
          source: 'Execution/LiveView',
        },
      );
    }

    // Clean up if no canvas was found
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [
    vncUrl,
    connectionReady,
    focusVnc,
    handlePasteFromClipboard,
    findVncCanvas,
    copyVncToSystemClipboard,
  ]);

  if (fetchingSession) {
    return <DefaultView text="Loading..." />;
  }

  if (!sessionInfo) {
    return <DefaultView text="No active Session found" />;
  }

  return vncUrl ? (
    <div className="flex flex-col h-full relative">
      {/* Connection status indicator */}
      {!connectionReady && (
        <div className="absolute top-2 right-2 z-10">
          <Box className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 rounded shadow-md text-sm">
            <Typography variant="body2">Connecting to VNC...</Typography>
          </Box>
        </div>
      )}

      {/* VNC screen */}
      <VncScreen
        url={vncUrl}
        ref={vncRef as React.Ref<VncScreenHandle>}
        style={{
          width: '100%',
          height: '100%',
        }}
        scaleViewport
        background="transparent"
        onConnect={handleVncConnected}
        onDisconnect={handleVncDisconnected}
        onClipboard={handleVncClipboard}
      />
    </div>
  ) : (
    <DefaultView text="Loading..." />
  );
}
