import { DefaultOperators } from '../operators';
import { enumerateSchemaFields } from '../zod-utilities';
import { createFieldConfig } from './field-config';
/**
 * Creates field configurations from a Zod schema
 *
 * @param schema - The Zod schema to convert
 * @param options - Configuration options
 * @returns Record of field configurations typed to match the schema
 */
export function fieldsFromSchema(schema, options) {
    var _a, _b;
    if (options === void 0) { options = {}; }
    var result = {};
    // Process each field in the schema
    for (var _i = 0, _c = enumerateSchemaFields(schema); _i < _c.length; _i++) {
        var _d = _c[_i], fieldKey = _d[0], fieldZodSchema = _d[1];
        var fieldName = fieldKey.toString();
        // Use configured options for this field
        var fieldOptions = options[fieldKey];
        var operators = (_a = fieldOptions === null || fieldOptions === void 0 ? void 0 : fieldOptions.operators) !== null && _a !== void 0 ? _a : DefaultOperators(fieldZodSchema);
        var label = (_b = fieldOptions === null || fieldOptions === void 0 ? void 0 : fieldOptions.label) !== null && _b !== void 0 ? _b : fieldName;
        // Create the specified field config
        result[fieldKey] = createFieldConfig(fieldName, fieldZodSchema, {
            label: label,
            operators: operators,
        });
    }
    return result;
}
