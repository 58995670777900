"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardWorkflowNotificationConfigTagSchema = exports.StandardWorkflowNotificationConfigTag = void 0;
var zod_1 = require("zod");
var StandardWorkflowNotificationConfigTag;
(function (StandardWorkflowNotificationConfigTag) {
    // MARK: User tag
    // The config with the tag = `user` is configurable by the user on the dashboard
    StandardWorkflowNotificationConfigTag["User"] = "user";
    // MARK: Admin tags (not user configurable)
    // `user_non_configurable` is used for a non-user-configurable config
    // which contains non-user-configurable events (workflow improvement detected, workflow imported, etc)
    StandardWorkflowNotificationConfigTag["UserNonConfigurable"] = "user_non_configurable";
    // `user_shadow` is used for a non-user-configurable _config_
    // which contains _user-configurable_ events (execution failed, execution success)
    StandardWorkflowNotificationConfigTag["UserShadow"] = "user_shadow";
})(StandardWorkflowNotificationConfigTag || (exports.StandardWorkflowNotificationConfigTag = StandardWorkflowNotificationConfigTag = {}));
exports.StandardWorkflowNotificationConfigTagSchema = zod_1.z.nativeEnum(StandardWorkflowNotificationConfigTag);
