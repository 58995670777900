import Skeleton from '@mui/material/Skeleton';
import { Button, Tooltip } from 'ui-kit';
import { useGetOrchestrationMetrics } from '../hooks';

export default function OrchestrationMetrics() {
  const { data, isLoading } = useGetOrchestrationMetrics();

  return (
    <div className="flex space-x-4 w-full mt-7">
      <div className="flex flex-col space-y-3 flex-1 px-6 py-4 rounded-lg border">
        <div className="flex items-center justify-between">
          <p className="text-base font-medium">Virtual machine cluster</p>
          <Tooltip
            arrow
            title="This is the current number of machines on your plan. Contact our sales team if more machines are needed!"
          >
            <Button variant="text" color="secondary">
              Upgrade
            </Button>
          </Tooltip>
        </div>
        {isLoading ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: '2rem' }}
            className="w-full"
            height={36}
          />
        ) : (
          <p className="text-3xl font-medium">{data.maxConcurrentExecutions}</p>
        )}
      </div>
      <div className="flex flex-1 justify-between items-center px-6 py-4 rounded-lg border">
        <div className="flex flex-col space-y-3">
          <p className="text-base font-medium">Queued Executions</p>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '2rem' }}
              className="w-full"
              height={36}
            />
          ) : (
            <p className="text-3xl font-medium">{data.queuedExecutionsCount}</p>
          )}
        </div>
      </div>
      <div className="flex flex-1 justify-between items-center px-6 py-4 rounded-lg border">
        <div className="flex flex-col space-y-3">
          <p className="text-base font-medium">Running Executions</p>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '2rem' }}
              className="w-full"
              height={36}
            />
          ) : (
            <p className="text-3xl font-medium">
              {data.runningExecutionsCount}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
