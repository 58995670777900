"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listAllExecutionsSchema = void 0;
const types_shared_1 = require("types-shared");
exports.listAllExecutionsSchema = {
    request: types_shared_1.z.object({
        query: types_shared_1.z.object({
            limit: types_shared_1.z.coerce.number().int().min(1).max(1000).default(100),
            fromTime: types_shared_1.z
                .string()
                .transform((val) => decodeURIComponent(val))
                .pipe(types_shared_1.z.string().datetime({ offset: true }))
                .optional(),
            toTime: types_shared_1.z
                .string()
                .transform((val) => decodeURIComponent(val))
                .pipe(types_shared_1.z.string().datetime({ offset: true }))
                .optional(),
            previousExecutionCursor: types_shared_1.z.string().optional(),
            workflowId: types_shared_1.z.string().optional(),
            statuses: types_shared_1.z
                .preprocess((val) => (typeof val === 'string' ? val.split(',') : val), types_shared_1.z.array(types_shared_1.ExecutionStatus))
                .optional(),
        }),
    }),
    response: types_shared_1.z.object({
        executions: types_shared_1.z.array(types_shared_1.z.object({
            id: types_shared_1.uuidSchema,
            createdAt: types_shared_1.z.string().datetime({ offset: true }),
            workflowId: types_shared_1.uuidSchema,
            status: types_shared_1.ExecutionStatus,
        })),
        lastExecutionCursor: types_shared_1.z.string().optional(),
    }),
};
