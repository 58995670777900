import { type ParagonIntegrationEnum } from 'dashboard-shared';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useMemo } from 'react';
import { FeatureFlag } from '../../utils/constants';

/**
 * Hook that provides integration configuration from the PostHog feature flag.
 * Integration configurations are memoized to prevent unnecessary recalculations.
 * @returns Object containing enabled and visible integration sets
 */
export function useIntegrationConfig() {
  const integrationWhitelistPayload = useFeatureFlagPayload(
    FeatureFlag.IntegrationWhitelist,
  ) as
    | {
        enabledIntegrations: string[];
        visibleIntegrations: string[];
      }
    | undefined;

  return useMemo(() => {
    // Initialize empty sets
    const enabledIntegrations = new Set<ParagonIntegrationEnum>();
    const visibleIntegrations = new Set<ParagonIntegrationEnum>();

    // Add integrations from the feature flag payload if available
    if (integrationWhitelistPayload?.enabledIntegrations) {
      integrationWhitelistPayload.enabledIntegrations.forEach((integration) => {
        enabledIntegrations.add(integration as ParagonIntegrationEnum);
      });
    }

    if (integrationWhitelistPayload?.visibleIntegrations) {
      integrationWhitelistPayload.visibleIntegrations.forEach((integration) => {
        visibleIntegrations.add(integration as ParagonIntegrationEnum);
      });
    }

    return {
      ENABLED_INTEGRATIONS: enabledIntegrations,
      VISIBLE_INTEGRATIONS: visibleIntegrations,
    };
  }, [integrationWhitelistPayload]);
}
