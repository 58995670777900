/**
 * Creates a field configuration for a zod type
 */
export function createFieldConfig(name, schema, options) {
    return {
        name: name,
        schema: schema,
        label: options.label,
        operators: options.operators,
    };
}
export function enumerateFieldConfigs(fieldConfigsForSchema) {
    return Object.entries(fieldConfigsForSchema);
}
