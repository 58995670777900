"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXCEL_FILETAB_SHORCUTS = exports.ExcelFileTabShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../../serializer");
var ExcelFileTabShortcuts;
(function (ExcelFileTabShortcuts) {
    ExcelFileTabShortcuts["ExportAsCSV"] = "Export As CSV";
    ExcelFileTabShortcuts["ExportAsPDF"] = "Export As PDF";
    ExcelFileTabShortcuts["ExportAsTXT"] = "Export As TXT";
})(ExcelFileTabShortcuts || (exports.ExcelFileTabShortcuts = ExcelFileTabShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.ExcelFileTab);
const SHORTCUT_MAPPING = {
    [ExcelFileTabShortcuts.ExportAsCSV]: [[types_shared_1.ALT, 'f'], ['e'], ['c', 'd']],
    [ExcelFileTabShortcuts.ExportAsPDF]: [[types_shared_1.ALT, 'f'], ['e'], ['p', 'a']],
    [ExcelFileTabShortcuts.ExportAsTXT]: [[types_shared_1.ALT, 'f'], ['e'], ['c', 'x']],
};
exports.EXCEL_FILETAB_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
