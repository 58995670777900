import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  // eslint disable next line camelcase
  Notifications_Enum_Platform_Enum as NotificationsEnumPlatformEnum,
  // eslint disable next line camelcase
  type Notifications_Workflow_Configuration_Events_Insert_Input as NotificationsWorkflowConfigurationEventsInsertInput,
} from 'hasura-gql';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useEffect, useMemo, useState } from 'react';
import { handleException } from 'sentry-browser-shared';
import { SlackPlatformMetadataSchema } from 'types-shared';
import {
  AlertVariant,
  Button,
  FormControlLabel,
  Input,
  MailIcon,
  Radio,
  RadioGroup,
  Tooltip,
  notify,
} from 'ui-kit';
import { FeatureFlag } from '../../../utils/constants';
import { useFeatureFlag } from '../../../utils/helper';
import {
  NotificationTabIdEnum,
  NotifyAboutEnum,
  convertBackendPayloadToNotificationData,
  convertNotificationDataToBackendPayload,
  convertNotificationDataToUpdatePayload,
  notificationPayloadHasValidChanges,
  type NotificationConfig,
  type NotificationData,
} from '../helper';
import {
  useCreateWorkflowNotification,
  useDeleteNotification,
  useGetSlackChannelName,
  useGetTeamSlackChannelId,
  useGetWorkflowNotifications,
  useUpdateNotification,
} from '../useNotificationData.gql';
import EmailHeader from './EmailHeader';
import SlackHeader from './SlackHeader';
import SwitchRow from './SwitchRow';

const defaultNotificationData: NotificationData = {
  slackChannel: '',
  notificationEmailRecipients: [],
  notifyAbout: NotifyAboutEnum.Custom,
  failedExecutions: false,
  improvementDetected: false,
  successfulExecutions: false,
  workflowExecutionCompleted: false,
};

const URGENT_NOTIFICATION_STATE = {
  failedExecutions: true,
  improvementDetected: true,
  successfulExecutions: false,
} as const;

export default function Notifications({
  workflowId,
  ownerId,
}: {
  workflowId: string;
  ownerId: string;
}) {
  const [saveAttempted, setSaveAttempted] = useState(false);
  const { channelId, loading: channelIdLoading } =
    useGetTeamSlackChannelId(workflowId);

  const {
    notifications,
    loading: fetchNotificationsLoading,
    refetchNotifications,
  } = useGetWorkflowNotifications(workflowId);

  const emailNotificationsEnabled = useFeatureFlag(
    FeatureFlag.NotificationsSettingsEmail,
  );

  const [selectedTab, setSelectedTab] = useState<NotificationsEnumPlatformEnum>(
    NotificationsEnumPlatformEnum.Slack,
  );
  const activeNotification = useMemo(() => {
    return notifications.find(
      (notification) =>
        !notification.deletedAt &&
        notification.platformMetadata.platform === selectedTab,
    );
  }, [notifications, selectedTab]);

  const platformMetadata = activeNotification?.platformMetadata;
  const isSlack =
    platformMetadata?.platform === NotificationsEnumPlatformEnum.Slack;
  const conversationId = isSlack ? platformMetadata.conversationId : channelId;

  const { channelName, loading: channelNameLoading } = useGetSlackChannelName(
    conversationId,
    isSlack,
  );

  const { createNotificationConfig, loading: notificationCreationLoading } =
    useCreateWorkflowNotification();

  const { deleteNotificationConfig, loading: deleteNotificationLoading } =
    useDeleteNotification();
  const {
    updateNotificationConfig,

    loading: updateNotificationLoading,
  } = useUpdateNotification();

  const isLoading =
    fetchNotificationsLoading ||
    notificationCreationLoading ||
    updateNotificationLoading;
  const [notificationData, setNotificationData] = useState<NotificationData>(
    defaultNotificationData,
  );

  const channelLoading =
    channelIdLoading || channelNameLoading || deleteNotificationLoading;

  const isUrgentMode = useMemo(() => {
    return isEqual(
      pick(notificationData, Object.keys(URGENT_NOTIFICATION_STATE)),
      URGENT_NOTIFICATION_STATE,
    );
  }, [notificationData]);

  const isWorkflowExecutionCompleted = useMemo(() => {
    return (
      notificationData.failedExecutions || notificationData.successfulExecutions
    );
  }, [
    notificationData.failedExecutions,
    notificationData.successfulExecutions,
  ]);

  const isActive = useMemo(() => {
    return [
      // notificationData.improvementDetected,
      notificationData.successfulExecutions,
      notificationData.failedExecutions,
    ].some((v) => v);
  }, [
    notificationData.successfulExecutions,
    notificationData.failedExecutions,
  ]);

  const [rawEmailInput, setRawEmailInput] = useState('');

  const recipientsText = useMemo(() => {
    if (isSlack) {
      return 'Enter the email addresses of the people to tag in Slack notifications. You can add multiple addresses, separated by commas, to ensure they are notified directly.';
    }
    return 'Enter the email addresses of the people to receive email notifications. You can add multiple addresses, separated by commas, to ensure they are notified directly.';
  }, [isSlack]);

  const emailsInvalid = useMemo(() => {
    const emailValidation =
      SlackPlatformMetadataSchema.shape.userEmails.element;
    return (
      (notificationData.notificationEmailRecipients.length === 0 &&
        !isSlack &&
        isActive) ||
      notificationData.notificationEmailRecipients.some(
        (email) => !emailValidation.safeParse(email).success,
      )
    );
  }, [notificationData.notificationEmailRecipients, isSlack, isActive]);

  const showEmailError = useMemo(() => {
    return emailsInvalid && saveAttempted;
  }, [emailsInvalid, saveAttempted]);

  const updateNotificationData = (
    key: keyof NotificationData | 'slackActive' | 'emailActive',
    value: string | boolean,
  ) => {
    switch (key) {
      case 'emailActive':
      case 'slackActive':
        setNotificationData((prev) => ({
          ...prev,
          failedExecutions: Boolean(value),
          improvementDetected: Boolean(value),
          successfulExecutions: Boolean(value),
        }));
        break;
      case 'notifyAbout':
        // Set to urgent default values
        if (value === NotifyAboutEnum.Urgent) {
          setNotificationData((prev) => ({
            ...prev,
            ...URGENT_NOTIFICATION_STATE,
          }));
        } else if (value === NotifyAboutEnum.Custom) {
          // Otherwise, custom sets to all true
          setNotificationData((prev) => ({
            ...prev,
            failedExecutions: true,
            improvementDetected: true,
            successfulExecutions: true,
          }));
        }
        break;

      // If the user enables/disables the workflowExecutionCompleted,
      // we correspondingly enable/disable the subtype-notifications: failedExecutions and successfulExecutions
      case 'workflowExecutionCompleted': {
        const newValue = Boolean(value);
        setNotificationData((prev) => ({
          ...prev,
          failedExecutions: newValue,
          successfulExecutions: newValue,
        }));
        break;
      }

      case 'notificationEmailRecipients':
        if (typeof value === 'string') {
          setRawEmailInput(value);
          // Also update the actual data with processed emails
          const emails = value
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email.length > 0);
          setNotificationData((prev) => ({
            ...prev,
            notificationEmailRecipients: emails,
          }));
        }
        break;

      default:
        setNotificationData((prev) => ({ ...prev, [key]: value }));
    }
  };

  const notifyUserNoChanges = () => {
    notify({
      message: 'No changes detected',
      variant: AlertVariant.WARNING,
    });
  };

  const onUpdateNotificationData = async () => {
    try {
      if (!activeNotification) {
        return;
      }
      const configId = activeNotification.events[0]?.configurationId;
      if (!configId) {
        return;
      }

      const notificationPayload = convertNotificationDataToUpdatePayload({
        workflowId,
        ownerId,
        notificationData,
        notificationId: activeNotification.id,
        configurationId: configId,
        platform: selectedTab,
      });

      const hasValidChanges = notificationPayloadHasValidChanges(
        notificationPayload,
        activeNotification as NotificationConfig,
      );

      if (!hasValidChanges) {
        notifyUserNoChanges();
        return;
      }

      await updateNotificationConfig(notificationPayload);
      notify({
        message: 'Notification settings updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      handleException(error, {
        name: 'Failed to update notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const onDeleteNotificationData = async () => {
    try {
      if (!activeNotification) {
        notifyUserNoChanges();
        return;
      }
      const notificationPayload = convertNotificationDataToUpdatePayload({
        workflowId,
        ownerId,
        notificationData,
        notificationId: activeNotification.id,
        configurationId: activeNotification.events[0]?.configurationId,
        platform: selectedTab,
      });

      await deleteNotificationConfig({
        ...notificationPayload,
        platform: selectedTab,
        events: (Array.isArray(notificationPayload.events)
          ? notificationPayload.events.map((e) => ({ event: e.event }))
          : []) as NotificationsWorkflowConfigurationEventsInsertInput[],
      });

      await refetchNotifications();
      setNotificationData(defaultNotificationData);
      notify({
        message: 'Notification settings deleted successfully',
        variant: AlertVariant.SUCCESS,
      });
      setNotificationData(defaultNotificationData);
    } catch (error) {
      handleException(error, {
        name: 'Failed to delete notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const onSave = async () => {
    try {
      const notificationPayload = convertNotificationDataToBackendPayload({
        workflowId,
        notificationData,
        ownerId,
        platform: selectedTab,
      });

      await createNotificationConfig(notificationPayload);

      notify({
        message: 'Notification settings saved successfully',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      handleException(error, {
        name: 'Failed to save notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const handleSaveChanges = async () => {
    const savingWithNoData = !isActive && !activeNotification;

    if (savingWithNoData) {
      notify({
        message: 'Please add details for notification before saving.',
        variant: AlertVariant.WARNING,
      });
      return;
    }

    setSaveAttempted(true);

    const { failedExecutions, successfulExecutions } = notificationData;
    const _isActive = failedExecutions || successfulExecutions;

    const isDelete = !_isActive;

    // Only fail if the emails are invalid and the user is saving a new config or updating an existing one
    if (emailsInvalid && _isActive) {
      notify({
        message: 'Please fix the invalid email addresses before saving.',
        variant: AlertVariant.ERROR,
      });
      return;
    }

    if (emailsInvalid || isLoading) {
      notify({
        message: isLoading
          ? 'Please wait for the notification settings to be saved.'
          : 'Please fix the invalid email addresses before saving.',
        variant: AlertVariant.WARNING,
      });
      return;
    }

    if (isDelete) {
      await onDeleteNotificationData();
    } else if (activeNotification?.id) {
      await onUpdateNotificationData();
    } else {
      await onSave();
    }

    setSaveAttempted(false);
  };

  const handleCancel = () => {
    const newDefault = activeNotification
      ? convertBackendPayloadToNotificationData(activeNotification)
      : defaultNotificationData;
    setNotificationData(newDefault);
    setRawEmailInput(newDefault.notificationEmailRecipients.join(', '));
  };

  useEffect(() => {
    if (!notificationData.slackChannel && channelId) {
      updateNotificationData('slackChannel', channelId);
    }
  }, [channelId, notificationData.slackChannel]);

  useEffect(() => {
    if (notifications.length > 0) {
      const selectedNotification = notifications.find(
        (notification) =>
          !notification.deletedAt &&
          notification.platformMetadata.platform === selectedTab,
      );
      if (selectedNotification) {
        const newData =
          convertBackendPayloadToNotificationData(selectedNotification);
        setNotificationData(newData);
        setRawEmailInput(newData.notificationEmailRecipients.join(', '));
      } else {
        setNotificationData(defaultNotificationData);
        setRawEmailInput(
          defaultNotificationData.notificationEmailRecipients.join(', '),
        );
      }
    }
  }, [notifications, selectedTab]);

  return (
    <div className="flex py-8 space-x-10 gap-6">
      <div>
        <Box sx={{ display: 'flex' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue as NotificationsEnumPlatformEnum);
            }}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '12px',
                minHeight: '48px',
                textAlign: 'left',
                paddingRight: '6rem',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
          >
            <Tab
              icon={
                <img src="/slack_icon.png" alt="Slack" width={24} height={24} />
              }
              label="SLACK"
              iconPosition="start"
              value={NotificationTabIdEnum.Slack}
            />

            <Tab
              icon={<MailIcon />}
              label="EMAIL"
              iconPosition="start"
              value={NotificationTabIdEnum.Email}
              disabled={!emailNotificationsEnabled}
            />

            <Tooltip
              arrow
              placement="right"
              title="Customizable notifications here coming soon! Contact sales if you are interested."
            >
              <Tab
                icon={
                  <img
                    src="/teams_icon.png"
                    alt="teams"
                    width={24}
                    height={24}
                  />
                }
                label="TEAMS"
                iconPosition="start"
                value={NotificationTabIdEnum.Teams}
                disabled
              />
            </Tooltip>
          </Tabs>
        </Box>
      </div>
      <div className="w-full max-w-[800px] flex flex-col gap-5">
        {selectedTab === NotificationsEnumPlatformEnum.Slack ? (
          <SlackHeader
            slackActive={isActive}
            channelLoading={channelLoading}
            channelName={channelName}
            updateNotificationData={updateNotificationData}
          />
        ) : null}
        {selectedTab === NotificationsEnumPlatformEnum.Email ? (
          <EmailHeader
            emailActive={isActive}
            updateNotificationData={updateNotificationData}
          />
        ) : null}

        <div
          className="flex flex-col gap-5"
          style={{ opacity: isActive ? 1 : 0.5 }}
        >
          <div className="pt-2">
            <p className="text-sm text-gray-500 !mt-2 mb-2">{recipientsText}</p>
            <Input
              placeholder="Enter email addresses (e.g., user@sola.ai, user2@sola.ai)"
              label="Notification email recipients"
              floatingLabel
              showErrorText
              error={showEmailError}
              errorText="Some of the emails you entered are invalid. Please check and try again."
              value={rawEmailInput}
              onChange={(e) => {
                updateNotificationData('notificationEmailRecipients', e);
              }}
              disabled={!isActive}
            />
          </div>

          <h2 className="font-medium text-xl text-info-dark mt-5">
            Notify about
          </h2>
          <RadioGroup
            className="mt-2"
            name="radio-buttons-group"
            onChange={(_, val) => {
              updateNotificationData('notifyAbout', val as NotifyAboutEnum);
            }}
            value={
              isUrgentMode ? NotifyAboutEnum.Urgent : NotifyAboutEnum.Custom
            }
          >
            <FormControlLabel
              control={<Radio color="secondary" disabled={!isActive} />}
              label={
                <span className="text-info-dark">
                  <span className="font-bold">Only Urgent:</span> Receive
                  notifications only for critical events.
                </span>
              }
              value={NotifyAboutEnum.Urgent}
            />
            <FormControlLabel
              control={<Radio color="secondary" disabled={!isActive} />}
              label={
                <span className="text-info-dark">
                  <span className="font-bold">Customize Notifications:</span>{' '}
                  Choose specific events to be notified about.
                </span>
              }
              value={NotifyAboutEnum.Custom}
            />
          </RadioGroup>

          <div>
            <SwitchRow
              checked={isWorkflowExecutionCompleted}
              onChange={(newChecked) => {
                updateNotificationData(
                  'workflowExecutionCompleted',
                  newChecked,
                );
              }}
            >
              <h2 className="font-medium text-lg text-info-dark">
                Workflow execution completed
              </h2>
              <p className="text-sm text-gray-500 !mt-2">
                Receive a notification for every completed workflow execution.
              </p>
            </SwitchRow>

            <SwitchRow
              checked={notificationData.failedExecutions}
              onChange={(newChecked) => {
                updateNotificationData('failedExecutions', newChecked);
              }}
            >
              <h2 className=" text-info-dark pl-5">Failed executions</h2>
            </SwitchRow>

            <SwitchRow
              checked={notificationData.successfulExecutions}
              onChange={(newChecked) => {
                updateNotificationData('successfulExecutions', newChecked);
              }}
            >
              <h2 className=" text-info-dark pl-5">Successful executions</h2>
            </SwitchRow>
          </div>

          {/* <SwitchRow
          checked={notificationData.improvementDetected}
          onChange={(newChecked) => {
            updateNotificationData('improvementDetected', newChecked);
          }}
        >
          <h2 className="font-medium text-lg text-info-dark">
            Improvement detected
          </h2>
          <p className="text-sm text-gray-500 !mt-2">
            Receive a notification when the bot has implemented or detected a
            workflow improvement for your approval.
          </p>
        </SwitchRow> */}
        </div>

        <div className="flex items-center space-x-4">
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSaveChanges}
            loading={isLoading}
          >
            Save Changes
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
