import { MOCK_USER_ID } from 'types-shared/constants';
import { isAdmin } from '../utils/env';
import { useAuth } from '../utils/helper';
import { useTeamDetails } from './useTeamDetails.gql';
import { useEffect, useMemo } from 'react';
import { handleException } from 'sentry-browser-shared';

// adminUserId is the userId to be used for admin users
// if not provided, MOCK_USER_ID will be used
export default function useUserTeamDetails(adminUserId = MOCK_USER_ID) {
  const { user } = useAuth();
  const userId = useMemo(() => {
    if (isAdmin) {
      return adminUserId;
    }
    return user?.sub;
  }, [user, adminUserId]);
  const { data: teamDetails, loading: teamDetailsLoading } =
    useTeamDetails(userId);

  useEffect(() => {
    if (user && !teamDetails && !teamDetailsLoading) {
      handleException(new Error('Team details not found'), {
        name: 'Team details not found',
        source: 'useUserTeamDetails',
      });
    }
  }, [teamDetails, teamDetailsLoading, user]);

  return { teamDetails, teamDetailsLoading };
}
