import type { ApolloClient } from '@apollo/client';
import {
  paragon,
  SDK_EVENT,
  type AuthenticatedConnectUser,
  type CallbackMap,
  type ConnectParams,
  type DisableWorkflowOptions,
  type DynamicMappingField,
  type DynamicMappingOptions,
  type GetIntegrationAccountOptions,
  type IConnectUser,
  type InstallIntegrationOptions,
  type InstallOptions,
  type IntegrationInstallEvent,
  type ModalConfig,
  type SDKIntegration,
  type SDKIntegrationConfig,
  type UninstallOptions,
} from '@useparagon/connect';
import type { IConnectCredential } from '@useparagon/connect/dist/src/entities/connectCredential.interface';
import type {
  IConnectIntegrationWithCredentialInfo,
  IIntegrationMetadata,
} from '@useparagon/connect/dist/src/entities/integration.interface';
import { getParagonUserTokenSchema, type ZodFetcher } from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { handleException } from 'sentry-browser-shared';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import { createZodKyFetcher } from '../fetcher';

export enum ParagonIntegrationEnum {
  GMAIL = 'gmail',
  OUTLOOK = 'outlook',
  GOOGLE_DRIVE = 'googledrive',
  SALESFORCE = 'salesforce',
  EXCEL = 'microsoftExcel',
  TEAMS = 'microsoftTeams',
  ONEDRIVE = 'onedrive',
  ONENOTE = 'onenote',
  SHAREPOINT = 'sharepoint',
}

export type {
  AuthenticatedConnectUser,
  CallbackMap,
  ConnectParams,
  DisableWorkflowOptions,
  DynamicMappingField,
  DynamicMappingOptions,
  GetIntegrationAccountOptions,
  IConnectCredential,
  IConnectIntegrationWithCredentialInfo,
  IConnectUser,
  IIntegrationMetadata,
  InstallIntegrationOptions,
  InstallOptions,
  ModalConfig,
  SDKIntegration,
  SDKIntegrationConfig,
  UninstallOptions,
};

export { SDK_EVENT };

export class ParagonSDK {
  private readonly _endpoint: string;
  private readonly _kyFetcher: ZodFetcher<KyInstance>;

  constructor(
    env: NodeEnv,
    apolloClient: ApolloClient<object>,
    kyOpts?: Options,
  ) {
    this._endpoint = apiEndpoints[env].miscApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  // Expose methods to subscribe/unsubscribe to events
  subscribe(event: SDK_EVENT, listener: () => void): void {
    paragon.subscribe(event, listener);
  }

  unsubscribe(event: SDK_EVENT, listener: () => void): void {
    paragon.unsubscribe(event, listener);
  }

  /**
   * Get a Paragon user token for authentication
   * @param userId - Optional user ID to get token for
   * @returns The Paragon user token
   */
  async getParagonUserToken(userId: string) {
    try {
      const response = await this._kyFetcher(
        getParagonUserTokenSchema.response,
        `${this._endpoint}/paragon-user-token?userId=${userId}`,
        {
          method: 'get',
        },
      );
      return response;
    } catch (err) {
      handleException(err, {
        name: 'Paragon user token fetch failed',
        source: 'ParagonSDK.getParagonUserToken',
        extra: { userId },
      });
      throw err;
    }
  }

  /**
   * Authenticate with Paragon using a user token
   * @param projectId - The Paragon project ID
   * @param token - The user token for authentication
   */
  async authenticate(projectId: string, token: string): Promise<void> {
    try {
      await paragon.authenticate(projectId, token);
    } catch (err) {
      handleException(err, {
        name: 'Paragon authentication failed',
        source: 'ParagonSDK.authenticate',
        extra: { projectId },
      });
      throw err;
    }
  }

  /**
   * Get the current authenticated user
   * @returns The authenticated user data or null if not authenticated
   */
  getUser(): AuthenticatedConnectUser | null {
    try {
      const user = paragon.getUser();
      if (user.authenticated) {
        return user;
      }
      handleException(new Error('User is not authenticated'), {
        name: 'Get Paragon user failed',
        source: 'ParagonSDK.getUser',
      });
    } catch (err) {
      handleException(err, {
        name: 'Get Paragon user failed',
        source: 'ParagonSDK.getUser',
      });
    }
    return null;
  }

  /**
   * Get metadata about available integrations
   */
  getIntegrationMetadata() {
    try {
      return paragon.getIntegrationMetadata();
    } catch (err) {
      handleException(err, {
        name: 'Get integration metadata failed',
        source: 'ParagonSDK.getIntegrationMetadata',
      });
      return [];
    }
  }

  /**
   * Install a new integration with support for multiple accounts
   * @param integrationType - The type of integration to install
   * @param options - Installation options with allowMultipleCredentials
   */
  async installIntegration(
    integrationType: ParagonIntegrationEnum,
    options: InstallIntegrationOptions = {},
  ): Promise<IntegrationInstallEvent> {
    try {
      // Start the installation process
      const installationResponse = await paragon.installIntegration(
        integrationType,
        {
          ...options,
          allowMultipleCredentials: true,
        },
      );

      if (!installationResponse) {
        throw new Error('Installation response is undefined');
      }

      return installationResponse;
    } catch (err) {
      handleException(err, {
        name: 'Paragon integration installation failed',
        source: 'ParagonSDK.installIntegration',
        extra: { integrationType, options },
      });
      throw err;
    }
  }

  /**
   * Makes a request to the Paragon API
   */
  async request<TResponse>(
    integrationType: ParagonIntegrationEnum,
    path: string,
    init: {
      method: RequestInit['method'];
      body: RequestInit['body'] | object;
      headers: RequestInit['headers'];
      selectedCredentialId?: string;
    },
  ): Promise<TResponse> {
    try {
      const response = await paragon.request<TResponse>(
        integrationType,
        path,
        init,
      );

      if (response === undefined) {
        throw new Error('Unexpected undefined response from Paragon SDK');
      }
      return response;
    } catch (err) {
      handleException(err, {
        name: 'Paragon request failed',
        source: 'ParagonSDK.request',
        extra: { integrationType, path, init },
      });
      throw err;
    }
  }

  /**
   * Get a human-readable identifier for a Paragon account
   */
  async getAccountIdentifier(
    account: IConnectCredential,
    integrationType: ParagonIntegrationEnum,
  ): Promise<{ primary: string; secondary?: string }> {
    try {
      switch (integrationType) {
        case ParagonIntegrationEnum.GMAIL:
        case ParagonIntegrationEnum.GOOGLE_DRIVE:
          return {
            primary: account.providerId || 'Unknown Account',
          };

        case ParagonIntegrationEnum.OUTLOOK: {
          const { providerData } = account;
          const email =
            typeof providerData.email === 'string' ? providerData.email : null;
          const name =
            typeof providerData.name === 'string' ? providerData.name : null;

          // If we have email in providerData, use it
          if (email) {
            return name
              ? { primary: name, secondary: email }
              : { primary: email };
          }

          try {
            // Make request to Microsoft Graph API via Paragon
            const userDetails = await this.request<{
              userPrincipalName?: string;
              mail?: string;
              displayName?: string;
            }>(integrationType, '/me', {
              method: 'GET',
              body: undefined,
              headers: {},
              selectedCredentialId: account.id,
            });

            // Return the most appropriate identifier
            if (userDetails.mail) {
              return userDetails.displayName
                ? {
                    primary: userDetails.displayName,
                    secondary: userDetails.mail,
                  }
                : { primary: userDetails.mail };
            }
            if (userDetails.userPrincipalName) {
              return userDetails.displayName
                ? {
                    primary: userDetails.displayName,
                    secondary: userDetails.userPrincipalName,
                  }
                : { primary: userDetails.userPrincipalName };
            }
            if (userDetails.displayName) {
              return {
                primary: userDetails.displayName,
                secondary: `ID: ${account.id.slice(0, 8)}`,
              };
            }
          } catch (err) {
            handleException(err, {
              name: 'Failed to fetch Outlook user details',
              source: 'ParagonSDK.getAccountIdentifier',
              extra: {
                accountId: account.id,
                error: err instanceof Error ? err.message : String(err),
              },
            });
          }

          // Fallback to a shortened ID if the API request fails
          return {
            primary: `Outlook Account ${account.id.slice(0, 8)}`,
          };
        }

        default: {
          // Log that we're handling an unsupported integration type
          handleException(
            new Error(
              `Unsupported integration type: ${String(integrationType)}`,
            ),
            {
              name: 'Unknown integration type',
              source: 'ParagonSDK.getAccountIdentifier',
              extra: { integrationType, accountId: account.id },
            },
          );

          // For other services, try provider data first, then fall back to ID
          const { providerData } = account;
          const email =
            typeof providerData.email === 'string' ? providerData.email : null;
          const name =
            typeof providerData.name === 'string' ? providerData.name : null;

          if (email && name) {
            return { primary: name, secondary: email };
          }

          return {
            primary: String(
              email ||
                name ||
                account.providerId ||
                (account.id
                  ? `Account ${account.id.slice(0, 8)}`
                  : 'Unknown Account'),
            ),
          };
        }
      }
    } catch (err) {
      handleException(err, {
        name: 'Get account identifier failed',
        source: 'ParagonSDK.getAccountIdentifier',
        extra: { account, integrationType },
      });
      return { primary: 'Unknown Account' };
    }
  }

  /**
   * Check if an account is valid and active
   * @param account - The Paragon account data
   * @returns boolean indicating if the account is valid
   */
  isAccountValid(account: SDKIntegration): boolean {
    try {
      return (
        account.credentialStatus ===
        ('VALID' as SDKIntegration['credentialStatus'])
      );
    } catch (err) {
      handleException(err, {
        name: 'Check account validity failed',
        source: 'ParagonSDK.isAccountValid',
        extra: { account },
      });
      return false;
    }
  }

  /**
   * Uninstall/disconnect a specific integration account
   * @param integrationType - The type of integration to uninstall
   * @param options - Uninstall options with selectedCredentialId
   */
  async uninstallIntegration(
    integrationType: ParagonIntegrationEnum,
    options: UninstallOptions = {},
  ): Promise<void> {
    try {
      await paragon.uninstallIntegration(integrationType, options);
    } catch (err) {
      handleException(err, {
        name: 'Uninstall integration failed',
        source: 'ParagonSDK.uninstallIntegration',
        extra: { integrationType, options },
      });
      throw err;
    }
  }
}
