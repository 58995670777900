import { useState } from 'react';
import { Modal } from 'ui-kit';
import {
  TriggersEmailEventEnum,
  type EmailTrigger,
} from '../../../../hooks/useEmailTriggers';
import TriggersList from './TriggersList';
import EventTriggerForm, { defaultCondition, type Form } from './Form';
import {
  useGetParagonUserToken,
  useParagonAuth,
} from '../../../../../Integrations/hooks';
import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import { isAdmin } from '../../../../../../utils/env';

interface Props {
  onCancel: () => void;
  emailTriggers: EmailTrigger[];
  workflowMetadata: GetWorkflowMetadataResponse;
}

const defaultForm: Form = {
  id: '',
  receiverEmailAddress: '',
  name: '',
  conditionQueryBuilderJson: defaultCondition,
  event: TriggersEmailEventEnum.OutlookNewMessage,
  workflowId: '',
};

export default function EventTriggerPopup({
  onCancel,
  emailTriggers,
  workflowMetadata,
}: Props) {
  const [form, setForm] = useState<Form>({
    ...defaultForm,
    workflowId: workflowMetadata.workflowId,
  });
  const { data: paragonUserToken } = useGetParagonUserToken(
    isAdmin ? workflowMetadata.userId : undefined,
  );
  const { data: user } = useParagonAuth(paragonUserToken?.token);

  return (
    <Modal
      className="w-full !max-w-7xl !p-0 !max-h-[90vh] !overflow-y-hidden"
      containerClassName="!flex !flex-col"
      onClose={onCancel}
      open
      closeBtnClassName="!top-6"
    >
      <h2 className="text-navy-blue font-medium text-lg">
        Email conditions to trigger the workflow
      </h2>

      <div className="flex flex-1 mt-8 !overflow-y-hidden space-x-8">
        <TriggersList
          formId={form.id}
          emailTriggers={emailTriggers}
          onSelect={(id) => {
            const trigger = emailTriggers.find((t) => t.id === id);
            setForm(trigger ? trigger : defaultForm);
          }}
        />
        <EventTriggerForm
          workflowId={workflowMetadata.workflowId}
          paragonUser={user}
          onClose={onCancel}
          form={form}
          resetForm={() => {
            setForm(defaultForm);
          }}
          onUpdate={(updates: Partial<EmailTrigger>) => {
            setForm((old) => ({ ...old, ...updates }));
          }}
        />
      </div>
    </Modal>
  );
}
