"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenAction = void 0;
var zod_1 = require("zod");
var action_types_1 = require("../../../extensionTypes/action-types");
var helper_types_1 = require("../../../helper-types");
var legacy_schema_1 = require("../../variable/legacy.schema");
var legacy_schema_2 = require("../legacy.schema");
exports.OpenAction = legacy_schema_2.WorkflowActionV2Base.extend({
    actionType: zod_1.z.literal(action_types_1.ActionsEnum.Open),
    variableId: zod_1.z.string(),
    parameters: zod_1.z.object({
        uri: legacy_schema_1.TemplateData,
        application: helper_types_1.SupportedApplication.optional(),
        flags: zod_1.z.array(zod_1.z.string()).optional(),
        arguments: zod_1.z.array(legacy_schema_1.TemplateData).optional(),
        waitDuration: helper_types_1.DurationString.optional(),
    }),
})
    .refine(function (_a) {
    var parameters = _a.parameters;
    return parameters.application || parameters.uri.length > 0;
}, {
    message: "At least one of 'application' or 'uri' is required",
    path: ['parameters'],
})
    .refine(function (_a) {
    var parameters = _a.parameters;
    return !(parameters.application && parameters.uri.length > 0);
}, {
    message: "Both 'application' and 'uri' are set",
    path: ['parameters'],
});
