import { type ReactNode } from 'react';
import { Switch } from 'ui-kit';

export default function SwitchRow({
  checked,
  onChange,
  children,
  disabled,
}: {
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
}) {
  return (
    <div className="flex items-center gap-2 w-full justify-between mb-5">
      <div>{children}</div>
      <Switch
        color="secondary"
        checked={checked}
        onChange={(_, newChecked) => {
          onChange(newChecked);
        }}
        disabled={disabled}
      />
    </div>
  );
}
