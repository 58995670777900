import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import type { GetExecutionResponse } from 'api-types-shared';
import { clsx } from 'clsx';
import values from 'lodash/values';
import {
  type ChangeEvent,
  Fragment,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  AlertVariant,
  Button,
  ContentCopyOutlined,
  CustomDownloadIcon,
  DataLoader,
  DescriptionOutlined,
  Flex,
  IconButton,
  notify,
} from 'ui-kit';
import { WORKFLOW_FILE_MAX_SIZE } from '../../../../../utils/constants';
import { isAdmin } from '../../../../../utils/env';
import { copyToClipboard, uploadFileToS3 } from '../../../../../utils/helper';
import { getOutputItemsFromExecutionArtifacts } from '../../../utils';
import OutputsTable from '../../OutputsTable';
import { type OutputItem } from '../../OutputsTable/Description';

interface Props {
  downloadZippedOutput: (
    executionId: string,
    outputs: OutputItem[],
  ) => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  artifactsLoading: boolean;
  fetchExecutionArtifacts: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<GetExecutionResponse>>;
  executionDetail: GetExecutionResponse;
  outputDetailsExportButtonEnabled: boolean;
}

export function RecordOutputs({
  executionDetail,
  downloadZippedOutput,
  downloadZippedOutputStatus,
  downloadLinkData,
  fetchExecutionArtifacts,
  artifactsLoading,
  outputDetailsExportButtonEnabled,
}: Props) {
  const { executionId } = useParams();

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const { artifacts = [], variableData } = executionDetail;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const variables = useMemo(() => values(variableData), [variableData]);

  const isDownloadingZippedOutput = downloadZippedOutputStatus === 'pending';

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const outputItems: OutputItem[] = useMemo(() => {
    return getOutputItemsFromExecutionArtifacts(
      executionDetail,
      onDownloadLinkData,
    );
  }, [executionDetail, onDownloadLinkData]);

  const handleDownloadZippedOutput = useCallback(async () => {
    const { data: refreshedExecutionDetail } = await fetchExecutionArtifacts();
    if (refreshedExecutionDetail) {
      const newOutputItems = getOutputItemsFromExecutionArtifacts(
        refreshedExecutionDetail,
        onDownloadLinkData,
      );
      void downloadZippedOutput(executionId, newOutputItems);
    }
  }, [
    fetchExecutionArtifacts,
    onDownloadLinkData,
    downloadZippedOutput,
    executionId,
  ]);

  const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size <= WORKFLOW_FILE_MAX_SIZE) {
        notify({
          message: 'Uploading artifact...',
          variant: AlertVariant.INFO,
        });

        // Always compress uploaded artifacts
        await uploadFileToS3(
          `${executionId}/artifacts/${selectedFile.name}`,
          selectedFile,
          { compress: true },
        );

        notify({
          message: 'Artifact uploaded successfully',
          variant: AlertVariant.SUCCESS,
        });
        void fetchExecutionArtifacts();
      } else {
        notify({
          message: 'File size should be less than 50 MB',
          variant: AlertVariant.ERROR,
        });
      }
    }
  };

  useEffect(() => {
    void fetchExecutionArtifacts();
  }, [fetchExecutionArtifacts]);

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Output details
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            View and manage workflow outputs. Copy or download data as needed.
          </span>
        </div>

        <div className="flex items-center space-x-4">
          {isAdmin ? (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => inputRef.current?.click()}
            >
              Upload artifact
            </Button>
          ) : null}
          <Button
            className="!border-none"
            color="secondary"
            loading={isDownloadingZippedOutput}
            disabled={
              artifacts.length === 0 || !outputDetailsExportButtonEnabled
            }
            onClick={handleDownloadZippedOutput}
            variant="outlined"
          >
            {!isDownloadingZippedOutput ? (
              <CustomDownloadIcon
                fontSize="small"
                className={clsx({
                  'text-gray-350 mr-1':
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled,
                  'text-info mr-1': !(
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled
                  ),
                })}
              />
            ) : null}
            Export all documents
          </Button>
        </div>
      </Flex>

      <OutputsTable
        executionId={executionId}
        outputItems={outputItems}
        loading={artifactsLoading}
        fetchExecutionArtifacts={fetchExecutionArtifacts}
        variables={variables}
      />
      <input
        hidden
        onChange={onFileSelect}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          e.currentTarget.value = '';
        }}
        ref={inputRef}
        type="file"
      />
    </div>
  );
}

interface OutputProps {
  title: string;
  description: string | object;
  onDownloadLinkData?: (url: string) => void;
  action: 'download' | 'copy';
  uri?: string;
  className?: string;
}
export function Output({
  title,
  description,
  action = 'copy',
  uri,
  onDownloadLinkData,
  className,
}: OutputProps) {
  const copy = useCallback(() => {
    const text =
      typeof description === 'string'
        ? description
        : JSON.stringify(description, null, 2);
    copyToClipboard(text);
  }, [description]);

  const downloadFile = useCallback(() => {
    if (uri && onDownloadLinkData) {
      onDownloadLinkData(uri);
    }
  }, [onDownloadLinkData, uri]);

  const descriptionText = useMemo(() => {
    if (typeof description === 'string') {
      const parts = description.split('\n');
      if (parts.length === 1) {
        return parts[0];
      }
      return parts.map((line: string) => (
        <Fragment key={line}>
          {line}
          <br />
        </Fragment>
      ));
    }
    return JSON.stringify(description, null, 2);
  }, [description]);

  const cookiesTitle =
    descriptionText === 'cookies.json'
      ? 'Workflow Execution Cookies'
      : undefined;

  return (
    <div
      className={clsx(
        'px-4 py-2 bg-neutral-50 rounded-lg border border-slate-300 flex items-start gap-2 w-fit',
        className,
      )}
    >
      {action === 'download' ? (
        <DescriptionOutlined color="action" fontSize="small" />
      ) : null}
      <div className="flex flex-col mr-2">
        <div className="text-gray-500 text-xs leading-3 tracking-tight break-all">
          {cookiesTitle ?? title}
        </div>
        <div className="text-cyan-900 text-base leading-normal tracking-tight break-all whitespace-pre-wrap font-mono">
          {descriptionText}
        </div>
      </div>
      <IconButton
        className="mt-2"
        onClick={action === 'download' ? downloadFile : copy}
      >
        {action === 'download' ? (
          <CustomDownloadIcon color="secondary" fontSize="small" />
        ) : (
          <ContentCopyOutlined color="secondary" fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
