import { type TemplateData } from 'types-shared';
import cloneDeep from 'lodash/cloneDeep';

// Check template data to generate values we will need to determine how to sanitise it to get rid of unnecessary new lines
const newLinesArePresent = (templateData: TemplateData) => {
  const finalValue = templateData[templateData.length - 1];
  const firstValue = templateData[0];

  const firstValueIsString = typeof firstValue === 'string';
  const finalValueIsString = typeof finalValue === 'string';
  const firstValueContainsNewLines =
    firstValueIsString && firstValue.includes('\n');
  const finalValueContainsNewLines =
    finalValueIsString && finalValue.includes('\n');

  const entireFinalValueIsNewLines =
    finalValueIsString &&
    finalValue.split('\n').filter((v) => v.trim()).length === 0;
  const entireFirstValueIsNewLines =
    firstValueIsString &&
    firstValue.split('\n').filter((v) => v.trim()).length === 0;

  const startIsNewLine = firstValueIsString && firstValue.startsWith('\n');

  return {
    startIsNewLine,
    firstValueIsString,
    finalValueIsString,
    entireFirstValueIsNewLines,
    entireFinalValueIsNewLines,
    finalValueContainsNewLines,
    firstValueContainsNewLines,
  };
};

// Recursively remove leading and trailing newlines before saving
export const sanitizeTemplateData = (
  templateData: TemplateData,
): TemplateData => {
  let result: TemplateData = [];

  const isOnlyASingleNewLine =
    templateData.length === 1 && templateData[0] === '\n';

  if (!isOnlyASingleNewLine) {
    const {
      startIsNewLine,
      firstValueIsString,
      finalValueIsString,
      entireFirstValueIsNewLines,
      entireFinalValueIsNewLines,
      finalValueContainsNewLines,
      firstValueContainsNewLines,
    } = newLinesArePresent(templateData);

    const finalValue = templateData[templateData.length - 1];
    const firstValue = templateData[0];
    const finalContent = cloneDeep(templateData);

    if (firstValueIsString) {
      if (entireFirstValueIsNewLines) {
        finalContent.shift();
      } else if (firstValueContainsNewLines) {
        if (startIsNewLine) {
          finalContent[0] = (firstValue as string).replace(/^\n+/, '');
        }
      }
    }

    if (finalValueIsString) {
      if (entireFinalValueIsNewLines) {
        finalContent.pop();
      } else if (finalValueContainsNewLines) {
        const endIsNewLine = (finalValue as string).endsWith('\n');
        if (endIsNewLine) {
          finalContent[finalContent.length - 1] = (
            finalValue as string
          ).replace(/\n+$/, '');
        }
      }
    }
    result = finalContent;
  } else {
    result = [];
  }

  const { entireFirstValueIsNewLines, entireFinalValueIsNewLines } =
    newLinesArePresent(result);

  const isValid = !entireFinalValueIsNewLines && !entireFirstValueIsNewLines;

  if (!isValid) {
    return sanitizeTemplateData(result);
  }

  return result;
};
