import { useEnvironment, WarningAmberOutlined } from 'ui-kit';
import SwitchRow from './SwitchRow';

export default function EmailHeader({
  emailActive,
  updateNotificationData,
}: {
  emailActive: boolean;
  updateNotificationData: (key: 'emailActive', value: boolean) => void;
}) {
  const { selectedEnv } = useEnvironment();
  const senderEmail =
    selectedEnv === 'production'
      ? 'notifications@alerts.sola.ai'
      : 'notifications@alerts.dev.sola.ai';

  return (
    <>
      <SwitchRow
        checked={emailActive}
        onChange={(newChecked) => {
          updateNotificationData('emailActive', newChecked);
        }}
      >
        <h2 className="font-medium text-xl text-info-dark">
          Email notifications
        </h2>
        <p className="text-sm text-gray-500 !mt-2">
          Set up notifications for your workflow to stay updated in real time
        </p>
      </SwitchRow>

      <div className="flex flex-col space-y-2">
        <h2 className="font-medium text-lg text-info-dark">
          Notification sender email
        </h2>
        <span className="text-sm text-info-dark bg-[#F5F5F5] rounded-lg p-4">
          {senderEmail}
        </span>
        <p className="text-sm text-gray-500 mb-6 mt-2">
          If you don't see notifications, check your spam folder and mark them
          as "Not spam" to ensure delivery.
        </p>
      </div>

      <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm mt-3">
        <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
        <div className="flex flex-col">
          <h2 className="text-warning-dark text-base font-medium">
            Ensure You Receive Notifications
          </h2>
          <span className="text-[#663C00]">
            Due to the high volume of emails, some messages may be filtered as
            spam. To stay in the loop, please whitelist {senderEmail} or check
            your spam folder.
          </span>
        </div>
      </div>

      <h2 className="font-medium text-lg text-info-dark -mb-8 mt-3">
        Notification email recipients
      </h2>
    </>
  );
}
