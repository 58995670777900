import { useCallback, useState } from 'react';
import {
  ActionPlatformEnum,
  type DatasourceMetadata,
  type GlobalVariable,
  type KeyValuePair,
  type NodeStatusEnum,
  type TemplateVariable,
  type TemporalRetryPolicy,
  type Variable,
  type VariableMap,
  type WorkflowTemporalNode,
} from 'types-shared';
import {
  Add,
  Button,
  ChevronRight,
  DeleteOutlineIcon,
  ExpandMoreOutlined,
  Input,
  Select,
} from 'ui-kit';
import { createTemplateVariable } from './request.helpers';
import { RequestVariableInput } from './RequestVariableInput';
import {
  getTemporalBlockTitle,
  getTemporalNodeDescription,
} from './temporal.helpers';
// import { NodeCheck } from '../NodeCheck';
import isEmpty from 'lodash/isEmpty';
import NonImageNodeWrapper from '../NonImageNodeWrapper';
import SectionLabel from './SectionLabel';

export type WorkflowTemporalNodeCoreData = Omit<
  WorkflowTemporalNode['data'],
  'nodeStatus' | 'selected'
>;

interface Props {
  node: WorkflowTemporalNode;
  onCancel: () => void;
  onUpdateData: (data: WorkflowTemporalNodeCoreData) => void;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  updateNodeName: (name: string) => void;
  datasourceMetadata: DatasourceMetadata | null;
}

const isTemporalRetryPolicyEmpty = (
  retryPolicy: TemporalRetryPolicy,
): boolean => {
  return Object.values(retryPolicy).every((val) => isEmpty(val));
};

// TODO: Disallow adding duplicate keys on editor
export function TemporalBlockAdmin({
  node,
  onCancel,
  onUpdateData,
  variablesMap,
  globalVariablesMap,
  addVariable,
  updateVariable,
  updateNodeName,
  datasourceMetadata,
}: Props) {
  const temporalData = node.data;
  const { workflowArgs = [], retryPolicy: temporalRetryPolicy } = temporalData;

  const addWorkflowArg = useCallback(() => {
    onUpdateData({
      ...temporalData,
      workflowArgs: [
        ...workflowArgs,
        {
          key: {
            variableId: createTemplateVariable(addVariable).id,
          },
          value: {
            variableId: createTemplateVariable(addVariable).id,
          },
        },
      ],
    });
  }, [addVariable, onUpdateData, temporalData, workflowArgs]);

  const deleteWorkflowArg = useCallback(
    (id: string) => {
      onUpdateData({
        ...temporalData,
        workflowArgs: workflowArgs.filter(
          (param) => param.key.variableId !== id,
        ),
      });
    },
    [onUpdateData, temporalData, workflowArgs],
  );

  const updateRetryPolicy = useCallback(
    (retryPolicy: TemporalRetryPolicy) => {
      const isRetryPolicyEmpty = isTemporalRetryPolicyEmpty(retryPolicy);
      onUpdateData({
        ...temporalData,
        retryPolicy: isRetryPolicyEmpty ? null : retryPolicy,
      });
    },
    [onUpdateData, temporalData],
  );

  const updateNodePlatform = useCallback(
    (platform: ActionPlatformEnum) => {
      onUpdateData({
        ...temporalData,
        platform,
      });
    },
    [onUpdateData, temporalData],
  );

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-auto p-8">
          <div className="my-6">
            <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
              {getTemporalBlockTitle(node)}
            </h2>
            <p className="text-zinc-500 text-sm leading-tight">
              {getTemporalNodeDescription()}
            </p>
          </div>
          <div className="temporal-block flex-1 flex flex-col gap-4">
            <Input
              floatingLabel
              label="Step name"
              onChange={(val: string) => {
                updateNodeName(val);
              }}
              placeholder="Step name"
              value={node.name ?? ''}
            />

            <div className="flex flex-col gap-2">
              <SectionLabel title="Workflow Type" />
              <RequestVariableInput
                datasourceMetadata={datasourceMetadata}
                onChange={(val) => {
                  const variable = variablesMap[
                    temporalData.workflowType.variableId
                  ] as TemplateVariable;
                  updateVariable({
                    ...variable,
                    data: val.data,
                  });
                }}
                variable={
                  variablesMap[
                    temporalData.workflowType.variableId
                  ] as TemplateVariable
                }
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                placeholder="Enter workflow type"
              />
            </div>

            <div className="flex flex-col gap-2">
              <SectionLabel title="Task Queue" />
              <RequestVariableInput
                datasourceMetadata={datasourceMetadata}
                onChange={(val) => {
                  const variable = variablesMap[
                    temporalData.taskQueue.variableId
                  ] as TemplateVariable;
                  updateVariable({
                    ...variable,
                    data: val.data,
                  });
                }}
                variable={
                  variablesMap[
                    temporalData.taskQueue.variableId
                  ] as TemplateVariable
                }
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                placeholder="Enter task queue"
              />
            </div>

            <div className="flex flex-col gap-2">
              <SectionLabel title="Platform" />
              <Select
                label="platform"
                getLabel={(opt: ActionPlatformEnum) => opt}
                getValue={(opt: ActionPlatformEnum) => opt}
                value={temporalData.platform ?? ActionPlatformEnum.Web}
                options={Object.values(ActionPlatformEnum)}
                onChange={(evt) => {
                  updateNodePlatform(evt.target.value as ActionPlatformEnum);
                }}
              />
            </div>

            <div className="request-block flex-1 flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <SectionLabel title="Workflow Arguments" />
                <Button
                  className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
                  color="secondary"
                  onClick={addWorkflowArg}
                  variant="outlined"
                >
                  <Add className="text-info" />
                </Button>
              </div>

              {workflowArgs.map((arg: KeyValuePair) => (
                <div
                  key={arg.key.variableId}
                  className="flex w-100 justify-between space-x-3"
                >
                  <RequestVariableInput
                    datasourceMetadata={datasourceMetadata}
                    onChange={(val) => {
                      const variable = variablesMap[
                        arg.key.variableId
                      ] as TemplateVariable;
                      updateVariable({
                        ...variable,
                        data: val.data,
                      });
                    }}
                    variable={
                      variablesMap[arg.key.variableId] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    placeholder="Key"
                    isHalf
                    multiline={false}
                  />

                  <RequestVariableInput
                    datasourceMetadata={datasourceMetadata}
                    onChange={(val) => {
                      const variable = variablesMap[
                        arg.value.variableId
                      ] as TemplateVariable;
                      updateVariable({
                        ...variable,
                        data: val.data,
                      });
                    }}
                    variable={
                      variablesMap[arg.value.variableId] as TemplateVariable
                    }
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    placeholder="Value"
                    multiline={false}
                    isHalf
                  />

                  <DeleteOutlineIcon
                    className="hover:text-red-500 cursor-pointer mt-3"
                    onClick={() => {
                      deleteWorkflowArg(arg.key.variableId);
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-2">
              <RetryPolicySection
                retryPolicy={temporalRetryPolicy ?? undefined}
                onChange={updateRetryPolicy}
              />
            </div>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}

function RetryPolicySection({
  retryPolicy,
  onChange,
}: {
  retryPolicy?: TemporalRetryPolicy;
  onChange: (policy: TemporalRetryPolicy) => void;
}) {
  const [errorType, setErrorType] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const hasRetryPolicy = Object.values(retryPolicy || {}).some((val) => val);

  const updatePolicy = (updates: Partial<TemporalRetryPolicy>) => {
    onChange({
      ...retryPolicy,
      ...updates,
    });
  };

  const addErrorType = () => {
    if (!errorType) return;

    const currentTypes = retryPolicy?.nonRetryableErrorTypes || [];
    if (!currentTypes.includes(errorType)) {
      updatePolicy({
        nonRetryableErrorTypes: [...currentTypes, errorType],
      });
      setErrorType('');
    }
  };

  const removeErrorType = (index: number) => {
    const currentTypes = retryPolicy?.nonRetryableErrorTypes || [];
    updatePolicy({
      nonRetryableErrorTypes: currentTypes.filter((_, i) => i !== index),
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 mt-4">
        <div
          className="flex items-center gap-2 cursor-pointer"
          aria-hidden="true"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <ExpandMoreOutlined className="w-4 h-4 text-gray-600" />
          ) : (
            <ChevronRight className="w-4 h-4 text-gray-600" />
          )}
          <span className="text-sm font-medium text-gray-700">
            Retry Policy
          </span>
          {hasRetryPolicy && !isExpanded ? (
            <span className="text-xs text-gray-500 italic">(Configured)</span>
          ) : null}
        </div>

        {isExpanded ? (
          <div className="flex flex-col gap-2 mt-2">
            <Input
              floatingLabel
              type="number"
              label="Backoff Coefficient"
              placeholder="Enter backoff coefficient"
              value={retryPolicy?.backoffCoefficient}
              onChange={(val: string) => {
                updatePolicy({
                  backoffCoefficient: val ? Number(val) : undefined,
                });
              }}
            />

            <Input
              floatingLabel
              label="Initial Interval"
              placeholder="e.g., 1s, 1m, 1h"
              value={retryPolicy?.initialInterval || ''}
              onChange={(val: string) => {
                updatePolicy({ initialInterval: val || undefined });
              }}
            />

            <Input
              floatingLabel
              type="number"
              label="Maximum Attempts"
              placeholder="Enter maximum attempts"
              value={retryPolicy?.maximumAttempts?.toString() || ''}
              onChange={(val: string) => {
                updatePolicy({
                  maximumAttempts: val ? Number(val) : undefined,
                });
              }}
            />

            <Input
              floatingLabel
              label="Maximum Interval"
              placeholder="e.g., 1s, 1m, 1h"
              value={retryPolicy?.maximumInterval || ''}
              onChange={(val: string) => {
                updatePolicy({ maximumInterval: val || undefined });
              }}
            />

            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Input
                  classes={{
                    wrapper: 'flex-1',
                  }}
                  floatingLabel
                  label="Non-Retryable Error Types"
                  placeholder="Enter error type"
                  value={errorType}
                  onChange={(val: string) => {
                    setErrorType(val);
                  }}
                />
                <Button
                  className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
                  color="secondary"
                  onClick={addErrorType}
                  variant="outlined"
                >
                  <Add className="text-info" />
                </Button>
              </div>

              <div className="flex flex-col gap-2">
                {retryPolicy?.nonRetryableErrorTypes?.map((type, index) => (
                  <div
                    key={`${index.toString()}-${type}`}
                    className="flex items-center justify-between bg-gray-50 p-2 rounded"
                  >
                    <span className="text-sm">{type}</span>
                    <DeleteOutlineIcon
                      className="hover:text-red-500 cursor-pointer"
                      onClick={() => {
                        removeErrorType(index);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
