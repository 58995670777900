import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import clone from 'lodash/clone';
import isNil from 'lodash/isNil';
import setWith from 'lodash/setWith';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { handleException } from 'sentry-browser-shared';
import {
  BranchModeEnum,
  NodeStatusEnum,
  NodeTypesEnum,
  SourceTypeEnum,
  StopNodeStatusEnum,
  WorkflowConditionalNode,
  type BranchData,
  type DatasourceMetadata,
  type DatasourceTable,
  type GlobalVariable,
  type Group,
  type RequestIntegrationTypeEnum,
  type TemplateData,
  type Variable,
  type VariableMap,
  type WorkflowContinueNode,
  type WorkflowEdge,
  type WorkflowEmailNode,
  type WorkflowFreeformNode,
  type WorkflowNode,
  type WorkflowRequestNode,
  type WorkflowRetryNode,
  type WorkflowStopNode,
} from 'types-shared';
import { v4 as uuid } from 'uuid';
import { useShallow } from 'zustand/react/shallow';
import { isAdmin } from '../../../../utils/env';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { autoFormat } from '../../utils/autoformat';
import {
  copyBranchData,
  createWorkflowData,
  deleteAllNodesAfter,
  getAllNodesAfter,
  insertNodeAfter,
  transformPastedWorkflow,
} from '../../utils/helper';
import { initialGroup } from '../Conditions/conditions.helpers';
import { AddRecordingBlock } from './AddRecordingBlock';
import { ConditionalBlock } from './ConditionalBlock';
import { DocumentsBlock } from './DocumentsBlock/DocumentsBlock';
import { EmailBlock } from './EmailBlock';
import { FreeformBlock } from './FreeformBlock';
import { OptionsBlock } from './OptionsBlock';
import {
  createTemplateVariable,
  initialRequestBlockData,
} from './request.helpers';
import type { WorkflowRequestNodeCoreData } from './RequestBlockAdmin';
import { RequestBlockAdmin } from './RequestBlockAdmin';
import { RequestBlockCustomer } from './RequestBlockCustomer';
import { RetryBlock } from './RetryBlock';
import { StopBlock } from './StopBlock';
import { initialTemporalBlockData } from './temporal.helpers';
import { TemporalBlockAdmin } from './TemporalBlockAdmin';
import { TriggerBlock } from './TriggerBlock/TriggerBlock';
import { useNavigateBetweenNodes } from '../NodeHeader/hooks';

interface Props {
  workflowId: string;
  allowBranchReordering?: boolean;
  continueRecordingBlockEnabled?: boolean;
  stopBlockEnabled?: boolean;
  enabledFeatureFlags: string[];
  nodeId: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setNodes: (nodes: WorkflowNode[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  onImport?: (replaceNodeId?: string) => Promise<boolean>;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
    model?: string,
  ) => Promise<string | undefined>;
  onUploadFile: (file: File) => Promise<{ fileId: string }>;
  continueRecording?: (nodeId: string) => void;
  fullRequestNodeVersion: boolean;
  sendEmailStepEnabled?: boolean;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
}

export function EditNodePanel({
  workflowId,
  allowBranchReordering,
  continueRecordingBlockEnabled,
  enabledFeatureFlags,
  sendEmailStepEnabled,
  stopBlockEnabled,
  nodeId,
  nodes,
  edges,
  setEdges,
  updateNode,
  setNodes,
  onImport,
  datasourceMetadata,
  variablesMap,
  globalVariablesMap,
  tableData,
  addVariable,
  updateVariable,
  onTransformApiReq,
  transformApiReqStatus,
  onUploadFile,
  sourceType,
  fullRequestNodeVersion,
  workflowMetadata,
}: Props) {
  const { targetsMap, addTargets, addVariables } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      targetsMap: state.targets,
      addTargets: state.addTargets,
      addVariables: state.addVariables,
    })),
  );
  const { currentNodeType, handleCloseNode } = useNavigateBetweenNodes();
  const [searchParams, setSearchParams] = useSearchParams();

  const setCurrentNodeType = useCallback(
    (type: string | null) => {
      const newParams = new URLSearchParams(searchParams);

      if (type) {
        newParams.set('nodeType', type);
        setSearchParams(newParams);
      } else {
        newParams.delete('nodeType');
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams],
  );

  const [editingEdge, setEditingEdge] = useState<WorkflowEdge>();
  const selectedNode = useMemo(
    () => nodes.find((node) => node.id === nodeId),
    [nodes, nodeId],
  );

  const { sourceVariable } = useSourceVariable(variablesMap);

  const insertNode = (sourceId: string) => {
    const sourceNode = nodes.find((node) => node.id === sourceId);

    if (!sourceNode) {
      throw Error('sourceNode not found');
    }

    const { edgeId, nodePositions } = insertNodeAfter(
      sourceNode,
      nodes,
      edges,
      {
        setNodes,
        setEdges,
      },
      true,
    );

    const dataPayload = {
      ...sourceNode.data,
      branchesData: [
        ...((sourceNode as WorkflowConditionalNode).data
          .branchesData as BranchData[]),
        {
          branchId: edgeId,
          selectedMode: BranchModeEnum.Rule,
          instruction: { variableId: createTemplateVariable(addVariable).id },
          rule: {
            data: initialGroup(addVariable),
            output: [{ id: edgeId }],
          },
        },
      ],
    };

    updateNode({
      ...sourceNode,
      position: nodePositions[sourceNode.id] ?? sourceNode.position,
      data: dataPayload,
    } as WorkflowNode);
  };

  const setNodeType = (
    nodeType: string,
    integrationType?: RequestIntegrationTypeEnum,
  ) => {
    if (isNil(selectedNode)) {
      throw Error('selectedNode step not found!');
    }

    switch (nodeType) {
      case 'conditional': {
        const filteredEdges = edges.map((e) => {
          if (e.source === selectedNode.id) {
            return {
              ...e,
              labelStyle: { display: 'block' },
            };
          }
          return e;
        });
        const onwardEdge = filteredEdges.find(
          (e) => e.source === selectedNode.id,
        );
        const branchesData: BranchData[] = [];

        if (onwardEdge) {
          branchesData.push({
            branchId: onwardEdge.id,
            selectedMode: BranchModeEnum.Rule,
            instruction: { variableId: createTemplateVariable(addVariable).id },
            rule: {
              data: initialGroup(addVariable),
              output: [{ id: onwardEdge.id }],
            },
          }); // HERE PAUL
        }
        const payload = {
          ...selectedNode,
          data: {
            ...selectedNode.data,
            branchesData,
          },
          type: NodeTypesEnum.Conditional,
          name: 'New Conditional Step',
        };

        WorkflowConditionalNode.parse(payload);

        updateNode(payload as WorkflowConditionalNode);
        setEdges(filteredEdges);
        break;
      }
      case 'freeform': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Freeform,
          data: {
            ...selectedNode.data,
            instructions: {
              variableId: createTemplateVariable(addVariable).id,
            },
          },
          name: 'New Freeform Step',
        });
        break;
      }
      case 'image': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Image,
          data: {
            ...selectedNode.data,
            actionData: {},
            actionOrder: [],
            imageData: {
              imageId: '',
            },
            nodeUrls: [],
          },
        } as WorkflowNode);
        break;
      }
      case 'stop': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Stop,
          data: {
            ...selectedNode.data,
            status: StopNodeStatusEnum.Success,
          },
        });
        break;
      }
      case 'retry': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Retry,
          data: {
            ...selectedNode.data,
            maxAttempts: 4,
            retryInterval: 1440,
          },
        });
        break;
      }
      case 'continue': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Continue,
          data: selectedNode.data,
        });
        break;
      }
      case 'document': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Document,
          data: {
            ...selectedNode.data,
            surfaceOutputVariables: false,
          },
        });
        break;
      }
      case 'request': {
        const requestData = initialRequestBlockData(
          addVariable,
          Boolean(isAdmin),
        );
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Request,
          data: {
            ...selectedNode.data,
            ...requestData,
            integrationType,
          },
        } as WorkflowRequestNode);
        break;
      }
      case 'temporal': {
        const temporalData = initialTemporalBlockData(addVariable);
        updateNode({
          ...selectedNode,
          hideFromUser: true,
          type: NodeTypesEnum.Temporal,

          data: {
            ...selectedNode.data,
            ...temporalData,
            nodeStatus: NodeStatusEnum.Checked, // Always checked. We don't want to block a user from running a workflow from a hidden node
          },
        });
        break;
      }
      case 'email': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Email,
          data: {
            ...selectedNode.data,
          },
        });
        break;
      }
    }

    setCurrentNodeType(nodeType);
  };

  const updateNodeProps = (key: string, value: unknown) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === selectedNode?.id) {
          return setWith(clone(_node), key, value, clone);
        }
        return _node;
      }),
    );
  };

  const updateEdge = ({
    name,
    group,
    instruction,
  }: Partial<{
    name: string;
    group: Group;
    instruction: { variableId: string };
  }>) => {
    if (!editingEdge) {
      throw Error('editingEdge not found!');
    }

    if (name) {
      // add the label on edge
      setEdges(
        edges.map((edge) => {
          if (edge.id === editingEdge.id) {
            return {
              ...edge,
              label: name,
            };
          }
          return edge;
        }),
      );
    }

    const updateBranchData = (
      branchesData: BranchData[],
      newInstruction: { variableId: string },
      newGroup?: Group,
    ) => {
      const branchData = branchesData.find(
        (b) => b.branchId === editingEdge.id,
      );

      if (!branchData) {
        return [
          ...branchesData,
          {
            branchId: editingEdge.id,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : undefined,
            instruction: newInstruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          },
        ];
      }
      return branchesData.map((b) => {
        if (b.branchId === editingEdge.id) {
          return {
            ...b,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : b.rule,
            instruction: newInstruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          };
        }
        return b;
      });
    };

    setNodes(
      nodes.map((_node) => {
        if (
          _node.id === selectedNode?.id &&
          _node.type === NodeTypesEnum.Conditional
        ) {
          return {
            ..._node,
            data: {
              ..._node.data,
              branchesData: updateBranchData(
                _node.data.branchesData ?? [],
                instruction ?? {
                  variableId: createTemplateVariable(addVariable).id,
                },
                group,
              ),
            },
          };
        }
        return _node;
      }),
    );

    setEditingEdge(undefined);
  };

  const deleteBranch = (edgeToDelete: WorkflowEdge) => {
    if (!selectedNode) {
      handleException(new Error('selectedNode not found!'), {
        name: 'Delete branch failed',
        source: 'EditNodePanel.deleteBranch',
        extra: { edgeToDelete },
      });
      return;
    }

    const branchesData = (selectedNode as WorkflowConditionalNode).data
      .branchesData;

    // branch to delete
    const branchData = branchesData?.find(
      (b) => b.branchId === edgeToDelete.id,
    );

    if (!branchData) {
      handleException(new Error('branchData not found!'), {
        name: 'Delete branch failed',
        source: 'EditNodePanel.deleteBranch',
        extra: { edgeToDelete, selectedNode },
      });
      return;
    }

    // remove the edge
    const updatedEdges = edges.filter((edge) => edge.id !== edgeToDelete.id);
    setEdges(updatedEdges);
    setEditingEdge(undefined);

    // remove the branch from the conditional node
    const updatedNodes = nodes.map((n) => {
      if (n.id === edgeToDelete.source) {
        return {
          ...n,
          data: {
            ...n.data,
            branchesData: branchesData?.filter(
              (b) => b.branchId !== edgeToDelete.id,
            ),
          },
        } as WorkflowNode;
      }
      return n;
    });

    // first node in the branch to be deleted
    const sourceNode = updatedNodes.find((n) => n.id === edgeToDelete.target);

    if (sourceNode) {
      // delete all nodes after the source node
      deleteAllNodesAfter(sourceNode, updatedNodes, updatedEdges, {
        setNodes,
        setEdges,
      });
    }
  };

  const duplicateBranch = (
    node: WorkflowConditionalNode,
    edge: WorkflowEdge,
  ) => {
    const sourceBranchData = node.data.branchesData?.find(
      (b) => b.branchId === edge.id,
    );
    if (!sourceBranchData) {
      handleException(new Error('source branch not found!'), {
        name: 'Duplicate branch failed',
        source: 'EditNodePanel.duplicateBranch',
        extra: { node, edge },
      });
      return;
    }
    const sourceNode = nodes.find((n) => n.id === edge.target);
    if (!sourceNode) {
      handleException(new Error('source node not found!'), {
        name: 'Duplicate branch failed',
        source: 'EditNodePanel.duplicateBranch',
        extra: { nodes, edge },
      });
      return;
    }
    const nodesAfter = getAllNodesAfter(sourceNode, nodes, edges);
    const nodesMap = nodes.reduce(
      (acc: Record<string, WorkflowNode>, _node: WorkflowNode) => {
        acc[_node.id] = _node;
        return acc;
      },
      {},
    );
    const selectedNodes = nodesAfter.map((id) => nodesMap[id]);
    const selectedEdges = edges.filter((e: WorkflowEdge) => {
      return nodesAfter.includes(e.source) && nodesAfter.includes(e.target);
    });
    const branchWorkflowData = createWorkflowData({
      workflowId,
      targets: targetsMap,
      variables: variablesMap,
      globalVariables: globalVariablesMap,
      nodes,
      selectedNodes,
      selectedEdges,
    });
    if (!branchWorkflowData) {
      handleException(new Error('branch workflow data not found!'), {
        name: 'Duplicate branch failed',
        source: 'EditNodePanel.duplicateBranch',
        extra: {
          branchWorkflowData,
          input: {
            workflowId,
            targets: targetsMap,
            variables: variablesMap,
            globalVariables: globalVariablesMap,
            nodes,
            selectedNodes,
            selectedEdges,
          },
        },
      });
      return;
    }
    const {
      workflowData,
      targetMap = {},
      variableMap: newVariableMap = {},
    } = transformPastedWorkflow(branchWorkflowData, true, false, variablesMap);
    addTargets(targetMap);
    addVariables(newVariableMap);
    const newNodes = workflowData.nodes;
    const newEdges = workflowData.edges;
    const newEdge = {
      id: uuid(),
      label: `${(edge.label as string | undefined) ?? 'New Branch'} (copy)`,
      labelStyle: { display: 'block' },
      source: node.id,
      target: newNodes[0].id,
    };
    const updatedEdges = [...edges, ...newEdges, newEdge];
    const updatedNodes = [...nodes, ...newNodes].map((n) => {
      if (n.id === node.id) {
        const { newBranchData, newVariables } = copyBranchData(
          newEdge.id,
          sourceBranchData,
          variablesMap,
        );
        addVariables(newVariables);
        return {
          ...n,
          data: {
            ...node.data,
            branchesData: [...(node.data.branchesData ?? []), newBranchData],
          },
        };
      }
      return n;
    }) as WorkflowNode[];
    setEdges(updatedEdges);
    autoFormat(updatedNodes, updatedEdges, setNodes);
  };

  const updateRequestNodeData = (data: WorkflowRequestNodeCoreData) => {
    setNodes(
      nodes.map((_node) => {
        if (
          _node.id === selectedNode?.id &&
          _node.type === NodeTypesEnum.Request
        ) {
          return {
            ..._node,
            data: {
              ..._node.data,
              ...data,
              nodeStatus: _node.data.nodeStatus,
            },
          };
        }
        return _node;
      }),
    );
  };

  const handleOnCancel = () => {
    handleCloseNode();
  };

  if (!nodeId || !selectedNode || currentNodeType === 'image') return null;

  if (!currentNodeType || currentNodeType === 'new') {
    return (
      <OptionsBlock
        workflowId={workflowId}
        continueRecordingBlockEnabled={continueRecordingBlockEnabled}
        fullRequestNodeVersion={fullRequestNodeVersion}
        enabledFeatureFlags={enabledFeatureFlags}
        stopBlockEnabled={stopBlockEnabled}
        node={selectedNode}
        onContinue={setNodeType}
        updateNode={updateNode}
        sendEmailStepEnabled={sendEmailStepEnabled}
        useEmailNodeIntegrationCheck={false}
      />
    );
  }

  if (
    selectedNode.type === NodeTypesEnum.Source &&
    sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger
  ) {
    return (
      <TriggerBlock
        workflowId={workflowId}
        node={selectedNode}
        onCancel={handleOnCancel}
        updateNode={updateNode}
        updateVariable={updateVariable}
        addVariable={addVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
        workflowMetadata={workflowMetadata}
      />
    );
  } else if (
    !fullRequestNodeVersion &&
    selectedNode.type === NodeTypesEnum.Request &&
    !isAdmin
  ) {
    return (
      <RequestBlockCustomer
        node={selectedNode}
        onCancel={handleOnCancel}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        onUpdateData={updateRequestNodeData}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (
    (fullRequestNodeVersion || isAdmin) &&
    selectedNode.type === NodeTypesEnum.Request
  ) {
    return (
      <RequestBlockAdmin
        workflowId={workflowId}
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        node={selectedNode}
        onCancel={handleOnCancel}
        onTransformApiReq={onTransformApiReq}
        onUpdateData={updateRequestNodeData}
        tableData={tableData}
        transformApiReqStatus={transformApiReqStatus}
        sourceType={sourceType}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  } else if (selectedNode.type === NodeTypesEnum.Document) {
    return (
      <DocumentsBlock
        node={selectedNode}
        updateNode={updateNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        addVariable={addVariable}
        updateVariable={updateVariable}
        onUploadFile={onUploadFile}
        onTransformApiReq={onTransformApiReq}
        transformApiReqStatus={transformApiReqStatus}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (selectedNode.type === NodeTypesEnum.Temporal) {
    return (
      <TemporalBlockAdmin
        node={selectedNode}
        onCancel={handleOnCancel}
        onUpdateData={(data) => {
          setNodes(
            nodes.map((_node) => {
              if (
                _node.id === selectedNode.id &&
                _node.type === NodeTypesEnum.Temporal
              ) {
                return {
                  ..._node,
                  data: {
                    ..._node.data,
                    ...data,
                    nodeStatus: _node.data.nodeStatus,
                  },
                };
              }
              return _node;
            }),
          );
        }}
        addVariable={addVariable}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        datasourceMetadata={datasourceMetadata}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
      />
    );
  } else if (selectedNode.type === NodeTypesEnum.Conditional) {
    return (
      <ConditionalBlock
        addVariable={addVariable}
        allowBranchReordering={allowBranchReordering}
        datasourceMetadata={datasourceMetadata}
        deleteBranch={deleteBranch}
        duplicateBranch={duplicateBranch}
        edges={edges}
        editingEdge={editingEdge}
        insertNode={insertNode}
        node={selectedNode}
        nodes={nodes}
        onCancel={handleOnCancel}
        onTransformApiReq={onTransformApiReq}
        onUpdateEdge={updateEdge}
        setEditingEdge={setEditingEdge}
        setEdges={setEdges}
        setNodes={setNodes}
        sourceType={sourceType}
        tableData={tableData}
        transformApiReqStatus={transformApiReqStatus}
        updateErrorOverlay={(val: boolean) => {
          updateNodeProps('errorOverlay', val);
        }}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateNode={updateNode}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        workflowId={workflowId}
      />
    );
  } else if (currentNodeType === 'email' && workflowMetadata) {
    return (
      <EmailBlock
        workflowMetadata={workflowMetadata}
        sourceType={sourceType}
        datasourceMetadata={datasourceMetadata}
        addVariable={addVariable}
        updateVariable={updateVariable}
        tableData={tableData}
        node={selectedNode as WorkflowEmailNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        transformApiReqStatus={transformApiReqStatus}
        onTransformApiReq={onTransformApiReq}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        onUpdateData={(data) => {
          setNodes(
            nodes.map((_node) => {
              if (
                _node.id === selectedNode.id &&
                _node.type === NodeTypesEnum.Email
              ) {
                return {
                  ..._node,
                  data: {
                    ..._node.data,
                    ...data,
                    nodeStatus: _node.data.nodeStatus,
                  },
                };
              }
              return _node;
            }),
          );
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'freeform') {
    return (
      <FreeformBlock
        workflowId={workflowId}
        sourceType={sourceType}
        datasourceMetadata={datasourceMetadata}
        node={selectedNode as WorkflowFreeformNode}
        onCancel={handleOnCancel}
        variablesMap={variablesMap}
        updateVariable={updateVariable}
        globalVariablesMap={globalVariablesMap}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'retry') {
    return (
      <RetryBlock
        workflowId={workflowId}
        node={selectedNode as WorkflowRetryNode}
        onCancel={handleOnCancel}
        updateNodeData={(data: object) => {
          updateNodeProps('data', {
            ...selectedNode.data,
            ...data,
          });
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'stop') {
    return (
      <StopBlock
        workflowId={workflowId}
        node={selectedNode as WorkflowStopNode}
        onCancel={handleOnCancel}
        updateNodeDataStatus={(val: string) => {
          updateNodeProps('data.status', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  }
  return (
    <AddRecordingBlock
      node={selectedNode as WorkflowContinueNode}
      onCancel={handleOnCancel}
      onImport={onImport}
    />
  );
}
