import { ApiCallMethods } from './Methods';
import { ApiCallSettings } from './Settings';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  ContentContainer,
  DataLoader,
  IconButton,
  ScrollableContainer,
  CustomTypography,
  HelpOutlineOutlined,
} from 'ui-kit';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import type {
  TargetMap,
  Variable,
  VariableMap,
  WorkflowNode,
} from 'types-shared';
import { NodeTypesEnum } from 'types-shared';
import type { WorkflowMetadataType } from 'api-types-shared';
import isEqual from 'lodash/isEqual';
import { getFilteredVariables } from '../../../utils/helper';
import { ComponentsToolbar } from '../../Editor/components/Toolbar/ComponentsToolbar';

interface GetRefProps {
  targetData: TargetMap;
  variableData: VariableMap;
}

interface Settings {
  retryInterval: number | string | null;
  maxAttempts: number | string | null;
}

interface Props {
  loading: boolean;
  workflowId: string;
  workflowMetadata: WorkflowMetadataType | null | undefined;
  nodeViewData: GetRefProps | undefined;
  openContactModal: () => void;
  nodes: WorkflowNode[];
  setMaxAttempts: (maxAttempts: number | null) => void;
  setRetryInterval: (retryInterval: number | null) => void;
  apiSettings: {
    retryInterval: number | null;
    maxAttempts: number | null;
  };
  extractDaysHoursMinutes: (minutes: number) => {
    days: number;
    hours: number;
    minutes: number;
  };
  getTotalMinutes: ({
    days,
    hours,
    minutes,
  }: {
    days: number;
    hours: number;
    minutes: number;
  }) => number;
  showApiPageRetryConfig: boolean;
  variablesMap: Record<string, Variable>;
}

export function Container({
  loading,
  workflowId,
  workflowMetadata,
  nodeViewData,
  openContactModal,
  nodes,
  apiSettings,
  setMaxAttempts,
  setRetryInterval,
  extractDaysHoursMinutes,
  getTotalMinutes,
  showApiPageRetryConfig,
  variablesMap,
}: Props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Record<string, string | null>>({});
  const [executionId, setExecutionId] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<'methods' | 'settings'>(
    'methods',
  );
  const [apiKey, setApiKey] = useState<string>('');

  const hasRetryNode = useMemo(() => {
    const node = nodes.find((_node) => _node.type === NodeTypesEnum.Retry);
    return Boolean(node);
  }, [nodes]);

  const filteredVariables = useMemo(() => {
    if (!nodeViewData?.variableData) {
      return undefined;
    }

    return getFilteredVariables(nodeViewData.variableData, nodes, variablesMap);
  }, [nodeViewData?.variableData, nodes, variablesMap]);

  const defaultSettings = {
    retryInterval: hasRetryNode ? 1440 : null,
    maxAttempts: hasRetryNode ? 3 : null,
  };
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const payloadData = useMemo(() => {
    if (filteredVariables) {
      const generatedPayloadData: Record<string, string | null> =
        Object.entries(filteredVariables).reduce(
          (acc: Record<string, string | null>, [_key, variable]) => {
            if (variable.name) {
              acc[variable.name] = '';
            }
            return acc;
          },
          {},
        );

      return generatedPayloadData;
    }
    return {};
  }, [filteredVariables]);

  const inputFields = useMemo(() => {
    if (filteredVariables) {
      return Object.entries(filteredVariables).map((item) => item);
    }
    return [];
  }, [filteredVariables]);

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: 'methods' | 'settings',
  ) => {
    setSelectedTab(newValue);
    setExecutionId('');
  };

  useEffect(() => {
    setFormData((data) => {
      if (isEqual(data, payloadData)) {
        return data;
      }
      return {
        ...payloadData,
        ...data,
      };
    });
  }, [payloadData]);

  useEffect(() => {
    if (apiSettings.retryInterval ?? apiSettings.maxAttempts) {
      setSettings(apiSettings);
    }
  }, [apiSettings]);

  // FIXME: Pop state seems to be deleting history entries, so we need to prevent that
  useEffect(() => {
    const handlePopState = () => {
      navigate(`/editor/${workflowId}`);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, workflowId]);

  return (
    <ScrollableContainer>
      {loading ? (
        <DataLoader loadingText="Fetching API call details" />
      ) : (
        <>
          <ComponentsToolbar
            containerClassName="!py-4"
            hasOuterContainer={false}
            crumbs={[
              { title: workflowMetadata?.workflowName ?? 'Workflow' },
              { title: 'API Call Details' },
            ]}
            onClose={() => {
              navigate(`/editor/${workflowId}`);
            }}
            RightEndContent={
              <IconButton
                className="rounded-lg group"
                onClick={openContactModal}
              >
                <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
              </IconButton>
            }
          />

          <ContentContainer withToolbar>
            <CustomTypography
              className="!font-medium !text-2xl text-info-dark"
              variant="h4"
            >
              API Call
            </CustomTypography>
            <p className="text-color-grey text-sm mb-6">
              Manage remote workflow executions programmatically
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={onTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
              >
                <Tab label="METHODS" value="methods" />
                {/* {showApiPageRetryConfig ? (
                  <Tab label="SETTINGS" value="settings" />
                ) : (
                  <Tooltip arrow title="Contact us to enable this setting">
                    <Tab disabled label="SETTINGS" value="settings" />
                  </Tooltip>
                )} */}
              </Tabs>
            </Box>
            {selectedTab === 'methods' ? (
              <ApiCallMethods
                apiKey={apiKey}
                executionId={executionId}
                formData={formData}
                hasRetryNode={hasRetryNode}
                inputFields={inputFields}
                payloadData={payloadData}
                setApiKey={setApiKey}
                setExecutionId={setExecutionId}
                setFormData={setFormData}
                settings={settings}
                showApiPageRetryConfig={showApiPageRetryConfig}
                workflowId={workflowId}
              />
            ) : null}
            {selectedTab === 'settings' ? (
              <ApiCallSettings
                apiSettings={apiSettings}
                extractDaysHoursMinutes={extractDaysHoursMinutes}
                getTotalMinutes={getTotalMinutes}
                hasRetryNode={hasRetryNode}
                setMaxAttempts={setMaxAttempts}
                setRetryInterval={setRetryInterval}
                setSettings={setSettings}
                settings={settings}
              />
            ) : null}
          </ContentContainer>
        </>
      )}
    </ScrollableContainer>
  );
}
