import { z } from 'types-shared';
// MARK: - Validators for string values
export var nonEmptyValidator = function (value) {
    if (value.trim().length > 0) {
        return { valid: true };
    }
    // TODO: Refine copy
    return { valid: false, reasons: ['Value cannot be empty.'] };
};
export var isEmailValidator = function (value) {
    try {
        z.string().email().parse(value);
        return { valid: true };
    }
    catch (_a) {
        // TODO: Refine copy
        return { valid: false, reasons: ['Value must be a valid email address.'] };
    }
};
// NOTE: Currently unused -- example of a validator with a custom message
export var constructEmailValidator = function (message) {
    return function (value) {
        try {
            z.string().email().parse(value);
            return { valid: true };
        }
        catch (_a) {
            return { valid: false, reasons: [message] };
        }
    };
};
