import dayjs, { type Dayjs } from 'dayjs';
import {
  type ActionsEnum,
  type TemplateData,
  type DatasourceTable,
  type DatasourceMetadata,
  type Variable,
  type WorkflowAction,
  type VariableMap,
  ExecutionVariableNameEnum,
} from 'types-shared';
import { createEventBus, type EventMap } from 'ui-kit';

export enum VariableEventsEnum {
  EDIT_VARIABLE = 'EDIT_VARIABLE',
  NEW_VARIABLE = 'NEW_VARIABLE',
  VARIABLE_PREVIEW = 'VARIABLE_PREVIEW',
}

export interface VariableModalOpenPayload {
  modalAction: VariableEventsEnum;
  actionType?: ActionsEnum;
  variableId?: string;
  isCondition?: boolean;
  updateBranchData?: (variable: Variable, isAdding?: boolean) => void;
  insertVariable?: (variable: Variable) => void;
}

export const defaultModalInput = {
  modalAction: VariableEventsEnum.VARIABLE_PREVIEW,
  actionType: undefined,
  variableId: undefined,
  isCondition: false,
  tableData: null,
  datasourceMetadata: null,
  updateBranchData: undefined,
  insertVariable: undefined,
  indexForVariableInsert: undefined,
};

export interface VariableModalEvents extends EventMap {
  open: (payload: VariableModalOpenPayload) => void;
  submit: () => void;
}

export const variableModalEventChannel = createEventBus<VariableModalEvents>();

export interface FormValues {
  variableName: string;
}

export const defaultFormValue: FormValues = {
  variableName: '',
};

export interface TransformationFormValues {
  name: string;
  key: TemplateData;
  transformQuery?: TemplateData;
  previewTransform?: string;
  initialValue?: string;
  valueType?: string;
  sourceId?: string;
  model?: string;
}

export const defaultTransformationValues: TransformationFormValues = {
  name: '',
  key: [],
  transformQuery: [],
  previewTransform: undefined,
  initialValue: '',
  valueType: 'String',
  sourceId: '',
  model: '',
};

export interface BaseVariablePreviewProps {
  variable: Variable;
  onCancel: () => void;
  className?: string;
  modalAction: VariableEventsEnum;
  changeModalAction: (newAction: VariableEventsEnum) => void;
}

export type DocumentVariablePreviewProps = BaseVariablePreviewProps;

export interface ScrapeVariablesModalProps extends BaseVariablePreviewProps {
  action: WorkflowAction;
  canAddNew: boolean;
}

export interface PreviewVariableProps {
  variable: Variable;
  onCancel: () => void;
  breadCrumb?: React.ReactNode;
  className?: string;
  onEdit: () => void;
  edgeName?: string;
  variables: Record<string, Variable>;
  globalVariables: Record<string, Variable>;
  updateVariable: (variable: Variable) => void;
  tableData: DatasourceTable | null;
  datasourceMetadata: DatasourceMetadata | null;
  label?: string;
  isCondition?: boolean;
  showTitle?: boolean;
  transformationResult?: string;
  variablesMap: VariableMap;
  changeModalAction: (newAction: VariableEventsEnum) => void;
  transformationKey?: TemplateData;
}

const getPreviousWeekday = (date = dayjs()): Dayjs => {
  let previousDay = date.subtract(1, 'day');

  if (previousDay.day() === 6) {
    previousDay = previousDay.subtract(1, 'day');
  } else if (previousDay.day() === 0) {
    previousDay = previousDay.subtract(2, 'day');
  }

  return previousDay;
};

export const getInitialValue = (variableName: string): string => {
  const dateFormat = 'dddd, MMMM D, YYYY h:mm A';
  switch (variableName) {
    case ExecutionVariableNameEnum.TodayDate.toString():
      return dayjs().format(dateFormat);
    case ExecutionVariableNameEnum.YesterdayDate.toString():
      return dayjs().subtract(1, 'day').format(dateFormat);
    case ExecutionVariableNameEnum.PreviousWeekdayDate.toString():
      return getPreviousWeekday().format(dateFormat);
    default:
      return '';
  }
};

export const submitTitles: Record<VariableEventsEnum, string> = {
  [VariableEventsEnum.EDIT_VARIABLE]: 'Save changes',
  [VariableEventsEnum.VARIABLE_PREVIEW]: 'Edit variable',
  [VariableEventsEnum.NEW_VARIABLE]: 'Add variable',
};
