import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { clsx } from 'clsx';
import { useMemo } from 'react';
import type { EventMap } from 'ui-kit';
import { AlertVariant, createEventBus, notify } from 'ui-kit';
import {
  NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  WorkflowSourceNode,
  type WorkflowImageNode,
  type WorkflowNode,
} from 'types-shared';
import { areAllNodesValid } from '../hooks/useNodeValidation';
import { EditorStore } from '../store/EditorState';
import { isAdmin } from '../../../utils/env';

interface NodeSelectionEvents extends EventMap {
  onToggleSelection: (mode: NodeSelectionModeEnums | null) => void;
}
export const nodeSelectEventChannel = createEventBus<NodeSelectionEvents>();

interface Props {
  nodes: WorkflowNode[];
  onEnableSelectionMode: () => void;
  onApplyChanges: (mode: NodeSelectionModeEnums) => void;
  onCancel: () => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  allowNodesMerging?: boolean;
  showSelectAllButton?: boolean;
  selectedMode: NodeSelectionModeEnums | null;
  setSelectedMode: (newMode: NodeSelectionModeEnums | null) => void;
}

export function ActionsHeader({
  nodes,
  onApplyChanges,
  onCancel,
  onEnableSelectionMode,
  allowNodesMerging = false,
  showSelectAllButton = false,
  selectedMode,
  setSelectedMode,
}: Props) {
  const isMergeMode = selectedMode === NodeSelectionModeEnums.MergeNodes;
  const { globalVariables, variables, setNodes, workflowId } = EditorStore();

  const allNodesChecked = useMemo(() => {
    const allNodes = nodes.filter(
      ({ type }) =>
        ![NodeTypesEnum.Source, NodeTypesEnum.Temporal].includes(type),
    );

    const _selectedNodes = allNodes.filter(
      (node) => node.data.nodeStatus === NodeStatusEnum.Checked,
    );

    return allNodes.length === _selectedNodes.length;
  }, [nodes]);

  const selectedNodes = useMemo(
    () =>
      nodes.filter((node) => {
        if (WorkflowSourceNode.safeParse(node).success) return false;
        if (!isAdmin && node.hideFromUser) return false;

        if (selectedMode === NodeSelectionModeEnums.BulkCheck) {
          return (
            (node as WorkflowImageNode).data.nodeStatus ===
            NodeStatusEnum.Checked
          );
        }

        return (node as WorkflowImageNode).data.selected;
      }),
    [nodes, selectedMode],
  );

  const startSelectionFlow = (mode: NodeSelectionModeEnums) => {
    setSelectedMode(mode);
    nodeSelectEventChannel.emit('onToggleSelection', mode);
  };

  const stopSelectionFlow = () => {
    setSelectedMode(null);
    nodeSelectEventChannel.emit('onToggleSelection', null);
  };

  const handleApplyChanges = () => {
    if (selectedMode) {
      onApplyChanges(selectedMode);
    }
    stopSelectionFlow();
  };

  const handleCancelChanges = () => {
    onCancel();
    stopSelectionFlow();
  };

  const toggleNodes = () => {
    const { allNodesValid, allUserFacingNodesValid, validations } =
      areAllNodesValid(
        nodes,
        globalVariables ?? {},
        variables,
        workflowId ?? '',
      );

    if (!allNodesValid) {
      let message =
        'Please fix the issues in the highlighted nodes before continuing.';

      // If this is an admin user, we will give more info on if the issue lies in hidden nodes or in the user nodes.
      if (isAdmin) {
        if (!allUserFacingNodesValid) {
          message += '\n - Some user facing nodes are invalid.';
        }

        notify({
          message,
          variant: AlertVariant.WARNING,
        });

        if (!allUserFacingNodesValid) return;
      } else if (!allUserFacingNodesValid) {
        // If it is not an admin user, we will just notify them that there are issues in the user facing nodes.
        notify({
          message,
          variant: AlertVariant.WARNING,
        });

        return;
      }
    }

    const filteredNodes = nodes.map((node) => {
      // If this is not an admin user, we will only mark the user facing nodes as checked
      if (node.hideFromUser && !isAdmin) {
        return node;
      }

      const validation = validations.find((v) => v.nodeId === node.id);
      const canCheck =
        node.hideFromUser ||
        Boolean(
          validation?.isValid &&
            node.data.nodeStatus !== NodeStatusEnum.Checked,
        );
      const isChecking = !allNodesChecked;
      let newNodeStatus: NodeStatusEnum;

      // Always check hidden nodes
      if (node.hideFromUser) {
        newNodeStatus = NodeStatusEnum.Checked;
      } else {
        newNodeStatus =
          canCheck && isChecking
            ? NodeStatusEnum.Checked
            : NodeStatusEnum.NotViewed;
      }

      // If this is an admin user, we will mark all nodes that are valid as checked because at this point allUserFacingNodesValid is true
      return {
        ...node,
        data: {
          ...node.data,
          selected: false,
          nodeStatus: newNodeStatus,
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
    stopSelectionFlow();

    if (allUserFacingNodesValid) {
      notify({
        message:
          'All user facing nodes have been validated and changes applied successfully.',
        variant: AlertVariant.SUCCESS,
      });
    }
  };

  return (
    <div
      className={clsx(
        'zoom-adjusted-container',
        selectedMode && 'bg-white',
        'px-5 py-3 min-h-[4.5rem] flex items-center  absolute top-0 left-0 z-10 w-full border-t transition-all duration-200',
      )}
    >
      <div className="flex gap-2 w-full">
        {selectedMode ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="p-0 text-sm font-medium text-info-dark">
                {selectedMode === NodeSelectionModeEnums.BulkCheck
                  ? 'Quick Review Steps'
                  : 'Merge Steps'}
              </span>
              <Divider
                orientation="vertical"
                sx={{
                  height: '1rem',
                  mx: 2,
                  borderColor: '#7A859C',
                  borderWidth: '0.5px',
                }}
              />
              <span className="font-medium text-sm text-info-light mr-4">
                {selectedNodes.length}
                {selectedMode === NodeSelectionModeEnums.BulkCheck
                  ? ` new step${selectedNodes.length > 1 ? 's' : ''} checked as done`
                  : ` new step${selectedNodes.length > 1 ? 's' : ''} selected to merge`}
              </span>
              {selectedMode === NodeSelectionModeEnums.BulkCheck &&
              showSelectAllButton ? (
                <Button
                  color="secondary"
                  onClick={toggleNodes}
                  variant="outlined"
                >
                  {allNodesChecked ? 'Uncheck All' : 'Check All'}
                </Button>
              ) : null}
            </div>
            <div className="flex items-center gap-2">
              <Button
                color="secondary"
                disabled={Boolean(isMergeMode && selectedNodes.length < 2)}
                onClick={handleApplyChanges}
                variant="outlined"
              >
                {isMergeMode ? 'Merge Nodes' : 'Apply Changes'}
              </Button>
              <Button
                color="secondary"
                onClick={handleCancelChanges}
                variant="text"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row gap-3">
            <Button
              className={clsx({
                'h-10 min-w-40 !text-info-dark !rounded !px-4 !bg-white':
                  !allowNodesMerging,
              })}
              color="secondary"
              onClick={() => {
                startSelectionFlow(NodeSelectionModeEnums.BulkCheck);
                onEnableSelectionMode();
              }}
              variant={allowNodesMerging ? 'contained' : 'text'}
            >
              Quick Review Steps
            </Button>
            {allowNodesMerging ? (
              <Button
                color="secondary"
                onClick={() => {
                  startSelectionFlow(NodeSelectionModeEnums.MergeNodes);
                }}
                variant="contained"
              >
                Merge Steps
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
