"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalNotificationEventFilter = exports.InternalNotificationEventType = void 0;
var zod_1 = require("zod");
var helper_types_1 = require("../helper-types");
var InternalNotificationEventType;
(function (InternalNotificationEventType) {
    InternalNotificationEventType["DesktopHITL"] = "internal_desktop_hitl";
    InternalNotificationEventType["DesktopSessionStarted"] = "internal_desktop_session_started";
    InternalNotificationEventType["DesktopSessionFailure"] = "internal_desktop_session_failure";
    InternalNotificationEventType["ExecutionFailedToNotify"] = "internal_execution_failed_to_notify";
    InternalNotificationEventType["EmailTriggerQueuedExecutions"] = "internal_email_trigger_queued_executions";
    InternalNotificationEventType["EmailTriggerFailedToEvaluateCEL"] = "internal_email_trigger_failed_to_evaluate_cel";
    InternalNotificationEventType["EmailTriggerFailedToQueueExecution"] = "internal_email_trigger_failed_to_queue_execution";
    InternalNotificationEventType["EmailTriggerDuplicateSkipped"] = "internal_email_trigger_duplicate_skipped";
    InternalNotificationEventType["LongRunningExecution"] = "internal_long_running_execution";
})(InternalNotificationEventType || (exports.InternalNotificationEventType = InternalNotificationEventType = {}));
exports.InternalNotificationEventFilter = zod_1.z.object({
    // TODO: Use '*' for catch-all (?)
    eventTypes: zod_1.z.array(zod_1.z.nativeEnum(InternalNotificationEventType)).optional(),
    // TODO: Use '*' for catch-all (?)
    workflowIds: zod_1.z.array(helper_types_1.uuidSchema).optional(),
});
