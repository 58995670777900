"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalNotificationConfiguration = exports.WorkflowNotificationConfigurationAdmin = exports.WorkflowNotificationConfiguration = void 0;
const types_shared_1 = require("types-shared");
const graphql_1 = require("../generated/graphql");
exports.WorkflowNotificationConfiguration = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    workflowId: types_shared_1.z.string(),
    platform: types_shared_1.z.nativeEnum(graphql_1.Notifications_Enum_Platform_Enum),
    platformMetadata: types_shared_1.PlatformMetadataSchema,
    events: types_shared_1.z.array(types_shared_1.z.object({
        event: types_shared_1.z.nativeEnum(graphql_1.Notifications_Enum_Workflow_Event_Enum),
    })),
});
exports.WorkflowNotificationConfigurationAdmin = exports.WorkflowNotificationConfiguration.extend({
    tag: types_shared_1.z.string(),
    ownerId: types_shared_1.z.string(),
});
exports.InternalNotificationConfiguration = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    eventFilter: types_shared_1.InternalNotificationEventFilter,
    platform: types_shared_1.z.nativeEnum(graphql_1.Notifications_Enum_Platform_Enum),
    platformMetadata: types_shared_1.PlatformMetadataSchema,
});
