import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function RunningExecutionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.5H6M1 10.3002V4.7002C1 3.58009 1 3.01962 1.21799 2.5918C1.40973 2.21547 1.71547 1.90973 2.0918 1.71799C2.51962 1.5 3.08009 1.5 4.2002 1.5H13.8002C14.9203 1.5 15.4796 1.5 15.9074 1.71799C16.2837 1.90973 16.5905 2.21547 16.7822 2.5918C17 3.0192 17 3.57899 17 4.69691V10.3031C17 11.421 17 11.98 16.7822 12.4074C16.5905 12.7837 16.2837 13.0905 15.9074 13.2822C15.48 13.5 14.921 13.5 13.8031 13.5H4.19691C3.07899 13.5 2.5192 13.5 2.0918 13.2822C1.71547 13.0905 1.40973 12.7837 1.21799 12.4074C1 11.9796 1 11.4203 1 10.3002ZM11.5 7.5L7 4.5V10.5L11.5 7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
