import { useState, useCallback, useMemo } from 'react';
import {
  NodeStatusEnum,
  type VariableMap,
  type WorkflowNode,
} from 'types-shared/workflowTypes';
import {
  type ConditionalValidationFunction,
  type EmailValidationFunction,
  type RequestValidationFunction,
  type ValidationFunction,
} from '../utils/nodeValidations';
import nodeValidations from '../utils/nodeValidations';

interface UseNodeValidationProps<_T> {
  node: WorkflowNode;
  variablesMap: VariableMap;
  globalVariablesMap: VariableMap;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  workflowId: string;
  validationFunction:
    | ValidationFunction<WorkflowNode>
    | ConditionalValidationFunction<WorkflowNode>
    | RequestValidationFunction<WorkflowNode>
    | EmailValidationFunction<WorkflowNode>;
}

export function useNodeValidation<T>({
  node,
  variablesMap,
  globalVariablesMap,
  updateNodeStatus,
  validationFunction,
  workflowId,
}: UseNodeValidationProps<T>) {
  const [validationAttempted, setValidationAttempted] = useState(false);

  const handleUpdateNodeStatus = useCallback(
    (status: NodeStatusEnum) => {
      if (status === NodeStatusEnum.Checked) {
        setValidationAttempted(false);
      }
      updateNodeStatus(status);
    },
    [updateNodeStatus],
  );

  const validationResult = useMemo(() => {
    const payload = validationFunction({
      node,
      variableMap: variablesMap,
      globalVariablesMap,
      workflowId,
    });
    const hasErrors = payload.errors.length > 0;
    if (hasErrors && node.data.nodeStatus === NodeStatusEnum.Checked) {
      handleUpdateNodeStatus(NodeStatusEnum.NotViewed);
    }
    return payload;
  }, [
    node,
    variablesMap,
    globalVariablesMap,
    handleUpdateNodeStatus,
    validationFunction,
    workflowId,
  ]);

  const handleValidateNode = useCallback(() => {
    const hasErrors = validationResult.errors.length > 0;

    setValidationAttempted(true);

    if (hasErrors) {
      updateNodeStatus(NodeStatusEnum.NotViewed);
    } else {
      updateNodeStatus(
        node.data.nodeStatus === NodeStatusEnum.Checked
          ? NodeStatusEnum.NotViewed
          : NodeStatusEnum.Checked,
      );
    }
  }, [validationResult, updateNodeStatus, node.data.nodeStatus]);

  return {
    validationAttempted,
    validationResult: validationResult as T,
    handleValidateNode,
    handleUpdateNodeStatus,
  };
}

export const areAllNodesValid = (
  nodes: WorkflowNode[],
  globalVariablesMap: VariableMap,
  variableMap: VariableMap,
  workflowId: string,
) => {
  const validations = nodes.map((node) => {
    const validationFunction = nodeValidations[node.type];
    const validationResult = validationFunction({
      node,
      variableMap,
      globalVariablesMap,
      workflowId,
    });

    return {
      nodeId: node.id,
      isValid: validationResult.errors.length === 0,
      isUserFacing: !node.hideFromUser,
    };
  });

  const allUserFacingNodesValid = validations
    .filter((v) => v.isUserFacing)
    .every((v) => v.isValid);

  const allHiddenNodesValid = validations
    .filter((v) => !v.isUserFacing)
    .every((v) => v.isValid);

  const allNodesValid = allHiddenNodesValid && allUserFacingNodesValid;

  return {
    allNodesValid,
    allUserFacingNodesValid,
    allHiddenNodesValid,
    validations,
  };
};
