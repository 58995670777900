"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cloneWorkflowSchema = void 0;
const types_shared_1 = require("types-shared");
exports.cloneWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({}),
        body: types_shared_1.z.object({
            workflowName: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        newWorkflowId: types_shared_1.z.string(),
        newWorkflowName: types_shared_1.z.string(),
    }),
};
