"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCondition = exports.isGroup = exports.Rule = exports.BranchRef = exports.Group = exports.Condition = exports.Operator = exports.OperatorEnum = exports.LogicDataObj = exports.LogicDataType = exports.LogicDataTypeEnum = void 0;
var zod_1 = require("zod");
var legacy_schema_1 = require("../workflowTypes/variable/legacy.schema");
var comparator_schema_1 = require("./comparator.schema");
var LogicDataTypeEnum;
(function (LogicDataTypeEnum) {
    LogicDataTypeEnum["String"] = "String";
    LogicDataTypeEnum["Number"] = "Number";
    LogicDataTypeEnum["Date"] = "Date";
})(LogicDataTypeEnum || (exports.LogicDataTypeEnum = LogicDataTypeEnum = {}));
exports.LogicDataType = zod_1.z.nativeEnum(LogicDataTypeEnum);
exports.LogicDataObj = zod_1.z.union([zod_1.z.string(), zod_1.z.number(), zod_1.z.date()]);
var OperatorEnum;
(function (OperatorEnum) {
    OperatorEnum["And"] = "And";
    OperatorEnum["Or"] = "Or";
})(OperatorEnum || (exports.OperatorEnum = OperatorEnum = {}));
exports.Operator = zod_1.z.nativeEnum(OperatorEnum);
exports.Condition = zod_1.z
    .object({
    dataType: exports.LogicDataType.optional(),
    field: legacy_schema_1.VariableIdContainer,
    comparator: comparator_schema_1.Comparator.optional(),
    value: legacy_schema_1.VariableIdContainer,
    id: zod_1.z.string(),
})
    .strict();
var baseGroupSchema = zod_1.z.object({
    operator: exports.Operator,
    id: zod_1.z.string(),
    not: zod_1.z.boolean().optional(),
});
exports.Group = baseGroupSchema.merge(zod_1.z
    .object({
    elements: zod_1.z.array(zod_1.z.lazy(function () { return exports.Condition.or(exports.Group); })),
})
    .strict());
// Do away with this
exports.BranchRef = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
});
exports.Rule = zod_1.z.object({
    data: exports.Group,
    output: legacy_schema_1.VariableIdContainer.array().or(exports.BranchRef.array()),
});
var isGroup = function (element) {
    return exports.Group.safeParse(element).success;
};
exports.isGroup = isGroup;
var isCondition = function (element) {
    return exports.Condition.safeParse(element).success;
};
exports.isCondition = isCondition;
