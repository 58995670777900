"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeApiVariableInputs = void 0;
var uuid_1 = require("uuid");
var schemas_1 = require("./schemas");
var normalizeApiVariableInputs = function (inputs) {
    if (Array.isArray(inputs)) {
        return inputs
            .map(function (input) {
            var parsedInput = schemas_1.ApiVariableInput.parse(input);
            switch (parsedInput.type) {
                case 'single':
                    return [
                        {
                            name: parsedInput.name,
                            value: parsedInput.value,
                            groupId: (0, uuid_1.v4)(),
                            orderInGroup: 0,
                        },
                    ];
                case 'multiple': {
                    var groupId_1 = (0, uuid_1.v4)();
                    return parsedInput.values.map(function (value, i) {
                        return {
                            name: parsedInput.name,
                            value: value,
                            groupId: groupId_1,
                            orderInGroup: i,
                        };
                    });
                }
                default:
                    // NOTE: This case should never happen!
                    throw new Error("Invalid input variable type: ".concat(schemas_1.ApiVariableInput.parse(input).type));
            }
        })
            .flat();
    }
    return Object.entries(inputs).map(function (_a) {
        var name = _a[0], value = _a[1];
        return {
            name: name,
            value: value,
            groupId: (0, uuid_1.v4)(),
            orderInGroup: 0,
        };
    });
};
exports.normalizeApiVariableInputs = normalizeApiVariableInputs;
