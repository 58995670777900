import { useQueryClient } from '@tanstack/react-query';
import { AdminVersionEnum } from 'api-types-shared';
import cloneDeep from 'lodash/cloneDeep';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import {
  type ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { handleException } from 'sentry-browser-shared';
import {
  DocumentSourceEnum,
  DocumentVariable,
  GlobalVariable,
  MOCK_USER_ID,
  type NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  SourceTypeEnum,
  SourceVariable,
  type TargetStateData,
  type VariableMap,
  type VariableStateData,
  VariableTypeEnum,
  type WorkflowData,
  type WorkflowImageNode,
  type WorkflowNode,
} from 'types-shared';
import type { EdgeTypes, NodeProps } from 'types-shared/reactflow';
import 'types-shared/reactflow.css';
import { AlertVariant, modalEventChannel, notify } from 'ui-kit';
import { v4 as uuid } from 'uuid';
import { useShallow } from 'zustand/react/shallow';
import usePrompt from '../../hooks/usePrompt';
import { FeatureFlag } from '../../utils/constants';
import { isAdmin } from '../../utils/env';
import { getExtensionData } from '../../utils/extension';
import {
  createVariableMap,
  sleep,
  useFeatureFlag,
  useGetActiveFeatureFlags,
} from '../../utils/helper';
import { getTabTitle } from '../../utils/tabTitle';
import {
  useFetchDatasourceTable,
  useGetDatasourceForWorkflow,
} from '../Datasource/hooks';
import { useGetGlobalVariables } from '../GlobalVariables/hooks.gql';
import { useWorkflowFiles } from '../WorkflowFiles/hooks';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import ActionsManager from './components/ActionsManager';
import { type RenderPdfProps } from './components/ActionsManager/DocumentVariables/AnnotatedPDF';
import { ActionViewCore } from './components/ActionViewCore';
import CustomEdge from './components/EdgeElement/CustomEdge';
import { EditorCore } from './components/EditorCore';
import AddRecordingNode from './components/NodeElement/AddRecordingNode';
import ConditionalNode from './components/NodeElement/ConditionalNode';
import DocumentNode from './components/NodeElement/DocumentNode';
import EmailNode from './components/NodeElement/EmailNode';
import EndingNode from './components/NodeElement/EndingNode';
import FreeformNode from './components/NodeElement/FreeformNode';
import ImageNode from './components/NodeElement/ImageNode';
import PlaceholderNode from './components/NodeElement/PlaceholderNode';
import RequestNode from './components/NodeElement/RequestNode';
import SelectedImageNodeContent from './components/NodeElement/SelectedImageNodeContent';
import SourceNode from './components/NodeElement/SourceNode';
import TemporalNode from './components/NodeElement/TemporalNode';
import Toolbar from './components/Toolbar/UserToolbar';
import {
  useAutolinkTaskPoller,
  useGetAllThumbnails,
  useGetRefData,
  useGetWorkflowData,
  useImportSubWorkflow,
  useRestoreWorkflowVersion,
  useSendSlackMessageWithFile,
  useTransformApiReq,
  useUpdateWorkflow,
  useUploadFiles,
} from './hooks';
import { useEditingNodeId } from './hooks/useEditingNodeId';
import useReadonlyWorkflow from './hooks/useReadonlyWorkflow';
import { versionHistoryEventBus } from './hooks/useVersionHistory';
import type {
  EditorStoreProps,
  EditorWorkflowDataProps,
} from './store/EditorState';
import { EditorStore } from './store/EditorState';
import {
  createEmailTriggerVariables,
  extractGlobalVariablesFromTemplates,
  getNonExecutionVariables,
  getWorkflowVersionType,
  isUUID,
  showVersionDiffDialog,
} from './utils/helper';
import { useNavigateBetweenNodes } from './components/NodeHeader/hooks';

const nodeTypes: Record<NodeTypesEnum, ComponentType<NodeProps>> = {
  image: ImageNode,
  source: SourceNode,
  new: PlaceholderNode,
  conditional: ConditionalNode,
  freeform: FreeformNode,
  document: DocumentNode,
  stop: EndingNode,
  retry: EndingNode,
  continue: AddRecordingNode,
  request: RequestNode,
  temporal: TemporalNode,
  email: EmailNode,
};

const pushTypeOptions = [
  AdminVersionEnum.NotifyPush,
  AdminVersionEnum.SilentPush,
  AdminVersionEnum.ForcePush,
];

const edgeTypes: EdgeTypes = {
  default: CustomEdge,
};

export default function Editor(): JSX.Element {
  const navigate = useNavigate();
  const { handleCloseNode } = useNavigateBetweenNodes();
  const [, setSearchParams] = useSearchParams();
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const { data: workflowFiles = [], isLoading: isLoadingWorkflowFiles } =
    useWorkflowFiles(workflowId);

  if (!isUUID(workflowId)) {
    navigate('/');
  }

  const {
    nodes,
    edges,
    datasourceMetadata,
    tableData,
    setNodes,
    setEdges,
    updateNode,
    addNodes,
    addEdges,
    setWorkflowId,
    resetWorkflow,
    setTargets,
    resetTargets,
    setVariables,
    setGlobalVariables,
    resetVariables,
    setDatasourceMetadata,
    setDatasourceTable,
    resetDatasource,
    setThumbnails,
    onNodesChange,
    onEdgesChange,
    onConnect,
    selectedNode,
    setSelectedNode,
    variables: variablesMap,
    globalVariables: globalVariablesMap,
    targets,
    updateVariable,
    updateVariableData,
    addVariable,
    currentVersionId,
    setCurrentVersionId,
    currentViewport,
    setCurrentViewport,
    localWorkflowId,
    resetUnsavedChanges,
    unsavedChanges,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      localWorkflowId: state.workflowId,
      selectedNode: state.selectedNode,
      setSelectedNode: state.setSelectedNode,
      nodes: state.nodes,
      edges: state.edges,
      addEdges: state.addEdges,
      datasourceMetadata: state.datasourceMetadata,
      tableData: state.tableData,

      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setWorkflowId: state.setWorkflowId,
      resetWorkflow: state.resetWorkflow,

      updateNode: state.updateNode,
      addNodes: state.addNodes,

      setTargets: state.setTargets,
      resetTargets: state.resetTargets,

      setVariables: state.setVariables,
      setGlobalVariables: state.setGlobalVariables,
      resetVariables: state.resetVariables,

      setThumbnails: state.setThumbnails,

      setDatasourceMetadata: state.setDatasourceMetadata,
      setDatasourceTable: state.setDatasourceTable,
      resetDatasource: state.resetDatasource,

      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      onConnect: state.onConnect,
      variables: state.variables,
      globalVariables: state.globalVariables,

      targets: state.targets,
      updateVariable: state.updateVariable,
      addVariable: state.addVariable,
      addVariables: state.addVariables,
      addTargets: state.addTargets,
      currentVersionId: state.currentVersionId,
      setCurrentVersionId: state.setCurrentVersionId,
      currentViewport: state.currentViewport,
      setCurrentViewport: state.setCurrentViewport,
      updateVariableData: state.updateVariableData,
      unsavedChanges: state.unsavedChanges,
      resetUnsavedChanges: state.resetUnsavedChanges,
    })),
  );

  const editorState = useMemo(
    () => ({
      nodes,
      edges,
      variables: variablesMap,
      globalVariables: globalVariablesMap,
      targets,
      currentVersionId,
      datasourceMetadata,
      tableData,
    }),
    [
      nodes,
      edges,
      variablesMap,
      globalVariablesMap,
      targets,
      currentVersionId,
      datasourceMetadata,
      tableData,
    ],
  );
  const editorStateRef = useRef<EditorWorkflowDataProps>(editorState);
  editorStateRef.current = editorState;

  const [annotatedPDFData, setAnnotatedPDFData] = useState<RenderPdfProps>();
  const [rejectImprovementsLoading, setRejectImprovementsLoading] =
    useState(false);

  const isReadonlyView = useReadonlyWorkflow();
  const hideEditorButton = useFeatureFlag(FeatureFlag.HideEditorButton, false);

  const downloadCloudVersion = useCallback(() => {
    localStorage.removeItem(workflowId);
    window.location.reload();
  }, [workflowId]);

  const resetCurrentVersion = useCallback(() => {
    setCurrentVersionId(undefined);
  }, [setCurrentVersionId]);

  const { mutateAsync: importSubWorkflow } =
    useImportSubWorkflow(resetCurrentVersion);
  const { data: workflowMetadata, refetch: refetchWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);
  const [localWorkflowData, setLocalWorkflowData] = useState<WorkflowData>({
    nodes: [],
    edges: [],
  });
  const currentCloudVersionId = workflowMetadata?.currentVersionId;

  const versionsList = useMemo(() => {
    return workflowMetadata?.versionConfigs
      ? Object.entries(workflowMetadata.versionConfigs)
          .sort(([, a], [, b]) => {
            const getTime = (dateStr: string | undefined) =>
              new Date(
                dateStr ??
                  workflowMetadata.committedAt ??
                  workflowMetadata.createdAt,
              ).getTime();
            return getTime(b.committedAt) - getTime(a.committedAt);
          })
          .map(([key, version]) => ({
            ...version,
            versionId: key,
          }))
      : [];
  }, [
    workflowMetadata?.versionConfigs,
    workflowMetadata?.committedAt,
    workflowMetadata?.createdAt,
  ]);

  const filteredVersions = useMemo(
    () =>
      versionsList.filter(
        (version) =>
          !version.commitUsers?.includes(MOCK_USER_ID) &&
          intersection(version.commitUsers ?? [], pushTypeOptions).length === 0,
      ),
    [versionsList],
  );

  const {
    isNotifyPush,
    isSilentPush,
    // isErrorPush,
    isErrorForcePush,
    isErrorPushType,
  } = useMemo(
    () => getWorkflowVersionType(workflowId, workflowMetadata),
    [workflowId, workflowMetadata],
  );

  const { mutateAsync: updateWorkflow, status: updateWorkflowStatus } =
    useUpdateWorkflow(resetCurrentVersion, downloadCloudVersion);

  const { hasNodesData, hasVariablesData } = useMemo(() => {
    const persistedDataStr = localStorage.getItem(workflowId);
    if (!persistedDataStr) {
      return {
        hasNodesData: false,
        hasVariablesData: false,
      };
    }
    const { state } = JSON.parse(persistedDataStr) as {
      state: WorkflowData & VariableStateData & TargetStateData;
    };
    const nonExecutionVariables = getNonExecutionVariables(state.variables);
    return {
      hasNodesData: state.nodes.length > 0 && state.edges.length > 0,
      hasVariablesData:
        nonExecutionVariables.length > 0 && size(state.targets) > 0,
    };
  }, [workflowId]);
  const { editingNodeId, setEditingNodeId } = useEditingNodeId();
  const {
    mutateAsync: restoreWorkflowVersion,
    status: restoreWorkflowVersionStatus,
  } = useRestoreWorkflowVersion();

  useEffect(() => {
    if (EditorStore.persist.getOptions().name !== workflowId) {
      EditorStore.persist.setOptions({
        name: 'root',
      });

      resetWorkflow();
      resetTargets();
      resetVariables();
      resetDatasource();

      void EditorStore.persist.rehydrate();

      EditorStore.persist.setOptions({
        name: workflowId,
        skipHydration: false,
      });
    }
  }, [
    workflowId,
    setNodes,
    setWorkflowId,
    resetWorkflow,
    resetTargets,
    resetVariables,
    resetDatasource,
  ]);

  useEffect(() => {
    if (hideEditorButton) {
      navigate('/');
    }
  }, [hideEditorButton, navigate]);

  // can be either local | cloud | [versionId](string hash)
  const [selectedVersion, setSelectedVersion] = useState<string>('local');

  const uploadLocalVersionToCloud = useCallback(
    async (bypassVersionCheck = false) => {
      const newVariables = extractGlobalVariablesFromTemplates(
        editorStateRef.current.variables,
        editorStateRef.current.globalVariables as VariableMap,
        editorStateRef.current.nodes,
      );

      const payload: typeof editorStateRef.current = {
        ...cloneDeep(editorStateRef.current),
        variables: newVariables,
      };

      delete payload.globalVariables;

      const result = await updateWorkflow({
        workflowId,
        editorState: payload,
        bypassVersionCheck,
      });
      if (result) {
        resetUnsavedChanges();
      }
    },
    [resetUnsavedChanges, updateWorkflow, workflowId],
  );

  const switchToVersionStore = useCallback(
    (show: boolean) => {
      if (show) {
        EditorStore.persist.setOptions({
          name: 'version-history',
          skipHydration: true,
        });
      } else {
        setSelectedVersion('local');
        resetWorkflow();
        EditorStore.persist.setOptions({
          name: workflowId,
          skipHydration: false,
        });
        void EditorStore.persist.rehydrate();
      }
    },
    [resetWorkflow, workflowId],
  );

  usePrompt({
    when: (args) => {
      return (
        args.nextLocation.pathname.startsWith('/api-trigger-settings') &&
        unsavedChanges
      );
    },
    message:
      'This workflow is saved on your device. To maintain these changes across devices and execute the workflow remotely, you must save your workflow changes to the cloud.',
    onLeave: (blocker) => {
      if (selectedNode) {
        setSearchParams({});
        setSelectedNode(null);
      } else if (editingNodeId) {
        setEditingNodeId(undefined);
      } else {
        blocker.proceed();
      }
    },
    onSave: async () => {
      await uploadLocalVersionToCloud();
    },
  });

  const { data: workflowData, isLoading: isFetchingWorkflowData } =
    useGetWorkflowData(workflowId, hasNodesData);

  const workflowVersionId: string | undefined = useMemo(() => {
    if (['local', 'cloud'].includes(selectedVersion)) {
      return undefined;
    }
    return selectedVersion;
  }, [selectedVersion]);

  const {
    data: workflowVersionData,
    isFetching: isFetchingWorkflowVersionData,
    refetch: refetchVersion,
  } = useGetWorkflowData(
    workflowId,
    false,
    false,
    isErrorPushType ? undefined : workflowVersionId,
  );

  const { data: nodeViewData, isLoading: isFetchingNodeData } = useGetRefData(
    workflowId,
    hasVariablesData,
  );

  const { data: globalVariablesRaw } = useGetGlobalVariables();
  useEffect(() => {
    if (!globalVariablesRaw?.length) return;

    const varResult = globalVariablesRaw
      .map((gVar) => {
        const parsed = GlobalVariable.safeParse({
          id: gVar.id,
          name: gVar.name,
          type: VariableTypeEnum.Global,
          data: [gVar.value],
        });

        if (parsed.success) {
          return parsed.data;
        }
        // eslint-disable-next-line
        console.error(parsed.error);
        return undefined;
      })
      .filter((v): v is GlobalVariable => Boolean(v));

    setGlobalVariables(createVariableMap(varResult));
  }, [globalVariablesRaw, setGlobalVariables]);

  const { data: versionNodeData, refetch: refetchRefVersionData } =
    useGetRefData(workflowId, false, workflowVersionId, 'user-editor-key-i');

  const endingStatusBlockFeatureEnabled = useFeatureFlag(
    FeatureFlag.EndingStatusBlock,
  );
  const fullRequestNodeVersion =
    useFeatureFlag(FeatureFlag.FullRequestNodeVersion) ?? false;
  const continueRecordingBlockEnabled = useFeatureFlag(
    FeatureFlag.ContinueRecordingBlock,
  );
  const sendEmailStepEnabled = useFeatureFlag(FeatureFlag.SendEmailStep);

  const activeFeatureFlags = useGetActiveFeatureFlags();
  const [acceptingSuggestions, setAcceptingSuggestions] = useState(false);

  const imageIds = useMemo(() => {
    return nodes
      .filter(
        (n): n is WorkflowImageNode =>
          n.type === NodeTypesEnum.Image && Boolean(n.data.imageData.imageId),
      )
      .map((n) => n.data.imageData.imageId);
  }, [nodes]);
  const { data: allThumbnails, isFetching: isFetchingThumbnails } =
    useGetAllThumbnails(workflowId, imageIds);

  const {
    data: datasourceMetadataResponse,
    isFetching: isFetchingDatasourceMetadata,
  } = useGetDatasourceForWorkflow(workflowId);

  const {
    data: datasourceTableData,
    isFetching: isFetchingDatasourceTableData,
  } = useFetchDatasourceTable(
    datasourceMetadata?.datasourceId,
    Boolean(datasourceMetadata) && tableData === null,
  );
  const [selectedMode, setSelectedMode] =
    useState<NodeSelectionModeEnums | null>(null);
  const [autolinkTaskId, setAutolinkTaskId] = useState<string | undefined>();
  const { data: autolinkData } = useAutolinkTaskPoller(autolinkTaskId);
  // useUpdateStoreAutolinkData(autolinkData?.data); // TODO: clean this out, autolink is broken

  const onLoadVersion = (version: string) => {
    setSelectedVersion(version);
    if (version !== 'local') {
      setTimeout(() => {
        void refetchVersion();
        void refetchRefVersionData();
      }, 200);
    } else {
      setNodes(localWorkflowData.nodes);
      setEdges(localWorkflowData.edges);
    }
  };

  const onRestore = async () => {
    const isLocal = selectedVersion === 'local';
    const editorData = isLocal ? workflowData : workflowVersionData;
    const actionManagerData = isLocal ? nodeViewData : versionNodeData;
    if (!editorData) {
      throw new Error(
        `No editor data to restore. Version: ${JSON.stringify(selectedVersion)}`,
      );
    }
    if (!actionManagerData) {
      throw new Error(
        `No action manager data to restore. Version: ${JSON.stringify(selectedVersion)}`,
      );
    }
    EditorStore.persist.clearStorage();
    EditorStore.persist.setOptions({
      name: workflowId,
      skipHydration: false,
    });
    setNodes(editorData.nodes);
    setEdges(editorData.edges);
    setTargets(actionManagerData.targetData);
    setVariables(actionManagerData.variableData);
    notify({
      message: 'Workflow version restored successfully!',
      variant: AlertVariant.SUCCESS,
    });
    await sleep(100);
    await uploadLocalVersionToCloud(true);
    window.location.reload();
  };

  const onImport = async (replaceNodeId?: string) => {
    const extensionData = await getExtensionData();
    if (
      !extensionData ||
      isEmpty(extensionData.actions) ||
      isEmpty(extensionData.scans)
    ) {
      notify({
        message:
          'No recording found – please try again after recording from the extension.',
        variant: AlertVariant.ERROR,
      });
      return false;
    }
    await importSubWorkflow({
      workflowId,
      extensionData,
      editorState: {
        nodes,
        edges,
        variables: variablesMap,
        targets,
      },
      config: { replaceNodeId },
    });
    EditorStore.persist.clearStorage();
    navigate('/');
    resetCurrentVersion();
    return true;
  };

  const onRejectVersion = async () => {
    try {
      setRejectImprovementsLoading(true);

      if (versionsList.length > 0) {
        const latestNonErrorPushVersion = versionsList.find(
          (version) =>
            !getWorkflowVersionType(workflowId, {
              currentVersionCommitUsers: version.commitUsers,
            }).isErrorPush,
        );
        if (latestNonErrorPushVersion?.versionId) {
          await restoreWorkflowVersion({
            workflowId,
            versionId: latestNonErrorPushVersion.versionId,
          });
        }
      }
      switchToVersionStore(false);
      setCurrentVersionId(undefined);
      await refetchWorkflowMetadata();
      setRejectImprovementsLoading(false);
      notify({
        message: 'Improvements have been rejected',
        variant: AlertVariant.INFO,
      });
    } catch (error) {
      if (rejectImprovementsLoading) {
        setRejectImprovementsLoading(false);
      }
      handleException(error, {
        name: 'Failed to reject workflow',
        source: 'onRejectVersion',
        extra: {
          workflowId,
          versionsList,
        },
      });
    }
  };

  const handleApplySuggestion = useCallback(async () => {
    try {
      await uploadLocalVersionToCloud(true);
      downloadCloudVersion();
      setAcceptingSuggestions(false);

      notify({
        message: 'Improvements have been successfully implemented',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while applying suggestion', error);

      if (acceptingSuggestions) {
        setAcceptingSuggestions(false);
      }

      handleException(error, {
        name: 'Error occurred while applying suggestion',
        source: 'handleApplySuggestion',
        extra: {
          workflowId,
          workflowState: editorStateRef.current,
        },
      });
    }
  }, [
    uploadLocalVersionToCloud,
    downloadCloudVersion,
    workflowId,
    acceptingSuggestions,
  ]);

  const onAcceptSuggestion = () => {
    try {
      if (versionNodeData) {
        setVariables(versionNodeData.variableData);
        setTargets(versionNodeData.targetData);
      }
      // Force state re-render and to handle anything that has a promise race related to this function + stop loading
      setAcceptingSuggestions(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred while accepting suggestion', error);
      handleException(error, {
        name: 'Error occurred while accepting suggestion',
        source: 'onAcceptSuggestion',
        extra: {
          workflowId,
          workflowState: editorStateRef.current,
        },
      });

      if (acceptingSuggestions) {
        setAcceptingSuggestions(false);
      }
    }
  };

  const loadingWorkflow =
    isLoadingWorkflowFiles ||
    isFetchingWorkflowData ||
    isFetchingNodeData ||
    isFetchingThumbnails;

  useEffect(() => {
    if (acceptingSuggestions) {
      void handleApplySuggestion();
    }
  }, [acceptingSuggestions, handleApplySuggestion]);

  useEffect(() => {
    if (
      autolinkData?.data.status &&
      ['finished', 'expired'].includes(autolinkData.data.status)
    ) {
      setAutolinkTaskId(undefined);
    }
  }, [autolinkData?.data]);

  useEffect(() => {
    const cloudWorkflowId = workflowMetadata?.workflowId;
    if (cloudWorkflowId && !localWorkflowId) {
      setWorkflowId(cloudWorkflowId);
    }
  }, [localWorkflowId, setWorkflowId, workflowMetadata?.workflowId]);

  useEffect(() => {
    if (
      workflowVersionData &&
      EditorStore.persist.getOptions().name === 'version-history' &&
      selectedVersion !== 'local'
    ) {
      setNodes(workflowVersionData.nodes);
      setEdges(workflowVersionData.edges);
    }
  }, [setEdges, setNodes, workflowVersionData, selectedVersion]);

  useEffect(() => {
    const unsubscribe = versionHistoryEventBus.on('open', switchToVersionStore);

    return () => {
      unsubscribe();
    };
  }, [switchToVersionStore]);

  useEffect(() => {
    if (!currentVersionId && currentCloudVersionId && nodes.length > 0) {
      setCurrentVersionId(currentCloudVersionId);
    }
  }, [
    currentVersionId,
    setCurrentVersionId,
    currentCloudVersionId,
    nodes.length,
  ]);

  useEffect(() => {
    if (
      localWorkflowId === workflowId &&
      currentVersionId &&
      currentCloudVersionId &&
      currentVersionId !== currentCloudVersionId &&
      !isErrorPushType
    ) {
      // eslint-disable-next-line
      console.log(
        'Version is different',
        currentVersionId,
        currentCloudVersionId,
      );
      if (isSilentPush) {
        handleCloseNode();
        downloadCloudVersion();
      } else if (isNotifyPush) {
        handleCloseNode();
        showVersionDiffDialog(downloadCloudVersion);
      }
    }
  }, [
    currentCloudVersionId,
    currentVersionId,
    downloadCloudVersion,
    localWorkflowId,
    workflowId,
    isSilentPush,
    isNotifyPush,
    isErrorPushType,
    handleCloseNode,
  ]);

  /*
   Note: We save the local data of the editor before the user selects
   cloud/previous workflow version because when they do the local state of
   zustand is overwritten by cloud/version data, so we want to make sure we
   have a copy of the local editor state user was on, when the user selects
   local version from version history panel, we set the saved local state
   into the zustand store.
   */
  useEffect(() => {
    if (selectedVersion === 'local') {
      setLocalWorkflowData((oldData) => {
        const updateLocalState =
          isEqual(oldData.nodes, nodes) && isEqual(oldData.edges, edges);
        if (updateLocalState) {
          return oldData;
        }
        return {
          nodes,
          edges,
        };
      });
    }
  }, [nodes, edges, selectedVersion]);

  const queryClient = useQueryClient();
  useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'workflowData' ||
          query.queryKey[0] === 'nodeData',
      });
    };
  }, [queryClient]);

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessageWithFile();
  const { workflowId: editorWorkflowId } = EditorStore();

  const sourceVariable = useMemo(() => {
    return Object.values(variablesMap).find(
      (variable): variable is SourceVariable =>
        SourceVariable.safeParse(variable).success,
    );
  }, [variablesMap]);

  const sourceType = useMemo(
    () => sourceVariable?.data.sourceType ?? SourceTypeEnum.API,
    [sourceVariable?.data.sourceType],
  );
  const setSourceType = useCallback(
    (newSourceType: SourceTypeEnum) => {
      if (!sourceVariable) return;
      updateVariableData(sourceVariable.id, {
        data: { sourceType: newSourceType },
      });

      // mark the node as checked if the source type is changed to trigger
      if (newSourceType !== SourceTypeEnum.EmailTrigger) return;

      createEmailTriggerVariables(sourceVariable.id, variablesMap).forEach(
        addVariable,
      );

      const sourceNode = nodes.find(
        (node: WorkflowNode) => node.type === NodeTypesEnum.Source,
      );

      if (!sourceNode) return;

      updateNode({
        ...sourceNode,
        data: {
          ...sourceNode.data,
          nodeStatus: NodeStatusEnum.Checked,
        },
      } as WorkflowNode);
    },
    [
      sourceVariable,
      updateVariableData,
      variablesMap,
      addVariable,
      nodes,
      updateNode,
    ],
  );

  const { mutateAsync: uploadFiles } = useUploadFiles();

  const onUploadFile = useCallback(
    async (file: File) => {
      if (!workflowId) {
        throw new Error('Workflow ID is not defined');
      }
      const fileIds = await uploadFiles({ files: [file], workflowId });
      return { fileId: fileIds[0] };
    },
    [uploadFiles, workflowId],
  );

  useEffect(() => {
    return () => {
      const _nodes = editorStateRef.current.nodes;
      const selectedNodes = _nodes.filter(
        (node: WorkflowNode) => (node as WorkflowImageNode).data.selected,
      );
      if (selectedNodes.length > 0) {
        setNodes(
          _nodes.map((node: WorkflowNode) => ({
            ...node,
            data: {
              ...(node as WorkflowImageNode).data,
              selected: false,
            },
          })) as WorkflowNode[],
        );
      }
      modalEventChannel.emit('close');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isErrorPushType &&
      EditorStore.persist.getOptions().name !== 'version-history'
    ) {
      switchToVersionStore(true);
      setSelectedVersion('cloud');
      handleCloseNode();
      void refetchVersion();
      void refetchRefVersionData();
    }
  }, [
    isErrorPushType,
    refetchRefVersionData,
    refetchVersion,
    handleCloseNode,
    switchToVersionStore,
  ]);

  useEffect(() => {
    document.title = workflowMetadata?.workflowName
      ? getTabTitle(`Editor | ${workflowMetadata.workflowName}`, isAdmin)
      : getTabTitle('Editor', isAdmin);
  }, [workflowMetadata?.workflowName]);

  // Create any missing file variables
  useEffect(() => {
    if (isLoadingWorkflowFiles) return;
    if (workflowFiles.length === 0) return;

    const existingFileVariables = Object.values(variablesMap).filter((v) => {
      try {
        const parsedVariable = DocumentVariable.parse(v);
        return (
          parsedVariable.data.source === DocumentSourceEnum.AWS &&
          Boolean(
            workflowFiles.find(
              (f) => f.fileId === parsedVariable.data.s3Ref?.fileId,
            ),
          )
        );
      } catch (e) {
        // No need to handle exception since we're essentially just filtering out non-document variables
        return false;
      }
    });

    if (workflowFiles.length === existingFileVariables.length) return;
    const variableMapArr = Object.values(variablesMap);

    workflowFiles.forEach((file) => {
      const fileExists = variableMapArr.find((v) => {
        try {
          const parsedVariable = DocumentVariable.parse(v);
          return parsedVariable.data.s3Ref?.fileName === file.name;
        } catch (e) {
          // No need to handle exception since we're essentially just filtering out non-document variables
          return false;
        }
      });

      if (!fileExists) {
        const newVariableId = uuid();

        const _newDocumentVariable: DocumentVariable = {
          id: newVariableId,
          type: VariableTypeEnum.Document,
          name: file.name,
          data: {
            source: DocumentSourceEnum.AWS,
            s3Ref: {
              fileId: file.fileId,
              fileName: file.name,
            },
          },
        };

        addVariable(_newDocumentVariable);
      }
    });
  }, [workflowFiles, isLoadingWorkflowFiles, variablesMap, addVariable]);

  return (
    <EditorCore
      loadingWorkflow={loadingWorkflow}
      isReadonlyView={isReadonlyView}
      hasSuggestions={isErrorPushType}
      ActionView={
        <ActionViewCore
          ActionsManager={ActionsManager}
          SelectedImageNodeContent={SelectedImageNodeContent}
          edges={edges}
          nodes={nodes}
          selectedNodeId={selectedNode}
          setSelectedNode={setSelectedNode}
          isReadonlyView={isReadonlyView}
          annotatedPDFData={annotatedPDFData}
          setAnnotatedPDFData={setAnnotatedPDFData}
          workflowMetadata={workflowMetadata}
        />
      }
      Toolbar={
        <Toolbar
          workflowStatus={workflowMetadata?.status}
          nodes={nodes}
          acceptingSuggestion={
            acceptingSuggestions ||
            updateWorkflowStatus === 'pending' ||
            rejectImprovementsLoading
          }
          autolinkLoading={Boolean(autolinkTaskId)}
          hasSuggestions={isErrorPushType}
          isForceErrorPush={isErrorForcePush}
          isReadonlyView={isReadonlyView}
          onAccept={onAcceptSuggestion}
          onReject={onRejectVersion}
          onSave={uploadLocalVersionToCloud}
          saving={updateWorkflowStatus === 'pending'}
          unsavedChanges={unsavedChanges}
          rejectingSuggestion={
            restoreWorkflowVersionStatus === 'pending' ||
            rejectImprovementsLoading ||
            acceptingSuggestions
          }
          setAutolinkTaskId={setAutolinkTaskId}
          setSourceType={setSourceType}
          workflowId={workflowId}
          workflowName={workflowMetadata?.workflowName}
          workflowMetadata={workflowMetadata}
          selectedMode={selectedMode}
        />
      }
      onUploadFile={onUploadFile}
      addEdges={addEdges}
      addNodes={addNodes}
      addVariable={addVariable}
      allThumbnails={allThumbnails}
      allowNodesMerging={false}
      continueRecordingBlockEnabled={continueRecordingBlockEnabled}
      sendEmailStepEnabled={sendEmailStepEnabled}
      currentViewport={currentViewport}
      datasourceMetadata={datasourceMetadata}
      datasourceMetadataResponse={datasourceMetadataResponse}
      datasourceTableData={datasourceTableData}
      downloadCloudVersion={downloadCloudVersion}
      edgeTypes={edgeTypes}
      edges={edges}
      editorWorkflowId={editorWorkflowId}
      endingStatusBlockFeatureEnabled={endingStatusBlockFeatureEnabled}
      enabledFeatureFlags={activeFeatureFlags}
      fullRequestNodeVersion={fullRequestNodeVersion}
      isFetchingDatasourceMetadata={isFetchingDatasourceMetadata}
      isFetchingDatasourceTableData={isFetchingDatasourceTableData}
      isFetchingNodeData={isFetchingNodeData}
      isFetchingThumbnails={isFetchingThumbnails}
      isFetchingWorkflowData={isFetchingWorkflowData}
      nodeTypes={nodeTypes}
      nodeViewData={nodeViewData}
      nodes={nodes}
      onConnect={onConnect}
      onEdgesChange={onEdgesChange}
      onImport={onImport}
      onLoadVersion={onLoadVersion}
      onNodesChange={onNodesChange}
      onRestore={onRestore}
      selectedNode={selectedNode}
      selectedVersion={selectedVersion}
      sendMessage={sendMessage}
      sendMessageStatus={sendMessageStatus}
      setCurrentViewport={setCurrentViewport}
      setDatasourceMetadata={setDatasourceMetadata}
      setDatasourceTable={setDatasourceTable}
      setEdges={setEdges}
      setNodes={setNodes}
      setTargets={setTargets}
      setThumbnails={setThumbnails}
      setSelectedNode={setSelectedNode}
      setSourceType={setSourceType}
      setVariables={setVariables}
      showSelectAllButton={false}
      tableData={tableData}
      targets={targets}
      transformApiReq={transformApiReq}
      transformApiReqStatus={transformApiReqStatus}
      sourceType={sourceType}
      updateNode={updateNode}
      updateVariable={updateVariable}
      uploadLocalVersionToCloud={uploadLocalVersionToCloud}
      variables={variablesMap}
      globalVariables={globalVariablesMap as Record<string, GlobalVariable>}
      versionLoading={isFetchingWorkflowVersionData}
      versions={filteredVersions}
      workflowData={workflowData}
      workflowMetadata={workflowMetadata}
      workflowVersionData={
        selectedVersion === 'local' ? localWorkflowData : workflowVersionData
      }
      setSelectedMode={setSelectedMode}
      selectedMode={selectedMode}
    />
  );
}
