import { Label } from 'ui-kit';
import { type WebhookData } from '../../../hooks/useGetExecutionWebhookData';
import { stringifyWithDynamicIndent } from '../../../../../utils/helper';
import { isJson } from '../../../utils';

interface Props {
  currentWebhookData: WebhookData;
}

export function WebhookDataOutputs({ currentWebhookData }: Props) {
  return (
    <div className="flex flex-col gap-4 py-4 px-6 overflow-auto">
      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>Method</Label>

        <p className="text-slate-500 text-sm [&>a]:text-blue-700 [&>a]:underline break-all">
          {currentWebhookData.method}
        </p>
      </div>

      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>URL</Label>

        <p className="text-slate-500 text-sm [&>a]:text-blue-700 [&>a]:underline break-all">
          {currentWebhookData.url}
        </p>
      </div>

      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>Request Headers</Label>

        <p className="bg-[#eaf1f3] p-8 rounded-lg text-info-dark whitespace-pre-wrap break-words relative mb-12">
          <span
            dangerouslySetInnerHTML={{
              __html: stringifyWithDynamicIndent(
                currentWebhookData.requestHeaders,
              ).replaceAll('\n', '<br/>'),
            }}
          />
        </p>
      </div>

      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>Request Body</Label>

        <p className="bg-[#eaf1f3] p-8 rounded-lg text-info-dark whitespace-pre-wrap break-words relative mb-12">
          <span
            dangerouslySetInnerHTML={{
              __html: stringifyWithDynamicIndent(
                currentWebhookData.requestBody,
              ).replaceAll('\n', '<br/>'),
            }}
          />
        </p>
      </div>

      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>Response Headers</Label>

        <p className="bg-[#eaf1f3] p-8 rounded-lg text-info-dark whitespace-pre-wrap break-words relative mb-12">
          <span
            dangerouslySetInnerHTML={{
              __html: stringifyWithDynamicIndent(
                currentWebhookData.responseHeaders,
              ).replaceAll('\n', '<br/>'),
            }}
          />
        </p>
      </div>

      <div className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6">
        <Label>Response Body</Label>

        <p className="bg-[#eaf1f3] p-8 rounded-lg text-info-dark whitespace-pre-wrap break-words relative mb-12">
          <span
            className="[&_a]:underline [&_a]:cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: isJson(currentWebhookData.responseBody)
                ? stringifyWithDynamicIndent(
                    JSON.parse(currentWebhookData.responseBody),
                  ).replaceAll('\n', '<br/>')
                : currentWebhookData.responseBody,
            }}
          />
        </p>
      </div>
    </div>
  );
}
