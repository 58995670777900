import isEqual from 'lodash/isEqual';
import {
  type EmailPlatformMetadata,
  type PlatformMetadata,
  type SlackPlatformMetadata,
} from 'types-shared/notificationTypes';
import {
  type TemplateData,
  type WebhookHeader,
  type WorkflowWebhook,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';

// eslint disable next line camelcase
import { Notifications_Enum_Platform_Enum as NotificationsEnumPlatformEnum } from 'hasura-gql';
import { handleException } from 'sentry-browser-shared';

export interface FormValues {
  id: string;
  eventName: string;
  event: string;
  action: string;
  url: TemplateData;
  headers: ({ id: string } & WebhookHeader)[];
}

export enum Event {
  ExecutionFinished = 'Execution finished',
  ExecutionStatusChange = 'Execution status change',
}

export enum Action {
  SendWebhook = 'Send webhook',
  SendNotification = 'Send notification',
}

export enum NotificationTabIdEnum {
  Slack = 'slack',
  Email = 'email',
  Teams = 'teams',
}

export enum NotifyAboutEnum {
  Urgent = 'urgent',
  Custom = 'custom',
}

export interface NotificationData {
  slackChannel: string;
  notificationEmailRecipients: string[];
  notifyAbout: NotifyAboutEnum;
  workflowExecutionCompleted: boolean;
  failedExecutions: boolean;
  successfulExecutions: boolean;
  improvementDetected: boolean;
}

export interface NotificationConfig {
  id: string;
  workflowId: string;
  ownerId: string;
  platform: string;
  platformMetadata: PlatformMetadata;
  events: { event: string; configurationId: string }[];
}

export interface NotificationConfigPayload
  extends Omit<NotificationConfig, 'id' | 'events'> {
  events: { event: string }[];
}

// Enum mappings between frontend data and backend
const NotifyAboutEnumToEventMapping = {
  [NotifyAboutEnum.Urgent]: [
    'execution_failed',
    'workflow_improvement_detected',
  ],
  [NotifyAboutEnum.Custom]: [
    'execution_failed',
    'execution_success',
    'workflow_improvement_detected',
  ],
};

export const events = [Event.ExecutionFinished, Event.ExecutionStatusChange];
export const actions = [Action.SendWebhook, Action.SendNotification];

export const defaultForm: FormValues = {
  id: uuid(),
  eventName: '',
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: [],
  headers: [],
};

export const convertWebhookToFormValues = (
  webhook: WorkflowWebhook,
): FormValues => ({
  id: webhook.id,
  eventName: webhook.name,
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: webhook.webhookUrl,
  headers: webhook.headers.map(({ key, value }) => ({
    id: uuid(),
    key,
    value,
  })),
});

export const convertFormValuesToWebhook = (
  formValues: FormValues,
): WorkflowWebhook => ({
  id: formValues.id,
  name: formValues.eventName,
  webhookUrl: formValues.url,
  headers: formValues.headers.map((header) => ({
    key: header.key,
    value: header.value,
  })),
});

const getEvents = (frontendData: NotificationData): { event: string }[] => {
  return frontendData.notifyAbout === NotifyAboutEnum.Urgent
    ? NotifyAboutEnumToEventMapping[NotifyAboutEnum.Urgent].map((event) => ({
        event,
      }))
    : [
        ...(frontendData.failedExecutions
          ? [{ event: 'execution_failed' }]
          : []),
        ...(frontendData.successfulExecutions
          ? [{ event: 'execution_success' }]
          : []),
        ...(frontendData.improvementDetected
          ? [{ event: 'workflow_improvement_detected' }]
          : []),
      ];
};

const getPlatformMetadata = (
  platform: NotificationsEnumPlatformEnum,
  notificationData: NotificationData,
) => {
  switch (platform) {
    case NotificationsEnumPlatformEnum.Slack:
      return {
        platform,
        userEmails: notificationData.notificationEmailRecipients,
        conversationId: notificationData.slackChannel,
      };
    case NotificationsEnumPlatformEnum.Email:
      return {
        platform,
        recipients: notificationData.notificationEmailRecipients,
      };
    default:
      throw new Error(`Unsupported platform ${String(platform)}`);
  }
};

export const convertNotificationDataToBackendPayload = ({
  workflowId,
  notificationData,
  ownerId,
  platform,
}: {
  workflowId: string;
  notificationData: NotificationData;
  ownerId: string;
  platform: NotificationsEnumPlatformEnum;
}): NotificationConfigPayload => {
  const platformMetadata: PlatformMetadata = getPlatformMetadata(
    platform,
    notificationData,
  );
  return {
    workflowId,
    platform,
    platformMetadata,
    events: getEvents(notificationData),
    ownerId,
  };
};

export const convertNotificationDataToUpdatePayload = ({
  workflowId,
  notificationData,
  ownerId,
  notificationId,
  configurationId,
  platform,
}: {
  workflowId: string;
  notificationData: NotificationData;
  ownerId: string;
  notificationId: string;
  configurationId: string;
  platform: NotificationsEnumPlatformEnum;
}): NotificationConfig => {
  const platformMetadata: PlatformMetadata = getPlatformMetadata(
    platform,
    notificationData,
  );
  return {
    id: notificationId,
    workflowId,
    platform,
    platformMetadata,
    events: getEvents(notificationData).map((event) => ({
      ...event,
      configurationId,
    })),
    ownerId,
  };
};

// Converts backend payload to frontend notification data
export function convertBackendPayloadToNotificationData(backendData: {
  id: string;
  platform: string;
  platformMetadata: PlatformMetadata;
  workflowId: string;
  events: {
    event: string;
    configurationId: string;
  }[];
}): NotificationData {
  backendData;
  const allEvents = backendData.events.map((e: { event: string }) => e.event);
  const platformMetadata = backendData.platformMetadata;
  const isSlack =
    platformMetadata.platform === NotificationsEnumPlatformEnum.Slack;
  const emails = isSlack
    ? platformMetadata.userEmails
    : platformMetadata.recipients;

  const payload = {
    slackChannel: isSlack ? platformMetadata.conversationId || '' : '',
    notificationEmailRecipients: emails,
    notifyAbout:
      allEvents.length > 1 ? NotifyAboutEnum.Custom : NotifyAboutEnum.Urgent,
    workflowExecutionCompleted:
      allEvents.includes('execution_failed') ||
      allEvents.includes('execution_success'),
    failedExecutions: allEvents.includes('execution_failed'),
    successfulExecutions: allEvents.includes('execution_success'),
    improvementDetected: allEvents.includes('workflow_improvement_detected'),
  };

  return payload;
}

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const notificationPayloadHasValidChanges = (
  payload: NotificationConfig,
  current: NotificationConfig,
) => {
  let currentRecipients: string[] = [];
  let payloadRecipients: string[] = [];

  if (current.platformMetadata.platform !== payload.platformMetadata.platform) {
    handleException(
      new Error('The payload and the current should have the same payload.'),
      {
        extra: {
          source: 'notificationPayloadHasValidChanges',
          payload,
          current,
        },
      },
    );
  }

  switch (current.platformMetadata.platform) {
    case NotificationsEnumPlatformEnum.Slack:
      currentRecipients = current.platformMetadata.userEmails;
      payloadRecipients = (payload.platformMetadata as SlackPlatformMetadata)
        .userEmails;
      break;
    case NotificationsEnumPlatformEnum.Email:
      currentRecipients = current.platformMetadata.recipients;
      payloadRecipients = (payload.platformMetadata as EmailPlatformMetadata)
        .recipients;
      break;
    default:
      handleException(
        new Error(
          `Found unsupported platform ${current.platformMetadata.platform}`,
        ),
        {
          extra: {
            source: 'notificationPayloadHasValidChanges',
            current,
            payload,
          },
        },
      );
      currentRecipients = [];
      payloadRecipients = [];
  }

  const currentEvents = current.events.map((e) => e.event);
  const payloadEvents = payload.events.map((e) => e.event);

  const recipientsDidNotChange = isEqual(currentRecipients, payloadRecipients);
  const eventsDidNotChange = isEqual(currentEvents, payloadEvents);

  const changesInvalid = recipientsDidNotChange && eventsDidNotChange;
  return !changesInvalid;
};
