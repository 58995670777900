"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariableStateData = exports.BackwardsCompatibleVariableMap = exports.VariableMap = exports.Variable = exports.ExecutionVariable = exports.ExecutionVariableName = exports.ExecutionVariableNameEnum = exports.GlobalVariable = exports.TabVariable = exports.DocumentVariable = exports.S3Ref = exports.DocumentSource = exports.DocumentSourceEnum = exports.MultiChoiceVariable = exports.MultiSelectVariable = exports.SelectVariable = exports.TemplateVariable = exports.ScrapeVariable = exports.ScrapeType = exports.ScrapeTypeEnum = exports.EmailTriggerVariable = exports.EmailValueType = exports.EmailValueTypeEnum = exports.EmailTriggerVariableName = exports.EmailTriggerVariableEnum = exports.QueryVariable = exports.QueryValueType = exports.QueryValueTypeEnum = exports.SourceVariable = exports.VariableBase = exports.TemplateData = exports.VariableIdContainer = exports.VariableRef = exports.VariableType = exports.VariableTypeEnum = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../../extensionTypes");
var sourceTypes_1 = require("../../sourceTypes");
var VariableTypeEnum;
(function (VariableTypeEnum) {
    VariableTypeEnum["Source"] = "Source";
    VariableTypeEnum["Query"] = "Query";
    VariableTypeEnum["Scrape"] = "Scrape";
    VariableTypeEnum["Template"] = "Template";
    VariableTypeEnum["Select"] = "Select";
    VariableTypeEnum["MultiSelect"] = "MultiSelect";
    VariableTypeEnum["MultiChoice"] = "MultiChoice";
    VariableTypeEnum["Document"] = "Document";
    VariableTypeEnum["Tab"] = "Tab";
    VariableTypeEnum["Global"] = "Global";
    VariableTypeEnum["Execution"] = "Execution";
    VariableTypeEnum["EmailTrigger"] = "EmailTrigger";
})(VariableTypeEnum || (exports.VariableTypeEnum = VariableTypeEnum = {}));
exports.VariableType = zod_1.z.nativeEnum(VariableTypeEnum);
exports.VariableRef = zod_1.z.object({
    id: zod_1.z.string(),
});
// VariableIdContainer is more clear than VariableRef, we should use this going forward
exports.VariableIdContainer = zod_1.z.object({ variableId: zod_1.z.string() });
exports.TemplateData = zod_1.z.array(zod_1.z.union([zod_1.z.string(), exports.VariableRef]));
exports.VariableBase = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    dashboardData: zod_1.z
        .object({
        initialValue: zod_1.z.union([zod_1.z.string(), zod_1.z.object({}).passthrough()]),
        transformInputs: zod_1.z
            .object({
            query: exports.TemplateData,
            transformedValue: zod_1.z.union([zod_1.z.string(), zod_1.z.object({}).passthrough()]),
        })
            .optional(),
    })
        .optional(),
    executionData: zod_1.z
        .object({
        initialValue: zod_1.z
            .union([zod_1.z.string(), zod_1.z.object({}).passthrough()])
            .nullable(),
        transformedValue: zod_1.z
            .union([zod_1.z.string(), zod_1.z.object({}).passthrough()])
            .nullable()
            .optional(),
        reasoning: zod_1.z.string().nullable().optional(),
    })
        .optional(),
    exportDuringExecution: zod_1.z.boolean().optional(),
    transformConfig: zod_1.z
        .object({
        model: zod_1.z.string().optional(),
    })
        .optional(),
});
exports.SourceVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Source),
    data: zod_1.z.object({
        sourceRef: zod_1.z
            .object({
            datasourceId: zod_1.z.string(),
            key: zod_1.z.string(),
        })
            .optional(),
        sourceType: sourceTypes_1.SourceType,
    }),
});
var QueryValueTypeEnum;
(function (QueryValueTypeEnum) {
    QueryValueTypeEnum["String"] = "String";
    QueryValueTypeEnum["Number"] = "Number";
    QueryValueTypeEnum["Boolean"] = "Boolean";
    QueryValueTypeEnum["Date"] = "Date";
    QueryValueTypeEnum["Object"] = "Object";
})(QueryValueTypeEnum || (exports.QueryValueTypeEnum = QueryValueTypeEnum = {}));
exports.QueryValueType = zod_1.z.nativeEnum(QueryValueTypeEnum);
exports.QueryVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Query),
    data: zod_1.z.object({
        sourceIds: zod_1.z.array(zod_1.z.string()),
        query: exports.TemplateData,
        valueType: exports.QueryValueType,
    }),
});
var EmailTriggerVariableEnum;
(function (EmailTriggerVariableEnum) {
    EmailTriggerVariableEnum["From"] = "From";
    EmailTriggerVariableEnum["To"] = "To";
    EmailTriggerVariableEnum["Subject"] = "Subject";
    EmailTriggerVariableEnum["Body"] = "Body";
    EmailTriggerVariableEnum["Attachments"] = "Attachments";
    EmailTriggerVariableEnum["EmailData"] = "Email Data";
})(EmailTriggerVariableEnum || (exports.EmailTriggerVariableEnum = EmailTriggerVariableEnum = {}));
exports.EmailTriggerVariableName = zod_1.z.nativeEnum(EmailTriggerVariableEnum);
var EmailValueTypeEnum;
(function (EmailValueTypeEnum) {
    EmailValueTypeEnum["String"] = "String";
    EmailValueTypeEnum["Number"] = "Number";
    EmailValueTypeEnum["Boolean"] = "Boolean";
    EmailValueTypeEnum["Date"] = "Date";
    EmailValueTypeEnum["Object"] = "Object";
})(EmailValueTypeEnum || (exports.EmailValueTypeEnum = EmailValueTypeEnum = {}));
exports.EmailValueType = zod_1.z.nativeEnum(EmailValueTypeEnum);
exports.EmailTriggerVariable = exports.VariableBase.omit({
    name: true,
}).extend({
    name: zod_1.z.union([exports.EmailTriggerVariableName, zod_1.z.string()]),
    type: zod_1.z.literal(VariableTypeEnum.EmailTrigger),
    // Data is redundant here. Only leaving this here to satisfy CI. Will address this later
    data: zod_1.z.object({
        sourceIds: zod_1.z.array(zod_1.z.string()),
        query: exports.TemplateData,
        valueType: exports.EmailValueType,
    }),
});
var ScrapeTypeEnum;
(function (ScrapeTypeEnum) {
    ScrapeTypeEnum["InnerText"] = "innerText";
    ScrapeTypeEnum["Html"] = "html";
    ScrapeTypeEnum["Csv"] = "csv";
})(ScrapeTypeEnum || (exports.ScrapeTypeEnum = ScrapeTypeEnum = {}));
exports.ScrapeType = zod_1.z.nativeEnum(ScrapeTypeEnum);
exports.ScrapeVariable = exports.VariableBase.required({ name: true }).extend({
    type: zod_1.z.literal(VariableTypeEnum.Scrape),
    data: zod_1.z.object({
        scrapedText: zod_1.z.string().optional(),
        outputType: exports.ScrapeType.default(ScrapeTypeEnum.InnerText),
        excludeFromOutputs: zod_1.z.boolean().default(false),
    }),
});
exports.TemplateVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Template),
    data: exports.TemplateData,
    showInVariableManager: zod_1.z.boolean().optional(),
});
exports.SelectVariable = exports.TemplateVariable.extend({
    type: zod_1.z.literal(VariableTypeEnum.Select),
    selectOptions: zod_1.z.array(extensionTypes_1.SelectOption),
});
exports.MultiSelectVariable = exports.TemplateVariable.extend({
    type: zod_1.z.literal(VariableTypeEnum.MultiSelect),
    multiSelectedOptions: zod_1.z.array(zod_1.z.number()),
});
exports.MultiChoiceVariable = exports.TemplateVariable.extend({
    type: zod_1.z.literal(VariableTypeEnum.MultiChoice),
    multiChoiceOptions: zod_1.z.array(extensionTypes_1.BaseQueryPaths),
    selectedChoiceIx: zod_1.z.number().nullable(),
});
var DocumentSourceEnum;
(function (DocumentSourceEnum) {
    DocumentSourceEnum["URL"] = "url";
    DocumentSourceEnum["AWS"] = "aws";
    DocumentSourceEnum["Execution"] = "execution";
})(DocumentSourceEnum || (exports.DocumentSourceEnum = DocumentSourceEnum = {}));
exports.DocumentSource = zod_1.z.nativeEnum(DocumentSourceEnum);
exports.S3Ref = zod_1.z.object({
    fileId: zod_1.z.string(),
    fileName: zod_1.z.string(),
});
exports.DocumentVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Document),
    data: zod_1.z.object({
        nodeId: zod_1.z.string().optional(),
        s3Ref: exports.S3Ref.optional(),
        source: exports.DocumentSource,
        url: exports.TemplateData.optional(),
    }),
});
exports.TabVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Tab),
    data: zod_1.z.object({
        url: exports.TemplateData,
    }),
});
exports.GlobalVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Global),
    data: exports.TemplateData,
});
var ExecutionVariableNameEnum;
(function (ExecutionVariableNameEnum) {
    ExecutionVariableNameEnum["ExecutionId"] = "ExecutionId";
    ExecutionVariableNameEnum["ExecutionAttempt"] = "ExecutionAttempt";
    ExecutionVariableNameEnum["TodayDate"] = "TodayDate";
    ExecutionVariableNameEnum["YesterdayDate"] = "YesterdayDate";
    ExecutionVariableNameEnum["PreviousWeekdayDate"] = "PreviousWeekdayDate";
    ExecutionVariableNameEnum["StatusDescription"] = "StatusDescription";
})(ExecutionVariableNameEnum || (exports.ExecutionVariableNameEnum = ExecutionVariableNameEnum = {}));
exports.ExecutionVariableName = zod_1.z.nativeEnum(ExecutionVariableNameEnum);
exports.ExecutionVariable = exports.VariableBase.omit({
    name: true,
}).extend({
    name: zod_1.z.string(),
    type: zod_1.z.literal(VariableTypeEnum.Execution),
    data: exports.TemplateData,
});
exports.Variable = zod_1.z.union([
    exports.SourceVariable,
    exports.QueryVariable,
    exports.ScrapeVariable,
    exports.TemplateVariable,
    exports.SelectVariable,
    exports.MultiSelectVariable,
    exports.MultiChoiceVariable,
    exports.DocumentVariable,
    exports.TabVariable,
    exports.GlobalVariable,
    exports.ExecutionVariable,
    exports.EmailTriggerVariable,
]);
exports.VariableMap = zod_1.z.record(zod_1.z.string(), exports.Variable);
function zToVariableMap(schema) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we need to preprocess the variable map to convert the old type to the new type
    return zod_1.z.preprocess(function (backwardsVariableMap) {
        var updatedVariableMap = __assign({}, backwardsVariableMap);
        return updatedVariableMap;
    }, schema);
}
exports.BackwardsCompatibleVariableMap = zToVariableMap(exports.VariableMap);
exports.VariableStateData = zod_1.z.object({
    variables: exports.VariableMap,
    globalVariables: exports.VariableMap.optional(),
});
