import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  AdminVersionEnum,
  type GetWorkflowMetadataResponse,
} from 'api-types-shared';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SourceTypeEnum, type VariableMap } from 'types-shared';
import {
  AccessTimeIcon,
  APITriggerIcon,
  Button,
  CloudDownload,
  CloudUpload,
  // DatabaseIcon,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  FolderIcon,
  IconButton,
  MailIcon,
  Menu,
  MenuItem,
  Modal,
  Select,
  SettingsIcon,
  Tooltip,
  Typography,
} from 'ui-kit';
import { autocompleteFilterOptions } from '../../../../utils/constants';
import { downloadLinkData, sleep } from '../../../../utils/helper';
import { type EditorToolbarMenuItem } from '../../../Execution/utils';
import ManualRun from '../../../ManualRun';
import ScheduleWorkflow from '../../../ScheduleWorkflow';
import { useGetWorkflowVideos, useUpdateWorkflowData } from '../../hooks';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { useSourceVariable } from '../../hooks/useSourceVariable';
import { useVersionHistory } from '../../hooks/useVersionHistory';
import { EditorStore } from '../../store/EditorState';
import { extractGlobalVariablesFromTemplates } from '../../utils/helper';
import { ConfirmWorkflowProcessing } from '../ConfirmWorkflowProcessing';
import AdminPushToolbarModal from './AdminPushModal';
import { ComponentsToolbar } from './ComponentsToolbar';

// Utility function to convert camel case to title case
const camelToTitleCase = (str: string): string => {
  return startCase(camelCase(str));
};

const versionPushOptionMapping: Record<string, string> = {
  [AdminVersionEnum.ForcePush]: 'Error Push',
};

interface Props {
  workflowId: string;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  selectNode: (nodeId: string | null) => void;
  nodeIds: string[];
  onImport: (replaceNodeId?: string) => Promise<boolean>;
  setSourceType: (sourceType: SourceTypeEnum) => void;
  saving?: boolean;
}

export default function AdminToolbar({
  workflowId,
  workflowMetadata,
  nodeIds,
  selectNode,
  onImport,
  setSourceType,
  saving,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const {
    datasourceMetadata,
    nodes,
    edges,
    variables,
    targets,
    globalVariables,
    setCurrentVersionId,
  } = EditorStore();
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const [showManualRunPopup, setShowManualRunPopup] = useState(false);
  const [showRunWorkflowPopup, setShowRunWorkflowPopup] =
    useState<boolean>(false);
  const { mutateAsync, status } = useUpdateWorkflowData();
  const { isFetching: loadingVideos, refetch: getVideos } =
    useGetWorkflowVideos(workflowId);

  const { setEditingNodeId } = useEditingNodeId();
  const { isApi, isEmailTrigger /*isDataSource*/ } =
    useSourceVariable(variables);

  const processedWorkflowName = useMemo(() => {
    if (!workflowMetadata) {
      return 'Workflow';
    }
    if (workflowMetadata.workflowName.length > 50) {
      return `${workflowMetadata.workflowName.slice(0, 50).trim()}...`;
    }
    return workflowMetadata.workflowName;
  }, [workflowMetadata]);

  const menuItems: EditorToolbarMenuItem[] = [
    {
      label: 'API CALL',
      value: SourceTypeEnum.API,
      isCurrent: isApi,
      icon: (
        <APITriggerIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
    },
    // {
    //   label: 'DATABASE',
    //   value: SourceTypeEnum.Datasource,
    //   isCurrent: isDataSource,
    //   icon: (
    //     <DatabaseIcon
    //       className="!text-info-dark group-hover:!text-info"
    //       fontSize="small"
    //     />
    //   ),
    //   disabled: true,
    // },
    {
      label: 'EMAIL TRIGGER',
      value: SourceTypeEnum.EmailTrigger,
      isCurrent: isEmailTrigger,
      icon: (
        <MailIcon
          className="!text-info-dark group-hover:!text-info"
          fontSize="small"
        />
      ),
    },
  ];

  const currentItem = menuItems.find((item) => item.isCurrent);

  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { toggleVersionHistory } = useVersionHistory();
  const [pushModalOpen, setPushModalOpen] = useState(false);

  const [versionPush, setVersionPush] = useState<AdminVersionEnum>(
    AdminVersionEnum.SilentPush,
  );

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const downloadWorkflow = () => {
    localStorage.removeItem(workflowId);
    window.location.reload();
  };

  const saveWorkflow = async () => {
    const workflowData = {
      edges,
      nodes,
      bulkSelectMode: false,
      selectedAction: null,
      selectedNode: null,
    };

    const withGlobalVars = extractGlobalVariablesFromTemplates(
      variables,
      globalVariables as VariableMap,
      nodes,
    );

    await mutateAsync({
      workflowId,
      workflowData,
      variableData: withGlobalVars,
      targetData: targets,
      datasourceMetadata,
      versionPush:
        versionPush === AdminVersionEnum.ProcessingPush
          ? undefined
          : versionPush,
      status: workflowMetadata?.status,
    });
    setCurrentVersionId(undefined);
  };

  const fetchVideo = async () => {
    const { data = [] } = await getVideos();
    if (data.length > 0) {
      for await (const url of data) {
        try {
          downloadLinkData(url, workflowId);
          await sleep(1000);
        } catch (e: unknown) {
          // eslint-disable-next-line no-console
          console.log('video download failed', (e as Error).message);
        }
      }
    }
  };

  return (
    <>
      <ComponentsToolbar
        hasOuterContainer={false}
        containerClassName="zoom-adjusted-container !py-2"
        crumbs={[
          { title: 'Workflow details', link: `/workflows/${workflowId}` },
          { title: `${processedWorkflowName} - Flow view` },
        ]}
        LeftEndContent={
          <div className="flex items-center space-x-2">
            <Button
              className="group !mr-2 !px-2 !text-nowrap !font-medium !leading-6 !text-sm !text-info-dark hover:!text-info !min-w-[140px]"
              color="secondary"
              endIcon={
                anchorEl ? (
                  <ExpandLessOutlined
                    className="!text-info-dark group-hover:!text-info"
                    fontSize="small"
                  />
                ) : (
                  <ExpandMoreOutlined
                    className="!text-info-dark group-hover:!text-info"
                    fontSize="small"
                  />
                )
              }
              onClick={() => {
                setAnchorEl(triggerRef.current);
              }}
              ref={triggerRef}
              startIcon={currentItem?.icon}
              variant="text"
            >
              {currentItem?.label}
            </Button>
            <Menu
              BackdropProps={{
                style: {
                  backgroundColor: 'transparent',
                },
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={onMenuClose}
              open={Boolean(anchorEl)}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '4px',
                },
                '& .MuiMenu-list': {
                  padding: '0',
                },
                '& .MuiMenu-paper': {
                  minWidth: '140px',
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {menuItems
                .filter((i) => !i.isCurrent)
                .map((item) => (
                  <MenuItem
                    className="!font-medium flex-row items-center"
                    key={item.value}
                    disabled={item.disabled}
                    onClick={() => {
                      setEditingNodeId(undefined);
                      setSourceType(item.value);
                      onMenuClose();
                    }}
                  >
                    {item.icon}
                    <span className="font-medium text-sm text-info-dark ml-2 mr-4">
                      {item.label}
                    </span>
                  </MenuItem>
                ))}
            </Menu>
          </div>
        }
        RightEndContent={
          <div className="flex items-center space-x-2">
            {nodes.length > 0 ? (
              <span className="font-medium !mr-4">
                Total nodes: {nodes.length}
              </span>
            ) : null}
            <Autocomplete
              className="ml-4 mr-1 min-w-40"
              classes={{ popper: 'px-sm' }}
              filterOptions={autocompleteFilterOptions}
              freeSolo
              onChange={(_, newValue) => {
                selectNode(newValue as string);
              }}
              options={nodeIds}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search for a node" />
              )}
            />
            <Select
              className="w-32"
              getLabel={(option) =>
                versionPushOptionMapping[option] ?? camelToTitleCase(option)
              }
              getValue={(option) => option}
              onChange={(e) => {
                const value = e.target.value as AdminVersionEnum;
                setVersionPush(value);
              }}
              options={[
                AdminVersionEnum.SilentPush,
                AdminVersionEnum.NotifyPush,
                AdminVersionEnum.ForcePush,
                AdminVersionEnum.ProcessingPush,
                AdminVersionEnum.ErrorForcePush,
              ]}
              value={versionPush}
            />
            <Tooltip
              arrow
              containerClassName="!ml-4"
              placement="bottom"
              title="Workflow files"
            >
              <IconButton
                onClick={() => {
                  navigate(`/editor/${workflowId}/files`);
                }}
              >
                <FolderIcon className="!text-info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Version History">
              <IconButton
                onClick={() => {
                  toggleVersionHistory(true);
                }}
              >
                <AccessTimeIcon className="!text-info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Workflow settings">
              <IconButton
                onClick={() => {
                  navigate(`/workflows/${workflowId}/settings`);
                }}
              >
                <SettingsIcon color="secondary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload Changes">
              <IconButton
                disabled={status === 'pending'}
                onClick={() => {
                  if (
                    [
                      AdminVersionEnum.ForcePush,
                      AdminVersionEnum.ErrorForcePush,
                    ].includes(versionPush)
                  ) {
                    setPushModalOpen(true);
                  } else {
                    void saveWorkflow();
                  }
                }}
              >
                <CloudUpload className="!text-info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Changes">
              <IconButton className="!mr-2" onClick={downloadWorkflow}>
                <CloudDownload className="!text-green-600" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Button
              color="secondary"
              onClick={() => {
                void onImport();
              }}
              variant="outlined"
            >
              Import
            </Button>
            <Button
              className="!uppercase"
              color="secondary"
              onClick={() => {
                setShowRunWorkflowPopup(true);
              }}
              variant="contained"
            >
              Run workflow
            </Button>
            <Button
              className="!text-info !border-info"
              disabled={loadingVideos}
              onClick={fetchVideo}
              variant="outlined"
            >
              Video
            </Button>
            <Button
              className="!text-info !border-info"
              onClick={() => {
                setIsProcessingModalOpen(true);
              }}
              variant="outlined"
            >
              Process
            </Button>
          </div>
        }
      />

      <Modal
        className="w-full max-w-screen-md"
        onClose={() => {
          setIsProcessingModalOpen(false);
        }}
        open={isProcessingModalOpen}
      >
        <ConfirmWorkflowProcessing
          workflowId={workflowId}
          onCancel={() => {
            setIsProcessingModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        className="w-full !max-w-2xl"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your execution is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </Modal>
      {showManualRunPopup ? (
        <ManualRun
          onClose={() => {
            setShowManualRunPopup(false);
          }}
          workflowId={workflowId}
        />
      ) : null}
      {showRunWorkflowPopup && !saving ? (
        <ScheduleWorkflow
          onClose={() => {
            setShowRunWorkflowPopup(false);
          }}
          workflowId={workflowId}
          workflowMetadata={workflowMetadata}
        />
      ) : null}

      <AdminPushToolbarModal
        workflowId={workflowId}
        workflowName={workflowMetadata?.workflowName}
        workflowMetadata={workflowMetadata}
        versionPush={versionPush}
        open={pushModalOpen}
        setOpen={setPushModalOpen}
      />
    </>
  );
}
