"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowWebhook = exports.WebhookHeader = void 0;
var zod_1 = require("zod");
var legacy_schema_1 = require("./variable/legacy.schema");
exports.WebhookHeader = zod_1.z.object({
    key: legacy_schema_1.TemplateData,
    value: legacy_schema_1.TemplateData,
});
exports.WorkflowWebhook = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    webhookUrl: legacy_schema_1.TemplateData,
    headers: zod_1.z.array(exports.WebhookHeader),
});
