export const branchMissingContentMessage =
  'Complete branch conditions to mark the node as ready.';
export const requestNodeVariableMissingError =
  'Fill all the mandatory fields to mark the node as ready';
export const emailNodeVariableMissingError =
  'All highlighted values must be filled in.';
export const imageNodeBrokenActionMessage =
  'Fill all the actions to mark the node as ready';
export const freeformNodeVariableMissingError =
  'Fill the instructions to mark the node as ready.';

// Unless including one of the messages below, then the node errors must only contain the message for that node.
export const defaultErrorMessage = 'Node is invalid. Please contact support.';
export const brokenVariablesMessage = 'Some variables in use are invalid.';
export const deletedVariablesMessage = 'This node is using deleted variables.';
