import {
  CreateEmailTriggerDocument,
  GetWorkflowEmailTriggersDocument,
  SoftDeleteEmailTriggerDocument,
  UpdateEmailTriggerDocument,
  type GetWorkflowEmailTriggersQuery,
} from 'hasura-gql';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { handleException } from 'sentry-browser-shared';
import { AlertVariant, notify } from 'ui-kit';
import {
  type AuthenticatedConnectUser,
  ParagonIntegrationEnum,
} from 'dashboard-shared';
import { getIntegrationAccounts } from '../../../utils/helper';
import {
  type AccountIdentifier,
  useAccountIdentifiers,
} from '../../Integrations/hooks';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';

export { Triggers_Enum_Email_Event_Enum as TriggersEmailEventEnum } from 'hasura-gql';

export type EmailTrigger =
  GetWorkflowEmailTriggersQuery['triggers_email'][number];

export const useGetEmailTriggers = (
  workflowId: string,
): {
  data: EmailTrigger[];
  loading: boolean;
} => {
  const { data, loading } = useQuery(GetWorkflowEmailTriggersDocument, {
    skip: !workflowId,
    variables: { workflowId },
  });

  const emailTriggers = useMemo(
    () => data?.triggers_email ?? [],
    [data?.triggers_email],
  );

  return {
    data: emailTriggers,
    loading,
  };
};

export const useCreateEmailTrigger = () => {
  const [createEmailTrigger, { loading }] = useMutation(
    CreateEmailTriggerDocument,
    {
      refetchQueries: [GetWorkflowEmailTriggersDocument],
      onError: (error, data) => {
        notify({
          message: 'Failed to create trigger',
          variant: AlertVariant.WARNING,
        });
        handleException(error, {
          name: 'CreateEmailTrigger',
          source: 'useCreateEmailTrigger',
          extra: {
            error,
            variables: data?.variables,
          },
        });
      },
    },
  );

  return {
    createEmailTrigger,
    loading,
  };
};

export const useUpdateEmailTrigger = () => {
  const [updateEmailTrigger, { loading }] = useMutation(
    UpdateEmailTriggerDocument,
    {
      refetchQueries: [GetWorkflowEmailTriggersDocument],
      onError: (error, data) => {
        notify({
          message: 'Failed to update trigger',
          variant: AlertVariant.WARNING,
        });
        handleException(error, {
          name: 'UpdateEmailTrigger',
          source: 'useUpdateEmailTrigger',
          extra: {
            error,
            variables: data?.variables,
          },
        });
      },
    },
  );

  return {
    updateEmailTrigger,
    loading,
  };
};

export const useDeleteEmailTrigger = () => {
  const [deleteEmailTrigger, { loading }] = useMutation(
    SoftDeleteEmailTriggerDocument,
    {
      refetchQueries: [GetWorkflowEmailTriggersDocument],
    },
  );

  return {
    deleteEmailTrigger,
    loading,
  };
};

export const useGetEmailTriggerAccounts = (
  paragonUser: AuthenticatedConnectUser | undefined,
) => {
  const gmailAccounts = useMemo(
    () => getIntegrationAccounts(paragonUser, ParagonIntegrationEnum.GMAIL),
    [paragonUser],
  );
  const outlookAccounts = useMemo(
    () => getIntegrationAccounts(paragonUser, ParagonIntegrationEnum.OUTLOOK),
    [paragonUser],
  );

  const {
    data: gmailAccountIdentifiers = {},
    isLoading: isLoadingGmailIdentifiers,
  } = useAccountIdentifiers(gmailAccounts, ParagonIntegrationEnum.GMAIL);
  const {
    data: outlookAccountIdentifiers = {},
    isLoading: isLoadingOutlookIdentifiers,
  } = useAccountIdentifiers(outlookAccounts, ParagonIntegrationEnum.OUTLOOK);

  const getAddressFromIdentifiers = useCallback(
    (identifiers: Record<string, AccountIdentifier>) =>
      uniq(
        Object.values(identifiers)
          .map((identifier) => identifier.secondary ?? identifier.primary)
          .filter((email) => !isNil(email)),
      ),
    [],
  );

  const gmailAddresses = useMemo(() => {
    return getAddressFromIdentifiers(gmailAccountIdentifiers);
  }, [getAddressFromIdentifiers, gmailAccountIdentifiers]);

  const outlookAddresses = useMemo(() => {
    return getAddressFromIdentifiers(outlookAccountIdentifiers);
  }, [getAddressFromIdentifiers, outlookAccountIdentifiers]);

  return {
    gmailAddresses,
    outlookAddresses,
    loading: isLoadingGmailIdentifiers || isLoadingOutlookIdentifiers,
  };
};
