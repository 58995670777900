"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkApolloQueryResult = checkApolloQueryResult;
exports.checkApolloMutationResult = checkApolloMutationResult;
function checkApolloQueryResult(result, queryPrefix) {
    if (result.errors && result.errors.length > 0) {
        throw new Error(`Failed to query: ${queryPrefix}: ${result.errors.map((e) => e.message).join(', ')}`);
    }
    if (!result.data) {
        throw new Error(`${queryPrefix}: No data returned`);
    }
    return result.data;
}
function checkApolloMutationResult(result, mutationPrefix) {
    if (result.errors && result.errors.length > 0) {
        throw new Error(`Failed to mutate: ${mutationPrefix}: ${result.errors.map((e) => e.message).join(', ')}`);
    }
    if (!result.data) {
        throw new Error(`${mutationPrefix}: No data returned`);
    }
    return result.data;
}
