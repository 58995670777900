"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retrieveTransformWithQueriesSchema = exports.queueTransformWithQueriesSchema = void 0;
const types_shared_1 = require("types-shared");
exports.queueTransformWithQueriesSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            fileId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            prompts: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.string()),
        }),
    }),
    response: types_shared_1.z.object({
        jobId: types_shared_1.z.string(),
    }),
};
exports.retrieveTransformWithQueriesSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            fileId: types_shared_1.z.string(),
            jobId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({}).nullable(),
    }),
    response: types_shared_1.z.object({
        status: types_shared_1.z.enum(['ready', 'processing']),
        url: types_shared_1.z.string(), // Presigned URL when status is 'ready'
    }),
};
