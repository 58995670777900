"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXCEL_SHORCUTS = exports.ExcelShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../../serializer");
var ExcelShortcuts;
(function (ExcelShortcuts) {
    ExcelShortcuts["GoTo"] = "Go To";
    ExcelShortcuts["GoToSpecial"] = "Go To (Special Menu)";
    ExcelShortcuts["PasteSpecial"] = "Paste (Special Menu)";
    ExcelShortcuts["SelectRow"] = "Select Row";
    ExcelShortcuts["NextSheet"] = "Next Sheet";
    ExcelShortcuts["PrevSheet"] = "Prev Sheet";
    ExcelShortcuts["Save"] = "Save";
    ExcelShortcuts["SaveAs"] = "SaveAs";
    ExcelShortcuts["SaveAndEnter"] = "Save and Enter";
    ExcelShortcuts["CloseWindowWithoutSave"] = "Close Window Without Saving";
    ExcelShortcuts["DisplayDropdown"] = "Display Dropdown Menu";
    ExcelShortcuts["ExtendSelectionRight"] = "Extend Selection Right";
    ExcelShortcuts["ExtendSelectionLeft"] = "Extend Selection Left";
    ExcelShortcuts["ExtendSelectionUp"] = "Extend Selection Up";
    ExcelShortcuts["ExtendSelectionDown"] = "Extend Selection Down";
})(ExcelShortcuts || (exports.ExcelShortcuts = ExcelShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.Excel);
const SHORTCUT_MAPPING = {
    [ExcelShortcuts.GoTo]: [[types_shared_1.CONTROL, 'g']],
    [ExcelShortcuts.GoToSpecial]: [
        [types_shared_1.CONTROL, 'g'],
        [types_shared_1.ALT, 's'],
    ],
    [ExcelShortcuts.PasteSpecial]: [[types_shared_1.CONTROL, types_shared_1.ALT, 'v']],
    [ExcelShortcuts.Save]: [[types_shared_1.CONTROL, 's']],
    [ExcelShortcuts.SaveAs]: [['F12']],
    [ExcelShortcuts.SaveAndEnter]: [[types_shared_1.CONTROL, 's'], ['Enter']],
    [ExcelShortcuts.SelectRow]: [[types_shared_1.SHIFT, 'Space']],
    [ExcelShortcuts.PrevSheet]: [[types_shared_1.CONTROL, 'PageUp']],
    [ExcelShortcuts.NextSheet]: [[types_shared_1.CONTROL, 'PageDown']],
    [ExcelShortcuts.DisplayDropdown]: [[types_shared_1.ALT, 'ArrowDown']],
    [ExcelShortcuts.CloseWindowWithoutSave]: [[types_shared_1.ALT, 'F4'], ['Tab'], ['Enter']],
    [ExcelShortcuts.ExtendSelectionRight]: [[types_shared_1.CONTROL, types_shared_1.SHIFT, 'ArrowRight']],
    [ExcelShortcuts.ExtendSelectionLeft]: [[types_shared_1.CONTROL, types_shared_1.SHIFT, 'ArrowLeft']],
    [ExcelShortcuts.ExtendSelectionUp]: [[types_shared_1.CONTROL, types_shared_1.SHIFT, 'ArrowUp']],
    [ExcelShortcuts.ExtendSelectionDown]: [[types_shared_1.CONTROL, types_shared_1.SHIFT, 'ArrowDown']],
};
exports.EXCEL_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
__exportStar(require("./data-tab"), exports);
__exportStar(require("./home-tab"), exports);
__exportStar(require("./file-tab"), exports);
