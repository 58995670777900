import { type ExecutionStatusEnum } from 'types-shared/executionTypes';
import * as echarts from 'echarts';
import {
  type ExecutionHourlyMetrics,
  type ExecutionCount,
  type WorkflowCount,
} from './hooks';
import { type GetTeamMembersWithWorkflowCountQuery } from 'hasura-gql';
import { type GridColDef } from '@mui/x-data-grid/models';
import dayjs from '../../config/dayjs';
import {
  DateFilterTitleEnum,
  type DateFilterValue,
} from '../Workflows/components/ExecutionListDateFilter';

export const createExecutionsListPageUrl = (
  statuses: ExecutionStatusEnum[],
) => {
  const queryParams = encodeURIComponent(`["${statuses.join('","')}"]`);
  return `/executions-list?status=${queryParams}`;
};

interface Color {
  r: string;
  g: string;
  b: string;
}

const getSeriesOptions = (name: string, color: Color, data: number[]) => ({
  name,
  type: 'line',
  smooth: true,
  lineStyle: {
    width: 2,
    color: `rgb(${color.r}, ${color.g}, ${color.b})`,
  },
  showSymbol: false,
  areaStyle: {
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: `rgba(${color.r}, ${color.g}, ${color.b}, 0.8)`,
      },
      {
        offset: 1,
        color: `rgba(${color.r}, ${color.g}, ${color.b}, 0.3)`,
      },
    ]),
  },
  emphasis: {
    focus: 'series',
  },
  data,
});

const successColor = {
  hex: '#429A8B',
  rgb: { r: '66', g: '154', b: '139' },
};
const failureColor = {
  hex: '#E97559',
  rgb: { r: '233', g: '117', b: '89' },
};
const runningColor = {
  hex: '#0f3d61',
  rgb: { r: '15', g: '61', b: '97' },
};
const infoColor = {
  hex: '#5D3FD3',
  rgb: { r: '93', g: '63', b: '211' },
};
const defaultColor = {
  hex: '#5c9ef3',
  rgb: { r: '49', g: '116', b: '250' },
};

export const getExecutionMonthsChartOptions = (
  data: [string, ExecutionCount][],
) => {
  return {
    color: [successColor.hex, failureColor.hex],
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Success', 'Failure'],
      bottom: '0',
      left: 'center',
      itemGap: 40,
    },
    grid: {
      left: '15',
      right: '15',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data.map(([month]) => month),
        axisTick: false,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: false,
      },
    ],
    series: [
      getSeriesOptions(
        'Success',
        successColor.rgb,
        data.map(([, { successfulExecutions }]) => successfulExecutions),
      ),
      getSeriesOptions(
        'Failure',
        failureColor.rgb,
        data.map(([, { failedExecutions }]) => failedExecutions),
      ),
    ],
  };
};

export const getWorkflowsMonthsChartOptions = (
  data: [string, WorkflowCount][],
) => {
  return {
    color: [infoColor.hex, defaultColor.hex],
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Total', 'New'],
      bottom: '0',
      left: 'center',
      itemGap: 40,
    },
    grid: {
      left: '15',
      right: '15',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data.map(([month]) => month),
        axisTick: false,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: false,
      },
    ],
    series: [
      getSeriesOptions(
        'Total',
        infoColor.rgb,
        data.map(([, { cumulative }]) => cumulative),
      ),
      getSeriesOptions(
        'New',
        defaultColor.rgb,
        data.map(([, { total }]) => total),
      ),
    ],
  };
};

const formatHour = (hour: string) => {
  const meridian = hour.slice(0, 2);
  const hourPart = hour.slice(2);
  return `${hourPart} ${meridian}`;
};

export const getExecutionHourlyChartOptions = (
  data: [string, ExecutionHourlyMetrics][],
) => ({
  color: [infoColor.hex, defaultColor.hex],
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: ['Executed', 'Queued'],
    bottom: '0',
    left: 'center',
    itemGap: 40,
  },
  grid: {
    left: '20',
    right: '20',
    bottom: '15%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: data.map(([hour]) => formatHour(hour)),
      axisTick: false,
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: false,
    },
  ],
  series: [
    getSeriesOptions(
      'Executed',
      infoColor.rgb,
      data.map(([, { executed }]) => executed),
    ),
    getSeriesOptions(
      'Queued',
      defaultColor.rgb,
      data.map(([, { queued }]) => queued),
    ),
  ],
});

export const getExecutionsPieChartOptions = (
  totalExecutionsData: ExecutionCount,
) => ({
  tooltip: { trigger: 'item' },
  color: [successColor.hex, runningColor.hex, failureColor.hex],
  legend: {
    bottom: '5%',
    left: 'center',
    itemGap: 25,
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '65%'],
      avoidLabelOverlap: false,
      label: { show: false },
      top: '-30',
      data: [
        {
          value: totalExecutionsData.successfulExecutions,
          name: 'Success',
        },
        {
          value:
            totalExecutionsData.runningExecutions +
            totalExecutionsData.queuedExecutions,
          name: 'Running & Queued',
        },
        {
          value: totalExecutionsData.failedExecutions,
          name: 'Failure',
        },
      ],
    },
  ],
});

export type MemberWithWorkflowCount =
  GetTeamMembersWithWorkflowCountQuery['users'][number];

export const membersTableColumns: GridColDef<MemberWithWorkflowCount>[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortable: false,
  },
  {
    field: 'workflows',
    headerName: 'Workflows',
    width: 150,
    sortable: false,
    renderCell: (params) =>
      params.row.workflows_aggregate.aggregate?.count ?? 0,
  },
  {
    field: 'executions',
    headerName: 'Executions',
    width: 150,
    sortable: false,
    renderCell: (params) =>
      params.row.executions_aggregate.aggregate?.count ?? 0,
  },
];

export const getPercentageIncrease = (num1: number, num2: number): number => {
  if (num2 === 0) {
    return 100;
  }
  return ((num1 - num2) / num2) * 100;
};

export const titleClasses = 'text-sm font-medium text-navy-blue';

export const getUtcTime = (time: string): string => {
  const timezone = dayjs().format('Z');
  const date = dayjs().format('YYYY-MM-DD');
  return dayjs(`${date}T${time}${timezone}`).utc().format('HH:mm:ssZ');
};

export const getUtcDate = (date: string): string =>
  dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ssZ');

export const getDateRange = (
  dateFilterValue: DateFilterValue,
): [string, string] => {
  let range: [string, string];
  const today = dayjs();
  switch (dateFilterValue.chosenFilter) {
    case DateFilterTitleEnum.Today: {
      const startOfDay = today.startOf('day');
      const endOfDay = today.endOf('day');
      range = [startOfDay.format(), endOfDay.format()];
      break;
    }
    case DateFilterTitleEnum.Yesterday: {
      const startOfYesterday = today.subtract(1, 'day').startOf('day');
      const endOfYesterday = today.subtract(1, 'day').endOf('day');
      range = [startOfYesterday.format(), endOfYesterday.format()];
      break;
    }
    case DateFilterTitleEnum['Last week']: {
      const startOfLastWeek = today.subtract(1, 'week').startOf('week');
      const endOfLastWeek = today.subtract(1, 'week').endOf('week');
      range = [startOfLastWeek.format(), endOfLastWeek.format()];
      break;
    }
    case DateFilterTitleEnum['Last month']:
    default: {
      const startOfLastMonth = today.subtract(1, 'month').startOf('month');
      const endOfLastMonth = today.subtract(1, 'month').endOf('month');
      range = [startOfLastMonth.format(), endOfLastMonth.format()];
      break;
    }
  }
  return range;
};
