var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// MARK: - Operators
export var OperatorType;
(function (OperatorType) {
    OperatorType["BuiltIn"] = "built-in";
    OperatorType["Custom"] = "custom";
})(OperatorType || (OperatorType = {}));
var BaseOperator = /** @class */ (function () {
    function BaseOperator(__zodFieldTypeBrand, type, name, defaultLabel, validators) {
        if (validators === void 0) { validators = []; }
        this.__zodFieldTypeBrand = __zodFieldTypeBrand;
        this.type = type;
        this.name = name;
        this.label = defaultLabel;
        this.validators = validators;
    }
    BaseOperator.prototype.eraseToUnknown = function () {
        return this;
    };
    return BaseOperator;
}());
export { BaseOperator };
// MARK: - Built-In Operator Class -- Operators supported by the query builder library out of the box
var BuiltInOperator = /** @class */ (function (_super) {
    __extends(BuiltInOperator, _super);
    function BuiltInOperator(__zodFieldTypeBrand, name, defaultLabel, validators) {
        if (validators === void 0) { validators = []; }
        var _this = _super.call(this, __zodFieldTypeBrand, OperatorType.BuiltIn, name, defaultLabel, validators) || this;
        _this.type = OperatorType.BuiltIn;
        _this.name = name;
        return _this;
    }
    BuiltInOperator.prototype.withLabel = function (label) {
        return new BuiltInOperator(this.__zodFieldTypeBrand, this.name, label, this.validators);
    };
    BuiltInOperator.prototype.withValidator = function (validator) {
        return new BuiltInOperator(this.__zodFieldTypeBrand, this.name, this.label, __spreadArray(__spreadArray([], this.validators, true), [validator], false));
    };
    return BuiltInOperator;
}(BaseOperator));
export { BuiltInOperator };
// MARK: - Custom Operator Class -- Custom operators for which we need to provide CEL conversion logic
var CustomOperator = /** @class */ (function (_super) {
    __extends(CustomOperator, _super);
    function CustomOperator(__zodFieldTypeBrand, name, defaultLabel, processAsCEL, validators) {
        if (validators === void 0) { validators = []; }
        var _this = _super.call(this, __zodFieldTypeBrand, OperatorType.Custom, name, defaultLabel, validators) || this;
        _this.type = OperatorType.Custom;
        _this.name = name;
        _this.processAsCEL = processAsCEL;
        return _this;
    }
    CustomOperator.prototype.withLabel = function (label) {
        return new CustomOperator(this.__zodFieldTypeBrand, this.name, label, this.processAsCEL);
    };
    CustomOperator.prototype.withValidator = function (validator) {
        return new CustomOperator(this.__zodFieldTypeBrand, this.name, this.label, this.processAsCEL, __spreadArray(__spreadArray([], this.validators, true), [validator], false));
    };
    return CustomOperator;
}(BaseOperator));
export { CustomOperator };
