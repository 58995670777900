"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addFilesSchema = void 0;
const types_shared_1 = require("types-shared");
const addFilesPayload = types_shared_1.z.object({
    workflowId: types_shared_1.z.string(),
    name: types_shared_1.z.string(),
});
exports.addFilesSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            files: types_shared_1.z.array(addFilesPayload),
        }),
    }),
    response: types_shared_1.z.array(types_shared_1.z
        .object({
        uploadUrl: types_shared_1.z.string(),
    })
        .merge(types_shared_1.S3Ref)),
};
