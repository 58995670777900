import {
  AccessTimeIcon,
  ContentContainer,
  CustomTypography,
  InputWithActiveBorder,
  ScrollableContainer,
  RunningExecutionIcon,
} from 'ui-kit';
import { NavigationPanel } from '../../components/NavigationPanel';
import OrchestrationMetrics from './components/Metrics';
import ListSkeleton from '../../components/ListSkeleton';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { type GridColDef } from '@mui/x-data-grid/models';
import { type Workflow, useGetWorkflowsWithExecutionsCount } from './hooks';
import { type ComponentType, useEffect, useMemo, useState } from 'react';
import EditConcurrencyBtn from './components/EditConcurrencyBtn';
import EmptyStatePlaceholder, {
  EmptyStateEnum,
} from '../../components/EmptyStatePlaceholder';
import { useImportWorkflow } from '../../hooks/useImportWorkflow';
import { FeatureFlag } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '../../utils/helper';
import { ExecutionStatusEnum } from 'types-shared/executionTypes';
import { type SvgIconProps } from '@mui/material/SvgIcon';
import { clsx } from 'clsx';
import isNil from 'lodash/isNil';

const sortComparator = (
  count1: number | undefined | null,
  count2: number | undefined | null,
): number => {
  if (isNil(count1) && isNil(count2)) return 0; // Both are null/undefined, consider equal
  if (isNil(count1)) return 1; // Place count1 after count2
  if (isNil(count2)) return -1; // Place count2 after count1

  if (count1 === 0 && count2 === 0) return 0; // Both are zero
  if (count1 === 0) return 1; // Place zero after non-zero numbers
  if (count2 === 0) return -1;

  return count2 - count1; // Descending order for numbers
};

interface ExecutionCountProps {
  count: number;
  type: ExecutionStatusEnum;
  className?: string;
  iconClassName?: string;
  Icon: ComponentType<SvgIconProps>;
}

function ExecutionCount({
  count,
  Icon,
  className,
  iconClassName,
}: ExecutionCountProps) {
  return (
    <div
      className={clsx(
        'px-2 py-0.5 rounded font-medium flex space-x-2 items-center',
        className,
      )}
    >
      <span>{count}</span>
      <Icon className={clsx('!w-4 !h-5', iconClassName)} />
    </div>
  );
}

const columns: GridColDef<Workflow>[] = [
  { field: 'name', headerName: 'Workflow name', flex: 1, sortable: false },
  {
    field: 'queued',
    headerName: 'Queued executions',
    flex: 1,
    sortComparator: (v1: Workflow['queued'], v2: Workflow['queued']) =>
      sortComparator(v1.aggregate?.count, v2.aggregate?.count),
    renderCell: (params) => {
      const count = Number(params.row.queued.aggregate?.count);
      return count > 0 ? (
        <ExecutionCount
          className="bg-primary-blue-extralight-2 text-navy-blue"
          Icon={AccessTimeIcon}
          count={count}
          type={ExecutionStatusEnum.Queued}
          iconClassName="!text-color-grey"
        />
      ) : (
        0
      );
    },
  },
  {
    field: 'running',
    headerName: 'Running executions',
    flex: 1,
    sortComparator: (v1: Workflow['running'], v2: Workflow['running']) =>
      sortComparator(v1.aggregate?.count, v2.aggregate?.count),
    renderCell: (params) => {
      const count = Number(params.row.running.aggregate?.count);
      return count > 0 ? (
        <ExecutionCount
          className="bg-navy-blue text-white"
          Icon={RunningExecutionIcon}
          count={count}
          type={ExecutionStatusEnum.Running}
          iconClassName="!text-white"
        />
      ) : (
        0
      );
    },
  },
  {
    field: 'maxConcurrentExecutions',
    headerName: 'Concurrency limit',
    flex: 1,
    sortComparator: (
      maxConcurrentExecutions1: number,
      maxConcurrentExecutions2: number,
    ) => sortComparator(maxConcurrentExecutions1, maxConcurrentExecutions2),
    renderCell: (params) => (
      <EditConcurrencyBtn
        concurrencyLimit={params.row.maxConcurrentExecutions}
        workflowId={params.row.id}
      />
    ),
  },
];

export default function Orchestration() {
  const navigate = useNavigate();
  const { data, loading } = useGetWorkflowsWithExecutionsCount();
  const [workflowName, setWorkflowName] = useState<string>('');
  const { loadingImport, handleOnClickImport, importWorkflowProps } =
    useImportWorkflow({ isLoading: loading });
  const orchestrationPageEnabled = useFeatureFlag(
    FeatureFlag.OrchestrationPage,
  );

  const hasFilters = Boolean(workflowName);

  const placeholderButtonProps = useMemo(() => {
    if (hasFilters) {
      return {
        onClick: () => {
          setWorkflowName('');
        },
        disabled: false,
        loading: false,
      };
    }
    return {
      onClick: handleOnClickImport,
      disabled: importWorkflowProps.disableImport,
      loading: loadingImport,
    };
  }, [
    handleOnClickImport,
    hasFilters,
    importWorkflowProps.disableImport,
    loadingImport,
  ]);

  const filteredData: Workflow[] = useMemo(() => {
    return data.filter(
      (workflow: Workflow) =>
        !workflowName ||
        workflow.name.toLowerCase().includes(workflowName.toLowerCase()),
    );
  }, [data, workflowName]);

  useEffect(() => {
    if (orchestrationPageEnabled === false) {
      navigate('/');
    }
  }, [navigate, orchestrationPageEnabled]);

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />
        <ContentContainer className="flex flex-col !pb-2">
          <CustomTypography variant="h4">Orchestration</CustomTypography>
          <OrchestrationMetrics />
          <InputWithActiveBorder
            classes={{ wrapper: 'w-80 !mt-7 !mb-4' }}
            style={{ fontSize: '14px' }}
            isSearch
            label="Workflow name"
            floatingLabel
            placeholder="Search by Name"
            onChange={setWorkflowName}
            value={workflowName}
          />
          {loading ? (
            <ListSkeleton columns={columns} idField="id" />
          ) : (
            <>
              {filteredData.length > 0 ? (
                <DataGrid
                  className="flex-1"
                  columns={columns}
                  getRowId={(row) => row.id}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                  }}
                  rows={filteredData}
                />
              ) : (
                <EmptyStatePlaceholder
                  type={EmptyStateEnum.WORKFLOW}
                  buttonProps={placeholderButtonProps}
                  title={
                    hasFilters
                      ? 'No workflows were found for this view'
                      : "No workflows yet? Let's get started!"
                  }
                  description={
                    hasFilters
                      ? 'Try adjusting your filters to find workflows.'
                      : 'Use a Sola recorder to capture your workflow process, then import it here to edit and run.'
                  }
                  buttonText={hasFilters ? 'Remove filters' : 'Import workflow'}
                />
              )}
            </>
          )}
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
