var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EmailMetadataSchemaV1 } from 'types-shared';
import { fieldsFromSchema } from '../fields';
import { DefaultStringArrayOperators, DefaultStringOperators, isEmailValidator, nonEmptyValidator, } from '../operators';
export var EmailMetadataForTriggerFields = fieldsFromSchema(EmailMetadataSchemaV1, {
    subject: {
        label: 'Subject',
        operators: __spreadArray([], DefaultStringOperators.ALL.map(function (op) {
            return op.withValidator(nonEmptyValidator);
        }).map(function (op) { return op.eraseToUnknown(); }), true),
    },
    sender: {
        label: 'Sender',
        operators: [
            DefaultStringOperators.EQUALS.withLabel('is').withValidator(isEmailValidator),
            DefaultStringOperators.NOT_EQUALS.withLabel('is not').withValidator(isEmailValidator),
            DefaultStringOperators.CONTAINS,
            DefaultStringOperators.BEGINS_WITH,
            DefaultStringOperators.ENDS_WITH,
            DefaultStringOperators.DOES_NOT_CONTAIN,
            DefaultStringOperators.DOES_NOT_BEGIN_WITH,
        ]
            .map(function (op) { return op.withValidator(nonEmptyValidator); })
            .map(function (op) { return op.eraseToUnknown(); }),
    },
    attachmentNames: {
        label: 'Attachment Names',
        operators: [
            DefaultStringArrayOperators.ANY_CONTAINS.withLabel('any attachment name contains').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.NONE_CONTAINS.withLabel('none of the attachment names contain').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.ANY_EQUALS.withLabel('any attachment name equals').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.NONE_EQUALS.withLabel('none of the attachment names equal').withValidator(nonEmptyValidator),
        ].map(function (op) { return op.eraseToUnknown(); }),
    },
    recipients: {
        label: 'Recipients',
        operators: [
            DefaultStringArrayOperators.ANY_CONTAINS.withLabel('any recipient address contains').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.NONE_CONTAINS.withLabel('none of the recipient addresses contain').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.ANY_EQUALS.withLabel('any recipient address equals').withValidator(nonEmptyValidator),
            DefaultStringArrayOperators.NONE_EQUALS.withLabel('none of the recipient addresses equal').withValidator(nonEmptyValidator),
        ].map(function (op) { return op.eraseToUnknown(); }),
    },
});
