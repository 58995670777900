import { clsx } from 'clsx';
import { type ReactNode } from 'react';

interface ContainerProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  selected?: boolean;
}

export default function Container({
  className,
  children,
  onClick,
  selected,
}: ContainerProps) {
  return (
    <div
      className={clsx(
        'p-4 rounded-lg border cursor-pointer flex flex-col space-y-3 hover:border-sola-primary hover:ring-1 hover:ring-sola-primary hover:bg-sola-primary-10',
        {
          'border-sola-primary ring-1 ring-sola-primary bg-sola-primary-10':
            selected,
        },
        className,
      )}
      onClick={onClick}
      role="presentation"
    >
      {children}
    </div>
  );
}
