"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailEvent = exports.WorkflowNotificationConfigurationsPreset = exports.WorkflowEventType = exports.PlatformType = exports.HasuraExecutionStatusEnum = exports.ActionType = exports.EmailTrigger = exports.HasuraExecution = exports.ClusterData = exports.TemporalAuthenticationEnum = exports.GlobalVariable = exports.HasuraDate = exports.TeamAndRoleInfo = exports.MemberRole = void 0;
const types_shared_1 = require("types-shared");
const graphql_1 = require("../generated/graphql");
Object.defineProperty(exports, "ActionType", { enumerable: true, get: function () { return graphql_1.Access_Control_Enum_Action_Enum; } });
Object.defineProperty(exports, "HasuraExecutionStatusEnum", { enumerable: true, get: function () { return graphql_1.Execution_Status_Enum; } });
Object.defineProperty(exports, "PlatformType", { enumerable: true, get: function () { return graphql_1.Notifications_Enum_Platform_Enum; } });
Object.defineProperty(exports, "WorkflowEventType", { enumerable: true, get: function () { return graphql_1.Notifications_Enum_Workflow_Event_Enum; } });
Object.defineProperty(exports, "WorkflowNotificationConfigurationsPreset", { enumerable: true, get: function () { return graphql_1.Notifications_Enum_Workflow_Configurations_Preset_Enum; } });
Object.defineProperty(exports, "EmailEvent", { enumerable: true, get: function () { return graphql_1.Triggers_Enum_Email_Event_Enum; } });
exports.MemberRole = types_shared_1.z.object({
    roleIdentifier: types_shared_1.z.string(),
    teamId: types_shared_1.z.string(),
});
exports.TeamAndRoleInfo = types_shared_1.z.object({
    teamId: types_shared_1.z.string(),
    teamName: types_shared_1.z.string(),
    roleName: types_shared_1.z.string(),
    isAdmin: types_shared_1.z.boolean(),
    workflowLimit: types_shared_1.z.number().optional().nullable(),
});
exports.HasuraDate = types_shared_1.z.union([types_shared_1.z.string(), types_shared_1.z.date()]).transform((val) => {
    return new Date(val);
});
exports.GlobalVariable = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    name: types_shared_1.z.string(),
    ownerId: types_shared_1.z.string(),
    value: types_shared_1.z.string(),
    updatedAt: exports.HasuraDate,
});
var TemporalAuthenticationEnum;
(function (TemporalAuthenticationEnum) {
    TemporalAuthenticationEnum["None"] = "none";
    TemporalAuthenticationEnum["ApiKey"] = "api_key";
    TemporalAuthenticationEnum["Mtls"] = "mtls";
})(TemporalAuthenticationEnum || (exports.TemporalAuthenticationEnum = TemporalAuthenticationEnum = {}));
exports.ClusterData = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    datahubAddress: types_shared_1.z.string(),
    seleniumAddress: types_shared_1.z.string(),
    temporalAddress: types_shared_1.z.string(),
    temporalNamespace: types_shared_1.z.string(),
    temporalAuthentication: types_shared_1.z.nativeEnum(TemporalAuthenticationEnum),
});
const TeamMember = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    email: types_shared_1.z.string(),
    role: types_shared_1.z.string(),
    workflowCount: types_shared_1.z.number(),
});
exports.HasuraExecution = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    adminRun: types_shared_1.z.boolean(),
    status: types_shared_1.ExecutionStatus, // z.enum([ExecutionStatusEnum.Running, ExecutionStatusEnum.Terminated])
    trigger: types_shared_1.ExecutionTrigger,
    workflowVersionId: types_shared_1.z.string(),
    attempt: types_shared_1.z.number(),
    proxyMode: types_shared_1.z.string().nullable(),
    // FIXME: We should amend HasuraDate to support serialization to the database by converting to a string in ISO8601 format
    // See https://hasura.io/blog/working-with-dates-time-timezones-graphql-postgresql#timestamp-with-time-zone
    createdAt: exports.HasuraDate,
    updatedAt: exports.HasuraDate,
    startedAt: exports.HasuraDate.nullable(), // null if the execution has not started yet
    // TODO: standardize "ownerId" across execution types
    // Currently "ownerId" is used on Hasura, and "userId" in ddb
    ownerId: types_shared_1.z.string(),
    workflowId: types_shared_1.z.string(),
});
exports.EmailTrigger = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    workflow: types_shared_1.z.object({
        id: types_shared_1.z.string(),
        name: types_shared_1.z.string(),
        ownerId: types_shared_1.z.string(),
    }),
    name: types_shared_1.z.string(),
    event: types_shared_1.z.nativeEnum(graphql_1.Triggers_Enum_Email_Event_Enum),
    receiverEmailAddress: types_shared_1.z.string().email(),
    conditionDataSchemaName: types_shared_1.z.string(),
    conditionDataSchemaVersion: types_shared_1.z.string(),
    conditionQueryBuilderJson: types_shared_1.z.record(types_shared_1.z.any()),
    conditionCelExpression: types_shared_1.z.string(),
    createdAt: exports.HasuraDate,
    updatedAt: exports.HasuraDate,
    deletedAt: exports.HasuraDate.nullable(),
});
// Export notification types
__exportStar(require("./notifications"), exports);
