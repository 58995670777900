import { useState } from 'react';
import {
  ExecutionTriggerEnum,
  type ExecutionBase,
} from 'types-shared/executionTypes';
import {
  Button,
  CustomTypography,
  DateTimePicker,
  Flex,
  Input,
  Modal,
  Select,
} from 'ui-kit';
import dayjs from '../../../config/dayjs';
import pick from 'lodash/pick';
import { useTeamMembers } from '../../Members/hooks.gql';
import { useUpdateExecution } from '../../Execution/hooks';

interface Props {
  execution: ExecutionBase;
  onCancel: () => void;
  onRefetch: () => void;
}

const triggerOptions = [
  ExecutionTriggerEnum.API,
  ExecutionTriggerEnum.Email,
  ExecutionTriggerEnum.Manual,
  ExecutionTriggerEnum.Scheduled,
];

const showUserInput = (() => false)();

export default function EditExecutionModal({
  execution,
  onCancel,
  onRefetch,
}: Props) {
  const [formData, setFormData] = useState<ExecutionBase>(execution);
  // TODO: use workflow owner id instead of execution userId
  const { data: members = [] } = useTeamMembers(execution.userId);
  const { mutateAsync: updateExecution, status } = useUpdateExecution();

  const loading = status === 'pending';

  const onSave = async () => {
    const transformedData = pick(formData, [
      'createdAt',
      'lastUpdatedAt',
      'trigger',
      ...(showUserInput ? ['userEmail', 'userId'] : []),
    ]);
    if (showUserInput) {
      const member = members.find(({ email }) => email === formData.userEmail);
      if (!member) {
        delete transformedData.userEmail;
      } else {
        transformedData.userId = member.id;
      }
    }
    await updateExecution({
      id: execution.executionId,
      ...transformedData,
    });
    onCancel();
    onRefetch();
  };

  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open>
      <CustomTypography variant="h5">Edit execution data</CustomTypography>

      <div className="mt-7 flex flex-col gap-4">
        {showUserInput ? (
          <Input
            floatingLabel
            label="User Email"
            value={formData.userEmail}
            onChange={(value) => {
              setFormData((prev) => ({
                ...prev,
                userEmail: value,
              }));
            }}
          />
        ) : null}
        <Select
          label="Trigger"
          options={triggerOptions}
          getLabel={(option) => option}
          getValue={(option) => option}
          value={formData.trigger}
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              trigger: e.target.value as ExecutionTriggerEnum,
            }));
          }}
        />
        <DateTimePicker
          label="Queued At"
          value={formData.createdAt ? dayjs(formData.createdAt) : undefined}
          disablePast={false}
          onChange={(value) => {
            setFormData((prev) => ({
              ...prev,
              createdAt: value.toISOString(),
            }));
          }}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null,
          }}
        />
        <DateTimePicker
          label="Last Updated At"
          value={
            formData.lastUpdatedAt ? dayjs(formData.lastUpdatedAt) : undefined
          }
          disablePast={false}
          onChange={(value) => {
            setFormData((prev) => ({
              ...prev,
              lastUpdatedAt: value.toISOString(),
            }));
          }}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null,
          }}
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          loading={loading}
          color="secondary"
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
