"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadVideoSchema = void 0;
const types_shared_1 = require("types-shared");
exports.uploadVideoSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
            videoUpload: types_shared_1.z.object({
                uploadId: types_shared_1.z.string(),
                urls: types_shared_1.z.array(types_shared_1.z.string()),
            }),
            imageUploadMap: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.object({
                imagePresignedS3Url: types_shared_1.z.string(),
                thumbnailPresignedS3Url: types_shared_1.z.string(),
            })),
        }),
    }),
    response: types_shared_1.z.array(types_shared_1.z.object({ etag: types_shared_1.z.string(), partNumber: types_shared_1.z.number() })),
};
