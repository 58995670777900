import { useTeamDetails } from '../../hooks/useTeamDetails.gql';
import { useAuth } from '../../utils/helper';
import { isAdmin } from '../../utils/env';
import { MOCK_USER_ID } from 'types-shared/constants';
import { NetworkStatus, useQuery, type ApolloError } from '@apollo/client';
import {
  GetTeamQueuedExecutionsCountDocument,
  GetTeamRunningExecutionsCountDocument,
  GetTeamSettingsDocument,
  GetWorkflowsWithExecutionsCountDocument,
  type GetWorkflowsWithExecutionsCountQuery,
} from 'hasura-gql';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../../utils/constants';
import { type DateFilterValue } from '../Workflows/components/ExecutionListDateFilter';
import { getUtcDate } from '../Insights/helper';

export const useGetTeamMaxConcurrentExecutions = () => {
  const { user } = useAuth();
  const userID = user?.sub ?? (isAdmin ? MOCK_USER_ID : undefined);
  const {
    data: teamDetails,
    loading: teamDetailsLoading,
    error: teamDetailsError,
  } = useTeamDetails(userID);

  const teamId = teamDetails?.teamId ?? '';

  const {
    data: teamSettings,
    loading: teamSettingsLoading,
    error: teamSettingsError,
    networkStatus: teamSettingsNetworkStatus,
    refetch: refetchTeamSettings,
  } = useQuery(GetTeamSettingsDocument, {
    variables: { teamId },
    skip: !teamId,
    notifyOnNetworkStatusChange: true,
  });

  const maxConcurrentExecutions =
    teamSettings?.organizations_teams[0]?.maxConcurrentExecutions ?? 0;

  return {
    maxConcurrentExecutions,
    teamId,
    loading:
      teamDetailsLoading ||
      teamSettingsLoading ||
      teamSettingsNetworkStatus === NetworkStatus.refetch,
    error: teamDetailsError || teamSettingsError,
    refetch: refetchTeamSettings,
  };
};

export const useGetOrchestrationMetrics = (): {
  data: {
    maxConcurrentExecutions: number;
    queuedExecutionsCount: number;
    runningExecutionsCount: number;
  };
  isLoading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
} => {
  const {
    maxConcurrentExecutions,
    teamId,
    loading,
    error: teamSettingsError,
  } = useGetTeamMaxConcurrentExecutions();

  const {
    data: runningExecutionsCountData,
    loading: runningExecutionsLoading,
    error: runningExecutionsError,
    refetch: refetchRunningExecutionsCount,
    networkStatus: runningExecutionsNetworkStatus,
  } = useQuery(GetTeamRunningExecutionsCountDocument, {
    variables: { teamId },
    skip: !teamId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: queuedExecutionsCountData,
    loading: queuedExecutionsLoading,
    error: queuedExecutionsError,
    refetch: refetchQueuedExecutionsCount,
    networkStatus: queuedExecutionsNetworkStatus,
  } = useQuery(GetTeamQueuedExecutionsCountDocument, {
    variables: { teamId },
    skip: !teamId,
    notifyOnNetworkStatusChange: true,
  });

  const runningExecutionsCount =
    runningExecutionsCountData?.executions_aggregate.aggregate?.count ?? 0;
  const queuedExecutionsCount =
    queuedExecutionsCountData?.executions_aggregate.aggregate?.count ?? 0;

  const isLoading =
    loading ||
    runningExecutionsLoading ||
    queuedExecutionsLoading ||
    runningExecutionsNetworkStatus === NetworkStatus.refetch ||
    queuedExecutionsNetworkStatus === NetworkStatus.refetch;

  const error =
    teamSettingsError ?? runningExecutionsError ?? queuedExecutionsError;

  const refetch = () => {
    void refetchRunningExecutionsCount();
    void refetchQueuedExecutionsCount();
  };

  return {
    data: {
      maxConcurrentExecutions,
      queuedExecutionsCount,
      runningExecutionsCount,
    },
    isLoading,
    error,
    refetch,
  };
};

export type Workflow =
  GetWorkflowsWithExecutionsCountQuery['workflows'][number];

export const useGetWorkflowsWithExecutionsCount = (
  memberId?: string,
  dateFilterValue?: DateFilterValue,
): {
  data: Workflow[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
} => {
  const [startDate, endDate] = (dateFilterValue?.dateRange ?? []) as [
    string,
    string,
  ];
  const hasCustomDateRange = startDate && endDate;
  const { data, loading, error, refetch, networkStatus } = useQuery(
    GetWorkflowsWithExecutionsCountDocument,
    {
      variables: {
        conditions: {
          deletedAt: {
            _is_null: true,
          },
          ...(hasCustomDateRange
            ? {
                createdAt: {
                  _gt: getUtcDate(startDate),
                  _lt: getUtcDate(endDate),
                },
              }
            : {}),
          ...(memberId ? { ownerId: { _eq: memberId } } : {}),
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    data: data?.workflows ?? [],
    loading: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  };
};

export const useGetBlockedConcurrencyLimitWorkflows = (): string[] => {
  const blockedWorkflowsData = useFeatureFlagPayload(
    FeatureFlag.BlockSettingMaxConcurrentExecutions,
  ) as { workflowIds: string[] } | undefined;

  return blockedWorkflowsData?.workflowIds ?? [];
};
