"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendSlackMessageSchema = exports.sendSlackMessagePayload = void 0;
const types_shared_1 = require("types-shared");
exports.sendSlackMessagePayload = types_shared_1.z.object({
    workflowId: types_shared_1.z.string(),
    channelId: types_shared_1.z.string(),
    text: types_shared_1.z.string(),
});
exports.sendSlackMessageSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: exports.sendSlackMessagePayload,
    }),
    response: types_shared_1.z.object({
        ok: types_shared_1.z.boolean(),
        error: types_shared_1.z.string().optional(),
    }),
};
