import Breadcrumbs from '@mui/material/Breadcrumbs';
import { type ReactElement, useMemo, type ReactNode } from 'react';
import { Clear, IconButton, Logo, Typography } from 'ui-kit';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  RightEndContent?: ReactElement | null;
  CentralContent?: ReactElement | null;
  LeftEndContent?: ReactElement | null;
  onClose?: () => void;
  hasOuterContainer?: boolean;
  containerClassName?: string;
  crumbs?: Crumb[];
  loading?: boolean;
}

interface Crumb {
  title: ReactElement | ReactNode;
  link?: string;
  onClick?: () => void;
  doNotTruncate?: boolean;
}

export function ComponentsToolbar({
  onClose,
  RightEndContent,
  CentralContent,
  LeftEndContent,
  hasOuterContainer = true,
  containerClassName,
  crumbs,
  loading,
}: Props) {
  const Component = useMemo(
    () => (
      <div className="flex items-center space-x-2 justify-between w-full">
        <div className="flex items-center space-x-3">
          <Link to="/workflows">
            <Logo className="!w-7 !h-7 mr-2" />
          </Link>
          {loading ? (
            <Skeleton variant="rounded" width={500} height={25} />
          ) : (
            <Breadcrumbs
              aria-label="breadcrumb"
              className="!text-sm !text-gray-500"
            >
              {crumbs?.map((crumb, index) => {
                const isLastCrumb = index === crumbs.length - 1;

                return crumb.link ? (
                  <Link
                    key={crumb.link}
                    color="inherit"
                    className={clsx(
                      '!text-sm hover:!text-info-dark cursor-pointer',
                      {
                        '!text-info-dark': isLastCrumb,
                        'truncate max-w-[50ch]': !crumb.doNotTruncate,
                      },
                    )}
                    to={crumb.link}
                    {...(crumb.onClick
                      ? {
                          onClick: crumb.onClick,
                          role: 'button',
                          tabIndex: 0,
                          onKeyDown: (
                            e: React.KeyboardEvent<HTMLAnchorElement>,
                          ) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              crumb.onClick?.();
                            }
                          },
                        }
                      : {})}
                  >
                    {crumb.title}
                  </Link>
                ) : (
                  <Typography
                    // No other usable field for the key prop, but index is valid here because the array will not be mutating
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={clsx('!text-sm', {
                      '!text-info-dark': isLastCrumb,
                      'hover:!text-info-dark': Boolean(crumb.onClick),
                      'truncate max-w-[50ch]': !crumb.doNotTruncate,
                    })}
                    {...(crumb.onClick
                      ? {
                          onClick: crumb.onClick,
                          role: 'button',
                          tabIndex: 0,
                          onKeyDown: (
                            e: React.KeyboardEvent<HTMLSpanElement>,
                          ) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              crumb.onClick?.();
                            }
                          },
                        }
                      : {})}
                  >
                    {crumb.title}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
          {LeftEndContent}
        </div>

        {CentralContent}

        <div className="flex items-center gap-5">
          {RightEndContent}

          {onClose ? (
            <IconButton className="!ml-auto !-mr-3 !my-auto" onClick={onClose}>
              <Clear className="!w-7 !h-7 !text-info" />
            </IconButton>
          ) : null}
        </div>
      </div>
    ),
    [onClose, CentralContent, RightEndContent, crumbs, LeftEndContent, loading],
  );

  return hasOuterContainer ? (
    Component
  ) : (
    <div
      className={clsx(
        'px-4 w-full bg-white py-5 border-b-2 border-gray-200',
        containerClassName,
      )}
    >
      {Component}
    </div>
  );
}
