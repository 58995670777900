"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleException = void 0;
const Sentry = __importStar(require("@sentry/browser"));
class CapturedError extends Error {
    name;
    message;
    source;
    originalError;
    constructor(name, message, source, originalError) {
        super(message);
        this.name = name;
        this.message = message;
        this.source = source;
        this.originalError = originalError;
    }
}
/**
 * Extracts the message from an error.
 * @param errLike - Error-like object.
 * @returns
 */
const extractErrorMessage = (errLike) => {
    if ('message' in errLike) {
        return errLike.message;
    }
    if ('data' in errLike) {
        return errLike.data;
    }
    return null;
};
/**
 * Captures an exception.
 */
const handleException = (err, { name = err instanceof Error ? err.name : 'CapturedError', source = name, userMessage, groupInSentry, extra, }) => {
    const captured = new CapturedError(name, extractErrorMessage(err) ?? 'unknown', source, err);
    console.error(`[${captured.name}] (from ${captured.source})`);
    console.error(captured.message);
    console.error(JSON.stringify(captured.originalError));
    if (extra) {
        console.table(Object.entries(extra).map(([k, v]) => ({
            key: k,
            value: v,
        })));
    }
    const sentryArgs = {
        tags: {
            source,
        },
        extra: {
            ...extra,
            originalError: captured.originalError,
            userMessage,
            originalStack: captured.originalError instanceof Error
                ? captured.originalError.stack
                : undefined,
        },
    };
    if (groupInSentry) {
        sentryArgs.fingerprint = [captured.name, source];
    }
    Sentry.captureException(captured, sentryArgs);
};
exports.handleException = handleException;
