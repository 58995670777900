import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import {
  CustomTypography,
  SortOrder,
  ScrollableContainer,
  ContentContainer,
} from 'ui-kit';
import {
  EXECUTIONS_LIST_REFETCH_INTERVAL,
  FeatureFlag,
} from '../../utils/constants';
import { useFetchGlobalExecutionsList, useExtensionListCols } from './hooks';
import {
  executionListFiltersSchema,
  defaultFilters,
  mapStatuses,
} from '../Workflows/utils/helper';
import usePersistedURLState from '../../hooks/usePersistedURLState';
import { type GridSortModel, type GridColDef } from '@mui/x-data-grid/models';
import { NavigationPanel } from '../../components/NavigationPanel';
import ExecutionListFilter, {
  type ExecutionListFilterRef,
} from '../Workflows/components/ExecutionListFilter';
import { WorkflowDetailSkeleton } from '../Workflows/components/WorkflowDetailSkeleton';
import { useUpdateExecution } from '../Execution/hooks';
import type { ExecutionBase } from 'types-shared/executionTypes';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import ExecutionsRefetch from '../../components/ExecutionsRefetch';
import usePersistentState from '../../hooks/usePersistentState';
import { useFeatureFlag } from '../../utils/helper';
import { getTabTitle } from '../../utils/tabTitle';
import { isAdmin } from '../../utils/env';
import useLiveViewBasedonWorkflow from '../Execution/hooks/useLiveViewBasedonWorkflow';
import EmptyStatePlaceholder, {
  EmptyStateEnum,
} from '../../components/EmptyStatePlaceholder';
import { isDeepEqual } from '@mui/x-data-grid/internals';

const sortOrder = [SortOrder.DESC, SortOrder.ASC];

export default function ExecutionsList() {
  const [timeToRefresh, setTimeToRefresh] = useState<number>(
    EXECUTIONS_LIST_REFETCH_INTERVAL,
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const { workflowIds } = useLiveViewBasedonWorkflow();
  const [autoRefresh, setAutoRefresh] = usePersistentState(
    true,
    'global-executions-list-auto-refresh',
  );
  const [orderBy, setOrderBy] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.DESC },
  ]);

  const [filters, setFilters] = usePersistedURLState(
    executionListFiltersSchema,
    defaultFilters,
    'global-executions-list-filter',
  );
  const filtersApplied = useMemo(
    () => !isDeepEqual(filters, defaultFilters),
    [filters],
  );
  const executionListFilterRef = React.useRef<ExecutionListFilterRef>(null);
  const executionDetailsEnabled =
    useFeatureFlag(FeatureFlag.ExecutionDetails) ?? false;

  const placeholderButtonProps = useMemo(
    () => ({
      onClick: () => {
        executionListFilterRef.current?.clearFilters();
      },
      disabled: false,
      loading: false,
    }),
    [],
  );

  const statusValue = useMemo(() => {
    const status = filters.status[0];
    return !status || (filters.status.length === 1 && status === 'All')
      ? undefined
      : mapStatuses(filters.status);
  }, [filters.status]);

  const teamsValue = useMemo(() => {
    const teams = filters.teams?.[0];
    return !teams || (filters.teams?.length === 1 && teams === 'All')
      ? undefined
      : filters.teams;
  }, [filters.teams]);

  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executionsData,
    dataUpdatedAt: executionsListDataUpdatedAt,
  } = useFetchGlobalExecutionsList(
    {
      adminRun:
        typeof filters.hideAdminRuns === 'boolean'
          ? filters.hideAdminRuns
          : undefined,
      status: statusValue,
      executionId: filters.id ? filters.id : undefined,
      owner: filters.owner ? filters.owner : undefined,
      dateFrom: filters.dateFrom ? filters.dateFrom : undefined,
      dateTo: filters.dateTo ? filters.dateTo : undefined,
      dateQuery: filters.dateQuery ? filters.dateQuery : undefined,
      workflowName: filters.workflowName ? filters.workflowName : undefined,
      teams: teamsValue,
    },
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      orderBy,
      doNotRefetch: !autoRefresh,
    },
  );

  const { status: executionUpdateStatus, mutate: updateExecution } =
    useUpdateExecution();

  const {
    executions = [],
    filteredExecutionsCount = 0,
    // totalExecutionsCount = 0,
  } = executionsData ?? {};

  const onResetPage = () => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  useEffect(() => {
    setTimeToRefresh(EXECUTIONS_LIST_REFETCH_INTERVAL);
  }, [executionsListDataUpdatedAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRefresh((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    document.title = getTabTitle('Executions', isAdmin);
  }, []);

  const columns = useExtensionListCols({
    updateExecution,
    executionUpdateStatus,
    executionDetailsEnabled,
    hitlLiveViewEnabled: true,
    liveViewEnabledWorkflowIds: workflowIds,
  });

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />

        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <CustomTypography variant="h4">Executions</CustomTypography>

            <ExecutionsRefetch
              timeToRefresh={timeToRefresh}
              setTimeToRefresh={setTimeToRefresh}
              autoRefresh={autoRefresh}
              setAutoRefresh={setAutoRefresh}
            />
          </div>

          <ExecutionListFilter
            ref={executionListFilterRef}
            isGlobalSearch
            filters={filters}
            setFilters={setFilters}
            onResetPage={onResetPage}
            onRefresh={() => {
              void refetchExecutions();
            }}
          />

          <div>
            {executionsListLoading ? (
              <WorkflowDetailSkeleton
                columns={columns as GridColDef<ExecutionBase>[]}
              />
            ) : (
              <>
                {executions.length > 0 ? (
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.executionId}
                    rows={executions}
                    sortingOrder={sortOrder}
                    rowCount={filteredExecutionsCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode="server"
                    sortModel={orderBy}
                    onSortModelChange={(val) => {
                      onResetPage();
                      setOrderBy(val);
                    }}
                  />
                ) : (
                  <>
                    {filtersApplied ? (
                      <div className="w-full h-full min-h-[99vh] border border-gray-200 rounded-lg mt-2 flex justify-center items-center">
                        <EmptyStatePlaceholder
                          type={EmptyStateEnum.EXECUTION}
                          buttonProps={placeholderButtonProps}
                          title="No executions were found for this view"
                          description="Try adjusting your filters to find executions."
                          buttonText="Remove filters"
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
