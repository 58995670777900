import type {
  GetExecutionRequest,
  GetExecutionResponse,
  GetZippedExecutionOuputsRequest,
  GetZippedExecutionOuputsResponse,
  ListExecutionsRequest,
  ListExecutionsResponse,
  PutExecutionDataRequest,
  PutExecutionDataResponse,
  QueueTemporalExecutionRequest,
  QueueTemporalExecutionResponse,
  SignalTemporalExecutionRequest,
  SignalTemporalExecutionResponse,
  UpdateExecutionRequest,
  UpdateExecutionResponse,
  ZodFetcher,
  BatchGetExecutionsResponse,
  BatchCancelExecutionsResponse,
  RetryExecutionResponse,
  RetryExecutionRequest,
  ResolveLinearizedFileResponse,
} from 'api-types-shared';
import {
  axios,
  createZodFetcher,
  getExecutionSchema,
  getZippedExecutionOutputsSchema,
  listExecutionsSchema,
  putExecutionDataSchema,
  queueTemporalExecutionSchema,
  retryExecutionSchema,
  signalTemporalExecutionSchema,
  updateExecutionSchema,
  UploadTypeEnum,
  zodAxios,
  batchGetExecutionsSchema,
  batchCancelExecutionsSchema,
  resolveLinearizedFileSchema,
} from 'api-types-shared';
import type {
  ExecutionBase,
  ExecutionWithWorkflowName,
  UuidSchema,
} from 'types-shared';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import type { KyInstance, Options } from 'ky';
import ky from 'ky';
import { getBlobFromS3, uploadBlobToS3, getUrlFromBlob } from '../utils';
import { type ApolloClient } from '@apollo/client';
import { handleException } from 'sentry-browser-shared';
import { GET_EXECUTIONS, GET_GLOBAL_EXECUTIONS } from '../utils/schema';
import { WorkflowSDK } from './workflowSDK';
import dayjs from 'dayjs';

interface ExecutionListConditions {
  workflowId?: string;
  adminRun?: boolean;
  status?: string[];
  executionId?: string;
  dateFrom?: string;
  dateTo?: string;
  dateQuery?: string;
  owner?: string;
}

interface GlobalExecutionListConditions extends ExecutionListConditions {
  workflowName?: string;
  owner?: string;
  teams?: string[];
}

export class ExecutionSDK {
  private _workflowSDK: WorkflowSDK;
  private _kyFetcher: ZodFetcher<KyInstance>;
  private _apolloClient: ApolloClient<object>;
  readonly endpoint: string;

  constructor(
    env: NodeEnv,
    apolloClient: ApolloClient<object>,
    opts?: Options,
  ) {
    this._kyFetcher = createZodFetcher(opts ? ky.extend(opts) : ky);
    this._apolloClient = apolloClient;
    this.endpoint = apiEndpoints[env].executionApiV1;
    this._workflowSDK = new WorkflowSDK(env, apolloClient, opts);
  }

  _updateExecution = (
    executionId: string,
    data: UpdateExecutionRequest['body'],
  ): Promise<UpdateExecutionResponse> => {
    return this._kyFetcher(
      updateExecutionSchema.response,
      `${this.endpoint}/${executionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
      },
    );
  };

  updateExecution = (
    executionId: string,
    data: UpdateExecutionRequest['body'],
  ): Promise<UpdateExecutionResponse> => {
    return this._updateExecution(executionId, data);
  };

  _putExecutionData = (
    req: PutExecutionDataRequest,
  ): Promise<PutExecutionDataResponse> => {
    // TODO(michael): Swap to use _kyFetcher with auth
    return zodAxios(
      putExecutionDataSchema.response,
      `${this.endpoint}/${req.params.executionId}/data`,
      {
        method: 'PUT',
        params: { uploadType: req.query.uploadType },
        data: req.body,
      },
    );
  };

  putExecutionData = async ({
    executionId,
    data,
    uploadType,
    name,
  }: {
    executionId: UuidSchema;
    data: unknown;
    uploadType: UploadTypeEnum;
    name?: string;
  }): Promise<{
    executionId?: UuidSchema;
    name?: string;
  }> => {
    const { uploadUrl } = await this._putExecutionData({
      params: { executionId },
      body: { name },
      query: { uploadType },
    });
    if (uploadType === UploadTypeEnum.Image) {
      await uploadBlobToS3(data as Blob, uploadUrl);
    } else if (uploadType === UploadTypeEnum.Variables) {
      await axios(uploadUrl, {
        method: 'PUT',
        data,
      });
    } else if (uploadType === UploadTypeEnum.Artifact) {
      await uploadBlobToS3(data as Blob, uploadUrl);
    }
    return { executionId, name };
  };
  _getExecution = (req: GetExecutionRequest): Promise<GetExecutionResponse> => {
    return this._kyFetcher(
      getExecutionSchema.response,
      `${this.endpoint}/${req.params.executionId}`,
      {
        method: 'get',
      },
    );
  };

  getExecution = async (
    executionId: UuidSchema,
  ): Promise<GetExecutionResponse> => {
    return this._getExecution({
      params: { executionId },
    });
  };

  batchGetExecutions = async (
    executionIds: string[],
  ): Promise<BatchGetExecutionsResponse> => {
    return this._kyFetcher(
      batchGetExecutionsSchema.response,
      `${this.endpoint}/batch`,
      {
        method: 'POST',
        body: JSON.stringify({ ids: executionIds }),
      },
    );
  };

  getExecutionScreenshots = async (
    imageUrls: string[],
  ): Promise<[string, string][]> =>
    (
      await Promise.allSettled(
        imageUrls.map(async (imageUrl: string): Promise<[string, string]> => {
          const fileUrl = imageUrl.split('?')[0]?.split('/').pop() ?? imageUrl;
          const isVideo = imageUrl.includes('.mp4');
          if (isVideo) {
            return [fileUrl, imageUrl];
          }
          const blob = await getBlobFromS3(imageUrl);
          const blobUrl = getUrlFromBlob(blob);
          return [fileUrl, blobUrl];
        }),
      )
    )
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);

  listExecutions = async (
    req: ListExecutionsRequest,
    filterAdminRun = true,
  ): Promise<ListExecutionsResponse> => {
    let url = `${this.endpoint}/list?workflowId=${req.query.workflowId}`;
    if (req.query.setId) {
      url += `&setId=${req.query.setId}`;
    }

    const resp = await this._kyFetcher(listExecutionsSchema.response, url, {
      method: 'get',
    });

    return resp.filter((exec) => !filterAdminRun || !exec.adminRun);
  };

  queueTemporalExecutions = (
    req: QueueTemporalExecutionRequest,
  ): Promise<QueueTemporalExecutionResponse> => {
    return this._kyFetcher(
      queueTemporalExecutionSchema.response,
      `${this.endpoint}/queue-remote/${req.params.workflowId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  queueDesktopExecutions = (
    req: QueueTemporalExecutionRequest,
  ): Promise<QueueTemporalExecutionResponse> => {
    return this._kyFetcher(
      queueTemporalExecutionSchema.response,
      `${this.endpoint}/queue-desktop/${req.params.workflowId}`,
      {
        method: 'PUT',
        body: JSON.stringify(req.body),
      },
    );
  };

  getZippedOutputs = async (
    req: GetZippedExecutionOuputsRequest,
  ): Promise<GetZippedExecutionOuputsResponse> => {
    return this._kyFetcher(
      getZippedExecutionOutputsSchema.response,
      `${this.endpoint}/zip`,
      {
        method: 'post',
        json: { executionIds: req.body?.executionIds },
        timeout: 30000,
      },
    );
  };

  sendExecutionSignal = async (
    req: SignalTemporalExecutionRequest,
  ): Promise<SignalTemporalExecutionResponse> => {
    return this._kyFetcher(
      signalTemporalExecutionSchema.response,
      `${this.endpoint}/signal-remote/${req.params.executionId}`,
      {
        method: 'post',
        json: { signalTypeBatch: req.body.signalTypeBatch },
        timeout: 30000,
      },
    );
  };

  private _getDateFilter(
    conditions: Pick<
      ExecutionListConditions,
      'dateQuery' | 'dateFrom' | 'dateTo'
    >,
  ) {
    const { dateQuery, dateFrom, dateTo } = conditions;
    if (dateFrom && dateTo) {
      return {
        createdAt: {
          _gte: dateFrom,
          _lte: dateTo,
        },
      };
    }

    if (!dateQuery) {
      return {};
    }

    const today = dayjs().startOf('day');
    let startDate: dayjs.Dayjs | undefined;
    let endDate: dayjs.Dayjs | undefined;

    switch (dateQuery) {
      case 'Today':
        startDate = today;
        endDate = today.endOf('day');
        break;
      case 'Yesterday':
        startDate = today.subtract(1, 'day').startOf('day');
        endDate = today.subtract(1, 'day').endOf('day');
        break;
      case 'Last week':
        startDate = today.startOf('week').subtract(1, 'week');
        endDate = today.startOf('week').subtract(1, 'week').endOf('week');
        break;
      case 'Last month':
        startDate = today.startOf('month').subtract(1, 'month');
        endDate = today.startOf('month').subtract(1, 'month').endOf('month');
        break;
    }

    return startDate
      ? {
          createdAt: {
            _gte: startDate.toISOString(),
            _lte: endDate?.toISOString() ?? today.toISOString(),
          },
        }
      : {};
  }

  fetchExecutionsListBatched = async (
    conditions: ExecutionListConditions,
    page: number,
    pageSize: number,
    orderBy: Record<string, 'asc' | 'desc'>,
  ): Promise<{
    executions: ExecutionBase[];
    filteredExecutionsCount: number;
    totalExecutionsCount: number;
  }> => {
    const {
      workflowId,
      adminRun,
      status,
      executionId,
      dateFrom,
      dateTo,
      dateQuery,
      owner,
    } = conditions;

    const dateFilter = this._getDateFilter({ dateQuery, dateFrom, dateTo });

    const variables = {
      where: {
        workflowId: { _eq: workflowId },
        ...(adminRun !== undefined ? { adminRun: { _eq: adminRun } } : {}),
        ...(status ? { status: { _in: status } } : {}),
        ...(executionId ? { idString: { _ilike: `%${executionId}%` } } : {}),
        ...(owner ? { user: { email: { _ilike: `%${owner}%` } } } : {}),
        ...dateFilter,
      },
      offset: (page - 1) * pageSize,
      limit: pageSize,
      orderBy,
      workflowId,
    };
    const executionsData = await this._apolloClient.query<{
      executions: { id: string }[];
      executions_aggregate: { aggregate: { count: number } };
      totalExecutionsCount: { aggregate: { count: number } };
    }>({
      query: GET_EXECUTIONS,
      variables,
    });
    const executionIds = executionsData.data.executions.map(
      (execution: { id: string }) => execution.id,
    );
    const filteredExecutionsCount =
      executionsData.data.executions_aggregate.aggregate.count;
    const totalExecutionsCount =
      executionsData.data.totalExecutionsCount.aggregate.count;

    const results = await this._kyFetcher(
      batchGetExecutionsSchema.response,
      `${this.endpoint}/batch`,
      {
        method: 'post',
        body: JSON.stringify({ ids: executionIds }),
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Execution batch fetch failed',
        source: 'executionSDK.fetchExecutionsListBatched',
        extra: { conditions, page, pageSize, orderBy },
      });
      return { executions: {} };
    });
    const executionsList = Object.values(results.executions);
    return {
      executions: executionsList,
      filteredExecutionsCount,
      totalExecutionsCount,
    };
  };

  fetchGlobalExecutionsListBatched = async (
    conditions: GlobalExecutionListConditions,
    page: number,
    pageSize: number,
    orderBy: Record<string, 'asc' | 'desc'>,
  ): Promise<{
    executions: ExecutionWithWorkflowName[];
    filteredExecutionsCount: number;
    totalExecutionsCount: number;
  }> => {
    const {
      workflowId,
      adminRun,
      status,
      executionId,
      owner,
      dateFrom,
      dateTo,
      dateQuery,
      workflowName,
      teams,
    } = conditions;

    const dateFilter = this._getDateFilter({ dateQuery, dateFrom, dateTo });

    const variables = {
      where: {
        ...(workflowId ? { workflowId: { _eq: workflowId } } : {}),
        ...(adminRun !== undefined ? { adminRun: { _eq: adminRun } } : {}),
        ...(status ? { status: { _in: status } } : {}),
        ...(executionId ? { idString: { _ilike: `%${executionId}%` } } : {}),
        ...(owner || teams
          ? {
              user: {
                ...(owner ? { email: { _ilike: `%${owner}%` } } : {}),
                ...(teams ? { memberships: { teamId: { _in: teams } } } : {}),
              },
            }
          : {}),
        ...(workflowName
          ? { workflow: { name: { _ilike: `%${workflowName}%` } } }
          : {}),
        ...dateFilter,
      },
      offset: (page - 1) * pageSize,
      limit: pageSize,
      orderBy,
      ...(workflowId ? { workflowId } : {}),
    };
    const executionsData = await this._apolloClient.query<{
      executions: { id: string }[];
      executions_aggregate: { aggregate: { count: number } };
      totalExecutionsCount: { aggregate: { count: number } };
    }>({
      query: GET_GLOBAL_EXECUTIONS,
      variables,
    });
    const executionIds = executionsData.data.executions.map(
      (execution: { id: string }) => execution.id,
    );
    const filteredExecutionsCount =
      executionsData.data.executions_aggregate.aggregate.count;
    const totalExecutionsCount =
      executionsData.data.totalExecutionsCount.aggregate.count;

    const results = await this._kyFetcher(
      batchGetExecutionsSchema.response,
      `${this.endpoint}/batch`,
      {
        method: 'post',
        body: JSON.stringify({ ids: executionIds }),
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Execution batch fetch failed',
        source: 'executionSDK.fetchGlobalExecutionsListBatched',
        extra: { conditions, page, pageSize, orderBy },
      });
      return { executions: {} };
    });
    const executionsList = Object.values(results.executions);
    const workflowIds = executionsList.map((e) => e.workflowId);
    const workflows = await this._workflowSDK.fetchWorkflowsByIds(
      Array.from(new Set(workflowIds)),
    );
    const workflowsMap = workflows.reduce((acc: Record<string, string>, w) => {
      acc[w.workflowId] = w.workflowName;
      return acc;
    }, {});
    const executionsWithWorkflowName = executionsList.map((e) => ({
      ...e,
      workflowName: workflowsMap[e.workflowId] ?? 'Unknown',
    }));
    return {
      executions: executionsWithWorkflowName,
      filteredExecutionsCount,
      totalExecutionsCount,
    };
  };

  batchCancelExecutions = async (
    executionIds: string[],
  ): Promise<BatchCancelExecutionsResponse> => {
    return this._kyFetcher(
      batchCancelExecutionsSchema.response,
      `${this.endpoint}/batch-cancel`,
      {
        method: 'POST',
        body: JSON.stringify({ executionIds }),
      },
    );
  };

  retryExecution = (
    req: RetryExecutionRequest,
  ): Promise<RetryExecutionResponse> => {
    return this._kyFetcher(
      retryExecutionSchema.response,
      `${this.endpoint}/retry/${req.params.executionId}`,
      {
        method: 'post',
        body: JSON.stringify(req.body),
      },
    );
  };

  resolveLinearizedFile = async (
    fileId: string,
  ): Promise<ResolveLinearizedFileResponse> => {
    return this._kyFetcher(
      resolveLinearizedFileSchema.response,
      `${this.endpoint}/${fileId}/linearized`,
      {
        method: 'POST',
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Error resolving linearized file',
        source: 'executionSDK.resolveLinearizedFile',
        extra: { fileId },
      });
      throw err;
    });
  };
}
