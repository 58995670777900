import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import isNil from 'lodash/isNil';
import { useNavigateBetweenNodes } from '../NodeHeader/hooks';

export default function FreeformNode(workflowData: WorkflowNodeProps) {
  const { handleNavigateToNode } = useNavigateBetweenNodes();
  const { nodes } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
    })),
  );

  const node = nodes.find((n) => n.id === workflowData.id);

  if (isNil(node)) {
    return null;
  }

  return (
    <NodeElement
      allowBulkCheck
      label="Freeform step"
      onClick={() => {
        handleNavigateToNode(workflowData.id);
      }}
      showAddButton
      showDeleteButton
      showEditButton
      workflowData={workflowData}
    >
      <div className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center">
        <img alt="logo" className="w-16" src="/logo-blue.png" />
      </div>
      <span className="text-sm font-bold truncate">
        {node.name ?? 'Freeform Step'}
      </span>
    </NodeElement>
  );
}
