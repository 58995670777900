import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import type {
  BranchData,
  DatasourceMetadata,
  DatasourceTable,
  GlobalVariable,
  Group,
  SourceTypeEnum,
  TemplateData,
  Variable,
  VariableMap,
  WorkflowConditionalNode,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import {
  Add,
  Button,
  ContentCopy,
  DeleteOutlineIcon,
  DragIndicator,
  EditOutlined,
  IconButton,
  InfoOutlined,
  Input,
  Menu,
  MenuItem,
  MoreVert,
  Select,
  Switch,
} from 'ui-kit';
import { ModelSelect } from '../../../../components/ModelSelect';
import { FeatureFlag } from '../../../../utils/constants';
import { isAdmin } from '../../../../utils/env';
import { useFeatureFlag } from '../../../../utils/helper';
import { useNodeValidation } from '../../hooks/useNodeValidation';
import nodeValidations, {
  type ConditionalNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';
import { EditBranch } from './EditBranch';

const validateConditionalNode = nodeValidations.conditional;

interface Props {
  node: WorkflowConditionalNode;
  nodes: WorkflowNode[];
  editingEdge: WorkflowEdge | undefined;
  setEditingEdge: (val: undefined | WorkflowEdge) => void;
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  duplicateBranch: (node: WorkflowConditionalNode, edge: WorkflowEdge) => void;
  deleteBranch: (branch: WorkflowEdge) => void;
  insertNode: (sourceId: string) => void;
  updateNodeName: (name: string) => void;
  updateErrorOverlay: (val: boolean) => void;
  updateNode: (node: WorkflowConditionalNode) => void;
  onUpdateEdge: (
    data: Partial<{
      name: string;
      group: Group;
      instruction: { variableId: string };
    }>,
  ) => void;
  onCancel: () => void;

  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  allowBranchReordering?: boolean;
  workflowId?: string;
}
const typeOptions: string[] = [
  'Execute the first branch that evaluates to true.',
  'Execute all branches that hold true',
];

export function ConditionalBlock({
  edges,
  node,
  editingEdge,
  onCancel,
  insertNode,
  updateNodeName,
  updateErrorOverlay,
  deleteBranch,
  duplicateBranch,
  setEditingEdge,
  addVariable,
  variablesMap,
  globalVariablesMap,
  datasourceMetadata,
  tableData,
  onTransformApiReq,
  transformApiReqStatus,
  sourceType,
  updateNodeStatus,
  allowBranchReordering = false,
  nodes,
  setNodes,
  setEdges,
  workflowId,
  updateNode,
}: Props) {
  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<ConditionalNodeValidationResult>({
      node,
      variablesMap,
      globalVariablesMap,
      updateNodeStatus,
      validationFunction: validateConditionalNode,
      workflowId: workflowId ?? '',
    });

  const [selectedModel, setSelectedModel] = useState<string | undefined>(
    node.data.aiConfig?.model,
  );

  const branchesOrder = useMemo(
    () => (node.data.branchesData ?? []).map((branch) => branch.branchId),
    [node.data.branchesData],
  );

  const nodeEdges = useMemo(() => {
    const outgoingEdges = edges.filter((edge) => edge.source === node.id);
    return outgoingEdges
      .filter((edge) => branchesOrder.includes(edge.id))
      .sort((e1, e2) => {
        const e1Index = branchesOrder.indexOf(e1.id);
        const e2Index = branchesOrder.indexOf(e2.id);
        return e1Index - e2Index;
      })
      .concat(outgoingEdges.filter((edge) => !branchesOrder.includes(edge.id)));
  }, [edges, node.id, branchesOrder]);

  const branchData: BranchData | undefined = useMemo(
    () =>
      node.data.branchesData?.find(
        (b: BranchData) => b.branchId === editingEdge?.id,
      ),
    [editingEdge?.id, node.data.branchesData],
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }
    const tempEdgesOrder = [...nodeEdges.map((edge) => edge.id)];
    const activeIndex = tempEdgesOrder.findIndex((id) => id === active.id);
    const overIndex = tempEdgesOrder.findIndex((id) => id === over?.id);
    const newEdgesOrder = arrayMove(tempEdgesOrder, activeIndex, overIndex);
    const newEdges = [...edges].sort((e1, e2) => {
      const e1Index = newEdgesOrder.indexOf(e1.id);
      const e2Index = newEdgesOrder.indexOf(e2.id);
      return e1Index - e2Index;
    });

    const thisNode = nodes.find(
      (_node) =>
        _node.id === node.id && _node.type === NodeTypesEnum.Conditional,
    ) as WorkflowConditionalNode;
    const nodeWithBranchSortUpdate = {
      ...thisNode,
      data: {
        ...thisNode.data,
        branchesData: [...(thisNode.data.branchesData ?? [])].sort((b1, b2) => {
          const b1Index = newEdgesOrder.indexOf(b1.branchId);
          const b2Index = newEdgesOrder.indexOf(b2.branchId);
          return b1Index - b2Index;
        }),
      },
    };

    const newNodes = nodes.map((_node) => {
      if (_node.id === node.id && _node.type === NodeTypesEnum.Conditional) {
        return {
          ..._node,
          data: {
            ..._node.data,
            branchesData: [...(_node.data.branchesData ?? [])].sort(
              (b1, b2) => {
                const b1Index = newEdgesOrder.indexOf(b1.branchId);
                const b2Index = newEdgesOrder.indexOf(b2.branchId);
                return b1Index - b2Index;
              },
            ),
          },
        };
      }
      return _node;
    });
    setEdges(newEdges);
    setNodes(newNodes);
    updateNode(nodeWithBranchSortUpdate);
  };

  const errorOverlay = Boolean(node.errorOverlay && !isAdmin);

  return (
    <NonImageNodeWrapper
      node={node}
      onClose={onCancel}
      hideNodeHeader={Boolean(editingEdge)}
    >
      <div className="pb-[40px]">
        {!editingEdge ? (
          <div className="node-block bg-white flex flex-col justify-between space-y-5">
            <div className="overflow-auto">
              <NodeCheckV2
                isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
                onCheck={handleValidateNode}
                errors={validationAttempted ? validationResult.errors : []}
                showErrors={validationAttempted}
                hidden={node.hideFromUser}
                noTopMargin
              />
              <div className="my-6 pl-8 pr-8">
                <h2 className="text-lg font-medium">
                  {errorOverlay ? 'Error Handling' : 'Conditional logic'}
                </h2>
                <p className="text-sm font-normal text-info-dark">
                  {errorOverlay
                    ? 'Handle errors via conditional logic and branching.'
                    : 'Create multiple branches and set conditions for their execution.'}
                </p>
              </div>
              <div className="flex-1 flex flex-col gap-4 conditional-block pl-8 pr-8">
                <Input
                  floatingLabel
                  label="Step Name"
                  onChange={updateNodeName}
                  placeholder="Step Name"
                  value={node.name ?? ''}
                />

                {!errorOverlay && (
                  <>
                    <Select
                      classes={{ select: 'w-100' }}
                      disabled
                      getLabel={(opt: string) => opt}
                      getValue={(opt: string) => opt}
                      label="Condition Type"
                      labelId="type"
                      options={typeOptions}
                      value={typeOptions[0]}
                    />

                    {isAdmin ? (
                      <div className="mt-2">
                        <ModelSelect
                          value={selectedModel}
                          onChange={(newModel) => {
                            setSelectedModel(newModel);
                            updateNode({
                              ...node,
                              data: {
                                ...node.data,
                                aiConfig: newModel
                                  ? { model: newModel }
                                  : undefined,
                              },
                            });
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                )}

                <div className="mt-8">
                  <p className="font-bold text-sm">
                    {errorOverlay ? 'Error Branches' : 'Branches'}
                  </p>
                  <div className="mt-2">
                    <DndContext
                      modifiers={[restrictToVerticalAxis]}
                      onDragEnd={onDragEnd}
                      sensors={sensors}
                    >
                      <SortableContext
                        disabled={!allowBranchReordering}
                        items={nodeEdges}
                        strategy={verticalListSortingStrategy}
                      >
                        {nodeEdges.map((edge) => {
                          return (
                            <Branch
                              allowBranchReordering={allowBranchReordering}
                              errorOverlay={errorOverlay}
                              id={edge.id}
                              key={edge.id}
                              label={edge.label as string}
                              onEdit={() => {
                                setEditingEdge(edge);
                              }}
                              onDelete={() => {
                                deleteBranch(edge);
                              }}
                              onDuplicate={() => {
                                duplicateBranch(node, edge);
                              }}
                              validationResult={
                                validationAttempted ? validationResult : null
                              }
                            />
                          );
                        })}
                      </SortableContext>
                    </DndContext>
                    {!errorOverlay && (
                      <Button
                        className="!mt-6 !mb-2"
                        color="secondary"
                        onClick={() => {
                          insertNode(node.id);
                        }}
                        startIcon={<Add />}
                        variant="text"
                      >
                        Add Branch
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isAdmin ? (
              <div className="mt-4 flex items-center pl-8 pr-8 pb-20">
                <Switch
                  checked={Boolean(node.errorOverlay)}
                  onChange={(event) => {
                    updateErrorOverlay(event.target.checked);
                  }}
                  color="primary"
                  id="toggle-error-overlay"
                />
                <label
                  htmlFor="toggle-error-overlay"
                  className="ml-2 text-sm font-medium text-gray-700"
                >
                  Toggle Error Overlay
                </label>
              </div>
            ) : null}
          </div>
        ) : (
          <EditBranch
            node={node}
            addVariable={addVariable}
            branchData={branchData}
            datasourceMetadata={datasourceMetadata}
            edge={editingEdge}
            onCancel={() => {
              setEditingEdge(undefined);
            }}
            onDelete={() => {
              deleteBranch(editingEdge);
            }}
            onTransformApiReq={onTransformApiReq}
            tableData={tableData}
            transformApiReqStatus={transformApiReqStatus}
            sourceType={sourceType}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            branchValidations={
              validationAttempted
                ? validationResult.validations.branchContentValidations?.[
                    editingEdge.id
                  ]
                : {}
            }
          />
        )}
      </div>
    </NonImageNodeWrapper>
  );
}

interface BranchProps {
  label: string;
  id: string;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  allowBranchReordering: boolean;
  errorOverlay?: boolean;
  validationResult: ConditionalNodeValidationResult | null;
}
function Branch({
  label,
  onEdit,
  onDuplicate,
  onDelete,
  id,
  allowBranchReordering,
  errorOverlay,
  validationResult,
}: BranchProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const allowBranchDuplication = useFeatureFlag(
    FeatureFlag.AllowCloningBranches,
    true,
  );

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const isHighlighted = useMemo(() => {
    return validationResult?.validations.branchContentValidations?.[id]
      ?.branchInvalid;
  }, [validationResult, id]);

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={clsx(
        'px-3 py-4 mt-6 rounded-lg border bg-gray-100 flex space-x-4 items-center hover:shadow-primary transition ',
        {
          'border-gray-800 cursor-grab': isDragging,
          'hover:border-primary-blue cursor-pointer': !isDragging,
          'border-info border-2': isHighlighted,
        },
      )}
      style={style}
      onClick={() => {
        // Do not attempt to edit if a user is just trying to close the menu by clicking outside it
        if (!anchorEl) {
          onEdit();
        }
      }}
      role="presentation"
    >
      {isHighlighted ? (
        <InfoOutlined className="!w-5 !h-5 !text-info !mt-0.5 -mr-2" />
      ) : null}
      <span className="flex-1">{label}</span>
      {allowBranchReordering && !errorOverlay ? (
        <div
          className={clsx(
            'rotate-90 text-gray-400 cursor-grab hover:text-gray-800',
            isDragging && 'text-gray-800',
          )}
        >
          <DragIndicator />
        </div>
      ) : null}
      {!errorOverlay && (
        <>
          <IconButton
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-expanded={anchorEl ? 'true' : undefined}
            aria-haspopup="true"
            aria-label="more"
            className="!p-0"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onMenuClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className="group min-w-72 h-12"
              onClick={() => {
                onEdit();
                onMenuClose();
              }}
            >
              <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                Edit branch
              </span>
              <EditOutlined className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
            </MenuItem>
            {allowBranchDuplication ? (
              <MenuItem
                className="group min-w-72 h-12"
                onClick={() => {
                  onDuplicate();
                  onMenuClose();
                }}
              >
                <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                  Duplicate branch
                </span>
                <ContentCopy className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
              </MenuItem>
            ) : null}
            <MenuItem
              className="group min-w-72 h-12"
              onClick={() => {
                onDelete();
                onMenuClose();
              }}
            >
              <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                Delete branch
              </span>
              <DeleteOutlineIcon className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
}
