import { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridValueFormatterParams,
  type GridValueGetterParams,
} from '@mui/x-data-grid/models';
import { Tooltip } from 'ui-kit';
import {
  type ExecutionBase,
  ExecutionStatusEnum,
  type ExecutionWithWorkflowName,
} from 'types-shared';
import dayjs from '../../../config/dayjs';
import {
  allowedUserExecutionStatuses,
  FeatureFlag,
  hitlStatuses,
  statusTitleMapping,
} from '../../../utils/constants';
import {
  capitalizeFirstLetter,
  runningStatuses,
} from '../../Workflows/utils/helper';
import { ExecutionAdminRun } from '../../Workflows/components/ExecutionAdminRun';
import { ExecutionStatusDropdown } from '../../Workflows/components/ExecutionStatusDropdown';
import ExecutionChip from '../../Workflows/components/Chips/ExecutionChip';
import { useFeatureFlag } from '../../../utils/helper';
import { isAdmin } from '../../../utils/env';
import { clsx } from 'clsx';
import { type UpdateExecutionPayload } from '../../Execution/hooks';

const useExtensionListCols = ({
  executionDetailsEnabled,
  executionUpdateStatus,
  updateExecution,
  hitlLiveViewEnabled,
  liveViewEnabledWorkflowIds,
}: {
  liveViewEnabledWorkflowIds?: string[];
  executionDetailsEnabled: boolean;
  executionUpdateStatus?: 'error' | 'success' | 'idle' | 'pending' | undefined;
  hitlLiveViewEnabled: boolean;
  updateExecution: (payload: UpdateExecutionPayload) => unknown;
}): GridColDef<ExecutionWithWorkflowName>[] => {
  const handleUpdateExecution = useCallback(updateExecution, [updateExecution]);
  const executionsLastUpdatedAtEnabled = useFeatureFlag(
    FeatureFlag.ExecutionsLastUpdatedAt,
  );

  const columns: GridColDef<ExecutionWithWorkflowName>[] = useMemo(
    () => [
      {
        field: 'workflowName',
        headerName: 'Workflow Name',
        flex: 1,
        sortable: false,
        minWidth: 120,
        valueGetter: (params) => params.row.workflowName,
      },
      {
        field: 'executionId',
        headerName: 'Execution ID',
        flex: 1,
        valueGetter: (params) => params.row.executionId,
        sortable: false,
      },
      {
        field: 'userEmail',
        headerName: 'Owner',
        flex: isAdmin ? undefined : 1,
        valueGetter: (params: GridValueGetterParams<ExecutionBase>) =>
          params.row.userEmail,
        minWidth: 120,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ExecutionBase>) => (
          <Tooltip
            containerClassName="w-full overflow-x-hidden"
            title={row.userEmail}
          >
            <span className="w-full truncate">{row.userEmail}</span>
          </Tooltip>
        ),
      },
      ...(isAdmin
        ? [
            {
              field: 'adminRun',
              headerName: 'Admin Run',
              sortable: false,
              renderCell: ({
                row: { adminRun, executionId },
              }: GridRenderCellParams<ExecutionBase>) => {
                return (
                  <ExecutionAdminRun
                    checked={Boolean(adminRun)}
                    id={executionId}
                    updateAdminRun={handleUpdateExecution}
                    adminRunStatus={executionUpdateStatus}
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'createdAt',
        headerName: 'Queued At',
        flex: 1,
        valueGetter: (params) => [
          params.row.createdAt,
          params.row.setId,
          params.row.recordId,
        ],
        valueFormatter: (params: GridValueFormatterParams<string[]>) =>
          dayjs(new Date(params.value[0])).format('MM/DD/YY -  h:mm A'),
        sortComparator: (v1: string[], v2: string[]) => {
          const [createdAt1, setId1, recordId1] = v1;
          const [createdAt2, setId2, recordId2] = v2;

          // If same createdAt, sort by setId
          if (createdAt1 === createdAt2) {
            // If same setId, sort by recordId
            if (setId1 === setId2) {
              return Number(recordId1) - Number(recordId2);
            }
            return Number(setId1 < setId2);
          }
          return (
            new Date(createdAt1).getTime() - new Date(createdAt2).getTime()
          );
        },
      },
      ...(executionsLastUpdatedAtEnabled
        ? [
            {
              field: 'updatedAt',
              headerName: 'Last Modification',
              flex: 1,
              valueGetter: (param: GridValueGetterParams<ExecutionBase>) =>
                new Date(param.row.lastUpdatedAt ?? param.row.createdAt),
              valueFormatter: (params: GridValueFormatterParams<Date>) =>
                dayjs(params.value).format('MM/DD/YY - h:mm A'),
            },
          ]
        : []),
      {
        field: 'status',
        headerName: 'Status',
        flex: isAdmin ? 1 : undefined,
        minWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ExecutionBase>) => {
          const formattedStatus =
            params.row.status === ExecutionStatusEnum.Retry
              ? 'Waiting to retry'
              : params.row.status.toLowerCase().replace('_', ' ');

          const title = isAdmin
            ? formattedStatus
            : statusTitleMapping[params.row.status] ?? formattedStatus;

          return (
            <ExecutionChip
              className="!h-6"
              status={params.row.status}
              label={
                <span className="normal-case">
                  {capitalizeFirstLetter(title)}
                </span>
              }
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
              variant="filled"
            />
          );
        },
      },
      ...(isAdmin
        ? [
            {
              field: 'update-status',
              headerName: 'Update Status',
              flex: 1,
              sortable: false,
              renderCell: (params: GridRenderCellParams<ExecutionBase>) => {
                return (
                  <ExecutionStatusDropdown
                    executionUpdateStatus={executionUpdateStatus}
                    id={params.row.executionId}
                    status={params.row.status}
                    updateExecutionStatus={handleUpdateExecution}
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'action',
        headerName: '',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const statusIsAllowed = allowedUserExecutionStatuses.includes(
            params.row.status,
          );
          const isLiveViewEnabled = liveViewEnabledWorkflowIds?.includes(
            params.row.workflowId,
          );
          const canUseHITL =
            (hitlLiveViewEnabled && hitlStatuses.includes(params.row.status)) ||
            isLiveViewEnabled;
          const isRunning =
            !isAdmin &&
            !isLiveViewEnabled &&
            runningStatuses.includes(params.row.status);
          const isQueued =
            !isAdmin && params.row.status === ExecutionStatusEnum.Queued;

          const canSeeButton =
            (statusIsAllowed || executionDetailsEnabled || canUseHITL) &&
            !isQueued &&
            (canUseHITL || !isRunning);

          return canSeeButton ? (
            <Tooltip
              placement="left"
              title="You can view this execution once it finishes running"
              hidden={!isRunning}
            >
              <Link
                className={clsx(
                  'px-3 py-1.5 uppercase rounded border border-sola-primary text-sola-primary font-medium text-sm hover:bg-sola-primary-4 hover:border-sola-primary-dark hover:text-sola-primary-dark',
                  { 'pointer-events-none !opacity-50': isRunning },
                )}
                target="_blank"
                to={`/execution/${params.row.executionId}`}
              >
                View Details
              </Link>
            </Tooltip>
          ) : null;
        },
        flex: 1,
        sortable: false,
      },
    ],
    [
      executionsLastUpdatedAtEnabled,
      handleUpdateExecution,
      executionUpdateStatus,
      hitlLiveViewEnabled,
      executionDetailsEnabled,
      liveViewEnabledWorkflowIds,
    ],
  );

  return columns;
};

export default useExtensionListCols;
