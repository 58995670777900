import { defaultRuleProcessorCEL } from 'react-querybuilder';
import { OperatorType } from '../operators/types';
import { enumerateFieldConfigs } from './field-config';
/**
 * Creates a React Query Builder configuration from field configs
 *
 * @param fieldConfigs - Object containing field configurations
 * @param options - Configuration options
 * @returns Query builder configuration with fields and rule processor
 */
export function createQueryBuilderConfig(fieldConfigs, options) {
    if (options === void 0) { options = {}; }
    // Convert field configs to React Query Builder fields with specified overrides and display options
    var fields = enumerateFieldConfigs(fieldConfigs).map(function (_a) {
        var _b, _c, _d, _e;
        var fieldKey = _a[0], fieldConfig = _a[1];
        var fieldName = fieldConfig.name;
        var fieldLabel = (_c = (_b = options[fieldKey]) === null || _b === void 0 ? void 0 : _b.labelOverride) !== null && _c !== void 0 ? _c : fieldConfig.label;
        var fieldValidator = createRuleValidator(fieldConfig.operators);
        var operators = fieldConfig.operators.map(function (op) { return ({
            name: op.name,
            label: op.label,
            validator: fieldValidator,
        }); });
        return {
            name: fieldName,
            label: fieldLabel,
            operators: operators,
            // MARK: Optional configuration:
            placeholder: (_d = options[fieldKey]) === null || _d === void 0 ? void 0 : _d.placeholder,
            defaultValue: (_e = options[fieldKey]) === null || _e === void 0 ? void 0 : _e.defaultValue,
            validator: fieldValidator,
        };
    });
    var celRuleProcessor = createRuleProcessorCEL(fieldConfigs);
    return {
        fields: fields,
        celRuleProcessor: celRuleProcessor,
    };
}
function createRuleProcessorCEL(fieldConfigs) {
    var celRuleProcessor = function (rule, options) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- values is any
        var field = rule.field, operator = rule.operator, value = rule.value;
        // Find the field config
        var fieldConfig = fieldConfigs[field];
        // Find the operator implementation
        var operatorImpl = fieldConfig.operators.find(function (op) { return op.name === operator; });
        if (!operatorImpl) {
            throw new Error("Operator \"".concat(operator, "\" not found for field \"").concat(field, "\""));
        }
        switch (operatorImpl.type) {
            case OperatorType.Custom:
                return operatorImpl.processAsCEL(field, value);
            case OperatorType.BuiltIn:
                return defaultRuleProcessorCEL(rule, options);
        }
    };
    return celRuleProcessor;
}
function createRuleValidator(operators) {
    return function (rule) {
        var operator = operators.find(function (op) { return op.name === rule.operator; });
        if (!operator) {
            return { valid: false, reasons: ['Unknown operator'] };
        }
        if (operator.validators.length === 0) {
            return { valid: true };
        }
        var value = rule.value;
        for (var _i = 0, _a = operator.validators; _i < _a.length; _i++) {
            var validator = _a[_i];
            var result = validator(value);
            // NOTE: The validator stops evaluating and returns the first invalid result (!)
            // TODO: Combine reasons from multiple validators (?)
            if (!result.valid) {
                return result;
            }
        }
        return { valid: true };
    };
}
