import type {
  GetWorkflowMetadataResponse,
  WorkflowStatusEnum,
} from 'api-types-shared';
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type {
  NodeData,
  NodeStatusEnum,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import { WorkflowImageNode, WorkflowSourceNode } from 'types-shared';
import { isAdmin } from '../../../utils/env';
import { EditorStore } from '../store/EditorState';
import { removeNode } from '../utils/helper';
import { ActionHeader } from './ActionsManager/ActionHeader';
import AnnotatedPDF, {
  type RenderPdfProps,
  AnnotatedPdfControls,
} from './ActionsManager/DocumentVariables/AnnotatedPDF';
import { IconButton } from 'ui-kit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  useNextAndPrevNode,
  useNavigateBetweenNodes,
} from './NodeHeader/hooks';
import isNull from 'lodash/isNull';

interface Props {
  selectedNodeId?: string | null;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setSelectedNode: (nodeId: string | null) => void;
  SelectedImageNodeContent: React.FunctionComponent<{
    nodeData: NodeData;
    className?: string;
  }>;

  ActionsManager: React.FunctionComponent<{
    updateNodeStatus: (status: NodeStatusEnum) => void;
    isReadonlyView?: boolean;
    workflowStatus?: WorkflowStatusEnum;
    setAnnotatedPDFData: React.Dispatch<
      React.SetStateAction<RenderPdfProps | undefined>
    >;
  }>;
  workflowStatus?: WorkflowStatusEnum;
  isReadonlyView?: boolean;
  annotatedPDFData?: RenderPdfProps;
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
}

export function ActionViewCore({
  selectedNodeId,
  nodes,
  edges,
  setSelectedNode,
  SelectedImageNodeContent,
  ActionsManager,
  workflowStatus,
  isReadonlyView = false,
  annotatedPDFData,
  setAnnotatedPDFData,
  workflowMetadata,
}: Props) {
  const { setNodes, setEdges } = EditorStore();
  const [zoomLevel, setZoomLevel] = useState(1.2); // 100% base zoom
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const { nextNodeId, prevNodeId } = useNextAndPrevNode({
    currentNodeId: isNull(selectedNodeId) ? undefined : selectedNodeId,
    nodes,
    edges,
  });

  const { handleNavigateToNode, handleCloseNode } = useNavigateBetweenNodes();

  const handleZoom = useCallback((increase: boolean) => {
    setZoomLevel((prev) => {
      const step = 0.12; // 10% step relative to base zoom of 1.2
      const newZoom = increase ? prev + step : prev - step;
      // Allow zoom from 50% to 200% relative to base zoom of 1.2
      if (newZoom >= 0.6 && newZoom <= 2.4) {
        return newZoom;
      }
      return prev;
    });
  }, []);

  const currentZoomPercentage = Math.round((zoomLevel / 1.2) * 100);

  const { filteredNodes } = useMemo(() => {
    let dataNodeId = '';
    const nonDatasourceNodes = nodes.filter((n) => {
      if (WorkflowSourceNode.safeParse(n).success) {
        dataNodeId = n.id;
        return false;
      }
      return true;
    });
    return {
      datasourceNodeId: dataNodeId,
      filteredNodes: nonDatasourceNodes,
    };
  }, [nodes]); // skip the datasource node

  const selectedNode = useMemo(() => {
    const _selectedNode = selectedNodeId
      ? filteredNodes.find((n) => n.id === selectedNodeId)
      : null;

    try {
      const parsedSelectedNode = WorkflowImageNode.parse(_selectedNode);
      return parsedSelectedNode;
    } catch (error) {
      return null;
    }
  }, [selectedNodeId, filteredNodes]);

  const deleteNode = () => {
    if (!selectedNode) return;
    const { nodes: newlyFilteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      selectedNode.id,
    );
    handleCloseNode();
    setNodes(newlyFilteredNodes);
    setEdges(filteredEdges);
  };

  const updateNodeProps = (key: string, value: unknown) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === selectedNodeId) {
          // Create a deep clone of the node
          const clonedNode = clone(_node);

          // Set the new value at the specified key path
          // The clone argument is used as a customizer function to ensure nested objects
          // are also deeply cloned when setting new values, preventing mutation of shared references
          const updatedNode = setWith(clonedNode, key, value, clone);

          return updatedNode;
        }
        return _node;
      }),
    );
  };

  useEffect(() => {
    const editsWereMade = currentPage !== 1 || zoomLevel !== 1.2;
    if (annotatedPDFData === undefined && editsWereMade) {
      setZoomLevel(1.2);
      setCurrentPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotatedPDFData]);

  if (!selectedNode) return null;

  return (
    <div className="zoom-adjusted-container fixed inset-0">
      <div className="px-4 w-full bg-white py-4 border-b-2 border-gray-200">
        <ActionHeader
          node={selectedNode}
          onDeleteNode={deleteNode}
          setSelectedNode={setSelectedNode}
          workflowMetadata={workflowMetadata}
        />
      </div>
      <div className="relative w-full h-full flex">
        <ActionsManager
          isReadonlyView={isReadonlyView}
          workflowStatus={workflowStatus}
          setAnnotatedPDFData={setAnnotatedPDFData}
          updateNodeStatus={(status: NodeStatusEnum) => {
            updateNodeProps('data.nodeStatus', status);
          }}
        />
        <div
          id={annotatedPDFData ? 'selected-image-node-content-container' : ''}
          className="w-full flex justify-center items-center bg-white relative"
        >
          {/* AnnotatedPDF stays rendered but hidden when no data */}
          <div style={{ display: annotatedPDFData ? 'block' : 'none' }}>
            <AnnotatedPdfControls
              handleZoom={handleZoom}
              zoomLevel={zoomLevel}
              currentZoomPercentage={currentZoomPercentage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              maxPages={maxPages}
              disableControls={annotatedPDFData?.isProcessing || false}
            />
            <AnnotatedPDF
              {...(annotatedPDFData || {})}
              maxPages={maxPages}
              setMaxPages={setMaxPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
            />
          </div>

          {/* The fallback content stays rendered but hidden when data exists */}
          <div
            className="flex-1"
            style={{
              display: annotatedPDFData ? 'none' : 'block',
            }}
          >
            <div
              id={
                !annotatedPDFData ? 'selected-image-node-content-container' : ''
              }
              className="w-full flex justify-center items-center relative"
            >
              <div className="w-[40px] mr-6">
                <IconButton
                  onClick={() => {
                    if (prevNodeId) {
                      handleNavigateToNode(prevNodeId, true);
                    }
                  }}
                  disabled={!prevNodeId}
                  className="!bg-white rounded-full p-2 shadow cursor-pointer"
                >
                  <ChevronLeftIcon />
                </IconButton>
              </div>

              <div className="relative !w-4/5">
                {isAdmin ? (
                  <span className="absolute left-1/2 -top-12 -translate-x-1/2 text-sm font-semibold bg-white px-3 py-1 rounded-lg min-w-max">
                    {selectedNode.id}
                  </span>
                ) : null}
                <SelectedImageNodeContent
                  className="!w-full max-h-[80vh] h-auto"
                  nodeData={selectedNode.data}
                />
              </div>

              <div className="w-[40px] ml-6">
                <IconButton
                  className="!bg-white rounded-full p-2 shadow cursor-pointer"
                  disabled={!nextNodeId}
                  onClick={() => {
                    if (nextNodeId) {
                      handleNavigateToNode(nextNodeId, true);
                    }
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
