import {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  type ChangeEvent,
} from 'react';
import { clsx } from 'clsx';
import { type SelectChangeEvent } from '@mui/material';
import {
  VariableTypeEnum,
  DocumentVariable,
  DocumentSourceEnum,
  SourceTypeEnum,
  type Variable,
  type WorkflowAction,
  WorkflowImageNode,
  NodeTypesEnum,
  type NodeData,
  ActionsEnum,
  type TemplateData,
} from 'types-shared';
import {
  CloseIcon,
  DescriptionOutlined,
  Button,
  Select,
  DataLoader,
} from 'ui-kit';
import { formatFileSize } from '../../../../../utils/helper';
import { useUploadFiles } from '../../../hooks';
import LinearProgress from '@mui/material/LinearProgress';
import { VariableInput } from '../../VariableTypes/VariableInput';
import { VariableChip } from '../../../../../components/VariableChip';
import { constructVariable } from '../../../utils/helper';
import { v4 as uuid } from 'uuid';
import { EditorStore } from '../../../store/EditorState';
import { type ImageNodeValidationResult } from '../../../utils/nodeValidations';
import { useWorkflowFiles } from '../../../../WorkflowFiles/hooks';

const EditDocumentSelectEnum = {
  [DocumentSourceEnum.AWS]: 'Upload local file',
  [DocumentSourceEnum.URL]: 'File URL',
  [DocumentSourceEnum.Execution]: 'Link an existing document variable',
};

interface EditDocumentActionProps {
  node: WorkflowImageNode;
  action: WorkflowAction;
  variableId?: string;
  updateVariable: (variable: Variable) => void;
  validationData: ImageNodeValidationResult;
  validationAttempted: boolean;
  workflowId: string;
  hasDatasource: boolean;
  sourceType?: SourceTypeEnum;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, Variable>;
  variableData: (
    | string
    | {
        id: string;
      }
  )[];
  setVariableData: React.Dispatch<
    React.SetStateAction<
      (
        | string
        | {
            id: string;
          }
      )[]
    >
  >;
  onAddNewVariable: () => void;
  onPreviewVariableV2: (vId: string) => void;
  onSaveAndExit: () => void;
}

export default function EditDocumentAction({
  node,
  action,
  variableId,
  workflowId,
  hasDatasource,
  sourceType,
  variablesMap,
  globalVariablesMap,
  onAddNewVariable,
  onPreviewVariableV2,
  onSaveAndExit,
}: EditDocumentActionProps) {
  const { data: workflowFiles = [], isLoading: isLoadingWorkflowFiles } =
    useWorkflowFiles(workflowId);

  const editorData = EditorStore();
  const [localVariableId, setLocalVariableId] = useState<string | undefined>(
    variableId,
  );

  const variable = useMemo(
    () =>
      localVariableId
        ? (variablesMap[localVariableId] as DocumentVariable)
        : undefined,
    [localVariableId, variablesMap],
  );

  const [localSource, setLocalSource] = useState<DocumentSourceEnum>(
    variable?.type === VariableTypeEnum.Document
      ? variable.data.source
      : DocumentSourceEnum.AWS,
  );

  const { addVariable, setNodes, nodes, updateVariable } = editorData;

  // Updates node data while preserving type safety and existing node structure
  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  // Links a document variable to the current action and updates the node data
  const linkVariable = (newVariableId: string) => {
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          actionType: ActionsEnum.UploadDocument,
          variableId: newVariableId,
        },
      },
    });
  };

  const selectedVariable = useMemo(() => {
    return node.data.actionData[action.id].variableId || localVariableId;
  }, [node, action.id, localVariableId]);

  // Filter variables to only include document variables that are execution-sourced
  const documentVariables = useMemo(() => {
    return Object.values(variablesMap).filter(
      (v) =>
        DocumentVariable.safeParse(v).success &&
        (v as DocumentVariable).data.source === DocumentSourceEnum.Execution,
    );
  }, [variablesMap]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { mutateAsync: uploadFiles } = useUploadFiles();

  const [pickedFile, setPickedFile] = useState<{
    name: string;
    size?: string;
    uploading?: boolean;
  } | null>(
    variable?.type === VariableTypeEnum.Document &&
      variable.data.source === DocumentSourceEnum.AWS &&
      variable.data.s3Ref?.fileName
      ? {
          name: variable.data.s3Ref.fileName,
          uploading: false,
        }
      : null,
  );

  const existingFileVariables = useMemo(() => {
    const result = Object.values(variablesMap).filter((v) => {
      try {
        const parsedVariable = DocumentVariable.parse(v);
        return (
          parsedVariable.data.source === DocumentSourceEnum.AWS &&
          Boolean(
            workflowFiles.find(
              (f) => f.fileId === parsedVariable.data.s3Ref?.fileId,
            ),
          )
        );
      } catch (e) {
        // No need to handle exception since we're essentially just filtering out non-document variables
        return false;
      }
    });
    return result;
  }, [variablesMap, workflowFiles]);

  // Complex logic to handle variable creation/updates when source changes
  // Creates new variable if none exists or updates existing one
  const handleSaveSourceAndVariable = (source: DocumentSourceEnum) => {
    let actualVariable = variable;
    // Whether we're linking to an existing execution variable
    const isLinkingVariable = source === DocumentSourceEnum.Execution;

    const currentVariableIsExistingFileVariable = Boolean(
      variable &&
        documentVariables
          .concat(existingFileVariables)
          .find((v) => v.id === variable.id),
    );
    // Track the current variable for modifications
    // Simple check if a variable exists
    const variableExists = Boolean(variable);

    // Check if current variable uses AWS storage
    const previousVariableIsAws =
      variable?.data.source === DocumentSourceEnum.AWS;

    // Check if current variable uses URL source
    const previousVariableIsUrl =
      variable?.data.source === DocumentSourceEnum.URL;

    // Check if source type is changing
    const sourceIsNotTheSame = variable && source !== variable.data.source;

    // Check if variable is only used in this action
    const variableNotUsedElsewhere = Boolean(
      variable && JSON.stringify(nodes).split(variable.id).length <= 2,
    );

    // Whether we can reuse the existing variable
    const shouldReuseVariable =
      variableExists &&
      (previousVariableIsUrl || previousVariableIsAws) &&
      variableNotUsedElsewhere &&
      !currentVariableIsExistingFileVariable;

    // Whether we need to create a new variable
    const shouldCreateNewVariable =
      !shouldReuseVariable &&
      sourceIsNotTheSame &&
      currentVariableIsExistingFileVariable;

    if (shouldCreateNewVariable) {
      const newVariableId = uuid();

      actualVariable = constructVariable(
        newVariableId,
        action.actionType,
        [''],
        action,
      ) as DocumentVariable;

      actualVariable.data = {
        ...actualVariable.data,
        source,
      };

      addVariable(actualVariable);
      linkVariable(actualVariable.id);
      setLocalVariableId(actualVariable.id);
    } else if (!isLinkingVariable && shouldReuseVariable) {
      updateVariable({
        ...variable,
        data: {
          ...variable.data,
          source,
        },
      });
    }

    setLocalSource(source);
  };

  const handleIdentifyAndSaveSource = (event: SelectChangeEvent) => {
    const source = event.target.value as DocumentSourceEnum;
    handleSaveSourceAndVariable(source);
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    if (variable?.type !== VariableTypeEnum.Document) return;

    try {
      const file = event.target.files?.[0];

      if (file) {
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
          return;
        }

        setPickedFile({
          name: file.name,
          size: formatFileSize(file.size),
          uploading: true,
        });

        const { fileId } = await onUploadFile(file);

        updateVariable({
          ...variable,
          data: {
            ...variable.data,
            source: DocumentSourceEnum.AWS,
            s3Ref: {
              fileId,
              fileName: file.name,
            },
          },
        });
      }
    } finally {
      setPickedFile((p) => ({
        ...p,
        uploading: false,
      }));
    }
  };

  const onUploadFile = useCallback(
    async (file: File) => {
      if (!workflowId) {
        throw new Error('Workflow ID is not defined');
      }
      const fileIds = await uploadFiles({ files: [file], workflowId });
      return { fileId: fileIds[0] };
    },
    [uploadFiles, workflowId],
  );

  const updateVariableUrl = (url: TemplateData) => {
    if (!variable) return;
    const payload = {
      ...variable,
      data: {
        ...variable.data,
        source: DocumentSourceEnum.URL,
        url,
      },
    };
    updateVariable(payload);
  };

  const handleInitiateFileUpload = () => {
    fileInputRef.current?.click();
    handleSaveSourceAndVariable(DocumentSourceEnum.AWS);
  };

  // Create any missing file variables
  useEffect(() => {
    if (isLoadingWorkflowFiles) return;
    if (workflowFiles.length === 0) return;
    if (workflowFiles.length === existingFileVariables.length) return;
    const variableMapArr = Object.values(variablesMap);
    workflowFiles.forEach((file) => {
      const fileExists = variableMapArr.find((v) => {
        try {
          const parsedVariable = DocumentVariable.parse(v);
          return parsedVariable.data.s3Ref?.fileName === file.name;
        } catch (e) {
          // No need to handle exception since we're essentially just filtering out non-document variables
          return false;
        }
      });

      if (!fileExists) {
        const newVariableId = uuid();
        const newVariable = constructVariable(
          newVariableId,
          action.actionType,
          [''],
          action,
        ) as DocumentVariable;
        newVariable.name = file.name;
        newVariable.data = {
          ...newVariable.data,
          source: DocumentSourceEnum.AWS,
          s3Ref: {
            fileId: file.fileId,
            fileName: file.name,
          },
        };
        addVariable(newVariable);
      }
    });
  }, [
    workflowFiles,
    isLoadingWorkflowFiles,
    variablesMap,
    addVariable,
    action,
    existingFileVariables,
  ]);

  return isLoadingWorkflowFiles ? (
    <DataLoader loadingText="Fetching workflow files" />
  ) : (
    <>
      <div className="my-10 border rounded-lg px-4 py-6 text-sm flex flex-col">
        <span className="font-medium mb-4 text-info-dark">Value</span>
        <Select
          className="mb-6"
          color="secondary"
          fullWidth
          getLabel={(opt: string) =>
            EditDocumentSelectEnum[opt as keyof typeof EditDocumentSelectEnum]
          }
          getValue={(opt: string) => opt}
          label="File source"
          name="source"
          onChange={handleIdentifyAndSaveSource}
          options={Object.keys(EditDocumentSelectEnum)}
          value={localSource}
        />

        {localSource === DocumentSourceEnum.AWS ? (
          <div>
            <p className="font-medium mb-4 text-info-dark">Local file</p>

            <p className="text-gray-500 mb-4">
              Upload a file from your device. <br />
              Supported formats: PDF, PNG, JPEG
            </p>

            <input
              accept=".pdf,.png,.jpg"
              className="hidden display-none"
              onChange={handleFileSelect}
              ref={fileInputRef}
              type="file"
            />
            {pickedFile ? (
              <div className="w-full rounded-lg border-2 border-slate-200 p-3 px-3 relative">
                <div
                  className={clsx('flex gap-2', pickedFile.uploading && 'mb-3')}
                >
                  <DescriptionOutlined className="mt-1 text-cyan-900" />
                  <div className="flex flex-col justify-center">
                    <span className="text-cyan-900 font-medium leading-tight">
                      {pickedFile.name}
                    </span>
                    {pickedFile.size ? (
                      <p className="text-slate-500 text-xs font-normal leading-none">
                        {pickedFile.size}
                      </p>
                    ) : null}
                  </div>
                </div>

                {variable ? (
                  <CloseIcon
                    className="absolute top-2 right-2 cursor-pointer"
                    color="secondary"
                    onClick={() => {
                      setPickedFile(null);
                      updateVariable({
                        ...variable,
                        data: {
                          ...variable.data,
                          s3Ref: undefined,
                        },
                      });
                    }}
                  />
                ) : null}

                {pickedFile.uploading ? (
                  <LinearProgress color="secondary" />
                ) : null}
              </div>
            ) : (
              <Button
                color="secondary"
                onClick={handleInitiateFileUpload}
                variant="outlined"
              >
                Upload file
              </Button>
            )}
          </div>
        ) : null}

        {localSource === DocumentSourceEnum.Execution ? (
          <div>
            <p className="font-medium mb-4 text-info-dark">
              Select a variable to link to this action
            </p>

            <div>
              {[...documentVariables, ...existingFileVariables].map((dVar) => {
                const isSelected = dVar.id === selectedVariable;
                return (
                  <VariableChip
                    key={dVar.id}
                    variableId={dVar.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    alwaysClickable
                    className={`!bg-secondary-purple my-1 mr-1 ${isSelected ? '!border-4 !border-secondary-purple-300' : ''}`}
                    style={{
                      border: isSelected
                        ? '4px solid #c880d4'
                        : '4px solid #ffffff',
                    }}
                    onClick={() => {
                      linkVariable(dVar.id);
                    }}
                  />
                );
              })}
            </div>
          </div>
        ) : null}

        {localSource === DocumentSourceEnum.URL ? (
          <div>
            <VariableInput
              allowAddVariable={
                hasDatasource ||
                sourceType === SourceTypeEnum.API ||
                sourceType === SourceTypeEnum.EmailTrigger
              }
              className="mt-4"
              value={variable?.data.url}
              label="File URL link"
              onClickAddNew={onAddNewVariable}
              onChange={updateVariableUrl}
              onClickVariableChip={onPreviewVariableV2}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
            />
          </div>
        ) : null}
      </div>

      <span className="flex-1" />
      <Button
        className="!text-info !border-info !mt-5"
        color="secondary"
        onClick={onSaveAndExit}
        variant="outlined"
      >
        Save & Exit
      </Button>
    </>
  );
}
