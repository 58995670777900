"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileSchema = void 0;
const types_shared_1 = require("types-shared");
exports.getFileSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            fileId: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        fileDownloadUrl: types_shared_1.z.string(),
    }),
};
