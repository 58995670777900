import { CustomOperator } from '../types';
/**
 * A custom operator which is not bound to a specific Zod (and thus field type)
 * You can bound this to a specific field type using the `typed` method
 *
 * @example
 *
 */
var UntypedCustomOperator = /** @class */ (function () {
    function UntypedCustomOperator(_a) {
        var name = _a.name, defaultLabel = _a.defaultLabel, processAsCEL = _a.processAsCEL;
        this.name = name;
        this.defaultLabel = defaultLabel;
        this.processAsCEL = processAsCEL;
    }
    UntypedCustomOperator.prototype.typed = function (zodFieldTypeBrand) {
        return new CustomOperator(zodFieldTypeBrand, this.name, this.defaultLabel, this.processAsCEL);
    };
    return UntypedCustomOperator;
}());
export var ANY_CONTAINS_STRING = new UntypedCustomOperator({
    name: 'anyContains',
    defaultLabel: 'any contains',
    processAsCEL: function (fieldPath, value) {
        return "".concat(fieldPath, ".exists(s, s.contains(\"").concat(value, "\"))");
    },
});
export var NONE_CONTAINS_STRING = new UntypedCustomOperator({
    name: 'noneContains',
    defaultLabel: 'none contains',
    processAsCEL: function (fieldPath, value) {
        return "!".concat(fieldPath, ".exists(s, s.contains(\"").concat(value, "\"))");
    },
});
export var ANY_EQUALS_STRING = new UntypedCustomOperator({
    name: 'anyEquals',
    defaultLabel: 'any equals',
    processAsCEL: function (fieldPath, value) { return "".concat(fieldPath, ".exists(s, s == \"").concat(value, "\")"); },
});
export var NONE_EQUALS_STRING = new UntypedCustomOperator({
    name: 'noneEquals',
    defaultLabel: 'none equals',
    processAsCEL: function (fieldPath, value) {
        return "!".concat(fieldPath, ".exists(s, s == \"").concat(value, "\")");
    },
});
export var COUNT_EQUALS_NUMBER = new UntypedCustomOperator({
    name: 'countEquals',
    defaultLabel: 'count equals',
    processAsCEL: function (fieldPath, value) {
        return "".concat(fieldPath, ".count() == ").concat(value.toString());
    },
});
