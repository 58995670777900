import {
  type NotificationConfig,
  type NotificationConfigPayload,
} from './helper';
import { type PlatformMetadata, PlatformMetadataSchema } from 'types-shared';
import { type TypedDocumentNode, useMutation, useQuery } from '@apollo/client';
import {
  graphql,
  GetWorkflowNotificationsDocument,
  UpsertNotificationConfigurationDocument,
  UpsertNotificationConfigurationAdminDocument,
  // eslint disable next line camelcase
  type Notifications_Enum_Platform_Enum as NotificationsEnumPlatformEnum,
  // eslint disable next line camelcase
  type Notifications_Workflow_Configuration_Events_Insert_Input as NotificationsWorkflowConfigurationEventsInsertInput,
} from 'hasura-gql';
import { handleException } from 'sentry-browser-shared';
import { isAdmin } from '../../utils/env';
import { useMemo } from 'react';

const USER_TAG = 'user';

export const useCreateWorkflowNotification = () => {
  const [
    createNotificationAdmin,
    { data: dataAdmin, loading: loadingAdmin, error: errorAdmin },
  ] = useMutation(UpsertNotificationConfigurationAdminDocument);

  const [
    createNotificationUser,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UpsertNotificationConfigurationDocument);

  const createNotificationConfig = async (
    payload: NotificationConfigPayload,
  ) => {
    const baseVariables = {
      workflowId: payload.workflowId,
      platform: payload.platform as NotificationsEnumPlatformEnum,
      platformMetadata: payload.platformMetadata,
      events:
        payload.events as NotificationsWorkflowConfigurationEventsInsertInput[],
    };

    if (isAdmin) {
      return createNotificationAdmin({
        variables: {
          ...baseVariables,
          tag: USER_TAG,
          ownerId: payload.ownerId,
        },
      });
    }

    return createNotificationUser({
      variables: baseVariables,
    });
  };

  const currentData = isAdmin ? dataAdmin : dataUser;
  const currentLoading = isAdmin ? loadingAdmin : loadingUser;
  const currentError = isAdmin ? errorAdmin : errorUser;

  return {
    createNotificationConfig,
    data: currentData,
    loading: currentLoading,
    error: currentError,
  };
};

export const useUpdateNotification = () => {
  const [
    updateNotificationAdmin,
    { data: dataAdmin, loading: loadingAdmin, error: errorAdmin },
  ] = useMutation(UpsertNotificationConfigurationAdminDocument);

  const [
    updateNotificationUser,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UpsertNotificationConfigurationDocument);

  const updateNotificationConfig = async (
    notificationPayload: NotificationConfig,
  ) => {
    const baseVariables = {
      workflowId: notificationPayload.workflowId,
      platform: notificationPayload.platform as NotificationsEnumPlatformEnum,
      platformMetadata: notificationPayload.platformMetadata,
      events: notificationPayload.events.map((e) => ({
        event: e.event,
      })) as NotificationsWorkflowConfigurationEventsInsertInput[],
    };

    if (isAdmin) {
      return updateNotificationAdmin({
        variables: {
          ...baseVariables,
          tag: USER_TAG,
          ownerId: notificationPayload.ownerId,
        },
      });
    }

    return updateNotificationUser({
      variables: baseVariables,
    });
  };

  return {
    updateNotificationConfig,
    data: isAdmin ? dataAdmin : dataUser,
    loading: isAdmin ? loadingAdmin : loadingUser,
    error: isAdmin ? errorAdmin : errorUser,
  };
};

export const useDeleteNotification = () => {
  const [
    deleteNotificationAdmin,
    { data: dataAdmin, loading: loadingAdmin, error: errorAdmin },
  ] = useMutation(UpsertNotificationConfigurationAdminDocument);

  const [
    deleteNotificationUser,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UpsertNotificationConfigurationDocument);

  const deleteNotificationConfig = async ({
    workflowId,
    platform,
    platformMetadata,
    events,
    ownerId,
  }: {
    workflowId: string;
    platform: NotificationsEnumPlatformEnum;
    platformMetadata: PlatformMetadata;
    events:
      | NotificationsWorkflowConfigurationEventsInsertInput
      | NotificationsWorkflowConfigurationEventsInsertInput[];
    ownerId?: string;
  }) => {
    const baseVariables = {
      workflowId,
      platform,
      platformMetadata,
      events,
      deletedAt: new Date().toISOString(),
    };

    if (isAdmin && ownerId) {
      return deleteNotificationAdmin({
        variables: {
          ...baseVariables,
          tag: USER_TAG,
          ownerId,
        },
      });
    }

    return deleteNotificationUser({
      variables: baseVariables,
    });
  };

  return {
    deleteNotificationConfig,
    data: isAdmin ? dataAdmin : dataUser,
    loading: isAdmin ? loadingAdmin : loadingUser,
    error: isAdmin ? errorAdmin : errorUser,
  };
};

export const useGetWorkflowNotifications = (workflowId: string) => {
  const { data, loading, error, refetch } = useQuery(
    GetWorkflowNotificationsDocument,
    {
      variables: { workflowId },
      skip: !workflowId,
    },
  );

  const notifications = useMemo(
    () =>
      (data?.notifications_workflow_configurations || [])
        .map((notification) => {
          try {
            return {
              ...notification,
              platformMetadata: PlatformMetadataSchema.parse(
                notification.platformMetadata,
              ),
            };
          } catch (parseError) {
            handleException(parseError, {
              name: 'Failed to parse platform metadata',
              source: 'useGetWorkflowNotifications',
              extra: {
                workflowId,
                notificationId: notification.id,
                platformMetadata: notification.platformMetadata,
              },
            });
            return undefined;
          }
        })
        .filter((notification) => notification !== undefined),
    [data?.notifications_workflow_configurations, workflowId],
  );

  return {
    refetchNotifications: refetch,
    notifications,
    loading,
    error,
  };
};

const GET_TEAM_SLACK_CHANNEL_IDS = graphql(`
  query GetTeamSlackChannelIds($workflowId: uuid!) {
    workflow: workflows_by_pk(id: $workflowId) {
      owner {
        memberships {
          team {
            id
            slackChannelId
          }
        }
      }
    }
  }
`) as TypedDocumentNode<
  {
    workflow: {
      owner: {
        memberships: {
          team: {
            id: string;
            slackChannelId: string;
          };
        }[];
      };
    };
  },
  {
    workflowId: string;
  }
>;

export const useGetTeamSlackChannelId = (workflowId: string) => {
  const { data, loading, error } = useQuery(GET_TEAM_SLACK_CHANNEL_IDS, {
    variables: { workflowId },
    skip: !workflowId,
  });

  const channelId = data?.workflow.owner.memberships[0]?.team?.slackChannelId;

  if (data && data.workflow.owner.memberships.length > 1) {
    handleException(new Error('Multiple Slack channel IDs found. Expected 1'), {
      name: 'Multiple Slack Channels',
      source: 'useGetTeamSlackChannelIds',
      extra: {
        workflowId,
        data,
      },
    });
  }

  return {
    channelId: channelId || '',
    loading,
    error,
  };
};

const GET_CONVERSATION = graphql(`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      name
    }
  }
`) as TypedDocumentNode<
  {
    getConversation: {
      name: string;
    };
  },
  {
    conversationId: string;
  }
>;

export const useGetSlackChannelName = (channelId: string, enabled: boolean) => {
  const { data, loading, error } = useQuery(GET_CONVERSATION, {
    variables: { conversationId: channelId },
    skip: !channelId || !enabled,
  });

  return {
    channelName: data?.getConversation.name || '',
    loading,
    error,
  };
};
