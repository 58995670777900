import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from 'react';
import Quill from 'quill';
import {
  TemplateData,
  type GlobalVariable,
  isVariableAllowedToAddInInput,
  Variable,
  type VariableMap,
  VariableRef,
  VariableIdContainer,
  isSubVariable,
} from 'types-shared';
import { AddCircleOutlineOutlined } from 'ui-kit';
import { clsx } from 'clsx';
import VariablesMenu from '../Menu';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import { type VariableInputProps } from './types';
import {
  checkIfVariableHasTransformations,
  isDerivedFromDocumentVariable,
  isDocumentVariable,
  isEmailVariable,
} from '../../../../utils/helper';
import { handleException } from 'sentry-browser-shared';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';

export const VARIABLE_ID_DATA_ATTRIBUTE = 'data-variable-id';

const stripAllNewLines = (str: string) => {
  return str.replace(/\n+/g, '');
};

interface QuillKeyboardBinding {
  key: number;
  handler: () => boolean;
}

interface QuillModules {
  mention: {
    allowedChars: RegExp;
    mentionDenotationChars: string[];
    dataAttributes: string[];
  };
  keyboard: {
    bindings: {
      Enter?: QuillKeyboardBinding | null;
      Tab?: QuillKeyboardBinding | null;
    };
  };
  clipboard: {
    _multiline: boolean;
  };
}

export const VariableInput = forwardRef(
  (
    {
      className,
      value = [],
      label,
      onChange,
      onClickAddNew,
      onClickVariableChip,
      disabled = false,
      allowAddVariable = true,
      showPlusButton = true,
      variablesMap,
      globalVariablesMap,
      placeholder,
      multiline = true,
      willRerender = false,
      containerClassName,
      hideVariableMenuButton = false,
      disabledAddVariableTooltip,
      allowedMenuTabs,
      isHighlighted = false,
      onClickAddVariable,
    }: VariableInputProps,
    ref,
  ) => {
    const editorRef = useRef<Quill | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);
    const [localVariables, setLocalVariables] = useState<VariableMap>({});
    const [localValue, setLocalValue] = useState<TemplateData>([]);
    const initialDataLoadDone = useRef<boolean>(false);

    const hasDeletedVariables = useMemo(() => {
      return value.some(
        (v) =>
          typeof v !== 'string' &&
          isNil(variablesMap[v.id]) &&
          isNil(globalVariablesMap[v.id]),
      );
    }, [variablesMap, globalVariablesMap, value]);

    const editorId = useMemo(() => uuid(), []);

    const allowedVariables = useMemo(() => {
      return values(variablesMap).filter((variable: Variable) => {
        return (
          isVariableAllowedToAddInInput(variable) ||
          isDocumentVariable(variable) ||
          isSubVariable(variable)
        );
      });
    }, [variablesMap]);

    const allowedGlobalVariables: GlobalVariable[] = useMemo(
      () => values(globalVariablesMap) as GlobalVariable[],
      [globalVariablesMap],
    );

    const insertVariable = useCallback(
      (variable: Variable, indexForVariableInsert?: number) => {
        if (editorRef.current) {
          const quill = editorRef.current;
          const currentContent = quill.getContents();
          const actualCurrentContent = currentContent.ops;

          const hasNoContent =
            !actualCurrentContent.length ||
            (actualCurrentContent.length === 1 &&
              actualCurrentContent[0].insert === '');

          const validIndexForInsert = hasNoContent ? 0 : indexForVariableInsert;
          if (hasNoContent) {
            //  Quill will insert a new line at the top by default so we clear that.
            quill.clipboard.dangerouslyPasteHTML('');
          }

          // Get the current cursor position or place it at the end if no selection exists
          let range = quill.getSelection(true);

          // If there is no selection (i.e., the cursor is not placed), place it at the end
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!range && !indexForVariableInsert) {
            const length = quill.getLength();
            range = { index: length, length: 0 };
            quill.setSelection(range);
          } else if (indexForVariableInsert) {
            range = {
              index: hasNoContent ? 0 : indexForVariableInsert,
              length: 0,
            };
            quill.setSelection(range);
          }
          const rangeIndex = range.index;

          const index = validIndexForInsert ?? rangeIndex;

          editorRef.current.insertEmbed(index, 'mention', {
            id: variable.id,
            name: variable.name,
            type: variable.type,
            value: variable.name,
            onClickVariableChip,
            variable,
            isEmailVariable: isEmailVariable(variable, variablesMap),
            isDerivedFromDocument: isDerivedFromDocumentVariable(
              variable,
              variablesMap,
            ),
          });

          // Delete any trailing newline that Quill may have added
          const length = quill.getLength();
          const lastChar = quill.getText(length - 1, 1);
          if (lastChar === '\n') {
            quill.deleteText(length - 1, 1);
          }

          editorRef.current.setSelection(index + 1);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClickVariableChip],
      // We do not want this to fire every time value changes. That would create an infinite render loop
    );

    const insertString = useCallback(
      (content: string) => {
        if (editorRef.current) {
          let cleanedContent: string = content;

          if (!multiline) {
            cleanedContent = stripAllNewLines(content);
          }

          if (cleanedContent.length) {
            const range = editorRef.current.getSelection();
            const index = range ? range.index : editorRef.current.getLength();
            editorRef.current.insertText(index, cleanedContent);
            editorRef.current.setSelection(index + cleanedContent.length);
          }
        }
      },
      [multiline],
    );

    const reRenderContent = useCallback(
      (valueToUse?: TemplateData) => {
        if (editorRef.current) {
          editorRef.current.clipboard.dangerouslyPasteHTML('');

          let _value = valueToUse ?? value;

          const dataInvalid = _value.length === 1 && _value[0] === '\n';

          // Handle removing leading and trailing new lines.
          if (!dataInvalid) {
            const finalValue = _value[_value.length - 1];
            const firstValue = _value[0];
            const finalContent = cloneDeep(_value);

            const firstValueIsString = typeof firstValue === 'string';
            const finalValueIsString = typeof finalValue === 'string';
            const firstValueContainsNewLines =
              firstValueIsString && firstValue.includes('\n');
            const finalValueContainsNewLines =
              finalValueIsString && finalValue.includes('\n');
            const entireFinalValueIsNewLines =
              finalValueIsString &&
              finalValue.split('\n').filter((v) => v).length === 0;
            const entireFirstValueIsNewLines =
              firstValueIsString &&
              firstValue.split('\n').filter((v) => v).length === 0;

            if (firstValueIsString) {
              if (entireFirstValueIsNewLines) {
                finalContent.shift();
              } else if (firstValueContainsNewLines) {
                const startIsNewLine = firstValue.startsWith('\n');
                if (startIsNewLine) {
                  finalContent[0] = firstValue.replace(/^\n+/, '');
                }
              }
            }

            if (finalValueIsString) {
              if (entireFinalValueIsNewLines) {
                finalContent.pop();
              } else if (finalValueContainsNewLines) {
                const endIsNewLine = finalValue.endsWith('\n');
                if (endIsNewLine) {
                  finalContent[finalContent.length - 1] = finalValue.replace(
                    /\n+$/,
                    '',
                  );
                }
              }
            }

            /*
             * Quill processes content sequentially on mount, inserting values one by one.
             * We need to verify the data is fully processed before updating state to avoid
             * operating on incomplete data and arbitrarily losing data.
             *
             * Note: This code is intentionally duplicated rather than extracted into a reusable function,
             * as attempts to do so caused unnecessary re-renders even when using useCallback
             */
            const isNotArbitrarilyLosingData =
              finalContent.length >= value.length;

            if (isNotArbitrarilyLosingData) {
              _value = finalContent;
            }
          }

          const _variablesUsed = (
            _value.filter((v) => {
              if (typeof v !== 'string') {
                const check = VariableRef.safeParse(v);
                if (check.success) {
                  return true;
                }
                handleException(
                  new Error('Invalid VariableRef data in template data'),
                  {
                    name: 'Failed VariableRef parse',
                    source: 'Variable Input re-render',
                    extra: {
                      templateData: _value,
                      errorValue: v,
                      err: check.error,
                    },
                  },
                );
              }
              return false;
            }) as VariableRef[]
          )
            .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
            .filter((item) => {
              const check = Variable.safeParse(item);
              if (check.success) {
                return true;
              }
              handleException(
                new Error('Invalid Variable data in template data'),
                {
                  name: 'Failed Variable parse',
                  source: 'Variable Input re-render',
                  extra: {
                    templateData: _value,
                    errorValue: item,
                    err: check.error,
                  },
                },
              );
              return false;
            });

          const _newLocalVariableMap: Record<string, Variable> =
            _variablesUsed.reduce<VariableMap>((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {});

          setLocalVariables(_newLocalVariableMap);

          _value.forEach((item) => {
            if (VariableRef.safeParse(item).success) {
              const parsedItem = VariableRef.parse(item);
              const variable = Variable.safeParse(
                globalVariablesMap[parsedItem.id] ??
                  variablesMap[parsedItem.id],
              );
              if (variable.success) {
                insertVariable(variable.data);
              } else {
                insertVariable({ id: parsedItem.id } as Variable);
                handleException(
                  new Error('Invalid Variable data in template data'),
                  {
                    name: 'Failed Variable parse',
                    source: 'Variable Input re-render',
                    extra: {
                      templateData: _value,
                      errorValue: item,
                      err: variable.error,
                    },
                  },
                );
              }
            } else if (typeof item === 'string') {
              let strResult: string = item;

              if (!multiline) {
                strResult = stripAllNewLines(item);
              }
              if (strResult) {
                if (multiline) {
                  insertString(strResult);
                } else {
                  insertString(strResult);
                }
              }
            } else {
              handleException(new Error('Invalid data in template data'), {
                name: 'Failed Variable parse',
                source: 'Variable Input re-render',
                extra: {
                  templateData: _value,
                  errorValue: item,
                },
              });
            }
          });
        }
      },
      [
        value,
        variablesMap,
        globalVariablesMap,
        insertString,
        insertVariable,
        multiline,
      ],
    );

    useImperativeHandle(
      ref,
      () => ({
        addVariable(variable: Variable, indexForVariableInsert?: number) {
          insertVariable(variable, indexForVariableInsert);
        },
        reRender(newValue?: TemplateData) {
          reRenderContent(newValue);
        },
        clear() {
          editorRef.current?.deleteText(0, Number.POSITIVE_INFINITY);
        },
      }),
      [insertVariable, reRenderContent],
    );

    const { variablesHaveChanged, variablesUsed } = useMemo(() => {
      const _variablesUsed = (
        value.filter((v) => {
          if (typeof v !== 'string') {
            const check = VariableRef.safeParse(v);
            if (check.success) {
              return true;
            }
            handleException(
              new Error('Invalid VariableRef data in template data'),
              {
                name: 'Failed VariableRef parse',
                source: 'Variable Input variables changed memo',
                extra: {
                  templateData: value,
                  errorValue: v,
                  err: check.error,
                },
              },
            );
          }
          return false;
        }) as VariableRef[]
      )
        .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
        .filter((item) => {
          const check = Variable.safeParse(item);
          if (check.success) {
            return true;
          }
          handleException(new Error('Invalid Variable data in template data'), {
            name: 'Failed Variable parse',
            source: 'Variable Input variables changed memo',
            extra: {
              templateData: value,
              errorValue: item,
              err: check.error,
            },
          });
          return false;
        });

      const _newLocalVariableMap: Record<string, Variable> =
        _variablesUsed.reduce<VariableMap>((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

      const _variablesHaventChanged = Object.keys(localVariables).length
        ? _variablesUsed.every((v) => {
            const localVariable = localVariables[v.id];

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const transformationsAreSame = localVariable
              ? checkIfVariableHasTransformations(localVariable) ===
                checkIfVariableHasTransformations(v)
              : true;

            return (
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              localVariable &&
              localVariable.name === v.name &&
              transformationsAreSame
            );
          })
        : true;

      const payload = {
        variablesHaveChanged: !_variablesHaventChanged,
        variablesUsed: _newLocalVariableMap,
      };

      return payload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, variablesMap, localVariables]);
    // We do not need to check globalVariables for changes since we can't update them anyway

    // Resolve mismatch between local state and external variable state
    useEffect(() => {
      const actualLocalVariables = value.filter(
        (v) => typeof v !== 'string',
      ) as { id: string }[];
      const localNotUpdated = actualLocalVariables.some(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (v) => !localVariables[v.id],
      );

      if (localNotUpdated) {
        setLocalVariables(variablesUsed);
      }
    }, [value, localVariables, variablesUsed]);

    // Handle re-rendering variables when the name of a variable changes || when it gets transformed
    useEffect(() => {
      const noValues = !value.length || !Object.keys(localVariables).length;
      if (noValues) return;

      if (variablesHaveChanged) {
        setLocalVariables(variablesUsed);
        editorRef.current?.clipboard.dangerouslyPasteHTML('');

        if (value.length) {
          value.forEach((item) => {
            if (VariableRef.safeParse(item).success) {
              const parsedItem = VariableRef.parse(item);
              const variable =
                globalVariablesMap[parsedItem.id] ??
                variablesMap[parsedItem.id];
              if (Variable.safeParse(variable).success) {
                insertVariable(variable);
              } else {
                insertVariable({ id: parsedItem.id } as Variable);
                handleException(
                  new Error('Invalid Variable data in template data'),
                  {
                    name: 'Failed Variable parse',
                    source: 'Variable Input re-render',
                    extra: {
                      templateData: value,
                      errorValue: item,
                      variable,
                    },
                  },
                );
              }
            } else if (typeof item === 'string') {
              let strResult: string = item;

              if (!multiline) {
                strResult = stripAllNewLines(item);
              }

              if (strResult) {
                insertString(strResult);
              }
            }
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variablesHaveChanged]); // We want to listen only to variablesHaveChanged

    const extractInputContent = (): TemplateData => {
      const content = editorRef.current?.getContents();

      const cleanedContent = content?.ops;

      const templateMap = cleanedContent
        ?.map((op) => {
          if (typeof op.insert === 'object' && op.insert.mention) {
            const check = VariableIdContainer.safeParse(op.insert.mention);

            if (check.success) {
              const mention = check.data.variableId;

              return { id: mention };
            }
            handleException(
              new Error('Invalid VariableIdContainer data in templateMap'),
              {
                name: 'Failed VariableIdContainer parse',
                source: 'Variable Input extractInputContent',
                extra: {
                  op,
                  err: check.error,
                },
              },
            );
          }
          return op.insert;
        })

        .filter((v) => Boolean(v)) as TemplateData;

      const check = TemplateData.safeParse(templateMap);

      if (check.success) {
        return check.data;
      }

      handleException(new Error('Invalid TemplateData'), {
        name: 'Failed TemplateData parse',
        source: 'Variable Input extractInputContent',
        extra: {
          templateMap,
          err: check.error,
        },
      });

      return [];
    };

    const showPlaceholder = useMemo(
      () =>
        placeholder &&
        (!value.length || (value.length === 1 && value[0] === '')) &&
        (!localValue.length ||
          (localValue.length === 1 && localValue[0] === '')) &&
        Object.keys(localVariables).length === 0,
      [placeholder, value, localValue, localVariables],
    );

    useEffect(() => {
      if (!editorRef.current) {
        const quill = new Quill(`#editor-${editorId}`, {
          modules: {
            clipboard: {
              _multiline: multiline,
              matchVisual: false,
            },
            mention: {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              mentionDenotationChars: ['@', '#'],
              dataAttributes: ['id', 'name', 'denotationChar', 'type'],
            },

            // Disable the enter key if multiline is false
            ...(!multiline
              ? {
                  keyboard: {
                    bindings: {
                      enter: {
                        key: 13,
                        handler() {
                          return false;
                        },
                      },
                    },
                  },
                }
              : {}),
          },
        });

        const keyboard = quill.getModule(
          'keyboard',
        ) as QuillModules['keyboard'];
        keyboard.bindings.Tab = null;

        // Disable the enter key again if multiline is false. Both disable blocks are needed
        if (!multiline) {
          keyboard.bindings.Enter = null;
        } else {
          // Include any new lines in pasted content if multiline is true
          const clipboard = quill.getModule(
            'clipboard',
          ) as QuillModules['clipboard'];

          clipboard._multiline = true;
        }

        editorRef.current = quill;

        // Clear the new lines that quill inserts by default.
        quill.clipboard.dangerouslyPasteHTML('');

        quill.on('text-change', () => {
          if (onChange) {
            const templateData = extractInputContent();
            const hasChanged = !isEqual(templateData, value);

            if (hasChanged) {
              let cleanedData = templateData
                .map((val) => {
                  if (typeof val === 'string') {
                    let valToReturn: string = val;
                    if (!multiline) {
                      valToReturn = stripAllNewLines(val);
                    }
                    return valToReturn;
                  }
                  return val;
                })
                .filter((val) => val);

              const dataInvalid =
                cleanedData.length === 1 && cleanedData[0] === '\n';

              // Handle removing leading and trailing new lines.
              if (!dataInvalid) {
                const finalValue = cleanedData[cleanedData.length - 1];
                const firstValue = cleanedData[0];
                const finalContent = cloneDeep(cleanedData);

                const firstValueIsString = typeof firstValue === 'string';
                const finalValueIsString = typeof finalValue === 'string';
                const firstValueContainsNewLines =
                  firstValueIsString && firstValue.includes('\n');
                const finalValueContainsNewLines =
                  finalValueIsString && finalValue.includes('\n');
                const entireFinalValueIsNewLines =
                  finalValueIsString &&
                  finalValue.split('\n').filter((v) => v).length === 0;
                const entireFirstValueIsNewLines =
                  firstValueIsString &&
                  firstValue.split('\n').filter((v) => v).length === 0;

                if (firstValueIsString) {
                  if (entireFirstValueIsNewLines) {
                    finalContent.shift();
                  } else if (firstValueContainsNewLines) {
                    const startIsNewLine = firstValue.startsWith('\n');
                    if (startIsNewLine) {
                      finalContent[0] = firstValue.replace(/^\n+/, '');
                    }
                  }
                }

                if (finalValueIsString) {
                  if (entireFinalValueIsNewLines) {
                    finalContent.pop();
                  } else if (finalValueContainsNewLines) {
                    const endIsNewLine = finalValue.endsWith('\n');
                    if (endIsNewLine) {
                      finalContent[finalContent.length - 1] =
                        finalValue.replace(/\n+$/, '');
                    }
                  }
                }

                /*
                 * Quill processes content sequentially on mount, inserting values one by one.
                 * We need to verify the data is fully processed before updating state to avoid
                 * operating on incomplete data and arbitrarily losing data.
                 *
                 * Note: This code is intentionally duplicated rather than extracted into a reusable function,
                 * as attempts to do so caused unnecessary re-renders even when using useCallback
                 */
                const isNotArbitrarilyLosingData =
                  finalContent.length >= value.length;

                if (isNotArbitrarilyLosingData) {
                  cleanedData = finalContent;
                }
              }

              setLocalValue(cleanedData);
              onChange(cleanedData);
            }
          }
        });
      }

      // Cleanup effect to handle Quill editor dismount
      // Focus on remving new lines that quill arbitrarily adds
      // return () => {
      //   // Only run cleanup on unmount, not on re-renders
      //   if (!willRerender) {
      //
      //     }
      //   }
      // };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variablesMap, value]);

    const contentDidNotLoad = useMemo(() => {
      if (initialDataLoadDone.current || !editorRef.current || willRerender) {
        return false;
      }
      const templateData = extractInputContent();
      const hasChanged = !isEqual(templateData, value);

      const valueIsOnlyNewLine =
        value.length && value[0] === '\n' && value.length === 1;

      const valueExists =
        value.length && !valueIsOnlyNewLine && !initialDataLoadDone.current;

      return hasChanged && valueExists;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, editorRef.current, willRerender]);
    // Need to keep track of if the editor has been initialized

    useEffect(() => {
      if (
        contentDidNotLoad &&
        !initialDataLoadDone.current &&
        editorRef.current
      ) {
        initialDataLoadDone.current = true;

        editorRef.current.clipboard.dangerouslyPasteHTML('');

        const _variablesUsed = (
          value.filter((v) => {
            if (typeof v !== 'string') {
              const check = VariableRef.safeParse(v);
              if (check.success) {
                return true;
              }
              handleException(
                new Error('Invalid VariableRef data in template data'),
                {
                  name: 'Failed VariableRef parse',
                  source: 'Variable Input useEffect',
                  extra: {
                    templateData: value,
                    errorValue: v,
                    err: check.error,
                  },
                },
              );
            }
            return false;
          }) as VariableRef[]
        )
          .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
          .filter((item) => {
            const check = Variable.safeParse(item);
            if (check.success) {
              return true;
            }
            handleException(
              new Error('Invalid Variable data in template data'),
              {
                name: 'Failed Variable parse',
                source: 'Variable Input useEffect',
                extra: {
                  templateData: value,
                  errorValue: item,
                  err: check.error,
                },
              },
            );
            return false;
          });

        const _newLocalVariableMap: Record<string, Variable> =
          _variablesUsed.reduce<VariableMap>((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});

        setLocalVariables(_newLocalVariableMap);

        value.forEach((item) => {
          if (VariableRef.safeParse(item).success) {
            const parsedItem = VariableRef.parse(item);
            const variable =
              globalVariablesMap[parsedItem.id] ?? variablesMap[parsedItem.id];
            if (Variable.safeParse(variable).success) {
              insertVariable(variable);
            } else {
              insertVariable({ id: parsedItem.id } as Variable);
              handleException(
                new Error('Invalid Variable data in template data'),
                {
                  name: 'Failed Variable parse',
                  source: 'Variable Input useEffect',
                  extra: {
                    templateData: value,
                    errorValue: item,
                    variable,
                  },
                },
              );
            }
          } else if (typeof item === 'string') {
            let strResult: string = item;
            if (!multiline) {
              strResult = stripAllNewLines(item);
            }
            if (strResult) {
              insertString(strResult);
            }
          } else {
            handleException(
              new Error('Invalid Variable data in template data'),
              {
                name: 'Failed Variable parse',
                source: 'Variable Input useEffect',
                extra: {
                  templateData: value,
                  errorValue: item,
                },
              },
            );
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentDidNotLoad]); // Don't need to listen to global variables

    // This is a hack to prevent the editor from adding new lines when the component unmounts
    useEffect(
      () => () => {
        // Only run if there is a value to trim && if this is an unmount
        if (value.length > 0 && initialDataLoadDone.current) {
          const finalContent = extractInputContent();
          if (editorRef.current && finalContent.length > 1) {
            const contentClone = cloneDeep(finalContent);
            const finalValue = contentClone[contentClone.length - 1];
            const firstValue = contentClone[0];

            const firstValueIsString = typeof firstValue === 'string';
            const finalValueIsString = typeof finalValue === 'string';
            const firstValueContainsNewLines =
              firstValueIsString && firstValue.includes('\n');
            const finalValueContainsNewLines =
              finalValueIsString && finalValue.includes('\n');
            const entireFinalValueIsNewLines =
              finalValueIsString &&
              finalValue.split('\n').filter((v) => v).length === 0;
            const entireFirstValueIsNewLines =
              firstValueIsString &&
              firstValue.split('\n').filter((v) => v).length === 0;

            if (firstValueIsString) {
              if (entireFirstValueIsNewLines) {
                finalContent.shift();
              } else if (firstValueContainsNewLines) {
                const startIsNewLine = firstValue.startsWith('\n');
                if (startIsNewLine) {
                  finalContent[0] = firstValue.replace(/^\n+/, '');
                }
              }
            }

            if (finalValueIsString) {
              if (entireFinalValueIsNewLines) {
                finalContent.pop();
              } else if (finalValueContainsNewLines) {
                const endIsNewLine = finalValue.endsWith('\n');
                if (endIsNewLine) {
                  finalContent[finalContent.length - 1] = finalValue.replace(
                    /\n+$/,
                    '',
                  );
                }
              }
            }

            if (finalContent.length) {
              onChange?.(finalContent);
            }
            editorRef.current.off('text-change');
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
      // We want this function to fire only on mount and on unmount, so we don't need any dependencies at all
    );
    return (
      <div
        className={clsx('flex flex-col space-y-1 w-full', containerClassName)}
      >
        <div
          className={clsx(
            'relative flex border pr-1 w-full rounded min-h-14 focus-within:border-gray-400',
            !multiline && '!h-[56px]',
            className,
            { '!border-error': hasDeletedVariables },
            { '!border-secondary !border-2': isHighlighted },
          )}
          ref={containerRef}
          style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
        >
          {label ? (
            <span
              className={clsx({
                'absolute -top-2 left-3 text-gray-500 bg-white px-1 text-xs':
                  true,
                '!text-info': isHighlighted,
              })}
            >
              {label}
            </span>
          ) : null}

          {showPlaceholder ? (
            <div
              className="absolute top-[12px] left-[15px] right-[40px] text-gray-400 pointer-events-none whitespace-pre-wrap"
              style={{
                fontSize: '16px',
              }}
            >
              {placeholder}
            </div>
          ) : null}

          <div
            id={`editor-${editorId}`}
            className={clsx({
              'w-full outline-none mt-1 leading-7 text-base h-full ql-align':
                true,
              'no-multiline': !multiline,
            })}
            style={{
              fontSize: '16px',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              marginTop: '4px',
              ...(showPlusButton
                ? {
                    paddingRight: '40px',
                  }
                : {}),
              ...(!multiline
                ? {
                    whiteSpace: 'nowrap',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                  }
                : {}),
            }}
          />

          {hideVariableMenuButton ? null : (
            <button
              className={clsx('absolute right-1 top-2.5 flex p-1.5 rounded', {
                'text-gray-400  !cursor-not-allowed': disabled,
                'text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
                '!hidden': !showPlusButton,
              })}
              disabled={disabled}
              onClick={() => {
                setShowVariableMenu(true);
                onClickAddVariable?.();
              }}
              type="button"
            >
              <AddCircleOutlineOutlined className="!w-5 !h-5" />
            </button>
          )}

          {showVariableMenu ? (
            <VariablesMenu
              allowAddVariable={allowAddVariable}
              anchorEl={containerRef.current}
              onAddNew={() => {
                setShowVariableMenu(false);
                onClickAddNew?.(
                  editorRef.current?.getSelection()?.length ??
                    editorRef.current?.getLength(),
                );
              }}
              onClose={() => {
                setShowVariableMenu(false);
              }}
              onSelect={(maybeVariable) => {
                const check = Variable.safeParse(maybeVariable);
                if (check.success) {
                  insertVariable(check.data);
                }
              }}
              open={showVariableMenu}
              variables={allowedVariables}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              globalVariables={allowedGlobalVariables}
              disabledAddVariableTooltip={disabledAddVariableTooltip}
              allowedMenuTabs={allowedMenuTabs}
            />
          ) : null}
        </div>
        {hasDeletedVariables ? (
          <span className="text-error text-xs px-2 mt-1.5">
            One or more variables have been deleted. Replace or remove them to
            run the workflow.
          </span>
        ) : null}
      </div>
    );
  },
);

VariableInput.displayName = 'VariableInput';
