import useUserTeamDetails from './useUserTeamDetails';
import { isAdmin } from '../utils/env';

export default function useWorkflowLimit(): number | null {
  const { teamDetails } = useUserTeamDetails();

  if (!teamDetails || isAdmin) {
    return null;
  }

  return teamDetails.workflowLimit ?? null;
}
