import {
  WorkflowRunVariables,
  type WorkflowRunVariablesProps,
} from './WorkflowRunVariables';
import { Button, Input } from 'ui-kit';
import { useState, useEffect } from 'react';
import { useFeatureFlag } from '../../../utils/helper';
import { FeatureFlag } from '../../../utils/constants';
import { EmailProviderEnum } from 'types-shared';
import { EditorStore } from '../../Editor/store/EditorState';
import { useSourceVariable } from '../../Editor/hooks/useSourceVariable';
import { isAdmin } from '../../../utils/env';

interface RunWorkflowProps extends WorkflowRunVariablesProps {
  handleSubmit: () => void;
  onClose: () => void;
}

function RunWorkflow({
  variables,
  isFetchingNodeViewData,
  isPendingTest,
  setVariableState,
  variableState,
  handleSubmit,
  onClose,
}: RunWorkflowProps) {
  const { variables: editorVariables } = EditorStore();
  const { isEmailTrigger } = useSourceVariable(editorVariables);
  const emailTriggerManualRunEnabled = useFeatureFlag(
    FeatureFlag.ArmstrongSandboxEmailTriggerManualRun,
  );

  const [emailConfig, setEmailConfig] = useState({
    threadId: 'Latest Thread ID',
    paragonCredentialId: 'Connected Email Account',
    provider: EmailProviderEnum.Gmail,
  });

  // When feature flag is enabled and the workflow is an email trigger,
  // add the email config to the variable state - for all users
  useEffect(() => {
    if (emailTriggerManualRunEnabled && isEmailTrigger) {
      setVariableState((prev) => ({
        ...prev,
        threadId: emailConfig.threadId,
        paragonCredentialId: emailConfig.paragonCredentialId,
        provider: emailConfig.provider,
      }));
    }
  }, [
    emailConfig,
    emailTriggerManualRunEnabled,
    isEmailTrigger,
    setVariableState,
  ]);

  return (
    <>
      <div className="flex flex-col px-10 overflow-y-auto flex-1">
        <WorkflowRunVariables
          className="!overflow-y-hidden !min-h-min"
          variables={variables}
          isFetchingNodeViewData={isFetchingNodeViewData}
          isPendingTest={isPendingTest}
          setVariableState={setVariableState}
          variableState={variableState}
          isEmailTrigger={isEmailTrigger}
        />

        {emailTriggerManualRunEnabled && isEmailTrigger && isAdmin ? (
          <div className="mt-6 border-t border-gray-200 pt-4">
            <h3 className="text-info-dark text-normal font-medium mb-2">
              Email Trigger Configuration
            </h3>
            <p className="text-gray-500 text-sm mb-4">
              Enter the required values for running an email-triggered workflow
              manually.
            </p>
            <div className="flex flex-col gap-4">
              <Input
                floatingLabel
                label="Thread ID"
                placeholder="Enter email thread ID"
                value={emailConfig.threadId}
                onChange={(value) => {
                  setEmailConfig((prev) => ({ ...prev, threadId: value }));
                }}
                disabled={isPendingTest}
              />
              <Input
                floatingLabel
                label="Paragon Credential ID"
                placeholder="Enter Paragon credential ID"
                value={emailConfig.paragonCredentialId}
                onChange={(value) => {
                  setEmailConfig((prev) => ({
                    ...prev,
                    paragonCredentialId: value,
                  }));
                }}
                disabled={isPendingTest}
              />
              <div className="flex flex-col">
                <label className="text-sm mb-1" htmlFor="emailProvider">
                  Email Provider
                </label>
                <select
                  id="emailProvider"
                  className="border border-gray-300 rounded-md p-2"
                  value={emailConfig.provider}
                  onChange={(e) => {
                    setEmailConfig((prev) => ({
                      ...prev,
                      provider: e.target.value as EmailProviderEnum,
                    }));
                  }}
                  disabled={isPendingTest}
                >
                  <option value={EmailProviderEnum.Gmail}>Gmail</option>
                  <option value={EmailProviderEnum.Outlook}>Outlook</option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col pt-6 border-t border-gray-200 pb-10 px-10">
        <span className="text-gray-500 text-sm">
          By clicking "Run workflow" the workflow test will start running and
          you'll be redirected to the execution list page.
        </span>
        <div className="!mt-5 flex space-x-4">
          <Button
            color="secondary"
            loading={isPendingTest}
            onClick={handleSubmit}
            disabled={isFetchingNodeViewData}
            variant="contained"
          >
            Run Workflow
          </Button>
          <Button color="secondary" onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}

export default RunWorkflow;
