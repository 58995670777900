import isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import { type WorkflowNodeProps } from 'types-shared';
import { useStore } from 'types-shared/reactflow';
import { minZoomLevelForFullContent } from '../../../utils/constants';
import { useSourceVariable } from '../../../hooks/useSourceVariable';
import { EditorStore } from '../../../store/EditorState';
import NodeElement from '../index';
import { DatasourceNode } from './DatasourceNode';
import { TriggerNode } from './TriggerNode';
import { APICallNode } from './APICallNode';
import { useNavigateBetweenNodes } from '../../NodeHeader/hooks';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function SourceNode(workflowData: WorkflowNodeProps) {
  const { handleNavigateToNode } = useNavigateBetweenNodes();
  const showFullContent = useStore(zoomSelector);
  const { nodes, datasourceMetadata, workflowId, tableData, variables } =
    EditorStore();
  const { isApi, isTrigger, isDataSource, sourceVariable } =
    useSourceVariable(variables);
  const node = nodes.find((n) => n.id === workflowData.id);
  const hasDatasource = !isNil(datasourceMetadata);

  const navigate = useNavigate();

  const openDatasourcePage = () => {
    navigate(workflowId ? `/datasource/${workflowId}` : '/');
  };

  const handleNodeClick = () => {
    if (isApi) {
      navigate(workflowId ? `/api-trigger-settings/${workflowId}` : '/');
    } else if (isTrigger) {
      handleNavigateToNode(workflowData.id);
    } else {
      if (!hasDatasource) {
        openDatasourcePage();
        return;
      }
      navigate(workflowId ? `/datasource/${workflowId}/preview` : '/');
    }
  };

  if (isNil(node)) {
    return null;
  }

  return (
    <NodeElement
      darkBg={isTrigger}
      onClick={handleNodeClick}
      showAddButton
      workflowData={workflowData}
    >
      {isApi ? <APICallNode /> : null}
      {isDataSource ? (
        <DatasourceNode
          datasourceMetadata={datasourceMetadata}
          openDatasourcePage={openDatasourcePage}
          showFullContent={showFullContent}
          tableData={tableData}
        />
      ) : null}
      {isTrigger ? <TriggerNode node={node} variable={sourceVariable} /> : null}
    </NodeElement>
  );
}
