import {
  ContentContainer,
  CustomTypography,
  DataLoader,
  Flex,
  HelpOutlineOutlined,
  IconButton,
  ScrollableContainer,
  Tooltip,
} from 'ui-kit';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { openContactModal } from '../../utils/contactModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import SettingsForm from './components/Form';
import { CopyData } from '../../components/CopyData';
import { type SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useFeatureFlag } from '../../utils/helper';
import { FeatureFlag } from '../../utils/constants';
import { useGetWorkflowWebhooks } from './hooks';
import { handleException } from 'sentry-browser-shared';
import { useGetGlobalVariables } from '../GlobalVariables/hooks.gql';
import { GlobalVariable, VariableTypeEnum } from 'types-shared/workflowTypes';
import { getTabTitle } from '../../utils/tabTitle';
import { isAdmin } from '../../utils/env';
import NotificationsSettings from './components/Notifications';
import { ComponentsToolbar } from '../Editor/components/Toolbar/ComponentsToolbar';

const sampleResponse = JSON.stringify(
  {
    event: 'execution.finished',
    data: {
      executionId: 'xxx-xxx-xxx-xxx-xxx',
      workflowId: 'xxx-xxx-xxx-xxx-xxx',
      status: 'FAILED',
      statusDescription: 'Error message from website',
      createdAt: '2024-10-18T22:31:21.38366+00:00',
      lastUpdatedAt: '2024-10-18T22:32:03.622Z',
      variableData: {
        variable1: 'xxx',
        variable2: 'xxx',
      },
      scrapeData: {
        scrape1: 'xxx',
        scrape2: 'xxx',
      },
      artifacts: [
        {
          uri: 'https://prod-sola-execution-data.s3.us-east-1.amazonaws.com/xxxxxx',
          filename: 'xxx',
        },
        {
          uri: 'https://prod-sola-execution-data.s3.us-east-1.amazonaws.com/xxxxxx',
          filename: 'xxx',
        },
      ],
    },
  },
  null,
  2,
);

enum TabIdEnum {
  Events = 'events',
  Notifications = 'notifications',
  Triggers = 'triggers',
  Limits = 'limits',
}

export default function WorkflowSettings() {
  const [selectedTab, setSelectedTab] = useState<TabIdEnum>(TabIdEnum.Events);
  const notificationsSettingsEnabled = useFeatureFlag(
    FeatureFlag.NotificationsSettings,
  );

  const handleTabChange = (event: SyntheticEvent, newValue: TabIdEnum) => {
    if (newValue === TabIdEnum.Notifications && !notificationsSettingsEnabled)
      return;
    setSelectedTab(newValue);
  };

  const { workflowId } = useParams();

  if (!workflowId) {
    const error = new Error('workflowId not found!');
    handleException(error, {
      name: 'WorkflowSettings',
      source: 'WorkflowSettings',
    });
    throw error;
  }

  const navigate = useNavigate();
  const webhookPageEnabled = useFeatureFlag(FeatureFlag.WebhookPage);
  const { data: webhooks = [], isLoading: isLoadingWebhooks } =
    useGetWorkflowWebhooks(workflowId);
  const { data: globalVariablesRaw, loading: globalVariablesLoading } =
    useGetGlobalVariables();

  const globalVariablesMap = useMemo(() => {
    if (globalVariablesRaw) {
      return globalVariablesRaw.reduce(
        (acc: Record<string, GlobalVariable>, globalVariableRaw) => {
          const globalVariableParsed = GlobalVariable.safeParse({
            id: globalVariableRaw.id,
            name: globalVariableRaw.name,
            type: VariableTypeEnum.Global,
            data: [globalVariableRaw.value],
          });
          if (globalVariableParsed.success) {
            acc[globalVariableParsed.data.id] = globalVariableParsed.data;
          }
          return acc;
        },
        {},
      );
    }
    return {};
  }, [globalVariablesRaw]);

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { data: workflowMetadata, isLoading } =
    useFetchWorkflowMetadata(workflowId);

  const loading = isLoading || isLoadingWebhooks || globalVariablesLoading;

  useEffect(() => {
    if (webhookPageEnabled === false) {
      navigate(`/editor/${workflowId}`);
    }
  }, [navigate, webhookPageEnabled, workflowId]);

  useEffect(() => {
    document.title = workflowMetadata?.workflowName
      ? getTabTitle(`Settings | ${workflowMetadata.workflowName}`, isAdmin)
      : getTabTitle('Settings', isAdmin);
  }, [workflowMetadata?.workflowName]);

  // FIXME: Pop state seems to be deleting history entries, so we need to prevent that
  useEffect(() => {
    const handlePopState = () => {
      navigate(`/editor/${workflowId}`);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, workflowId]);

  return (
    <ScrollableContainer>
      {loading ? (
        <DataLoader loadingText="Fetching workflow settings" />
      ) : (
        <>
          <ComponentsToolbar
            hasOuterContainer={false}
            containerClassName="!py-4"
            onClose={() => {
              navigate(`/editor/${workflowId}`);
            }}
            crumbs={[
              { title: 'Workflow details', link: `/workflows/${workflowId}` },
              {
                title: `${workflowMetadata?.workflowName ?? 'Workflow'} - Flow view`,
                link: `/editor/${workflowId}`,
              },
              { title: 'Workflow Settings' },
            ]}
            RightEndContent={
              <IconButton
                className="rounded-lg group"
                onClick={() => {
                  openContactModal(workflowId);
                }}
              >
                <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
              </IconButton>
            }
          />

          <ContentContainer withToolbar>
            <CustomTypography
              className="!font-medium !text-2xl !text-info-dark"
              variant="h4"
            >
              Workflow Settings
            </CustomTypography>
            <p className="text-color-grey text-sm mb-6">
              Configure workflow-level settings for specific events, including
              webhooks
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
                onChange={handleTabChange}
              >
                <Tab label="Events" value={TabIdEnum.Events} />

                <Tab
                  value={TabIdEnum.Notifications}
                  label={
                    <Tooltip
                      title="Beta feature in progress… will be enabled soon for all users!"
                      arrow
                      hidden={notificationsSettingsEnabled}
                    >
                      <span
                        className={
                          !notificationsSettingsEnabled ? 'text-gray-400' : ''
                        }
                      >
                        Notifications
                      </span>
                    </Tooltip>
                  }
                />

                {/* <Tooltip
                  arrow
                  title="Beta feature in progress… will be enabled soon for all users!"
                >
                  <Tab label="Notifications" value="notifications" disabled />
                </Tooltip> */}
                <Tooltip
                  arrow
                  title="Beta feature in progress… will be enabled soon for all users!"
                >
                  <Tab label="Triggers" value={TabIdEnum.Triggers} disabled />
                </Tooltip>
                <Tooltip
                  arrow
                  title="Beta feature in progress… will be enabled soon for all users!"
                >
                  <Tab label="Limits" value={TabIdEnum.Limits} disabled />
                </Tooltip>
              </Tabs>
            </Box>
            {selectedTab === TabIdEnum.Events ? (
              <div className="flex justify-between py-8 space-x-10">
                <SettingsForm
                  globalVariablesMap={globalVariablesMap}
                  workflowId={workflowId}
                  webhooks={webhooks}
                />

                <Flex
                  className="space-y-8 w-120 xl:w-160"
                  flexDirection="column"
                >
                  <p className="text-info-dark text-lg font-medium">
                    Sample webhook
                  </p>
                  <p className="text-sm text-[#7A859C] !mt-2">
                    This is an example payload that will be sent to your
                    endpoint. Please note that this is not customizable, and the
                    values that are sent during executions will vary, though not
                    the keys.
                  </p>
                  <CopyData data={sampleResponse} dataType="json" />
                </Flex>
              </div>
            ) : null}

            {selectedTab === TabIdEnum.Notifications &&
            workflowMetadata?.userId ? (
              <NotificationsSettings
                workflowId={workflowId}
                ownerId={workflowMetadata.userId}
              />
            ) : null}
          </ContentContainer>
        </>
      )}
    </ScrollableContainer>
  );
}
