import { useEffect, useRef, useState } from 'react';
import {
  type OpenAction,
  WorkflowImageNode,
  NodeTypesEnum,
  type GlobalVariable,
  type TemplateData,
} from 'types-shared/workflowTypes';
import { EditorStore } from '../../../store/EditorState';
import {
  Add,
  Button,
  ChevronRight,
  DeleteOutlineIcon,
  ExpandMoreOutlined,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Select as SelectUIKit,
} from 'ui-kit';
import { SUPPORTED_APPLICATIONS } from 'types-shared/constants';
import {
  VariableInput,
  type VariableInputRef,
} from '../../VariableTypes/VariableInput';
import { TemplateDataPreview } from '../Action/ActionRenderer';

enum OpenActionEditMode {
  URI = 0,
  APP = 1,
}

export function EditOpenAction({
  action,
  node,
  onSaveAndExit,
}: {
  action: OpenAction;
  node: WorkflowImageNode;
  onSaveAndExit: () => void;
}) {
  const { nodes, setNodes } = EditorStore();
  const [editMode, setEditMode] = useState(OpenActionEditMode.URI);
  const [showAdvSettings, setShowAdvSettings] = useState(false);
  const [configuredAction, setConfiguredAction] = useState<OpenAction>(action);

  const updateNodeActionData = () => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              actionData: {
                ...updateNode.data.actionData,
                [configuredAction.id]: configuredAction,
              },
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const handleUpdatedConfiguredAction = (updates: Partial<OpenAction>) => {
    setConfiguredAction((prev: OpenAction) => {
      return {
        ...prev,
        ...updates,
      };
    });
  };

  const handleSaveAndExit = () => {
    updateNodeActionData();
    onSaveAndExit();
  };

  useEffect(() => {
    setConfiguredAction(action);
    setEditMode(
      action.parameters.application
        ? OpenActionEditMode.APP
        : OpenActionEditMode.URI,
    );
  }, [action]);

  return (
    <div className="mt-10 flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <RadioGroup
          className="flex !flex-row justify-around items-center"
          name="node-types-group-2 stop-block"
          onChange={(e, v) => {
            setEditMode(Number(v));
          }}
          value={editMode}
        >
          <FormControlLabel
            className="max-w-max"
            control={<Radio color="secondary" />}
            label="Uri"
            value={OpenActionEditMode.URI}
          />
          <FormControlLabel
            className="max-w-max"
            control={<Radio color="secondary" />}
            label="Application"
            value={OpenActionEditMode.APP}
          />
        </RadioGroup>

        {editMode === OpenActionEditMode.APP ? (
          <EditOpenApplicationAction
            configuredAction={configuredAction}
            updateAction={handleUpdatedConfiguredAction}
          />
        ) : (
          <EditOpenUriAction
            configuredAction={configuredAction}
            updateAction={handleUpdatedConfiguredAction}
          />
        )}

        <div className="flex flex-col gap-2 mt-4">
          <div
            className="flex items-center gap-2 cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              setShowAdvSettings(!showAdvSettings);
            }}
          >
            {showAdvSettings ? (
              <ExpandMoreOutlined className="w-4 h-4 text-gray-600" />
            ) : (
              <ChevronRight className="w-4 h-4 text-gray-600" />
            )}
            <span className="text-sm text-gray-600">
              {showAdvSettings
                ? 'Hide Advanced Settings'
                : 'Show Advanced Settings'}
            </span>
          </div>
          {showAdvSettings ? (
            <EditAdvancedSettings
              configuredAction={configuredAction}
              updateAction={handleUpdatedConfiguredAction}
            />
          ) : null}
        </div>
      </div>

      <div className="w-full">
        <Button
          className="!text-info !border-info !mt-5 w-full"
          color="secondary"
          onClick={handleSaveAndExit}
          variant="outlined"
        >
          Save & Exit
        </Button>
      </div>
    </div>
  );
}
interface EditOpenActionProps {
  configuredAction: OpenAction;
  updateAction: (updates: Partial<OpenAction>) => void;
}
function EditOpenApplicationAction({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  const appPlatform = configuredAction.parameters.application?.platform;

  return (
    <>
      <SelectUIKit
        label="Application"
        options={Object.values(SUPPORTED_APPLICATIONS).map(({ name }) => name)}
        getLabel={(opt: string) => opt}
        getValue={(opt: string) => opt}
        value={
          configuredAction.parameters.application?.name ??
          SUPPORTED_APPLICATIONS[0].name
        }
        placeholder="Select Application"
        onChange={(evt) => {
          const application = SUPPORTED_APPLICATIONS.find(
            ({ name }) => name === evt.target.value,
          );

          if (!application) {
            throw new Error(
              `invalid application: ${JSON.stringify(evt.target.value)}`,
            );
          }

          updateAction({
            parameters: {
              ...configuredAction.parameters,
              uri: [],
              application,
            },
            title: 'Application',
            options: {
              adminOnly: application.adminOnly,
            },
          });
        }}
      />

      {appPlatform ? (
        <div className="!mt-4">
          <Input floatingLabel label="Platform" value={appPlatform} disabled />
        </div>
      ) : null}
    </>
  );
}

function EditOpenUriAction({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  const { variables: variablesMap, globalVariables: globalVariablesMap } =
    EditorStore();

  return (
    <VariableInput
      multiline
      onChange={(val) => {
        updateAction({
          parameters: {
            ...configuredAction.parameters,
            uri: val,
            application: undefined,
          },
          title: 'URI',
        });
      }}
      value={configuredAction.parameters.uri}
      variablesMap={variablesMap}
      globalVariablesMap={globalVariablesMap as Record<string, GlobalVariable>}
    />
  );
}

function EditAdvancedSettings({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  return (
    <div className="flex flex-col gap-6">
      <EditWaitDuration
        configuredAction={configuredAction}
        updateAction={updateAction}
      />
      <EditOpenFlags
        configuredAction={configuredAction}
        updateAction={updateAction}
      />
      <EditOpenArguments
        configuredAction={configuredAction}
        updateAction={updateAction}
      />
    </div>
  );
}

function EditWaitDuration({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-gray-600">Wait Duration</span>
      <Input
        floatingLabel
        placeholder="Enter the duration to wait in milliseconds"
        value={configuredAction.parameters.waitDuration}
        onChange={(val: string) => {
          updateAction({
            parameters: {
              ...configuredAction.parameters,
              waitDuration: val,
            },
          });
        }}
      />
      <span className="text-xs text-gray-500">
        Specify the wait duration for the application to be opened
      </span>
    </div>
  );
}

function EditOpenFlags({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  const [flag, setFlag] = useState('');
  const currentFlags = configuredAction.parameters.flags || [];

  const addFlag = () => {
    if (!flag) return;

    if (!currentFlags.includes(flag)) {
      updateAction({
        parameters: {
          ...configuredAction.parameters,
          flags: [...currentFlags, flag],
        },
      });
      setFlag('');
    }
  };

  const removeFlag = (index: number) => {
    updateAction({
      parameters: {
        ...configuredAction.parameters,
        flags: currentFlags.filter((_, i) => i !== index),
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-gray-600">Flags</span>
      <div className="flex items-center gap-2">
        <Input
          classes={{
            wrapper: 'flex-1',
          }}
          floatingLabel
          placeholder="Enter flag"
          value={flag}
          onChange={(val: string) => {
            setFlag(val);
          }}
        />
        <Button
          className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
          color="secondary"
          onClick={addFlag}
          variant="outlined"
        >
          <Add className="text-info" />
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {currentFlags.map((val, index) => (
          <div
            key={`${index.toString()}-${JSON.stringify(val)}`}
            className="flex items-center justify-between bg-gray-50 p-2 rounded"
          >
            <span className="text-sm">{val}</span>
            <DeleteOutlineIcon
              className="hover:text-red-500 cursor-pointer"
              onClick={() => {
                removeFlag(index);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function EditOpenArguments({
  configuredAction,
  updateAction,
}: EditOpenActionProps) {
  const inputRef = useRef<VariableInputRef>(null);
  const [argument, setArgument] = useState<TemplateData>([]);
  const { variables: variablesMap, globalVariables } = EditorStore();
  const globalVariablesMap = globalVariables as Record<string, GlobalVariable>;

  const addArgument = () => {
    if (argument.length === 0 || (argument.length === 1 && argument[0] === ''))
      return;

    updateAction({
      parameters: {
        ...configuredAction.parameters,
        arguments: [...(configuredAction.parameters.arguments ?? []), argument],
      },
    });
    setArgument(['']);
    inputRef.current?.clear();
  };

  const removeArgument = (index: number) => {
    updateAction({
      parameters: {
        ...configuredAction.parameters,
        arguments: (configuredAction.parameters.arguments ?? []).filter(
          (_, i) => i !== index,
        ),
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm text-gray-600">Arguments</span>
      <div className="flex items-center gap-2">
        <VariableInput
          ref={inputRef}
          multiline
          placeholder="Enter argument"
          onChange={(val) => {
            setArgument(val);
          }}
          value={argument}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
        />
        <Button
          className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
          color="secondary"
          onClick={addArgument}
          variant="outlined"
        >
          <Add className="text-info" />
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {configuredAction.parameters.arguments?.map((val, index) => (
          <div
            key={`${index.toString()}-${JSON.stringify(val)}`}
            className="flex items-center justify-between bg-gray-50 p-2 rounded"
          >
            <TemplateDataPreview
              className="text-sm"
              dataLike={val}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
            />
            <DeleteOutlineIcon
              className="hover:text-red-500 cursor-pointer"
              onClick={() => {
                removeArgument(index);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
