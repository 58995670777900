import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  type WorkflowNode,
  ActionPlatformEnum,
  NodeTypesEnum,
  WorkflowImageNode,
} from 'types-shared';
import { Button, Switch } from 'ui-kit';
import { isAdmin } from '../../../../utils/env';
import { EditorStore } from '../../store/EditorState';
import {
  mergeSelectedNodes,
  removeNode,
  unCheckAllNodes,
} from '../../utils/helper';
import { ComponentsToolbar } from '../Toolbar/ComponentsToolbar';

interface Props {
  onDeleteNode?: () => void;
  handleOnClose?: () => void;
  node: WorkflowNode | undefined;
  setSelectedNode: (nodeId: string | null) => void;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
}

export function ActionHeader({
  onDeleteNode,
  handleOnClose,
  node,
  setSelectedNode,
  workflowMetadata,
}: Props) {
  const { nodes, edges, setNodes, setEdges, updateNode } = EditorStore();
  const [, setSearchParams] = useSearchParams();

  const onClose = () => {
    if (handleOnClose) {
      handleOnClose();
    } else {
      setSearchParams({});
      setSelectedNode(null);
    }
  };

  const selectedNodes = useMemo(() => {
    if (!node) return [];
    return nodes.filter((_n) => {
      const imageNode = WorkflowImageNode.safeParse(_n);
      if (!imageNode.success) {
        return false;
      }
      return imageNode.data.data.selected;
    });
  }, [nodes, node]);

  const handleMergeNodes = () => {
    const { newNodes, newEdges } = mergeSelectedNodes(
      nodes as WorkflowImageNode[],
      edges,
    );
    setEdges(newEdges);
    setNodes(newNodes);
  };

  const handleDeleteNodes = () => {
    setSelectedNode(null);
    let newNodes = [...nodes];
    let newEdges = [...edges];
    selectedNodes.forEach((_node) => {
      const data = removeNode(newNodes, newEdges, _node.id);
      newNodes = data.nodes;
      newEdges = data.edges;
    });
    setNodes(newNodes);
    setEdges(newEdges);
  };

  const handleCancel = () => {
    unCheckAllNodes(nodes, setNodes);
  };

  const handleChangePlatform = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const imageNode = WorkflowImageNode.safeParse(node);
    if (!imageNode.success) {
      return;
    }

    updateNode({
      ...imageNode.data,
      data: {
        ...imageNode.data.data,
        platform: checked ? ActionPlatformEnum.Windows : ActionPlatformEnum.Web,
      },
    });
  };

  if (!node) {
    return null;
  }

  return (
    <ComponentsToolbar
      onClose={onClose}
      crumbs={[
        {
          title: `${workflowMetadata?.workflowName ?? 'Workflow'} - Flow view`,
          onClick: onClose,
        },
        { title: 'Action view' },
      ]}
      CentralContent={
        isAdmin ? (
          <div className="flex items-center gap-2">
            <div className="pr-6">
              <Button color="error" onClick={onDeleteNode} variant="contained">
                Delete Node
              </Button>
            </div>

            <div className="flex items-center gap-2">
              <Button
                color="secondary"
                disabled={selectedNodes.length < 2}
                onClick={handleMergeNodes}
                variant="outlined"
              >
                Merge Nodes
              </Button>
              <Button
                color="secondary"
                disabled={selectedNodes.length < 2}
                onClick={handleCancel}
                variant="outlined"
              >
                Uncheck All
              </Button>
              <Button
                color="error"
                disabled={selectedNodes.length < 1}
                onClick={handleDeleteNodes}
                variant="contained"
              >
                Delete Nodes
              </Button>
              <Button color="secondary" onClick={handleCancel} variant="text">
                Cancel
              </Button>
              {node.type === NodeTypesEnum.Image ? (
                <div className="flex flex-row items-center gap-2 ml-4">
                  <span className="text-sm text-gray-500 min-w-max">
                    Desktop
                  </span>
                  <Switch
                    color="secondary"
                    checked={node.data.platform === ActionPlatformEnum.Windows}
                    onChange={handleChangePlatform}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null
      }
    />
  );
}
