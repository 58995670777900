import { type SignalTypeEnum } from 'api-types-shared';
import truncate from 'lodash/truncate';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertVariant, notify } from 'ui-kit';
import {
  allowedUserExecutionStatuses,
  canResumeStatuses,
  FeatureFlag,
  hitlStatuses,
  notAllowedExecutionSignalStatuses,
  terminalStatuses,
} from '../../utils/constants';
import {
  createVariableMap,
  downloadLinkData,
  useFeatureFlag,
} from '../../utils/helper';
import { useGetRefData, useGetWorkflowData } from '../Editor/hooks';
import { isUUID } from '../Editor/utils/helper';
import { useGetGlobalVariables } from '../GlobalVariables/hooks.gql';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';

import {
  ExecutionStatusEnum,
  ExecutionTriggerEnum,
  GlobalVariable,
  SourceVariable,
  VariableTypeEnum,
  WorkflowSourceNode,
  type Variable,
} from 'types-shared';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import pick from 'lodash/pick';
import { NoDataFound } from '../../components/NoDataFound';
import { isAdmin } from '../../utils/env';
import { getExecutionTabTitle } from '../../utils/tabTitle';
import BranchDecider from './components/BranchDecider';
import BranchResume from './components/BranchResume';
import ExecutionControls from './components/ExecutionControls';
import ExecutionDetailHeader from './components/ExecutionDetailHeader';
import { LiveView } from './components/LiveView';
import { type OutputItem } from './components/OutputsTable/Description';
import { RecordInputVariables } from './components/RecordInputVariables';
import { RunOverview } from './components/RunOverview';
import UpdateVariable from './components/UpdateVariable';
import { RecordOutputs } from './components/WorkflowScreenshotTabs/components/RecordOutputs';
import {
  useDeleteExecutionScreenshot,
  useDownloadExecutionData,
  useFetchExecutionDetail,
  useFetchExecutionScreenshots,
  useSendExecutionSignal,
  useUpdateExecution,
  useWorkflowScreenshotUrls,
} from './hooks';
import { useGetExecutionWebhook } from './hooks/useGetExecutionWebhookData';
import useLiveViewBasedonWorkflow from './hooks/useLiveViewBasedonWorkflow';
import { type ScreenshotForDelete, type ScreenshotUrl } from './utils';
import { ComponentsToolbar } from '../Editor/components/Toolbar/ComponentsToolbar';

const defaultSignalForm = {
  variable: undefined,
  value: '',
};

export default function Execution() {
  const { executionId } = useParams();
  const navigate = useNavigate();

  const [screenshotsForDelete, setScreenshotsForDelete] = useState<
    ScreenshotForDelete[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [deletingScreenshots, setDeletingScreenshots] = useState(false);
  const [stopPollingDetails, setStopPollingDetails] = useState(false);
  const [stopPollingScreenshots, setStopPollingScreenshots] = useState(false);
  const [executionImageUrls, setExecutionImageUrls] = useState<string[]>([]);
  const [downloadingExecution, setDownloadingExecution] =
    useState<boolean>(false);
  const [imageArray, setImageArray] = useState<[string, string][]>([]);
  const [selectedTab, setSelectedTab] = useState<
    'old' | 'overview' | 'outputs' | 'inputs'
  >('overview');
  const [signalForm, setSignalForm] = useState<{
    variable: Variable | undefined;
    value: string;
  }>(defaultSignalForm);

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const liveExecutionViewEnabled = useFeatureFlag(
    FeatureFlag.LiveExecutionView,
  );
  const executionDetailsEnabled = useFeatureFlag(FeatureFlag.ExecutionDetails);
  const liveViewBasedonWorkflowData = useLiveViewBasedonWorkflow();
  const executionScreenshotDetailsEnabled = useFeatureFlag(
    FeatureFlag.ExecutionScreenshotDetails,
  );
  const showAllNodeLogs = useFeatureFlag(FeatureFlag.ShowAllNodeLogs);
  const outputDetailsExportButtonEnabled = useFeatureFlag(
    FeatureFlag.OutputDetailsExportButton,
  );
  const hitlLiveViewEnabled = useFeatureFlag(FeatureFlag.HitlLiveView);

  const {
    data: executionDetail,
    isLoading: executionDetailLoading,
    refetch: refetchExecutionDetails,
    error,
  } = useFetchExecutionDetail(executionId, stopPollingDetails);

  const liveViewEnabledForWorkflow = useMemo(
    () =>
      executionDetail?.metadata.workflowId
        ? liveViewBasedonWorkflowData.workflowIds?.includes(
            executionDetail.metadata.workflowId,
          )
        : false,
    [
      executionDetail?.metadata.workflowId,
      liveViewBasedonWorkflowData.workflowIds,
    ],
  );

  const imageUrls = useMemo(
    () => executionDetail?.imageUrls ?? [],
    [executionDetail?.imageUrls],
  );
  const {
    data: executionImages = [],
    isLoading: executionScreenshotsLoading,
    refetch: refetchExecutionImages,
  } = useFetchExecutionScreenshots(
    executionId,
    imageUrls.slice(executionImageUrls.length),
    stopPollingScreenshots,
  );
  const variables = useMemo(
    () => executionDetail?.variableData ?? {},
    [executionDetail?.variableData],
  );

  const showLive = useMemo(() => {
    return (
      (liveExecutionViewEnabled || liveViewEnabledForWorkflow) &&
      executionDetail?.metadata.sessionId &&
      !terminalStatuses.includes(executionDetail.metadata.status)
    );
  }, [
    liveExecutionViewEnabled,
    executionDetail?.metadata.sessionId,
    executionDetail?.metadata.status,
    liveViewEnabledForWorkflow,
  ]);

  const {
    mutateAsync: downloadExecutionDataZip,
    status: downloadExecutionDataZipStatus,
  } = useDownloadExecutionData();
  const { mutate: sendExecutionSignal, status: signalStatus } =
    useSendExecutionSignal();
  const isSignalLoading = signalStatus === 'pending';
  const { data: webhookData } = useGetExecutionWebhook(executionDetail);

  const { mutateAsync: updateExecution, status: updateExecutionStatus } =
    useUpdateExecution();
  const { mutateAsync: deleteScreenshot } = useDeleteExecutionScreenshot();
  const { data: workflowMetadata, isLoading } = useFetchWorkflowMetadata(
    executionDetail?.metadata.workflowId ?? '',
  );
  const workflowId = executionDetail?.metadata.workflowId ?? '';

  const workflowVersionId = executionDetail?.metadata.workflowVersionId;
  const { data: workflowData, isLoading: isLoadingWorkflowData } =
    useGetWorkflowData(
      workflowId,
      false,
      Boolean(workflowId && workflowVersionId),
      workflowVersionId,
    );
  const nodes = useMemo(() => workflowData?.nodes ?? [], [workflowData?.nodes]);
  const completedSteps = useMemo(
    () => executionDetail?.metadata.completedSteps ?? [],
    [executionDetail?.metadata.completedSteps],
  );

  const currentStep = executionDetail?.metadata.currentStep;
  const { data: refData, isLoading: isLoadingRefData } = useGetRefData(
    workflowId,
    !workflowId || !workflowVersionId,
    workflowVersionId,
    'execution-key',
  );

  const allowHitlSignals = useMemo(
    () =>
      executionDetail?.metadata.status &&
      hitlStatuses.includes(executionDetail.metadata.status) &&
      hitlLiveViewEnabled,
    [hitlLiveViewEnabled, executionDetail],
  );

  const isAPITrigger =
    executionDetail?.metadata.trigger === ExecutionTriggerEnum.API;

  const sourceNode = useMemo(() => {
    return nodes.find((n) => WorkflowSourceNode.safeParse(n).success) as
      | WorkflowSourceNode
      | undefined;
  }, [nodes]);

  const sourceVariable = useMemo(() => {
    if (!sourceNode) {
      return undefined;
    }
    const { variableId } = sourceNode.data;
    return SourceVariable.parse(variables[variableId]);
  }, [sourceNode, variables]);

  const screenshotUrls = useWorkflowScreenshotUrls({
    nodes,
    sourceNode,
    sourceVariable,
    completedSteps,
    showAllNodeLogs: Boolean(showAllNodeLogs) || isAdmin,
    currentStep,
    imageArray,
    webhookData,
  });

  const executionStillQueued =
    executionDetail?.metadata.status === ExecutionStatusEnum.Queued;
  const executionRunningAndEmpty =
    executionDetail?.metadata.status === ExecutionStatusEnum.Running &&
    screenshotUrls.length === 0;

  const runId = useMemo(() => {
    let _runId = '';

    if (executionDetail?.metadata) {
      const { setId } = executionDetail.metadata;
      _runId = truncate(setId, { length: 5 });
    }

    return _runId;
  }, [executionDetail]);

  const downloadZippedOutput = async (
    execId: string,
    outputs: OutputItem[],
    isInput?: boolean,
  ) => {
    await downloadExecutionDataZip({ executionId: execId, outputs, isInput });
  };

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: 'overview' | 'outputs' | 'inputs',
  ) => {
    if (
      executionScreenshotsLoading ||
      isLoadingWorkflowData ||
      isLoadingRefData ||
      isLoading ||
      !executionDetail
    )
      return;
    setSelectedTab(newValue);
  };

  const onSignalClick =
    (signalType: SignalTypeEnum, payload = {}) =>
    () => {
      sendExecutionSignal({
        executionId,
        signalType,
        payload,
      });
    };

  const onDeleteScreenshot = async (
    id: string,
    isImage: boolean,
    item?: ScreenshotUrl,
  ) => {
    try {
      setDeletingScreenshots(true);
      const { timestamp } = item?.sortData ?? {};
      const isCurrentStep = currentStep?.nodeId === id;
      const isCompletedStep = executionDetail?.metadata.completedSteps?.some(
        (step) => step.timestamp === timestamp && step.nodeId === id,
      );
      const newMetadata = pick(executionDetail?.metadata, [
        'currentStep',
        'completedSteps',
      ]);
      if (!isImage) {
        if (isCompletedStep) {
          newMetadata.completedSteps =
            executionDetail?.metadata.completedSteps?.filter(
              (step) => step.timestamp !== timestamp || step.nodeId !== id,
            );
        } else if (isCurrentStep) {
          newMetadata.currentStep = undefined;
        }
      }
      await deleteScreenshot({
        executionId,
        screenshotId: decodeURIComponent(id),
        ...(!isImage ? { metadata: newMetadata } : {}),
      });
      await refetchExecutionDetails();
      if (isImage) {
        setExecutionImageUrls([]);
        setImageArray([]);
      }

      setDeletingScreenshots(false);
      notify({
        message: 'Successfully deleted screenshot',
        variant: AlertVariant.SUCCESS,
      });
    } catch (deleteError) {
      const actualError = deleteError as Error;
      notify({
        message: `Failed to delete screenshot: ${actualError.message}`,
        variant: AlertVariant.ERROR,
      });
      setDeletingScreenshots(false);
    }
  };

  const onBulkDeleteScreenshots = async () => {
    try {
      setDeletingScreenshots(true);
      const items = screenshotsForDelete;
      const newMetadata = pick(executionDetail?.metadata, [
        'currentStep',
        'completedSteps',
      ]);

      for (const { id, isImage, item } of items) {
        const { timestamp } = item?.sortData ?? {};
        const isCurrentStep = currentStep?.nodeId === id;
        const isCompletedStep = executionDetail?.metadata.completedSteps?.some(
          (step) => step.timestamp === timestamp && step.nodeId === id,
        );

        if (!isImage) {
          if (isCompletedStep) {
            newMetadata.completedSteps =
              executionDetail?.metadata.completedSteps?.filter(
                (step) => step.timestamp !== timestamp || step.nodeId !== id,
              );
          } else if (isCurrentStep) {
            newMetadata.currentStep = undefined;
          }
        }

        await deleteScreenshot({
          executionId,
          screenshotId: decodeURIComponent(id),
          ...(!isImage ? { metadata: newMetadata } : {}),
        });
      }

      // Refresh execution details once after all deletions
      await refetchExecutionDetails();

      // Clear image-related states if any of the items are images
      if (items.some(({ isImage }) => isImage)) {
        setExecutionImageUrls([]);
        setImageArray([]);
      }

      setDeletingScreenshots(false);
      setScreenshotsForDelete([]);
      notify({
        message: 'Successfully deleted screenshots',
        variant: AlertVariant.SUCCESS,
      });
    } catch (deleteError) {
      const actualError = deleteError as Error;
      notify({
        message: `Failed to delete screenshots: ${actualError.message}`,
        variant: AlertVariant.ERROR,
      });
      setDeletingScreenshots(false);
    }
  };

  const handleAddScreenshotForDelete = (newItem: ScreenshotForDelete) => {
    const isIncluded = screenshotsForDelete.find((i) => i.id === newItem.id);

    let payload: ScreenshotForDelete[];
    if (isIncluded) {
      payload = screenshotsForDelete.filter((i) => i.id !== newItem.id);
    } else {
      payload = [...screenshotsForDelete, newItem];
    }
    setScreenshotsForDelete(payload);
  };

  const handleUpdateExecution = useCallback(
    async (newStatusDesc: string) => {
      if (executionDetail?.metadata.executionId) {
        await updateExecution({
          id: executionDetail.metadata.executionId,
          statusDescr: newStatusDesc,
        });
      }
    },
    [updateExecution, executionDetail?.metadata.executionId],
  );

  const { data: globalVariablesRaw } = useGetGlobalVariables();
  const globalVariables = useMemo(() => {
    if (!globalVariablesRaw?.length) return {};

    const varResult = globalVariablesRaw
      .map((gVar) => {
        const parsed = GlobalVariable.safeParse({
          id: gVar.id,
          name: gVar.name,
          type: VariableTypeEnum.Global,
          data: [gVar.value],
        });

        if (parsed.success) {
          return parsed.data;
        }
        return undefined;
      })
      .filter((v): v is GlobalVariable => Boolean(v));

    return createVariableMap(varResult);
  }, [globalVariablesRaw]);

  const executionInFinalState = useMemo(
    () =>
      executionDetail
        ? notAllowedExecutionSignalStatuses.includes(
            executionDetail.metadata.status,
          )
        : false,
    [executionDetail],
  );

  const allowExecutionRetry = useMemo(
    () =>
      executionDetail?.metadata.status
        ? terminalStatuses.includes(executionDetail.metadata.status)
        : false,
    [executionDetail?.metadata.status],
  );
  const canResumeExecution = useMemo(
    () =>
      executionDetail
        ? canResumeStatuses.includes(executionDetail.metadata.status)
        : false,
    [executionDetail],
  );

  const handleStopPolling = (newPolling: boolean) => {
    setStopPollingScreenshots(newPolling);
    setStopPollingDetails(newPolling);
  };

  useEffect(() => {
    if (!executionDetail) return;

    const shouldStopPolling =
      executionDetail.metadata.status === ExecutionStatusEnum.Timeout ||
      executionInFinalState;

    const hasDownloadableArtifacts =
      executionDetail.artifacts.length > 0 ||
      executionDetail.inputFiles.length > 0;

    if (shouldStopPolling) {
      if (stopPollingDetails && hasDownloadableArtifacts) {
        setStopPollingDetails(false);
      } else if (!stopPollingDetails && !hasDownloadableArtifacts) {
        setStopPollingDetails(true);
      }
    }
  }, [executionInFinalState, stopPollingDetails, executionDetail]);

  useEffect(() => {
    if (imageUrls.length > executionImageUrls.length) {
      void refetchExecutionImages();
      setExecutionImageUrls(imageUrls);
    }
  }, [executionImageUrls.length, imageUrls, refetchExecutionImages]);

  useEffect(() => {
    if (executionImages.length > 0) {
      setImageArray((blobs) => {
        const newBlobs = [...blobs, ...executionImages];
        return uniqBy(newBlobs, ([url]) => url);
      });
    }
  }, [executionImages]);

  useEffect(() => {
    if (
      !isUUID(executionId) ||
      (error as Error | undefined)?.message.includes('404')
    ) {
      navigate(workflowId ? `/workflows/${workflowId}` : '/');
    }
  }, [error, executionId, navigate, workflowId]);

  useEffect(() => {
    if (
      isAdmin ||
      !executionDetail?.metadata.status ||
      executionDetailsEnabled === undefined ||
      hitlLiveViewEnabled === undefined ||
      liveViewBasedonWorkflowData.liveViewBasedOnWorkflowEnabled === undefined
    )
      return;
    const isViewableByAll = allowedUserExecutionStatuses.includes(
      executionDetail.metadata.status,
    );
    if (isViewableByAll) return;

    const canUseLiveViewBasedonWorkflow =
      liveViewBasedonWorkflowData.workflowIds?.includes(
        executionDetail.metadata.workflowId,
      );
    if (canUseLiveViewBasedonWorkflow) return;

    const canUseHITL =
      hitlLiveViewEnabled &&
      hitlStatuses.includes(executionDetail.metadata.status);

    if (!canUseHITL && !executionDetailsEnabled) {
      navigate(`/workflows/${executionDetail.metadata.workflowId}`);
    }
  }, [
    executionDetail?.metadata,
    executionDetailsEnabled,
    hitlLiveViewEnabled,
    liveViewBasedonWorkflowData,
    navigate,
  ]);

  useEffect(() => {
    if (!isAdmin && executionDetail?.metadata.adminRun) {
      navigate(`/workflows/${executionDetail.metadata.workflowId}`);
    }
  }, [executionDetail?.metadata, navigate]);

  useEffect(() => {
    // Resume polling when user returns to tab.
    const handleReturnToTab = () => {
      const newDetailsPollingValue = !stopPollingDetails;
      const newScreenshotsPollingValue = !stopPollingScreenshots;

      setStopPollingScreenshots(newScreenshotsPollingValue);
      setStopPollingDetails(newDetailsPollingValue);
      setTimeout(() => {
        setStopPollingScreenshots(!newScreenshotsPollingValue);
        setStopPollingDetails(!newDetailsPollingValue);
      }, 1000);
    };

    document.addEventListener('visibilitychange', handleReturnToTab);

    return () => {
      document.removeEventListener('visibilitychange', handleReturnToTab);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want this to fire once on document load
  }, []);

  useEffect(() => {
    document.title = getExecutionTabTitle(
      workflowMetadata?.workflowName,
      isAdmin,
    );
  }, [executionDetail?.metadata, workflowMetadata?.workflowName, runId]);

  useEffect(() => {
    const handlePopState = () => {
      if (executionDetail?.metadata) {
        navigate(`/workflows/${executionDetail.metadata.workflowId}`, {
          replace: true,
        });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [executionDetail?.metadata, navigate]);

  return (
    <div className="w-full h-full">
      <ComponentsToolbar
        hasOuterContainer={false}
        containerClassName="!py-4"
        loading={isLoading || executionDetailLoading}
        crumbs={[
          { title: 'Home', link: '/workflows' },
          ...(executionDetail
            ? [
                {
                  title: workflowMetadata?.workflowName ?? 'Execution Details',
                  link: `/workflows/${executionDetail.metadata.workflowId}`,
                },
              ]
            : []),
          { title: 'Execution details' },
        ]}
        // This empty div helps give the toolbar a consistent height to match the editor toolbar on the next page
        LeftEndContent={<div className="h-11" />}
        RightEndContent={
          <ExecutionControls
            selectedIndex={selectedIndex}
            screenshotUrls={screenshotUrls}
            isSignalLoading={isSignalLoading}
            executionInFinalState={executionInFinalState}
            canResumeExecution={canResumeExecution}
            downloadingExecution={downloadingExecution}
            onSignalClick={(newSignal) => {
              onSignalClick(newSignal)();
            }}
            setDownloadingExecution={setDownloadingExecution}
            workflowId={workflowId}
            workflowName={workflowMetadata?.workflowName}
            executionId={executionId}
            allowExecutionRetry={allowExecutionRetry}
            refetchExecutionDetails={refetchExecutionDetails}
          />
        }
      />

      <div className="pt-12 pb-8 px-8 overflow-y-auto relative flex-grow h-[calc(100%_-_92px)] flex flex-col">
        {isAdmin ? (
          <>
            <UpdateVariable
              signalForm={signalForm}
              globalVariablesMap={globalVariables}
              onSignalClick={onSignalClick}
              isSignalLoading={isSignalLoading}
              refData={refData}
              setSignalForm={setSignalForm}
            />

            <BranchDecider
              nodes={workflowData?.nodes ?? []}
              edges={workflowData?.edges ?? []}
              onResume={onSignalClick}
              signalNotAllowed={executionInFinalState}
              isLoading={isSignalLoading}
              executionUpdateStatus={signalStatus}
            />

            <BranchResume
              nodeId={currentStep?.nodeId}
              nodes={workflowData?.nodes ?? []}
              edges={workflowData?.edges ?? []}
              onResume={onSignalClick}
              isLoading={isSignalLoading}
              signalNotAllowed={!canResumeExecution}
            />
          </>
        ) : null}

        <ExecutionDetailHeader
          executionDetail={executionDetail}
          executionId={executionId}
          executionDetailLoading={executionDetailLoading}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            onChange={onTabChange}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
            value={selectedTab}
          >
            <Tab label="Overview" value="overview" />
            <Tab label="Inputs" value="inputs" />
            <Tab label="Outputs" value="outputs" />
          </Tabs>
        </Box>

        {!executionScreenshotsLoading &&
        !isLoadingWorkflowData &&
        !isLoadingRefData &&
        !executionDetailLoading &&
        !isLoading &&
        screenshotUrls.length === 0 &&
        executionDetail?.artifacts.length === 0 &&
        !executionStillQueued &&
        !executionRunningAndEmpty ? (
          <NoDataFound
            heading="Execution Details"
            subHeading="Something went wrong. This execution does not have any data."
            showIcon={false}
            className="mt-5"
          />
        ) : (
          <>
            {
              selectedTab === 'overview' && (
                <RunOverview
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  setStopPolling={handleStopPolling}
                  executionDetailLoading={executionDetailLoading}
                  globalVariables={globalVariables}
                  edges={workflowData?.edges ?? []}
                  updateExecutionStatus={updateExecutionStatus}
                  updateExecution={handleUpdateExecution}
                  executionDetail={executionDetail}
                  executionScreenshotDetailsEnabled={Boolean(
                    executionScreenshotDetailsEnabled,
                  )}
                  liveExecutionViewEnabled={
                    liveExecutionViewEnabled || liveViewEnabledForWorkflow
                  }
                  screenshotUrls={screenshotUrls}
                  webhookData={webhookData}
                  isAPITrigger={isAPITrigger}
                  loading={
                    executionScreenshotsLoading ||
                    isLoadingWorkflowData ||
                    isLoadingRefData ||
                    isLoading ||
                    !executionDetail ||
                    executionStillQueued ||
                    executionRunningAndEmpty
                  }
                  nodes={workflowData?.nodes}
                  signalLoading={isSignalLoading}
                  onSignalClick={
                    isAdmin || allowHitlSignals
                      ? (signal, payload) => {
                          onSignalClick(signal, payload)();
                        }
                      : undefined
                  }
                  showAllNodeLogs={Boolean(showAllNodeLogs)}
                  showLive={Boolean(showLive)}
                  status={executionDetail?.metadata.status}
                  targetData={refData?.targetData}
                  variablesData={executionDetail?.variableData}
                  workflowData={workflowData ?? undefined}
                  workflowName={workflowMetadata?.workflowName}
                  workflowId={workflowMetadata?.workflowId}
                  executionVariables={variables}
                  onDownloadLinkData={downloadLinkData}
                  hasSuggestions={false}
                  onDeleteScreenshot={onDeleteScreenshot}
                  onAddScreenshotForDelete={handleAddScreenshotForDelete}
                  screenshotsForDelete={screenshotsForDelete}
                  onBulkDeleteScreenshots={onBulkDeleteScreenshots}
                  deletingScreenshots={deletingScreenshots}
                >
                  {showLive && executionDetail ? (
                    <div className="!h-[70vh] w-full zoom-countered-element">
                      <LiveView
                        sessionId={executionDetail.metadata.sessionId ?? ''}
                        userId={executionDetail.metadata.userId}
                      />
                    </div>
                  ) : null}
                </RunOverview>
              )

              // <NoDataFound
              //   heading="Execution Screenshots"
              //   subHeading="This execution does not have any screenshots."
              //   showIcon={false}
              // />
            }
            {selectedTab === 'outputs' && executionDetail ? (
              <RecordOutputs
                executionDetail={executionDetail}
                artifactsLoading={executionDetailLoading}
                downloadLinkData={downloadLinkData}
                downloadZippedOutput={downloadZippedOutput}
                downloadZippedOutputStatus={downloadExecutionDataZipStatus}
                fetchExecutionArtifacts={refetchExecutionDetails}
                outputDetailsExportButtonEnabled={Boolean(
                  outputDetailsExportButtonEnabled,
                )}
              />
            ) : null}
            {selectedTab === 'inputs' && executionDetail ? (
              <RecordInputVariables
                artifacts={executionDetail.inputFiles}
                artifactsLoading={executionDetailLoading}
                downloadLinkData={downloadLinkData}
                downloadZippedOutput={downloadZippedOutput}
                downloadZippedOutputStatus={downloadExecutionDataZipStatus}
                fetchExecutionArtifacts={refetchExecutionDetails}
                executionVariables={variables}
                variableMetadata={executionDetail.metadata.variableData}
                outputDetailsExportButtonEnabled={Boolean(
                  outputDetailsExportButtonEnabled,
                )}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
