import {
  NodeTypesEnum,
  StopNodeStatusEnum,
  type WorkflowStopNode,
  type WorkflowNodeProps,
} from 'types-shared';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { RetryV2Icon, FlagIcon } from 'ui-kit';
import { type SvgIconProps } from '@mui/material/SvgIcon';
import { useNavigateBetweenNodes } from '../NodeHeader/hooks';

const allowedNodeTypes = [NodeTypesEnum.Retry, NodeTypesEnum.Stop];

function RetryIcon(props: SvgIconProps) {
  return <RetryV2Icon className="!h-12 !w-12 " stroke="#ffffff" {...props} />;
}

export default function EndingNode(workflowData: WorkflowNodeProps) {
  const { handleNavigateToNode } = useNavigateBetweenNodes();
  const { nodes } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
    })),
  );

  const node = useMemo(
    () => nodes.find((n) => n.id === workflowData.id),
    [nodes, workflowData.id],
  );

  if (isNil(node) || !allowedNodeTypes.includes(node.type)) {
    return null;
  }

  const isRetryNode = node.type === NodeTypesEnum.Retry;
  const nodeStatus = (() => {
    if (node.type === NodeTypesEnum.Retry) {
      return 'Retry Step';
    }
    switch ((node as WorkflowStopNode).data.status) {
      case StopNodeStatusEnum.Failure:
        return 'Stop and mark as failure';
      case StopNodeStatusEnum.Success:
        return 'Stop and mark as success';
      default:
        return 'Stop Step';
    }
  })();

  const Icon = isRetryNode ? RetryIcon : FlagIcon;

  return (
    <NodeElement
      allowBulkCheck
      label="Stop step"
      onClick={() => {
        handleNavigateToNode(workflowData.id);
      }}
      rightConnectable={false}
      showAddButton
      showDeleteButton
      showEditButton
      workflowData={workflowData}
    >
      <div className="flex-1 rounded-lg border border-indigo-light flex justify-center items-center bg-info-dark text-white">
        <Icon className="!h-12 !w-12" />
      </div>
      <span className="text-sm font-bold truncate">{nodeStatus}</span>
    </NodeElement>
  );
}
