import { useEffect, useState } from 'react';
import { ExecutionStatusEnum } from 'types-shared';
import { Select } from 'ui-kit';
import startCase from 'lodash/startCase';
import { type UpdateExecutionPayload } from '../../Execution/hooks';

const executionStatusSelectOptions: ExecutionStatusEnum[] = [
  ExecutionStatusEnum.Queued,
  ExecutionStatusEnum.PendingUser,
  ExecutionStatusEnum.PendingAdmin,
  ExecutionStatusEnum.PendingSystem,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Running,
  ExecutionStatusEnum.Success,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Paused,
  ExecutionStatusEnum.Timeout,
  ExecutionStatusEnum.Retry,
];

interface Props {
  id: string;
  status: ExecutionStatusEnum;
  executionUpdateStatus?: 'error' | 'idle' | 'pending' | 'success';
  updateExecutionStatus?: (payload: UpdateExecutionPayload) => unknown;
  isSmall?: boolean;
}

export function ExecutionStatusDropdown({
  id,
  status,
  executionUpdateStatus = 'idle',
  updateExecutionStatus,
  isSmall,
}: Props) {
  const [hasRequestedUpdate, setHasRequestedUpdate] = useState(false);
  useEffect(() => {
    if (executionUpdateStatus === 'idle') setHasRequestedUpdate(false);
  }, [executionUpdateStatus]);

  return (
    <Select<ExecutionStatusEnum>
      disabled={executionUpdateStatus === 'pending' && hasRequestedUpdate}
      getLabel={
        (option) => startCase(option)
        // option === ExecutionStatusEnum.PendingUser
        //   ? 'Human in the loop'
        //   : startCase(option)
      }
      getValue={(option) => option}
      onChange={(event) => {
        setHasRequestedUpdate(true);
        updateExecutionStatus?.({
          id,
          status: event.target.value as ExecutionStatusEnum,
        });
      }}
      options={executionStatusSelectOptions}
      value={status}
      style={{ fontSize: '14px' }}
      sx={
        isSmall
          ? {
              minHeight: '40px',
              lineHeight: '1em',
              '& .custom-select': {
                minHeight: '40px !important',
              },
              '& .MuiSelect-root': {
                lineHeight: '1em !important',
              },
            }
          : {}
      }
    />
  );
}
