import { NetworkStatus, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  GetExecutionsInTimeRangeDocument,
  GetTeamMembersWithWorkflowCountDocument,
  type GetTeamMembersWithWorkflowCountQuery,
} from 'hasura-gql';
import {
  generateExecutionHourlyMetricsQuery,
  generateMonthWiseExecutionsCountQuery,
  generateMonthWiseWorkflowsCountQuery,
} from './schema';
import { useMemo } from 'react';
import { type DateFilterValue } from '../Workflows/components/ExecutionListDateFilter';
import {
  useGetTeamMaxConcurrentExecutions,
  useGetWorkflowsWithExecutionsCount,
} from '../Orchestration/hooks';
import { useGetAuthUserTeamMembers } from '../Members/hooks.gql';
import { ExecutionStatusEnum } from 'types-shared/executionTypes';
import { getUtcDate } from './helper';

const todayDate = dayjs();
const sameDateLastMonth = todayDate.subtract(1, 'month');
const beginningOfCurrentMonth = todayDate.startOf('month');
const getBeginningOfMonth = (months: number) => {
  return beginningOfCurrentMonth.subtract(months, 'month');
};
const beginningOflastMonth = getBeginningOfMonth(1);
const beginningOf2ndLastMonth = getBeginningOfMonth(2);
const beginningOf3rdLastMonth = getBeginningOfMonth(3);
const beginningOf4thLastMonth = getBeginningOfMonth(4);
const beginningOf5thLastMonth = getBeginningOfMonth(5);
const beginningOfTime = dayjs().subtract(100, 'year');

const monthWiseTimeRanges = [
  [
    'month5',
    beginningOf5thLastMonth.format(),
    beginningOf4thLastMonth.format(),
  ],
  [
    'month4',
    beginningOf4thLastMonth.format(),
    beginningOf3rdLastMonth.format(),
  ],
  [
    'month3',
    beginningOf3rdLastMonth.format(),
    beginningOf2ndLastMonth.format(),
  ],
  ['month2', beginningOf2ndLastMonth.format(), beginningOflastMonth.format()],
  ['month1', beginningOflastMonth.format(), beginningOfCurrentMonth.format()],
  ['month0', beginningOfCurrentMonth.format(), todayDate.format()],
];

export interface ExecutionCount {
  totalExecutions: number;
  successfulExecutions: number;
  failedExecutions: number;
  runningExecutions: number;
  queuedExecutions: number;
}

export interface WorkflowCount {
  total: number;
  cumulative: number;
}

interface TimeRange {
  start: string;
  end: string;
}

interface Filter {
  memberId?: string;
  timeRange: TimeRange;
}

interface QueryResult<T> {
  data: T;
  loading: boolean;
  refetch: () => void;
}

export const useGetTeamMembersWithWorkflowCount = (): QueryResult<
  GetTeamMembersWithWorkflowCountQuery | undefined
> => {
  const { data, loading, refetch, networkStatus } = useQuery(
    GetTeamMembersWithWorkflowCountDocument,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  return {
    data,
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useGetExecutionsInTimeRange = ({
  memberId,
  timeRange,
}: Filter): QueryResult<ExecutionCount> => {
  const { data, loading, refetch, networkStatus } = useQuery(
    GetExecutionsInTimeRangeDocument,
    {
      variables: {
        conditions: {
          createdAt: {
            _gt: getUtcDate(timeRange.start),
            _lt: getUtcDate(timeRange.end),
          },
          ...(memberId ? { ownerId: { _eq: memberId } } : {}),
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  return {
    data: {
      totalExecutions: data?.totalExecutions.aggregate?.count ?? 0,
      successfulExecutions: data?.successfulExecutions.aggregate?.count ?? 0,
      failedExecutions: data?.failedExecutions.aggregate?.count ?? 0,
      runningExecutions: data?.runningExecutions.aggregate?.count ?? 0,
      queuedExecutions: data?.queuedExecutions.aggregate?.count ?? 0,
    },
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useGetTotalAndLastMonthWorkflowsCount = (
  memberId?: string,
): QueryResult<{
  allWorkflows: number;
  currentMonthWorkflows: number;
  lastMonthWorkflows: number;
}> => {
  const monthWiseQuery = generateMonthWiseWorkflowsCountQuery(
    // Use sliding windows:
    // - Current month (sliding): today's date last month to today
    // - Previous month (sliding): today's date two months ago to today's date last month
    [
      ['month0', sameDateLastMonth.format(), todayDate.format()],
      [
        'month1',
        dayjs().subtract(2, 'month').format(),
        sameDateLastMonth.format(),
      ],
      // Also include the 'all time' range
      ['all', beginningOfTime.format(), todayDate.format()],
    ],
    memberId,
  );
  const { data, loading, refetch, networkStatus } =
    useQuery<ExecutionCountData>(monthWiseQuery, {
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: {
      allWorkflows: data?.all_total?.aggregate?.count ?? 0,
      currentMonthWorkflows: data?.month0_total?.aggregate?.count ?? 0,
      lastMonthWorkflows: data?.month1_total?.aggregate?.count ?? 0,
    },
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useGetCurrentMonthAndLastMonthExecutionsCount = (
  memberId?: string,
): QueryResult<{
  currentMonthExecutions: number;
  lastMonthExecutions: number;
}> => {
  const monthWiseQuery = generateMonthWiseExecutionsCountQuery(
    [
      // current month (sliding window): today's date last month to today
      ['month0', sameDateLastMonth.format(), todayDate.format()],
      // previous month (sliding window): today's date two months ago to today's date last month
      [
        'month1',
        dayjs().subtract(2, 'month').format(),
        sameDateLastMonth.format(),
      ],
    ],
    memberId,
  );
  const { data, loading, refetch, networkStatus } =
    useQuery<ExecutionCountData>(monthWiseQuery, {
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: {
      currentMonthExecutions: data?.month0_total?.aggregate?.count ?? 0,
      lastMonthExecutions: data?.month1_total?.aggregate?.count ?? 0,
    },
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useGetAllTimeExecutionsCount = (
  memberId?: string,
  dateFilterValue?: DateFilterValue,
): QueryResult<ExecutionCount> => {
  const range = (dateFilterValue?.dateRange ?? []) as [string, string];
  const hasCustomDateRange = range[0] && range[1];
  const startDate = hasCustomDateRange ? range[0] : beginningOfTime.format();
  const endDate = hasCustomDateRange ? range[1] : todayDate.format();
  return useGetExecutionsInTimeRange({
    memberId,
    timeRange: {
      start: startDate,
      end: endDate,
    },
  });
};

const formatAggregateCount = (
  data: { aggregate?: { count?: number } } | undefined,
) => {
  return data?.aggregate?.count ?? 0;
};

export const useGetMonthWiseWorkflowsCount = (
  memberId?: string,
): QueryResult<[string, WorkflowCount][]> => {
  const monthWiseQuery = generateMonthWiseWorkflowsCountQuery(
    monthWiseTimeRanges,
    memberId,
    true,
  );
  const { data, loading, refetch, networkStatus } =
    useQuery<ExecutionCountData>(monthWiseQuery, {
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: loading
      ? []
      : monthWiseTimeRanges.map((dateRange) => {
          const [key, startDate] = dateRange;
          const monthName = dayjs(startDate).format('MMM');
          const total = formatAggregateCount(data?.[`${key}_total`]);
          const cumulative = formatAggregateCount(data?.[`${key}_cumulative`]);
          return [monthName, { total, cumulative }];
        }),
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useGetMonthWiseExecutionsCount = (
  memberId?: string,
): QueryResult<[string, ExecutionCount][]> => {
  const monthWiseQuery = generateMonthWiseExecutionsCountQuery(
    monthWiseTimeRanges,
    memberId,
    true,
  );
  const { data, loading, refetch, networkStatus } =
    useQuery<ExecutionCountData>(monthWiseQuery, {
      notifyOnNetworkStatusChange: true,
    });

  return {
    data: loading
      ? []
      : monthWiseTimeRanges.map((dateRange) => {
          const [key, startDate] = dateRange;
          const monthName = dayjs(startDate).format('MMM');
          const totalExecutions = data?.[`${key}_total`]?.aggregate?.count ?? 0;
          const successfulExecutions = formatAggregateCount(
            data?.[`${key}_${ExecutionStatusEnum.Success}`],
          );
          const failedExecutions = formatAggregateCount(
            data?.[`${key}_${ExecutionStatusEnum.Failed}`],
          );
          const runningExecutions = formatAggregateCount(
            data?.[`${key}_${ExecutionStatusEnum.Running}`],
          );
          // we don't need queued executions for this
          const queuedExecutions = 0;
          return [
            monthName,
            {
              totalExecutions,
              successfulExecutions,
              failedExecutions,
              runningExecutions,
              queuedExecutions,
            },
          ];
        }),
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

const timeRanges = [
  ['AM12', '00:00:00', '00:59:59'],
  ['AM1', '01:00:00', '01:59:59'],
  ['AM2', '02:00:00', '02:59:59'],
  ['AM3', '03:00:00', '03:59:59'],
  ['AM4', '04:00:00', '04:59:59'],
  ['AM5', '05:00:00', '05:59:59'],
  ['AM6', '06:00:00', '06:59:59'],
  ['AM7', '07:00:00', '07:59:59'],
  ['AM8', '08:00:00', '08:59:59'],
  ['AM9', '09:00:00', '09:59:59'],
  ['AM10', '10:00:00', '10:59:59'],
  ['AM11', '11:00:00', '11:59:59'],
  ['PM12', '12:00:00', '12:59:59'],
  ['PM1', '13:00:00', '13:59:59'],
  ['PM2', '14:00:00', '14:59:59'],
  ['PM3', '15:00:00', '15:59:59'],
  ['PM4', '16:00:00', '16:59:59'],
  ['PM5', '17:00:00', '17:59:59'],
  ['PM6', '18:00:00', '18:59:59'],
  ['PM7', '19:00:00', '19:59:59'],
  ['PM8', '20:00:00', '20:59:59'],
  ['PM9', '21:00:00', '21:59:59'],
  ['PM10', '22:00:00', '22:59:59'],
  ['PM11', '23:00:00', '23:59:59'],
];

type ExecutionCountData = Record<
  string,
  { aggregate?: { count?: number } } | undefined
>;

export interface ExecutionHourlyMetrics {
  executed: number;
  queued: number;
}

export const useGetExecutionHourlyMetrics = (
  memberId?: string,
  dateFilterValue?: DateFilterValue,
): QueryResult<[string, ExecutionHourlyMetrics][]> => {
  const [startDate, endDate] = (dateFilterValue?.dateRange ?? []) as [
    string,
    string,
  ];
  const hasCustomDateRange = startDate && endDate;
  const hourlyQuery = generateExecutionHourlyMetricsQuery(
    hasCustomDateRange
      ? [startDate, endDate]
      : // check for last 30 days
        [sameDateLastMonth.format(), todayDate.format()],
    timeRanges,
    memberId,
  );
  const { data, loading, refetch, networkStatus } =
    useQuery<ExecutionCountData>(hourlyQuery, {
      notifyOnNetworkStatusChange: true,
    });

  const result: [string, ExecutionHourlyMetrics][] = useMemo(() => {
    if (!data) {
      return [];
    }
    return timeRanges.map(([key]) => {
      const executedCount = data[`${key}_executed`]?.aggregate?.count ?? 0;
      const queuedCount = data[`${key}_queued`]?.aggregate?.count ?? 0;
      return [key, { executed: executedCount, queued: queuedCount }];
    });
  }, [data]);

  return {
    data: result,
    loading: loading || networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export const useInsights = (
  memberId?: string,
  dateFilterValue?: DateFilterValue,
) => {
  const {
    maxConcurrentExecutions,
    loading: maxConcurrentExecutionsLoading,
    refetch: refetchTeamMaxConcurrentExecutions,
  } = useGetTeamMaxConcurrentExecutions();
  const { data: teamMembers = [], loading: teamMembersLoading } =
    useGetAuthUserTeamMembers();
  const {
    data: teamMembersWithWorkflowCountData,
    loading: teamMembersWithWorkflowCountLoading,
    refetch: refetchTeamMembersWithWorkflowCount,
  } = useGetTeamMembersWithWorkflowCount();
  const {
    data: workflowsMonthData,
    loading: workflowsMonthLoading,
    refetch: refetchWorkflowsMonth,
  } = useGetTotalAndLastMonthWorkflowsCount(memberId);
  const {
    data: executionsMonthData,
    loading: executionsMonthLoading,
    refetch: refetchExecutionsMonth,
  } = useGetCurrentMonthAndLastMonthExecutionsCount(memberId);
  const {
    data: totalExecutionsData,
    loading: totalExecutionsLoading,
    refetch: refetchTotalExecutions,
  } = useGetAllTimeExecutionsCount(memberId, dateFilterValue);
  const {
    data: monthWiseExecutionsCountData,
    loading: monthWiseExecutionsCountLoading,
    refetch: refetchMonthWiseExecutionsCount,
  } = useGetMonthWiseExecutionsCount(memberId);
  const {
    data: monthWiseWorkflowsCountData,
    loading: monthWiseWorkflowsCountLoading,
    refetch: refetchMonthWiseWorkflowsCount,
  } = useGetMonthWiseWorkflowsCount(memberId);
  const {
    data: executionHourlyMetricsData,
    loading: executionHourlyMetricsLoading,
    refetch: refetchExecutionHourlyMetrics,
  } = useGetExecutionHourlyMetrics(memberId, dateFilterValue);
  const {
    data: workflowsWithExecutionsCount = [],
    loading: workflowsWithExecutionsCountLoading,
    refetch: refetchWorkflowsWithExecutionsCount,
  } = useGetWorkflowsWithExecutionsCount(memberId, dateFilterValue);

  const refetch = () => {
    void refetchTeamMaxConcurrentExecutions();
    refetchTeamMembersWithWorkflowCount();
    refetchWorkflowsMonth();
    refetchExecutionsMonth();
    refetchTotalExecutions();
    refetchMonthWiseExecutionsCount();
    refetchMonthWiseWorkflowsCount();
    refetchExecutionHourlyMetrics();
    refetchWorkflowsWithExecutionsCount();
  };

  return {
    data: {
      maxConcurrentExecutions,
      teamMembers,
      teamMembersWithWorkflowCountData,
      workflowsMonthData,
      executionsMonthData,
      totalExecutionsData,
      monthWiseExecutionsCountData,
      monthWiseWorkflowsCountData,
      executionHourlyMetricsData,
      workflowsWithExecutionsCount,
    },
    loading: {
      maxConcurrentExecutionsLoading,
      teamMembersLoading,
      teamMembersWithWorkflowCountLoading,
      workflowsMonthLoading,
      executionsMonthLoading,
      totalExecutionsLoading,
      monthWiseExecutionsCountLoading,
      monthWiseWorkflowsCountLoading,
      executionHourlyMetricsLoading,
      workflowsWithExecutionsCountLoading,
    },
    refetch,
  };
};
