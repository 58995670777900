"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.captureAnalyticsEventSchema = void 0;
const types_shared_1 = require("types-shared");
exports.captureAnalyticsEventSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            eventName: types_shared_1.z.string(),
            type: types_shared_1.z.enum(['Recording', 'Execution', 'Update']),
            data: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.any()).optional(),
        }),
    }),
};
