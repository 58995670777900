"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunnableWorkflowState = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../extensionTypes");
var commit_schema_1 = require("./commit.schema");
var workflow_schema_1 = require("./workflow.schema");
var legacy_schema_1 = require("./variable/legacy.schema");
var runnable_commit_helper_1 = require("./runnable-commit.helper");
var legacy_schema_2 = require("./actions/legacy.schema");
exports.RunnableWorkflowState = commit_schema_1.CommitWorkflowState.refine(
// Ensure all nodes are of valid types
function (data) {
    var invalidNodeTypes = [workflow_schema_1.WorkflowNewNode];
    var validNodes = data.nodes.filter(function (node) { return !invalidNodeTypes.some(function (type) { return type.safeParse(node).success; }); });
    return validNodes.length === data.nodes.length;
}, {
    message: "Workflow won't execute with an empty step.",
})
    // Ensure all nodes are either datasource nodes, or checked nodes
    .refine(function (data) {
    return data.nodes.every(function (node) {
        return workflow_schema_1.WorkflowSourceNode.safeParse(node).success ||
            node.data.nodeStatus === workflow_schema_1.NodeStatusEnum.Checked;
    });
}, {
    message: 'Not all steps have been reviewed.',
})
    // Ensure all conditions for conditional nodes
    .superRefine(function (data, ctx) {
    var conditionalNodes = data.nodes.filter(function (node) { return workflow_schema_1.WorkflowConditionalNode.safeParse(node).success; });
    var invalidNodes = [];
    for (var _i = 0, conditionalNodes_1 = conditionalNodes; _i < conditionalNodes_1.length; _i++) {
        var node = conditionalNodes_1[_i];
        var branchesData = node.data.branchesData;
        if (!branchesData || branchesData.length === 0) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: 'A conditional step with no branches is not valid.',
                path: ['nodes', node.id, 'branches'],
            });
            invalidNodes.push(node);
            continue;
        }
        for (var _a = 0, branchesData_1 = branchesData; _a < branchesData_1.length; _a++) {
            var branch = branchesData_1[_a];
            var rule = branch.rule, branchId = branch.branchId, selectedMode = branch.selectedMode;
            if (selectedMode === workflow_schema_1.BranchModeEnum.Rule) {
                if (!rule) {
                    ctx.addIssue({
                        code: zod_1.z.ZodIssueCode.custom,
                        message: 'A conditional branch with no rule is not valid.',
                        path: ['nodes', node.id, 'branches', branchId, 'rule'],
                    });
                    return zod_1.z.NEVER;
                }
                try {
                    var validGroup = (0, runnable_commit_helper_1.evaluateGroupValidity)(rule.data);
                    if (!validGroup) {
                        ctx.addIssue({
                            code: zod_1.z.ZodIssueCode.custom,
                            message: 'Invalid rule found in a conditional branch.',
                            path: ['nodes', node.id, 'branches', branchId, 'rule'],
                        });
                        return zod_1.z.NEVER;
                    }
                }
                catch (e) {
                    ctx.addIssue({
                        code: zod_1.z.ZodIssueCode.custom,
                        message: "Invalid rule found in a conditional branch.",
                        path: ['nodes', node.id, 'branches', branchId, 'rule'],
                    });
                    return zod_1.z.NEVER;
                }
            }
        }
    }
})
    // Ensure all conditional actions have valid statements
    .superRefine(function (data, ctx) {
    var imageNodes = data.nodes.filter(function (node) { return workflow_schema_1.WorkflowImageNode.safeParse(node).success; });
    for (var _i = 0, imageNodes_1 = imageNodes; _i < imageNodes_1.length; _i++) {
        var node = imageNodes_1[_i];
        var actionData = node.data.actionData;
        for (var _a = 0, _b = Object.values(actionData); _a < _b.length; _a++) {
            var action = _b[_a];
            var criteria = action.criteria, rules = action.rules;
            if (criteria !== legacy_schema_2.ActionValueCriteriaEnum.Condition) {
                return true;
            }
            if (!rules) {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.custom,
                    message: 'A conditional action with no rules is not valid.',
                    path: ['nodes', node.id, 'actionData', action.id, 'rules'],
                });
                return zod_1.z.NEVER;
            }
            for (var _c = 0, rules_1 = rules; _c < rules_1.length; _c++) {
                var rule = rules_1[_c];
                var validGroup = (0, runnable_commit_helper_1.evaluateGroupValidity)(rule.data);
                if (!validGroup) {
                    ctx.addIssue({
                        code: zod_1.z.ZodIssueCode.custom,
                        message: 'An invalid rule was found in an conditional action.',
                        path: ['nodes', node.id, 'actionData', action.id, 'rules'],
                    });
                    return zod_1.z.NEVER;
                }
            }
        }
    }
})
    // Ensure all upload document actions have valid variables
    .superRefine(function (data, ctx) {
    var _a;
    var uploadDocumentActions = data.nodes.flatMap(function (node) {
        var parsedNode = workflow_schema_1.WorkflowImageNode.safeParse(node);
        if (parsedNode.success) {
            return Object.values(parsedNode.data.data.actionData).filter(function (action) { return action.actionType === extensionTypes_1.ActionsEnum.UploadDocument; });
        }
        return [];
    });
    for (var _i = 0, uploadDocumentActions_1 = uploadDocumentActions; _i < uploadDocumentActions_1.length; _i++) {
        var action = uploadDocumentActions_1[_i];
        var variableId = action.variableId;
        if (!variableId || !(variableId in data.variables)) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: 'An upload document action has no document to upload.',
                path: [action.id, 'variableId'],
            });
            return zod_1.z.NEVER;
        }
        var variable = legacy_schema_1.DocumentVariable.parse(data.variables[variableId]);
        if (variable.data.source === legacy_schema_1.DocumentSourceEnum.AWS &&
            !variable.data.s3Ref) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: 'An upload document action has no source document.',
                path: [action.id, 'variableId'],
            });
            return zod_1.z.NEVER;
        }
        else if (variable.data.source === legacy_schema_1.DocumentSourceEnum.URL &&
            !((_a = variable.data.url) === null || _a === void 0 ? void 0 : _a.filter(function (a) { return Boolean(a); }).length)) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: 'An upload document action has no source URL.',
                path: [action.id, 'variableId'],
            });
            return zod_1.z.NEVER;
        }
    }
});
// Ensure all scrape variables are valid, being defined before being used
// FIXME (neil): We should bring this back
// .superRefine((data, ctx) => {
//   const { nodeVariableMap } = parseVariablesFromWorkflow(
//     data.nodes,
//     data.variables,
//   );
//   Object.entries(nodeVariableMap).forEach(([nodeId, actionVariableMap]) => {
//     if (Array.isArray(actionVariableMap)) {
//       actionVariableMap.forEach((variableId) => {
//         const variable = data.variables[variableId as string];
//         if (!ScrapeVariable.safeParse(variable).success) return;
//         const valid = evaluateScrapeVariableValidity(
//           data.nodes,
//           data.edges,
//           nodeId,
//           variableId as string,
//         );
//         if (!valid) {
//           ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: 'A scrape variable was used before it was scraped.',
//             path: [nodeId, variableId],
//           });
//           return z.NEVER;
//         }
//       });
//     } else {
//       Object.entries(actionVariableMap as Record<string, string[]>).forEach(
//         ([actionId, variableIds]) => {
//           const node = WorkflowImageNode.parse(
//             data.nodes.find((n) => n.id === nodeId),
//           );
//           const actionType = node.data.actionData[actionId].actionType;
//           if (actionType === ActionsEnum.Scrape) return;
//           variableIds.forEach((variableId) => {
//             const variable = data.variables[variableId];
//             if (!ScrapeVariable.safeParse(variable).success) return;
//             const valid = evaluateScrapeVariableValidity(
//               data.nodes,
//               data.edges,
//               nodeId,
//               variableId,
//               actionId,
//             );
//             if (!valid) {
//               ctx.addIssue({
//                 code: z.ZodIssueCode.custom,
//                 message: 'A scrape variable was used before it was scraped.',
//                 path: [nodeId, actionId, variableId],
//               });
//               return z.NEVER;
//             }
//           });
//         },
//       );
//     }
//   });
// });
