import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import { ComponentsToolbar } from '../../Toolbar/ComponentsToolbar';
import { EditorStore } from '../../../store/EditorState';

interface Props {
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  onCancel: () => void;
}

export default function VersionHistoryToolbar({
  workflowMetadata,
  onCancel,
}: Props) {
  const { workflowId } = EditorStore();

  return (
    <ComponentsToolbar
      hasOuterContainer={false}
      containerClassName="zoom-adjusted-container !py-4"
      crumbs={[
        {
          title: `${workflowMetadata?.workflowName ?? 'Workflow'} - Flow view`,
          link: workflowId ? `/editor/${workflowId}` : undefined,
          onClick: onCancel,
        },
        { title: 'Version history (View only)' },
      ]}
      onClose={onCancel}
    />
  );
}
