"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scanSchema = void 0;
const types_shared_1 = require("types-shared");
exports.scanSchema = {
    request: types_shared_1.z.object({
        sender: types_shared_1.z.object({
            tab: types_shared_1.z.object({ id: types_shared_1.z.number(), windowId: types_shared_1.z.number() }),
        }),
    }),
    response: types_shared_1.z.object({
        dataUrl: types_shared_1.z.string(),
    }),
};
