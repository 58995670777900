"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowMetadata = exports.AdminVersion = exports.AdminVersionEnum = exports.VersionConfigurations = exports.WorkflowStatus = exports.WorkflowStatusEnum = void 0;
const types_shared_1 = require("types-shared");
var WorkflowStatusEnum;
(function (WorkflowStatusEnum) {
    WorkflowStatusEnum["ProcessingImport"] = "processing_import";
    WorkflowStatusEnum["ReviewingImport"] = "reviewing_import";
    WorkflowStatusEnum["ProcessedImport"] = "processed_import";
    WorkflowStatusEnum["ProcessingImportError"] = "processing_import_error";
    WorkflowStatusEnum["ProcessingImportErrorAuthentication"] = "processing_import_error_authentication";
    WorkflowStatusEnum["Ready"] = "ready";
    WorkflowStatusEnum["Invalid"] = "invalid";
})(WorkflowStatusEnum || (exports.WorkflowStatusEnum = WorkflowStatusEnum = {}));
exports.WorkflowStatus = types_shared_1.z.nativeEnum(WorkflowStatusEnum);
exports.VersionConfigurations = types_shared_1.z.object({
    stateVersion: types_shared_1.z.string(),
    targetsVersion: types_shared_1.z.string(),
    variablesVersion: types_shared_1.z.string(),
    status: exports.WorkflowStatus.default(WorkflowStatusEnum.ProcessingImport),
    committedAt: types_shared_1.z.string().optional(),
    versionId: types_shared_1.z.string().optional(),
    commitUsers: types_shared_1.z.array(types_shared_1.z.string()).optional(),
});
var AdminVersionEnum;
(function (AdminVersionEnum) {
    AdminVersionEnum["SilentPush"] = "SilentPush";
    AdminVersionEnum["ForcePush"] = "ForcePush";
    AdminVersionEnum["NotifyPush"] = "NotifyPush";
    AdminVersionEnum["ProcessingPush"] = "ProcessingPush";
    AdminVersionEnum["ErrorForcePush"] = "ErrorForcePush";
})(AdminVersionEnum || (exports.AdminVersionEnum = AdminVersionEnum = {}));
exports.AdminVersion = types_shared_1.z.nativeEnum(AdminVersionEnum);
exports.WorkflowMetadata = types_shared_1.z.object({
    userId: types_shared_1.z.string(),
    email: types_shared_1.z.string().optional(),
    workflowId: types_shared_1.z.string(),
    createdAt: types_shared_1.z.string(),
    updatedAt: types_shared_1.z.string().optional(),
    workflowName: types_shared_1.z.string(),
    deletedAt: types_shared_1.z.string().optional(),
    committedAt: types_shared_1.z.string().optional(),
    status: exports.WorkflowStatus.optional(),
    saveCookies: types_shared_1.z.boolean().optional(),
    proxyMode: types_shared_1.z
        .union([
        types_shared_1.z
            .boolean()
            .transform((bool) => bool ? types_shared_1.ProxyModeDefaultEnum.Any : types_shared_1.ProxyModeDefaultEnum.None),
        types_shared_1.z.string(),
    ])
        .optional(),
    versionConfigs: types_shared_1.z.record(types_shared_1.z.string(), exports.VersionConfigurations).optional(),
    currentVersionId: types_shared_1.z.string().optional(),
    currentVersionCommitUsers: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    updates: types_shared_1.z
        .object({
        issuesFound: types_shared_1.z.array(types_shared_1.z.string()).optional(),
        improvements: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    })
        .optional(),
});
