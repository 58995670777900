import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Flex,
  FormControlLabel,
  GrayedOutInput,
  InfoOutlined,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Tooltip,
  WarningAmberOutlined,
} from 'ui-kit';
import { handleException } from 'sentry-browser-shared';
import {
  useGetWorkflowConcurrencyLimit,
  useUpdateWorkflowConcurrentLimit,
} from '../hooks/useWorkflowConcurrency.gql';
import isNil from 'lodash/isNil';
import { clsx } from 'clsx';
import {
  useGetBlockedConcurrencyLimitWorkflows,
  useGetOrchestrationMetrics,
} from '../../Orchestration/hooks';
import { isAdmin } from '../../../utils/env';

interface Props {
  onCancel: () => void;
  workflowId: string;
}

enum LimitType {
  Max = 'max',
  Custom = 'custom',
}

export default function ConcurrencyLimitModal({ onCancel, workflowId }: Props) {
  const [limitType, setLimitType] = useState<LimitType>(LimitType.Max);
  const [limit, setLimit] = useState<number | null | undefined>(null);
  const { data, loading: loadingLimit } =
    useGetWorkflowConcurrencyLimit(workflowId);
  const { mutateAsync, loading: updatingLimit } =
    useUpdateWorkflowConcurrentLimit();
  const { data: orchestrationMetrics } = useGetOrchestrationMetrics();
  const blockedWorkflows = useGetBlockedConcurrencyLimitWorkflows();

  const isEditBlocked = useMemo(
    () => blockedWorkflows.includes(workflowId),
    [blockedWorkflows, workflowId],
  );

  const maxConcurrentExecutions = orchestrationMetrics.maxConcurrentExecutions;

  const isMaxLimit = limitType === LimitType.Max;
  const isCustomLimit = limitType === LimitType.Custom;
  const isLimitGreaterThanMax =
    !isAdmin && limit && limit > maxConcurrentExecutions;

  const isDisabled = useMemo(() => {
    return (
      limit === undefined ||
      limit === data ||
      isLimitGreaterThanMax ||
      loadingLimit ||
      updatingLimit
    );
  }, [limit, data, isLimitGreaterThanMax, loadingLimit, updatingLimit]);

  const onSubmit = useCallback(async () => {
    if (limit === undefined) return;

    try {
      await mutateAsync({
        workflowId,
        limit,
      });
    } catch (error) {
      handleException(error, {
        name: 'Set concurrency limit',
        source: 'ConcurrencyLimitModal',
        extra: {
          workflowId,
          limit,
        },
      });
    }
    onCancel();
  }, [onCancel, mutateAsync, workflowId, limit]);

  const handleLimitTypeChange = (val: LimitType) => {
    if (isEditBlocked || val === limitType) return;
    setLimitType(val);
    if (val === LimitType.Max) {
      setLimit(null);
    } else {
      setLimit(data);
    }
  };

  useEffect(() => {
    setLimit(data ?? null);
    setLimitType(isNil(data) ? LimitType.Max : LimitType.Custom);
  }, [data]);

  return (
    <Modal
      className="w-full !max-w-4xl !p-0"
      onClose={onCancel}
      open
      closeBtnClassName="!top-6"
    >
      <div>
        <h2 className="text-navy-blue text-lg font-medium">
          Workflow concurrency limit
        </h2>
        <p className="text-sm text-color-grey mt-2 mr-6">
          Set the maximum number of virtual machines allowed to run executions
          for this workflow at the same time. Note that the execution queue is
          organized chronologically.
        </p>
      </div>

      {isEditBlocked ? (
        <div className="bg-warning-light mt-8 flex space-x-3 rounded-lg px-4 py-3 w-full">
          <WarningAmberOutlined className="text-warning" />
          <div className="flex flex-col">
            <p className="text-warning-dark text-base font-medium">
              This limit can’t be changed
            </p>
            <p className="text-warning-dark text-sm">
              Sola has determined this to be the maximum concurrency. Contact us
              to understand why or request a change!
            </p>
          </div>
        </div>
      ) : null}

      <div className="mt-7 px-2 pointer-events-auto z-[199999]">
        <RadioGroup name="limit-type" value={limitType}>
          <FormControlLabel
            className="!items-start"
            classes={{ label: 'w-full' }}
            control={<Radio color="secondary" disabled={isEditBlocked} />}
            label={
              <div className="flex flex-col w-full">
                <p>Use maximum available</p>
                <p className="text-xs text-color-grey">
                  The available machines will be distributed among all running
                  executions.
                </p>
                {isMaxLimit && !isEditBlocked ? (
                  <div className="bg-warning-light mt-3 flex space-x-3 rounded-lg px-4 py-3 w-full">
                    <WarningAmberOutlined className="text-warning" />
                    <p className="text-warning-dark text-sm">
                      High workflow volume can temporarily congest the queue.
                    </p>
                  </div>
                ) : null}
              </div>
            }
            value={LimitType.Max}
            onClick={() => {
              handleLimitTypeChange(LimitType.Max);
            }}
          />
          <div className="mt-6" />
          <FormControlLabel
            className={clsx({ '!items-start': isCustomLimit })}
            control={<Radio color="secondary" disabled={isEditBlocked} />}
            label={
              <div className="flex flex-col">
                <p>Custom limit</p>
                {isCustomLimit ? (
                  <>
                    {isEditBlocked ? (
                      <GrayedOutInput
                        className="!min-w-100 !mt-3"
                        label="Virtual machines limit"
                        value={String(limit)}
                      />
                    ) : (
                      <div className="flex items-center space-x-4 mt-3">
                        <Input
                          disabled={isEditBlocked}
                          classes={{ wrapper: '!min-w-100' }}
                          label={`Concurrent machines limit${isAdmin ? '' : ` (up to ${String(maxConcurrentExecutions)})`}`}
                          floatingLabel
                          value={limit}
                          type="number"
                          inputProps={{ min: 0 }}
                          onChange={(val) => {
                            if (val === '') {
                              setLimit(null);
                            } else {
                              const num = Number(val);
                              if (
                                !Number.isNaN(num) &&
                                Number.isInteger(num) &&
                                num >= 0
                              ) {
                                setLimit(num);
                              } else {
                                setLimit(undefined);
                              }
                            }
                          }}
                          error={Boolean(isLimitGreaterThanMax)}
                          errorText="The number of machines should be less than the cluster maximum"
                        />
                        {isLimitGreaterThanMax ? (
                          <Tooltip
                            arrow
                            placement="right"
                            title="Contact sales to increase your machine count and enhance execution throughput!"
                          >
                            <InfoOutlined color="primary" />
                          </Tooltip>
                        ) : null}
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            }
            value={LimitType.Custom}
            onClick={() => {
              handleLimitTypeChange(LimitType.Custom);
            }}
          />
        </RadioGroup>
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={loadingLimit || updatingLimit}
          disabled={isDisabled}
          onClick={onSubmit}
          variant="contained"
        >
          Save Changes
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
