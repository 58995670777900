"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDatasourceSchema = void 0;
const types_shared_1 = require("types-shared");
exports.updateDatasourceSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.DatasourceMetadata.pick({
            datasourceId: true,
        }),
        query: types_shared_1.z.object({}),
        body: types_shared_1.DatasourceMetadata.pick({
            name: true,
            description: true,
        }),
    }),
    response: types_shared_1.DatasourceMetadata,
};
