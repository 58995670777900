import {
  Menu,
  MenuItem,
  DoNotDisturbIcon,
  Tooltip,
  ReplayIcon,
  Edit,
} from 'ui-kit';
import CircularProgress from '@mui/material/CircularProgress';
import { clsx } from 'clsx';
import { useFeatureFlag } from '../../../utils/helper';
import { FeatureFlag, terminalStatuses } from '../../../utils/constants';
import { useMemo } from 'react';
import { type ExecutionBase } from 'types-shared/executionTypes';
import { isAdmin } from '../../../utils/env';

export interface WorkflowDetailsMenuProps {
  el: HTMLButtonElement | null;
  execution: ExecutionBase;
  cantCancel: boolean;
}

interface CancellingExecutionProps {
  menuProps: WorkflowDetailsMenuProps;
  cancellingExecutionIds: string[];
  closeMenu: () => void;
  handleCancelExecutions: () => void;
  handleRetryExecutions: () => void;
  handleEditExecution: () => void;
  retryingExecution: boolean;
}

function WorkflowDetailsMenu({
  menuProps: { el, cantCancel, execution },
  cancellingExecutionIds,
  closeMenu,
  handleCancelExecutions,
  handleRetryExecutions,
  handleEditExecution,
  retryingExecution,
}: CancellingExecutionProps) {
  const alreadyCancelled = cantCancel;
  const cancellingNow = useMemo(
    () => cancellingExecutionIds.includes(execution.executionId),
    [execution.executionId, cancellingExecutionIds],
  );
  const allowExecutionReplay = useFeatureFlag(FeatureFlag.RetryExecutions);
  const allowExecutionRetry = useMemo(
    () => terminalStatuses.includes(execution.status),
    [execution.status],
  );
  const cannotCancel = alreadyCancelled || cancellingNow;

  return (
    <Menu
      anchorEl={el}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => {
        closeMenu();
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      open={Boolean(el)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        className="min-w-64 h-8 !px-4"
        onClick={
          cannotCancel
            ? undefined
            : () => {
                handleCancelExecutions();
              }
        }
      >
        <Tooltip
          hidden={!cannotCancel}
          arrow
          placement="right"
          title={
            cancellingNow
              ? 'This execution is already attempting to cancel.'
              : "This execution already finished and can't be cancelled."
          }
          containerClassName="w-full"
        >
          <div className="w-full flex flex-row items-center justify-between">
            <span
              className={clsx({
                'font-normal mr-4': true,
                'text-gray-400': cannotCancel,
              })}
            >
              Cancel execution
            </span>

            {cancellingNow ? (
              <CircularProgress
                // color="error"
                className="text-gray-400"
                size={20}
                thickness={5}
                sx={{ scale: '-1 1' }}
                variant="indeterminate"
              />
            ) : (
              <DoNotDisturbIcon
                className={clsx({
                  'text-gray-400': cannotCancel,
                })}
              />
            )}
          </div>
        </Tooltip>
      </MenuItem>
      {isAdmin ? (
        <MenuItem className="min-w-64 h-8 !px-4" onClick={handleEditExecution}>
          <div className="w-full flex flex-row items-center justify-between">
            <span className="font-normal mr-4">Edit execution</span>
            <Edit className="!w-4 !h-4" />
          </div>
        </MenuItem>
      ) : null}
      {allowExecutionReplay ? (
        <MenuItem
          className="min-w-64 h-8 !px-4"
          onClick={
            !allowExecutionRetry
              ? undefined
              : () => {
                  handleRetryExecutions();
                }
          }
        >
          <Tooltip
            hidden={allowExecutionRetry}
            arrow
            placement="right"
            title="This execution has not finished yet and can't be re-run."
            containerClassName="w-full"
          >
            <div className="w-full flex flex-row items-center justify-between">
              <span
                className={clsx({
                  'font-normal mr-4': true,
                  'text-gray-400': !allowExecutionRetry,
                })}
              >
                Retry Execution
              </span>

              {retryingExecution ? (
                <CircularProgress
                  // color="error"
                  className="text-gray-400"
                  size={20}
                  thickness={5}
                  sx={{ scale: '-1 1' }}
                  variant="indeterminate"
                />
              ) : (
                <ReplayIcon
                  className={clsx('!-scale-x-100 !rotate-45', {
                    'text-gray-400': !allowExecutionRetry,
                  })}
                />
              )}
            </div>
          </Tooltip>
        </MenuItem>
      ) : null}
    </Menu>
  );
}

export default WorkflowDetailsMenu;
