import { clsx } from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { handleException } from 'sentry-browser-shared';
import type {
  Condition,
  DatasourceMetadata,
  DatasourceTable,
  GlobalVariable,
  Rule,
  SourceTypeEnum,
  TemplateData,
  Variable,
  VariableMap,
} from 'types-shared';
import { OperatorEnum } from 'types-shared';
import { Button, CloseCircle, IconButton } from 'ui-kit';
import { v4 as uuid } from 'uuid';
import { type ImageNodeValidationResult } from '../../utils/nodeValidations';
import ConditionalInput from './ConditionalInput';
import GroupBlock from './GroupBlock';
import { Bordered, ConditionalLabel, Padded, Separator } from './UiElements';
import {
  addConditionHelper,
  copyVariable,
  deleteConditionHelper,
  extractLabel,
  initialGroup,
  updateConditionHelper,
} from './conditions.helpers';

interface Props {
  variable: Variable;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  sourceType?: SourceTypeEnum;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  defaultRules?: Rule[];
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  onCancel: () => void;
  onSave: (rules: Rule[]) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  preview?: boolean;
  validationData: ImageNodeValidationResult;
  validationAttempted: boolean;
}

export function ConditionalField({
  variable,
  variablesMap,
  globalVariablesMap,
  datasourceMetadata,
  tableData,
  addVariable,
  updateVariable,
  defaultRules,
  onSave,
  onCancel: _onCancel,
  transformApiReqStatus,
  onTransformApiReq,
  preview,
  sourceType,
  validationData,
  validationAttempted,
}: Props) {
  const defaultElseRule = useMemo(() => {
    return {
      data: {
        id: uuid(),
        operator: OperatorEnum.Or,
        elements: [],
      },
      output: [{ variableId: variable.id }],
    } as Rule;
  }, [variable.id]);

  const [showingVariableModal, setShowingVariableModal] =
    useState<boolean>(false);
  const label = extractLabel(variable);
  const [elseRule, setElseRule] = useState<Rule>();

  const [rules, setRules] = useState<Rule[]>();

  const generateDefaultRule = useCallback(() => {
    return [
      {
        data: initialGroup(addVariable),
        output: [
          {
            // To copy all the initial properties of the original variable, then create a new template variable
            // that we can assign to only this particular output.
            variableId: copyVariable(variablesMap[variable.id], addVariable).id,
          },
        ],
      },
    ];
    // Causing infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addVariable]);

  useEffect(() => {
    const needsRules = !rules?.length;
    if (!needsRules) {
      return;
    }

    const rulesPayload = defaultRules?.slice(0, -1) ?? generateDefaultRule();

    setRules(rulesPayload);
  }, [variable, generateDefaultRule, rules, defaultRules]);

  useEffect(() => {
    if (elseRule) {
      return;
    }
    if (!defaultRules) {
      setElseRule(defaultElseRule);
    } else {
      setElseRule(defaultRules.slice(-1)[0]);
    }
  }, [defaultRules, defaultElseRule, elseRule]);

  const addGroup = () => {
    setRules((r) => {
      if (!r) {
        handleException(new Error(), {
          name: 'No rules during addGroup',
          source: 'ConditionalField/addGroup',
          extra: {
            rules,
            r,
          },
        });
      }
      return [
        ...(r ?? []),
        {
          data: initialGroup(addVariable),
          output: [
            {
              // To copy all the initial properties of the original variable, then create a new template variable
              // that we can assign to only this particular output.
              variableId: copyVariable(variablesMap[variable.id], addVariable)
                .id,
            },
          ],
        },
      ];
    });
  };

  const addCondition = (
    i: number,
    groupId: string,
    operator: OperatorEnum,
    andConditionId?: string,
  ) => {
    setRules((r) => {
      if (!r) {
        handleException(new Error(), {
          name: 'No rules during addCondition',
          source: 'ConditionalField/addCondition',
          extra: {
            rules,
            r,
            i,
            groupId,
            operator,
            andConditionId,
          },
        });
      }
      return r?.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: addConditionHelper(
              rule.data,
              groupId,
              operator,
              addVariable,
              andConditionId,
            ),
          };
        }
        return rule;
      });
    });
  };

  const deleteCondition = (i: number, conditionId: string) => {
    setRules((r) => {
      if (!r) {
        handleException(new Error(), {
          name: 'No rules during deleteCondition',
          source: 'ConditionalField/deleteCondition',
          extra: {
            rules,
            r,
            i,
            conditionId,
          },
        });
        return r;
      }
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: deleteConditionHelper(rule.data, conditionId),
          };
        }
        return rule;
      });
    });
  };

  const updateCondition = (
    i: number,
    conditionId: string,
    condition: Partial<Condition>,
  ) => {
    setRules((r) => {
      if (!r) {
        handleException(new Error(), {
          name: 'No rules during updateCondition',
          source: 'ConditionalField/updateCondition',
          extra: {
            rules,
            r,
            i,
            conditionId,
            condition,
          },
        });
        return r;
      }
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: updateConditionHelper(rule.data, conditionId, condition),
          };
        }
        return rule;
      });
    });
  };

  const saveChanges = () => {
    const hasRules = rules?.length;

    let changesToSave: Rule[] = [];
    if (hasRules && elseRule) {
      changesToSave = [...rules, elseRule];
    } else if (hasRules && !elseRule) {
      changesToSave = [...rules];
    } else if (!hasRules && elseRule) {
      changesToSave = [elseRule];
    }
    onSave(changesToSave);
  };

  const onDeleteRule = (i: number) => {
    setRules((r) => {
      if (!r) {
        handleException(new Error(), {
          name: 'No rules during onDeleteRule',
          source: 'ConditionalField/onDeleteRule',
          extra: {
            rules,
            r,
            i,
          },
        });
        return r;
      }
      return r.filter((_, index) => index !== i);
    });
  };

  const onCancel = () => {
    setRules(
      defaultRules ?? [
        {
          data: initialGroup(addVariable),
          output: [variable],
        },
      ],
    );
    _onCancel();
  };

  return (
    <div
      className={clsx(
        { 'pointer-events-none opacity-60': preview },
        { 'h-0 overflow-hidden': showingVariableModal },
      )}
    >
      <p className="text-color-secondary-text mb-6">
        Determine selection using conditional logic
      </p>
      <Bordered>
        {rules?.map((r, index) => {
          return (
            <React.Fragment key={r.data.id}>
              <Padded>
                <ConditionalLabel>
                  <span>{label}</span>
                  {rules.length > 1 ? (
                    <IconButton
                      className="!text-info-dark cursor-pointer"
                      onClick={() => {
                        onDeleteRule(index);
                      }}
                    >
                      <CloseCircle />
                    </IconButton>
                  ) : null}
                </ConditionalLabel>
                <ConditionalInput
                  datasourceMetadata={datasourceMetadata}
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  rule={r}
                  sourceType={sourceType}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  validationData={validationData}
                  validationAttempted={validationAttempted}
                />
                <ConditionalLabel className="mb-4">When</ConditionalLabel>
                <GroupBlock
                  // addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  group={r.data}
                  onAddCondition={(...props) => {
                    addCondition(index, ...props);
                  }}
                  onDeleteCondition={(...props) => {
                    deleteCondition(index, ...props);
                  }}
                  onTransformApiReq={onTransformApiReq}
                  onUpdateCondition={(...props) => {
                    updateCondition(index, ...props);
                  }}
                  setShowingModal={setShowingVariableModal}
                  tableData={tableData}
                  transformApiReqStatus={transformApiReqStatus}
                  sourceType={sourceType}
                  updateVariable={updateVariable}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  branchValidations={
                    validationAttempted
                      ? validationData.validations.variablesThatHaveNoContent
                      : {}
                  }
                />
              </Padded>
              <Separator />
            </React.Fragment>
          );
        })}

        <Padded>
          <ConditionalLabel className="mb-4">{`Or ${label.toLowerCase()}`}</ConditionalLabel>
          <Button
            color="secondary"
            onClick={() => {
              addGroup();
            }}
            variant="text"
          >
            Add value
          </Button>
        </Padded>

        <Separator />
        <Padded>
          <ConditionalLabel>{`Else ${label.toLowerCase()}`}</ConditionalLabel>
          <ConditionalInput
            datasourceMetadata={datasourceMetadata}
            onChange={(updatedVariable) => {
              updateVariable(updatedVariable);
            }}
            rule={elseRule ?? defaultElseRule}
            sourceType={sourceType}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            validationData={validationData}
            validationAttempted={validationAttempted}
          />
        </Padded>
      </Bordered>

      {!preview ? (
        <div className="flex w-full gap-9 pt-6 bottom-0 z-50 pb-[40px]">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={saveChanges}
            variant="contained"
          >
            Save changes
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      ) : null}
    </div>
  );
}
