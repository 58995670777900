import { useEffect, useMemo, useRef } from 'react';
import { handleException } from 'sentry-browser-shared';
import {
  isVariableAllowedToAddInInput,
  NodeStatusEnum,
  SourceTypeEnum,
  TemplateVariable,
  WorkflowFreeformNode,
  type DatasourceMetadata,
  type GlobalVariable,
  type TemplateData,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { Input } from 'ui-kit';
import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';
import { useNodeValidation } from '../../hooks/useNodeValidation';
import nodeValidations, {
  type FreeformNodeValidationResult,
} from '../../utils/nodeValidations';
import NodeCheckV2 from '../NodeCheckV2';
import NonImageNodeWrapper from '../NonImageNodeWrapper';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';

const validateFreeformNode = nodeValidations.freeform;

interface Props {
  node: WorkflowFreeformNode;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateNodeName: (name: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
  sourceType?: SourceTypeEnum;
  datasourceMetadata: DatasourceMetadata | null;
  updateVariable: (variable: Variable) => void;
  workflowId?: string;
}

export function FreeformBlock({
  node,
  variablesMap,
  globalVariablesMap,
  onCancel,
  updateNodeName,
  updateNodeStatus,
  sourceType,
  datasourceMetadata,
  updateVariable,
  workflowId,
}: Props) {
  const inputRef = useRef<VariableInputRef>();
  const prevInputData = useRef<TemplateData>([]);

  const hasDatasource = Boolean(datasourceMetadata);

  const { validationResult, validationAttempted, handleValidateNode } =
    useNodeValidation<FreeformNodeValidationResult>({
      node,
      variablesMap,
      globalVariablesMap,
      updateNodeStatus,
      validationFunction: validateFreeformNode,
      workflowId: workflowId ?? '',
    });

  const instructions = useMemo(() => {
    if (
      !WorkflowFreeformNode.safeParse(node).success ||
      !node.data.instructions.variableId
    ) {
      return [];
    }
    const instructionsVariable =
      variablesMap[node.data.instructions.variableId];
    if (!TemplateVariable.safeParse(instructionsVariable).success) {
      handleException(
        new Error('freeform instruction variable is not a template variable'),
        {
          name: 'FreeformBlock',
          source: 'FreeformBlock',
          extra: {
            variableId: node.data.instructions.variableId,
            instructionsVariable,
            variablesMap,
          },
        },
      );
      return [];
    }
    return instructionsVariable.data as TemplateData;
  }, [variablesMap, node]);

  const handleUpdateNodeInstructions = (data: TemplateData) => {
    const instructionsVariable = variablesMap[
      node.data.instructions.variableId
    ] as TemplateVariable;

    updateVariable({
      ...instructionsVariable,
      data,
    });
  };

  const updateBranchData = (variableToAdd: Variable) => {
    if (!isVariableAllowedToAddInInput(variableToAdd)) {
      return;
    }
    const data: TemplateData = [{ id: variableToAdd.id }];
    const instructionsVariable = variablesMap[
      node.data.instructions.variableId
    ] as TemplateVariable;

    updateVariable({
      ...instructionsVariable,
      data: instructionsVariable.data.length
        ? [...instructionsVariable.data, ...data]
        : data,
    });
  };

  useEffect(() => {
    if (instructions.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = instructions;
      inputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructions, prevInputData.current]);

  return (
    <NonImageNodeWrapper node={node} onClose={onCancel}>
      <div className="node-block absolute bg-white rounded-lg flex flex-col justify-between space-y-5">
        <div className="overflow-auto pb-20">
          <NodeCheckV2
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            onCheck={handleValidateNode}
            errors={validationAttempted ? validationResult.errors : []}
            showErrors={validationAttempted}
            hidden={node.hideFromUser}
            noTopMargin
          />

          <div className="my-6 pl-8 pr-8">
            <h2 className="text-lg font-medium">Freeform Instructions</h2>
            <p className="text-sm font-normal mt-2 text-info-dark">
              For complex or unsupported actions, provide instructions or
              requests so that we can handle them in your workflow.
            </p>
          </div>
          <div className="freeform-block flex-1 flex flex-col gap-4 pl-8 pr-8">
            <div className="relative pt-1">
              <Input
                InputProps={{ className: '!py-1' }}
                label={
                  <label
                    className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 mt-0.5 text-gray-400"
                    htmlFor="name"
                  >
                    Step Name
                  </label>
                }
                onChange={updateNodeName}
                placeholder="Step Name"
                value={node.name ?? ''}
              />
            </div>
            <div className="flex flex-row items-center mt-6 justify-between">
              <p className="font-medium text-info-dark text-sm">Instructions</p>
            </div>
            <div className="relative pt-1">
              <VariableInput
                ref={inputRef}
                isHighlighted={Boolean(
                  validationAttempted &&
                    validationResult.validations.instructionsMissing,
                )}
                willRerender
                multiline
                label="Instructions"
                onChange={(val) => {
                  if (val.length === 0) {
                    handleUpdateNodeInstructions(['']);
                  } else {
                    handleUpdateNodeInstructions(val);
                  }
                }}
                value={instructions}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                placeholder="Provide detailed instructions for this step in your workflow"
                className="!min-h-[174px]"
                allowAddVariable={
                  hasDatasource ||
                  sourceType === SourceTypeEnum.API ||
                  sourceType === SourceTypeEnum.EmailTrigger
                }
                onClickVariableChip={(variableId) => {
                  openPreviewVariableModal({
                    variableId,
                  });
                }}
                onClickAddNew={(indexForVariableInsert) => {
                  openAddVariableModal({
                    insertVariable(newVariable: Variable) {
                      inputRef.current?.addVariable(
                        newVariable,
                        indexForVariableInsert,
                      );
                    },
                    updateBranchData,
                  });
                }}
              />
            </div>
          </div>

          <div className="px-8 pt-2">
            <span className="text-color-grey text-sm">
              Open-ended AI behavior on freeform steps may not always be
              accurate. Reviewing results for key cases is always a good
              practice.
            </span>
          </div>
        </div>
      </div>
    </NonImageNodeWrapper>
  );
}
