"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.sentryScope = exports.extensionBrowserOptions = exports.defaultBrowserOptions = exports.handleException = exports.SentryBrowser = void 0;
const browser_1 = require("@sentry/browser");
exports.SentryBrowser = __importStar(require("@sentry/browser"));
var exception_handler_1 = require("./exception-handler");
Object.defineProperty(exports, "handleException", { enumerable: true, get: function () { return exception_handler_1.handleException; } });
const defaultBrowserOptions = (environment = process.env.NODE_ENV) => {
    const sentryTarget = process.env.REACT_APP_SENTRY_DSN || process.env.SENTRY_DSN;
    if (environment === 'production') {
        if (!sentryTarget) {
            throw new Error(`Missing SENTRY_DSN environment variable in production environment`);
        }
    }
    return {
        dsn: sentryTarget,
        environment,
    };
};
exports.defaultBrowserOptions = defaultBrowserOptions;
const extensionBrowserOptions = (environment = process.env.NODE_ENV) => {
    const sentryTarget = process.env.PLASMO_PUBLIC_SENTRY_DSN || process.env.SENTRY_DSN;
    if (environment === 'production') {
        if (!sentryTarget) {
            throw new Error(`Missing SENTRY_DSN environment variable in production environment`);
        }
    }
    const integrations = (0, browser_1.getDefaultIntegrations)({}).filter((defaultIntegration) => {
        return ![
            'BrowserApiErrors',
            'TryCatch',
            'Breadcrumbs',
            'GlobalHandlers',
        ].includes(defaultIntegration.name);
    });
    return {
        dsn: sentryTarget,
        autoSessionTracking: false,
        environment,
        integrations,
        transport: browser_1.makeFetchTransport,
        stackParser: browser_1.defaultStackParser,
    };
};
exports.extensionBrowserOptions = extensionBrowserOptions;
const sentryScope = (opts) => {
    const client = new browser_1.BrowserClient(opts);
    const scope = new browser_1.Scope();
    scope.setClient(client);
    return scope;
};
exports.sentryScope = sentryScope;
